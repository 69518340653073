import { DataTable } from "primereact/datatable";
import { BattleIcon, CancelIcon, ConfirmIcon } from "../../SVGIcon";
import { baseURL } from "../../config";
import StockComponent from "./StockComponent";
import { Column } from "primereact/column";

const ComparisonDueler = ({
  duelersList, duelInfo, duelmode, isJoined, anotherSymbolsList, show, multiUser, exchangeID1, swapSymbol,
  setShowFirst, setShowSecond, showFirst, showSecond, handlestandardBattleList, setAllDuelers, selected, setSelected
  , isEditing, setisEditing, handleEdit, isSwitch, handleSwapDuel, loading, setswapSymbol, handlecancel, setbattleSelected, battleselected,
  getBattleSymbolSwappingId, reSet, setreSet, handlegetEditCoinsAPI, setShowBlocker, showBlocker,
  setSelectedBlocker, selectedBlocker, handlegetBlockerBattle, Trader, tipHide }) => {

  const logoBodyTemplate = (rowData) => {
    return (
      <>{rowData.logo ? (<img src={rowData.logo} className="img-fluid img-coins" />) : (<img src='/images/bitcoin-icon.png' />)}</>
    )
  }

  const SymbolBodyTemplate = (rowData) => {
    return (
      <>
        <p className="mb-0 duel-heading cfs-6">{rowData.base_symbol}</p>
      </>
    )
  }

  const BattleBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.battle_person_short_name ? <button className={`btn btn-battle-apply disabled`}>{rowData.battle_person_short_name}</button> : ''}
      </>
    )
  }
  const BattleBlockerBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.blocker_person_name === "blocker" && <img src='https://api.moonleague.com/images/email_template/blocker.png' />}


      </>
    )
  }

  const priceBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.percentage_change !== '' ? (
          <>  {rowData.percentage_change >= 0 ? <>{rowData.percentage_change === '0' ? <p className="text-warning fw-bold cfs-6 mb-0">{rowData.percentage_change}</p> : <p className="green-text fw-bold cfs-6 mb-0">{rowData.percentage_change}%</p>}</>
            : <p className="text-danger fw-bold cfs-6 mb-0">{rowData.percentage_change}%</p>}</>)

          : "-"

        }

      </>
    )
  }
  const nameBodyTemplate = (rowData) => {

    return (
      <>

        {reSet && rowData?.base_symbol_id === battleselected?.base_symbol_id ? <>
          <div className="d-flex justify-content-evenly align-items-center">
            <button className="btn duel-btn p-2 mx-2" onClick={() => { getBattleSymbolSwappingId(rowData) }}><ConfirmIcon /></button>
            <button className="btn  duel-btn cancelIcon p-2" onClick={() => { setreSet(false); setbattleSelected(false) }}><CancelIcon style={{ stroke: "#dc3545!important" }} /></button>
          </div>
        </> : <button className={`btn duel-btn p-2 ${rowData?.base_symbol_id === battleselected?.base_symbol_id ? "btn-swap" : 'btn-primary'}`} onClick={() => { setbattleSelected(rowData); }}><BattleIcon /></button>}

      </>
    )
  }

  const rowClass = (rowData) => {
    return {
      'bg-clicked': rowData.base_symbol_id === battleselected?.base_symbol_id
    };
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold text-primary">{anotherSymbolsList?.length}/9</span>
    </div>
  );


  return (
    <>

      <div className="card card-custom gutter-b px-0">
        <div className="custom-card-header">
          <div className="d-flex justify-content-end card-title mb-0">
            <div className="mx-auto ">
              <h4 className="text-uppercase custom-heading">Comparison </h4>
            </div>
          </div>
          <div className="bg-blue d-flex justify-content-evenly p-3">
          {duelersList.length === 0 ? "":  <>  {Trader === "Day Trader" && (duelmode === "Standard & Battle" || duelmode === "Bloodbath & Battle" || duelmode === "Standard" || duelmode === "Bloodbath")
              ? <button className={`${duelmode === "Standard & Battle" || duelmode === "Bloodbath & Battle" ? "btn pink-text btn-outline fs-5 btn-rounded" : "btn pink-text btn-outline fs-5 btn-rounded w-100"} ${showFirst ? "btn-selected btn btn-rounded fs-5" : ""} tablet-mode-btn`} onClick={() => {
                setShowFirst(!showFirst);
                setShowSecond(false);
                handlegetEditCoinsAPI()
              }}>Trade</button>
              : null
            }

            {duelmode === "Standard & Battle" || duelmode === "Bloodbath & Battle"
              ? <button
                className={`${Trader === "Classic" && (duelmode === "Standard & Battle" || duelmode === "Bloodbath & Battle") ? "btn pink-text btn-outline fs-5 btn-rounded w-100" : "btn pink-text btn-outline fs-5 btn-rounded"} ${showSecond ? "btn-selected btn btn-rounded fs-5" : ""} tablet-mode-btn`}
                onClick={() => {
                  setShowSecond(!showSecond);
                  setShowFirst(false);
                  handlestandardBattleList();
                }}> Battle</button>
              : null
            }
            </>
            }

          </div>

          <div className="">
            <div className="d-flex w-100 comp-height">
              {(isJoined === 1) && !show ? (
                <>
                  <div className="w-100 shadow">
                    <div className="bg-gray-back p-3">
                      <div className="d-flex justify-content-center align-items-center">

                        {duelInfo?.user_avatar ? (
                          <>
                            <img
                              src={duelInfo?.user_avatar}
                              className="img-fluid img-symbols"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src="https://api.moonleague.com/images/email_template/user.png"
                              className="img-fluid img-symbols"
                            />
                          </>
                        )}

                        <h5 className="mb-0 mt-0 ms-1">{duelInfo?.user_name}</h5>
                      </div>
                    </div>



                    <StockComponent duelersList={duelersList} duelInfo={duelInfo} swapSymbol={swapSymbol}
                      setAllDuelers={setAllDuelers} selected={selected} setSelected={setSelected}
                      isEditing={isEditing} setisEditing={setisEditing} handleEdit={handleEdit} isSwitch={isSwitch}
                      handleSwapDuel={handleSwapDuel} loading={loading} setswapSymbol={setswapSymbol} handlecancel={handlecancel}
                      reSet={reSet} setreSet={setreSet} setbattleSelected={setbattleSelected} setShowBlocker={setShowBlocker} showBlocker={showBlocker}
                      setSelectedBlocker={setSelectedBlocker} selectedBlocker={selectedBlocker} handlegetBlockerBattle={handlegetBlockerBattle}
                      show={show} exchangeID1={exchangeID1} Trader={Trader} tipHide={tipHide}/>
                  </div>
                </>
              ) : <> {selected?.join_duel_symbol_id
                ? <>
                  <div className="w-100 shadow">
                    <div className="bg-gray-back p-3">
                      <div className="d-flex justify-content-center align-items-center">
                        {duelInfo?.user_avatar ? (
                          <>
                            <img
                              src={duelInfo?.user_avatar}
                              className="img-fluid img-symbols"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src="https://api.moonleague.com/images/email_template/user.png"
                              className="img-fluid img-symbols"
                            />
                          </>
                        )}

                        <h5 className="mb-0 mt-0 ms-1">{duelInfo?.user_name}</h5>
                      </div>
                    </div>
                    <StockComponent duelersList={duelersList} duelInfo={duelInfo}
                      setAllDuelers={setAllDuelers} selected={selected} setSelected={setSelected}
                      isEditing={isEditing} setisEditing={setisEditing} handleEdit={handleEdit} isSwitch={isSwitch}
                      handleSwapDuel={handleSwapDuel} loading={loading} setswapSymbol={setswapSymbol} handlecancel={handlecancel}
                      setbattleSelected={setbattleSelected} setShowBlocker={setShowBlocker} showBlocker={showBlocker}
                      setSelectedBlocker={setSelectedBlocker} selectedBlocker={selectedBlocker} handlegetBlockerBattle={handlegetBlockerBattle}
                      show={show} exchangeID1={exchangeID1} Trader={Trader} tipHide={tipHide} />
                  </div>
                </>
                : <>{!battleselected?.base_symbol_id ? (<div className="w-50 shadow" style={{ paddingRight: "3px" }}>
                  <div className="bg-gray-back p-3">
                    <div className="d-flex justify-content-center align-items-center">
                      {duelInfo?.user_avatar ? (
                        <>
                          <img
                            src={duelInfo?.user_avatar}
                            className="img-fluid img-symbols"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src="https://api.moonleague.com/images/email_template/user.png"
                            className="img-fluid img-symbols"
                          />
                        </>
                      )}

                      <h5 className="mb-0 mt-0 ms-1">{duelInfo?.user_name}</h5>
                    </div>
                  </div>

                  <StockComponent duelersList={duelersList} duelInfo={duelInfo} swapSymbol={swapSymbol}
                    setAllDuelers={setAllDuelers} selected={selected} setSelected={setSelected}
                    isEditing={isEditing} setisEditing={setisEditing} handleEdit={handleEdit} isSwitch={isSwitch}
                    handleSwapDuel={handleSwapDuel} loading={loading} setswapSymbol={setswapSymbol} handlecancel={handlecancel}
                    setbattleSelected={setbattleSelected} setShowBlocker={setShowBlocker} showBlocker={showBlocker}
                    setSelectedBlocker={setSelectedBlocker} selectedBlocker={selectedBlocker} handlegetBlockerBattle={handlegetBlockerBattle}
                    show={show} exchangeID1={exchangeID1} Trader={Trader} tipHide={tipHide}/>
                </div>) : null}</>
              }</>
              }

              {show && !selected?.join_duel_symbol_id
                ? (<>

                  <div
                    className={`${isJoined === 1
                      ? "w-50 shadow "
                      : "w-100 shadow"
                      } ${battleselected?.base_symbol_id && "w-100 shadow"}`}
                  >
                    <div className="d-flex w-100 comp-height">
                      <div className="w-100 shadow">

                        <div className="bg-gray-back p-3">
                          <div className="d-flex justify-content-center align-items-center">
                            {multiUser?.user_avatar !== "" ? (<>
                              <img src={`${baseURL}images/avatar/${multiUser?.user_avatar}`} className="img-fluid img-symbols" />

                            </>) : (<>
                              <img src="https://api.moonleague.com/images/email_template/user.png" className="img-fluid img-symbols" />

                            </>)}

                            <h5 className="mb-0 mt-0 ms-1">{multiUser?.user_name}</h5>
                          </div>
                        </div>
                        <div className="custom-active">
                          <DataTable
                            value={anotherSymbolsList} rows={10}
                            loading={loading}
                            tableStyle={{ minHeight: '31.8rem', minWidth: "1rem" }}
                            emptyMessage="There is no symbol data available yet!"
                            rowClassName={rowClass}
                            body={(options) => options.rowIndex}>
                            <Column field="logo" body={logoBodyTemplate} style={{ width: "12%" }}></Column>
                            <Column field="base_symbol"
                              header="Symbol"
                              body={SymbolBodyTemplate} ></Column>
                            {duelmode === "Standard & Battle" || duelmode === "Bloodbath & Battle" ? <Column field={"battle_person_short_name"}
                              header="Battle"
                              body={BattleBodyTemplate} ></Column> : ''}
                            {duelmode === "Standard & Battle" || duelmode === "Bloodbath & Battle" ? <Column field="blocker_person_name"
                              body={BattleBlockerBodyTemplate} header="Blocker"></Column> : ''}
                            <Column field="tier" header="Tier"
                            ></Column>
                            <Column
                              body={priceBodyTemplate} header="%"
                            >

                            </Column>
                            {duelmode === "Standard & Battle" || duelmode === "Bloodbath & Battle" ? <Column body={nameBodyTemplate} header={header} style={{ width: "25%" }}></Column> : ''}
                          </DataTable>
                        </div>
                        <div className="text-black cfs-6 fw-bold mb-0 d-flex justify-content-center py-4 compare-screen">Score:

                          {multiUser?.score >= "0.00" ? <>{multiUser?.score === "0.00" ? <p className="text-warning fw-bold cfs-6 mb-0 ms-2"> {multiUser?.score} </p>
                            : <p className="green-text fw-bold cfs-6 mb-0 ms-2">{multiUser?.score}</p>}</> : <p className="text-danger fw-bold cfs-6 mb-0 ms-2"> {multiUser?.score} </p>}

                        </div>
                      </div>
                    </div>
                  </div>

                </>
                ) : null}


            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ComparisonDueler;
