import React from 'react';
import { Dropdown } from 'primereact/dropdown';
const SlotList = ({getDuelSlot,selectedOption,setSelectedOption}) => {
  const items = getDuelSlot?.map((_,i) => (  { label: `Slot ${_.slot}`, value: _.slot }));
  return (
    <>


        <div className="card flex justify-content-center">
              <Dropdown value={selectedOption} onChange={(e) => setSelectedOption(e.value)} options={items} 
                placeholder="Slot 1" className="w-full md:w-14rem" />
        </div>
    </>
  )
}

export default SlotList