import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Link, useNavigate,Navigate } from "react-router-dom";
import useForm from "./useForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Redux call
import { login, userSelector } from "../../slices/authSlice";

const Login = () => {
  //call validation form
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    togglePasswordVisiblity,
    passwordShown,
  } = useForm(() => loginUserApi(values));
  const eye = <FontAwesomeIcon icon={faEye} />;

  //API Call
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((state) => state.auth);
  
  const { isFetching} = useSelector(userSelector);


  const loginUserApi = async (values) => {  
    try {
      const res = await dispatch(login(values)).unwrap();
      if (res.status_code === 200 && res.status === true) {
       // console.log(res,'Login res');
        window.localStorage.setItem("userToken", res.data.token);
        window.localStorage.setItem("userid", res.data.id);
        window.localStorage.setItem("username",res.data.user_name);
        toast.success(res.message);
          setTimeout( () => {
      navigate("/dashboard");
     },1500)
       
      
      } 
    } catch (error) {
      toast.error(error.message);
      console.log("error tr", error);
    }

 
  };
  
  let getToken =  window.localStorage.getItem("userToken");
  if (getToken) {
                
    return <Navigate to="/dashboard" />;
  } else {
  
     <Navigate to="/" />;
    
  }

  return (
    <>
      <div className="vid-container">
        <video muted autoPlay loop className="video">
          <source src="images/Moonleague.webm" type="video/webm" />
        </video>login-form

        <div className="login-form">
          <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4">
            <div className="form-box">
              <div className="logo">
                <img
                  src="images/logo-white.png"
                  className="img-fluid img-responsive"
                  alt="moon logo"
                />
              </div>

              <div className="text-center">
                <h3 className="create-account mt-2">Sign In to Moon Duel</h3>
                {/* <div className="sign-font">
                  New Here?&nbsp;&nbsp;{" "}
                  <Link to="/register">Create an Account</Link>
                </div> */}
              </div>
              <Form className="mt-4" onSubmit={handleSubmit} noValidate>
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="formlabel">Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    className="form-input"
                    name="email"
                    autoComplete="email"
                    value={values.email || ""}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div
                      className="help text-danger text-start"
                      aria-live="assertive"
                    >
                      {errors.email}
                    </div>
                  )}
                </Form.Group>

                <Form.Group
                  className="mb-3 text-start password-input"
                  controlId="formBasicPassword"
                >
                  <Form.Label className="formlabel">Password</Form.Label>
                  <Form.Control
                  
                    placeholder="Password"
                    className="form-input"
                    autoComplete="new-password"
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    value={values.password || ""}
                    onChange={handleChange}
                  />
                  <div
                    className={`font-icons ${faEyeSlash}`}
                    onClick={togglePasswordVisiblity}
                  >
                    <FontAwesomeIcon
                      icon={passwordShown ? faEye : faEyeSlash}
                    />
                  </div>
                  {errors.password && (
                    <div
                      className="help text-danger text-start "
                      aria-live="assertive"
                    >
                      {errors.password}
                    </div>
                  )}
                </Form.Group>
                <div className="text-end">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3 custom-btn"
                  size="lg"
                >
                  Submit
                </Button>

                 {isFetching ? (<><p className="text-info">Please wait</p></>) : ""}
              </Form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Login;
