import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React from 'react'
import { PlusIcon } from '../../SVGIcon'

const TradeAddList = ({ header, priceBodyTemplate, SymbolBodyTemplate, logoBodyTemplate, filterResult }) => {


  const nameBodyTemplate = () => {
    return (
      <>
        <button className="btn  duel-btn p-2" id="65"><PlusIcon /></button>

      </>
    )
  }


  return (
    <>
      <div className="custom-active">
        <DataTable
          value={filterResult} rows={10}
          header={header}
          scrollable
          scrollHeight="569px"

          emptyMessage="No symbols found."
        >

          <Column field="logo" body={logoBodyTemplate} ></Column>
          <Column field="base_symbol"
            header="Symbol"
            body={SymbolBodyTemplate}></Column>
          <Column field="tier" header="Tier"></Column>
          <Column
            body={priceBodyTemplate} header="Price"></Column>
          <Column
            body={nameBodyTemplate}></Column>



        </DataTable>
      </div>
    </>
  )
}

export default TradeAddList