import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import HotPickComponent from "./HotPickComponent";
import { AcceptRejectFrndAPI, CancelSendRequestAPI, FollowUserAPI, UnFollowUserAPI, UnfriendAPI, addfriendReuestsAPI, friendReuestsReceviedAPI, friendReuestsSentAPI, getFollowersAPI, getFollowingUserAPI, getFriendListAPI, hotPlayerList } from "../../slices/userSlice";



const HotPicks = () => {
  const dispatch = useDispatch()
  const [hot, setHot] = useState([]);
  let locacluserid = window.localStorage.getItem("userid");

  const [followingList, setFollowingList] = useState([]);
  const [followingCount, setFollowingCount] = useState();
  const [followersList, setFollowersList] = useState([]);
  const [followersCount, setFollowersCount] = useState();
  const [friendList, setFrindList] = useState([]);
  const [friendCount, SetFriendCount] = useState();
  const [friendRequestRcvdList, SetFriendRequestRcvdList] = useState([]);
  const [friendRequestRcvdCount, SetFriendRequestRcvdCount] = useState(0);
  const [friendRequestSentList, SetFriendRequestSentList] = useState([]);
  const [friendRequestSentCount, SetFriendRequestSentCount] = useState(0);
  const [user, setUser] = useState([]);


  const handleAllUser = () => {
    dispatch(hotPlayerList()).unwrap()
      .then((res) => {
        setHot(res.data);
      })
  }
  useEffect(() => {
    handleAllUser();
  }, [dispatch]);

  const handleFriendAPI = async () => {
    await dispatch(getFriendListAPI({ userId: locacluserid }))
      .unwrap()
      .then((res) => {

        setFrindList(res?.data);
        SetFriendCount(res?.friends_count);
      })
  }

  useEffect(() => {
    handleFriendAPI();
  }, [])

  //request_received_count


  const handleFriendRequestsRcvd = async () => {
    await dispatch(friendReuestsReceviedAPI({ userId: locacluserid }))
      .unwrap()
      .then((res) => {

        SetFriendRequestRcvdList(res?.data);
        SetFriendRequestRcvdCount(res?.request_received_count);
      })
  }
  useEffect(() => {
    handleFriendRequestsRcvd();
  }, [])

  //SetFriendRequestSentList ,SetFriendRequestSentCount

  const handleFriendRequestsSent = async () => {
    await dispatch(friendReuestsSentAPI({ userId: locacluserid }))
      .unwrap()
      .then((res) => {

        SetFriendRequestSentList(res?.data);
        SetFriendRequestSentCount(res?.request_sent_count);
      })
  }
  useEffect(() => {
    handleFriendRequestsSent();
  }, [])

  //Send Friend Requests
  //  friendReuestsSentAPI

  const handleSendRequest = (friend_id) => {
    let followId = friend_id;
    let SendfrndData = {
      loggedin_user_id: locacluserid,
      friend_id: followId

    }

    dispatch(addfriendReuestsAPI({ SendfrndData })).unwrap()
      .then((res) => {
        let newData = res.data;
        handleFriendRequestsRcvd();
        handleFriendRequestsSent();
        handleAllUser();
      }).catch((err) => console.log(err));
  }




  //UnfriendAPI

  const handleUnfriend = (friend_id) => {
    let unfrndID = friend_id;
    let unfrndData = {
      loggedin_user_id: locacluserid,
      friend_id: unfrndID
    }

    dispatch(UnfriendAPI({ unfrndData })).unwrap()
      .then((res) => {
        let newData = res.data;
        handleAllUser();
        handleFriendAPI();

      }).catch((err) => console.log(err));
  }


  //confirm accept frnd
  const handleCnfRjcFrnd = (friend_id, isconfirrm) => {
    let frndId = friend_id;
    let isConfirm = isconfirrm;
    let frndConfirmData = {
      loggedin_user_id: locacluserid,
      friend_id: frndId,
      is_request_accepted_or_cancelled: isConfirm
    }
    // AcceptRejectFrndAPI
    dispatch(AcceptRejectFrndAPI({ frndConfirmData })).unwrap()
      .then((res) => {
        let newData = res.data;
        handleAllUser();
        handleFriendAPI();

        handleFriendRequestsRcvd();

      }).catch((err) => console.log(err));
  }


  //cancel send request
  //CancelSendRequestAPI

  const handleCancelRequest = (friend_id) => {
    let frndId = friend_id;
    let cancelfrndRequestData = {
      loggedin_user_id: locacluserid,
      friend_id: frndId
    }

    dispatch(CancelSendRequestAPI({ cancelfrndRequestData })).unwrap()
      .then((res) => {
        let newData = res.data;
        handleAllUser();
        handleFriendAPI();
        //  handleFriendRequestsRcvd();
        handleFriendRequestsSent();
        // console.log("friend API");

      }).catch((err) => console.log(err));
  }

  //Get Followed BY


  const handleAllFollowers = async () => {

    await dispatch(getFollowersAPI({ userId: locacluserid }))
      .unwrap()
      .then((res) => {

        setFollowersList(res?.data);
        setFollowersCount(res?.followers_count);
      })
  }


  const handleAllFollowing = async () => {
    await dispatch(getFollowingUserAPI({ userId: locacluserid }))
      .unwrap()
      .then((res) => {
        setFollowingList(res?.data);
        setFollowingCount(res?.following_user_count);
      })


  }

  useEffect(() => {

    handleAllFollowing();

  }, [])

  //Followers User Card

  useEffect(() => {

    handleAllFollowers();

  }, [])
  //Follow User 
  const handleFollow = (follow_user_id) => {
    //return false;
    let followId = follow_user_id;
    let FollowUserData = {
      user_id: locacluserid,
      following_user_id: followId
    }


    dispatch(FollowUserAPI({ FollowUserData })).unwrap()
      .then((res) => {
        let newData = res.data;
        handleAllUser();
        handleAllFollowing();
        handleAllFollowers();
      }).catch((err) => console.log(err));

  }



  const handleUnFollow = (follow_user_id) => {
    //return false;
    let followId = follow_user_id;
    let UnFollowUserData = {
      user_id: locacluserid,
      following_user_id: followId
    }

    dispatch(UnFollowUserAPI({ UnFollowUserData })).unwrap()
      .then((res) => {
        let newData = res.data;
        handleAllUser();
        handleAllFollowing();
        handleAllFollowers();
      }).catch((err) => console.log(err));
  }

  return (
    <>
      <HotPickComponent hot={hot} handleUnfriend={handleUnfriend} handleSendRequest={handleSendRequest}
        handleCancelRequest={handleCancelRequest} handleCnfRjcFrnd={handleCnfRjcFrnd} handleFollow={handleFollow} handleUnFollow={handleUnFollow} />
    </>
  )
}
export default HotPicks