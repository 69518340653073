import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
// import authService from "../../../services/auth-services";
import { getUserDetails, userInfolist } from "../../../slices/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../slices/authSlice";
// import Dropdown from "react-bootstrap/Dropdown";
import { Container, Dropdown, DropdownButton } from 'react-bootstrap';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getNotificationsAPI, resetNotificationsAPI } from "../../../slices/notificationSlice";
const Navbar = () => {
  const [userContent, setuserContent] = useState({});
  const [notify, setNotify] = useState([]);
  const [reset, setReset] = useState();



  const dispatch = useDispatch();
  let locacluseid = window.localStorage.getItem("userid");
  const { userInfolist } = useSelector((state) => state.userProfile);
  const [notificationCount, setNotification] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAllNotify = async () => {
    await dispatch(getNotificationsAPI({ userId: locacluseid }))
      .unwrap()
      .then((res) => {
        let notifylist = res?.notification;
        setNotification(res?.notification_count)
        setNotify(notifylist);

      })
      .catch((err) => console.log(err.message));
  };

  const handleResetNotify = async () => {
    setLoading(true)
    await dispatch(resetNotificationsAPI({ userId: locacluseid }))
      .unwrap()
      .then((res) => {
        let resetAll = res;
        setReset(resetAll);
        setNotify('');
        setLoading(false);
        // console.log(reset,"resetreset",resetAll);
      })
      .catch((err) => console.log(err.message));
  };


  useEffect(() => {
    handleAllNotify();
  }, []);



  const formatDate = (value) => {
    return new Date(value).toLocaleDateString('en-US',
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.created_at);
  };

  useEffect(() => {

    dispatch(getUserDetails({ userId: locacluseid }))
      .unwrap()
      .then((res) => {
        // console.log(res.data, " userResponse userResponse");
        setuserContent(res?.data);
      });

  }, [dispatch]);

  //Log Out


  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <>
      <div className="main-background">
        <div className=" " id="container-main">
          <nav className="nav-main">
            <ul className="mb-0 ps-md-0 px-2">
              <li>
                <NavLink to="/">
                  <img
                    src="/images/logo-white.png"
                    className="img-fluid w250"
                    alt="moonleague logo"
                  />

                </NavLink>
              </li>
              <ul className="mb-0 ps-0 profile-nav">
                <li>
                  <NavLink to="/profile">
                    <span className="userImage">
                      {userContent?.avatar ? <img src={userContent?.avatar} className="img-fluid" alt="userProfile" /> : <img
                        src="https://api.moonleague.com/images/email_template/user.png"
                        className="img-fluid "
                        alt="profile-Image"
                      />}


                    </span>
                    <span className="profilename-block text-capitalize">{userContent?.user_name} </span>
                  </NavLink>
                </li>

                <li className="notify">
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        background: "#020838",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <img src="/icon/bell.png" className="img-fluid" />
                      {notify?.length > 0 ? <span className="badge badge-count">{notificationCount}</span> : null}

                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="notify-scroll">
                        <div className="row border-bottom">
                          <div className="col-6 d-flex justify-content-start"><h5 className="text-center m-3 fs-6 ">Notifications</h5></div>
                          <div className="col-6 d-flex justify-content-end">
                            <button className="btnclear">
                              <h5 className="text-center m-3 text-danger fs-6" onClick={handleResetNotify}>CLEAR ALL</h5></button>
                          </div>

                        </div>


                        {loading ? <><p className='pink-text fw-semibold ms-3'>Please wait...</p></>
                          : <> {notificationCount > 0 && notify?.length > 0 ? notify.slice(0, 4).map((item, index) => {
                            return (
                              <Dropdown.Item key={index}>

                                <div className="row d-flex justify-content-between border-bottom height-noti">
                                  <div className="col-md-6">
                                    <span className="notify-text d-inline-block text-truncate fs-6" style={{ maxWidth: "250px" }}>{item.description}</span>{" "}
                                  </div>
                                  <div className="col-md-6 d-flex justify-content-end">
                                    <span className="timenotify">
                                      {new Date(
                                        item.created_at
                                      ).toLocaleDateString()}
                                    </span>
                                  </div>
                                </div>

                              </Dropdown.Item>
                            );
                          }) : <span className="mt-5 fs-6">No new notification</span>


                          }</>
                        }
                      </div>

                      <NavLink to="/notification">
                        <div className=" row mt-1 mb-1">
                          <div className="col-md-12 d-flex justify-content-center">
                            <button className="btn btn-primary text-center  ">
                              View More
                            </button>

                          </div>
                        </div>
                      </NavLink>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li>
                  <NavLink to="/wallet">
                    <img src="/icon/wallet.png" className="img-fluid" />
                  </NavLink>
                </li>
                {/* <li>
                <NavLink to="/" onClick={handleLogout}>
                  Log Out
                </NavLink>
                </li> */}
              </ul>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
export default Navbar;
