import React, {useState,useEffect} from 'react'
import Navbar from '../MainLayout/Navbar/Navbar'
import Header from '../MainLayout/Header/Header'
import Footer from '../MainLayout/Footer/Footer'
import { useDispatch } from 'react-redux'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { getNotificationsAPI } from '../../slices/notificationSlice'

const Notification = () => {
  const [notify, setNotify] = useState([]);
  const dispatch = useDispatch();
  let locacluseid = window.localStorage.getItem("userid");

  const handleAllNotify = async () => {
    await dispatch(getNotificationsAPI({ userId: locacluseid }))
      .unwrap()
      .then((res) => {
        let notifylist = res?.notification;
        setNotify(notifylist);
      })
      .catch( (err) => console.log(err.message));
  };

  useEffect(() => {
    handleAllNotify();
  }, []);
 
  const formatDate = (value) => {
    return new Date(value).toLocaleString('en-US',    
 
    );
};

const dateBodyTemplate = (rowData) => {
  return formatDate(rowData.created_at);
};

  return (
    <>
    <Navbar />
      <div className="main-layout" style={{ marginBottom: '70px' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="mb-3">
              <Header />
            </div>
            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
            <div className='card-custom notify-height'>
            <div className='d-flex justify-content-end card-title'>
              <div className='mx-auto '><h4 className='text-center text-uppercase custom-heading'>Notification</h4></div>
            
            </div>

            <DataTable value={notify} scrollable scrollHeight="475px" rows={10} dataKey="id"  emptyMessage="No Notification" className='notifications-datatable'>
                <Column style={{ width: '1%' }} className='notification-first-column'></Column >
                <Column field="description" header="Description" style={{ width: '25%' }}></Column>
                <Column style={{ width: '13%' }} className='notification-first-column'></Column>
                <Column field="created_at" header="Date" style={{ width: '25%', verticalAlign:"top" }} body={dateBodyTemplate}></Column>
            </DataTable>
         
            </div>
            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Notification