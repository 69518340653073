import Footer from "../MainLayout/Footer/Footer"
import Header from "../MainLayout/Header/Header"
import Navbar from "../MainLayout/Navbar/Navbar"
import ProfileDashboard from "../Profile/ProfileDashboard"
import PlayGame from "./PlayGame"
import { useLocation } from "react-router-dom"

const MainGameComponent = () => {

    const location = useLocation();
    return (
        <>
            <Navbar />
            <div className='main-layout'>

                <div className='row mx-0 mb-3'>
                    <Header />
                </div>
                <div className='row mb-3'>
                    <ProfileDashboard />
                </div>
                <div className="row mb-3 mx-0">
                    <PlayGame  getlocation={location}/>
                </div>
            </div>

            <Footer />
        </>
    )
}
export default MainGameComponent