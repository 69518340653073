import React, { useEffect, useState, useCallback, forwardRef } from "react";
import {
  Nav,
  Tab,
  Row,
  Col,
  Container,
  Form,
  Button,
  FormControl,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getUserDetails, editProfile } from "../../slices/profileSlice";
import moment from "moment";
import "moment-timezone";
import DatePicker from "react-datepicker";
import { subYears } from "date-fns";
import UserProfileForm from "./UserProfileForm";
import { logout } from "../../slices/authSlice";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { changeLocation } from "../../util/common";
import PhoneInput from "react-phone-input-2";
import { ToastContainer } from "react-toastify";
import { ChangepasswordIcon, EditProfile, KycVerificationIcon, LogoutIcon, UserIcon } from "../../SVGIcon";
import VerificationComponent from '../KYC/VerificationComponent'
import Users from "./Users";

const Profile = () => {
  let locacluserid = window.localStorage.getItem("userid");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    const getUserAPI = async () => {
      await dispatch(getUserDetails({ userId: locacluserid }))
        .unwrap()
        .then((res) => {

          setUserDetails(res?.data);
        });
    };

    getUserAPI();
  }, [dispatch]);

  const {
    errors,
    handleEdit,
    setEditprofileData,
    editProfileData,
    handleChangeRaw,
    setStartDate,
    startDate,
    valid,
    handleFileSelect,
    imageUploader,
    uploadedImage,
    getFileName,
    setGetFileName,
    selectedFile,
    setSelectedFile,
    getupolad,
    setUpload,
    dobVal,
    handlePrfoile,
    handleMobile,
    cnfPasswordShown,
    newPasswordShown,
    oldPasswordShown,
    toggleConfirmPassword,
    togglePasswordVisiblity,
    oldPasswordVisiblity,
    handleChangePassword, updatePassword, handlePassword
  } = UserProfileForm(() => handleSubmit());

  //Log Out

  const handleLogout = useCallback(() => {
    dispatch(logout());
    navigate("/");
  }, [dispatch]);

  //Custum Datepicker ICON

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <div onClick={onClick}>
        <input
          placeholder="mm/dd/yyyy"
          value={value}
          className={`${valid ? "form-control " : "form-control is-invalid"}`}
          onChange={onChange}
          ref={ref}
        ></input>
        <span className="example-custom-input">
          <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
        </span>
      </div>
    </>
  ));

  //const Update form
  let mobileVal;

  if (editProfileData.mobile === "") {
    mobileVal = userDetails.phone.substring(0);
  } else {
    mobileVal = editProfileData.phone;

  }

  //Uplaod Image

  let updatedImage;
  if (selectedFile !== undefined) {
    updatedImage = selectedFile;
  } else {
    updatedImage = "";
  }

  //FormValues
  let formValues = {
    first_name: editProfileData.first_name,
    last_name: editProfileData.last_name,

    mobile_no: editProfileData.phone,

    birth_date: dobVal,

    avatar: updatedImage,
    zip: editProfileData.zip,
    address_1: editProfileData.address_1,
  };

  const handleSubmit = async () => {
    await dispatch(editProfile({ formValues, userId: locacluserid }))
      .unwrap()
      .then((res) => {
        changeLocation("/profile", navigate);
      })
      .catch((err) => {
        console.log(err, "errors");
      });
  };


  return (
    <>
      <div className="mb-3 min-vh">
        <Tab.Container id="left-tabs-example" defaultActiveKey="editprofile">
          <Row>

            <Col xxl={3} xl={3} lg={12}>
              <Nav
                variant="pills"
                className="flex-md-row flex-xl-column uprofile-sidebar justify-content-between justify-content-lg-between justify-content-xl-start "
              >
                <div className="profile-show profile-sm d-none d-xl-block mb-2">
                  {userDetails?.avatar ? (
                    <>
                      <img
                        src={userDetails?.avatar}
                        className="img-fluid"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src="https://api.moonleague.com/images/email_template/user.png"
                        className="img-fluid"
                      />
                    </>
                  )}

                  <p className="mb-0">{userDetails?.user_name}</p>
                </div>



                <Nav.Item>
                  <Nav.Link eventKey="editprofile">
                    {" "}
                    <span className="me-md-0 me-lg-3">
                      <EditProfile />
                    </span>
                    <span className="user-menu">Edit Profile</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="changepswrd">
                    {" "}
                    <span className="me-md-0 me-lg-3">
                      <ChangepasswordIcon />
                    </span>
                    <span className="user-menu">Change Password</span>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="kycVerification">
                    <span className="me-md-0 me-lg-3">
                      <KycVerificationIcon />


                    </span>
                    <span className="user-menu">KYC Verification</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="usersProfile">
                    <span className="me-md-0 me-lg-3">
                      <UserIcon />
                    </span>
                    <span className="user-menu"> Users</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="lastchilduser" onClick={handleLogout}>
                  <Nav.Link eventKey="logout">
                    <span className="me-md-0 me-lg-3">
                      <LogoutIcon />
                    </span>
                    <span className="user-menu"> Logout</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col xxl={9} xl={9} lg={12} className="mt-xl-0 mt-lg-3 mt-md-3">
              <Tab.Content>



                <Tab.Pane eventKey="editprofile">
                  <div className="editForm-sec">
                    <h5 className="editForm-head">Edit Profile</h5>
                    <div className="editForm-body">
                      <Container>
                        <Form
                          autoComplete="off"
                          noValidate
                          onSubmit={handlePrfoile}
                        >
                          {/* 1st Row */}
                          <Row className="mt-4">
                            <Col lg={4} xl={4} md={6}>
                              <Form.Group className="mb-4">
                                <Form.Label>User Name </Form.Label>

                                <FormControl
                                  type="text"
                                  placeholder="Username"
                                  autoComplete="off"
                                  id="username"
                                  name="username"
                                  required
                                  value={userDetails?.user_name}
                                  disabled
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={4} xl={4} md={6}>
                              <Form.Group className="mb-4">
                                <Form.Label>First Name </Form.Label>

                                <FormControl
                                  type="text"
                                  placeholder="First Name"
                                  autoComplete="off"
                                  id="fname"
                                  name="first_name"
                                  required
                                  value={editProfileData.first_name}
                                  onChange={handleEdit}
                                />
                                {errors.first_name && (
                                  <div
                                    className="help text-danger text-start"
                                    aria-live="assertive"
                                  >
                                    {errors.first_name}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={4} xl={4} md={6}>
                              <Form.Group className="mb-4">
                                <Form.Label>Last Name </Form.Label>

                                <FormControl
                                  type="text"
                                  placeholder="Last Name"
                                  autoComplete="off"
                                  id="lname"
                                  name="last_name"
                                  required
                                  value={editProfileData.last_name}
                                  onChange={handleEdit}
                                />
                                {errors.last_name && (
                                  <div
                                    className="help text-danger text-start"
                                    aria-live="assertive"
                                  >
                                    {errors.last_name}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            {/* 2nd Row */}
                            <Col lg={4} xl={4} md={6}>
                              <Form.Group className="mb-4">
                                <Form.Label>Email ID</Form.Label>

                                <FormControl
                                  type="email"
                                  placeholder="Email ID"
                                  autoComplete="off"
                                  id="email"
                                  value={userDetails?.email}
                                  name="email"
                                  disabled
                                  required
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={4} xl={4} md={6}>
                              <Form.Group className="mb-4">
                                <Form.Label>Mobile Number </Form.Label>

                                <PhoneInput
                                  country={"us"}
                                  value={`${editProfileData.phone}` || ""}
                                  onChange={handleMobile}
                                  placeholder="(702) 123-4567"
                                  disableAreaCodes
                                  disableCountryCode
                                  disableDropdown
                                  defaultCountry="us"
                                  onlyCountries={["us"]}
                                  inputProps={{
                                    name: "phone",

                                    autoFocus: true,
                                  }}
                                />
                                {errors.phone && (
                                  <div
                                    className="help text-danger text-start mt-1"
                                    aria-live="assertive"
                                  >
                                    {errors.phone}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={4} xl={4} md={6}>
                              <Form.Group controlId="dob" className="mb-4">
                                <Form.Label>Date of Birth
                                </Form.Label>
                                <DatePicker
                                  name="birth_date"
                                  dateFromat='MM-DD-YYYY'
                                  defaultValue={moment(editProfileData.birth_date).format('MM/DD/YYYY')}
                                  value={moment(editProfileData.birth_date).format('MM/DD/YYYY')}
                                  selected={startDate}
                                  onChange={(birth_date) => {
                                    setEditprofileData({
                                      ...editProfileData,
                                      birth_date,
                                    });
                                    setStartDate(birth_date);
                                  }}
                                  onChangeRaw={(event) =>
                                    handleChangeRaw(event.target.value)
                                  }
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  maxDate={subYears(new Date(), 18)}
                                  disabledKeyboardNavigation
                                  customInput={<ExampleCustomInput />}
                                  dayClassName={() =>
                                    "example-datepicker-day-class"
                                  }
                                  popperClassName="example-datepicker-class"
                                />

                                <Form.Control.Feedback
                                  type="invalid"
                                  className="col-md-6  px-0"
                                >
                                  {!valid ? "Test" : ""}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={4} xl={4} md={6}>
                              <Form.Group className="mb-4">
                                <Form.Label>Zip code </Form.Label>

                                <FormControl
                                  type="text"
                                  placeholder="Zip code"
                                  autoComplete="off"
                                  id="zip"
                                  name="zip"
                                  required
                                  value={editProfileData.zip}
                                  onChange={handleEdit}
                                />

                              </Form.Group>
                            </Col>

                            <Col lg={4} xl={4} md={6}>
                              <Form.Group className="mb-4">
                                <Form.Label>Address </Form.Label>

                                <FormControl
                                  type="text"
                                  placeholder="Address"
                                  autoComplete="off"
                                  id="address_1"
                                  name="address_1"
                                  required
                                  value={editProfileData.address_1}
                                  onChange={handleEdit}
                                />

                              </Form.Group>
                            </Col>
                            <Col lg={4} xl={4} md={6}>
                              <Form.Group className="mb-4">
                                <Form.Label>Upload Image</Form.Label>

                                <div className="customfilearea">
                                  <input
                                    type="file"
                                    id="myFile"
                                    placeholder="Upload Image"
                                    name="avtarimage"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    onChange={handleFileSelect}
                                    ref={imageUploader}
                                    className={`form-control ${"is-danger"}`}
                                  />
                                  <label
                                    className="upload_btn m-0"
                                    htmlFor="actual-btn"
                                  >
                                    Upload
                                  </label>

                                  <span id="file-chosen">{getFileName}</span>
                                </div>
                              </Form.Group>
                            </Col>
                            <Col
                              lg={4}
                              xl={4}
                              md={4}
                              className="d-flex align-items-center"
                            >
                              <div
                                className={getupolad ? "img-wrap" : ""}
                                onClick={() => imageUploader.current.click()}
                              >
                                <img
                                  ref={uploadedImage}
                                  src=""
                                  className="profile-upload sportsimg"
                                />
                              </div>
                            </Col>



                            <Col
                              lg={{ span: 6, offset: 3 }}
                              md={{ span: 6, offset: 3 }}
                              className="text-center mb-5"
                            >
                              <Button type="submit" className="btn-form">
                                Submit
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Container>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="changepswrd">
                  <div className="editForm-sec">
                    <h5 className="editForm-head">Change Password</h5>
                    <div className="editForm-body">
                      <Container>
                        <Form noValidate autoComplete="off" onSubmit={handlePassword}>
                          <Row className="mt-4">
                            <Col lg={4} xl={4} md={12} sm={12}>
                              <Form.Group className="mb-4 password-input">
                                <Form.Label>Old Password</Form.Label>

                                <FormControl
                                  type={oldPasswordShown ? "text" : "password"}
                                  placeholder="Old password"
                                  autoComplete="new_password"
                                  name="old_password"
                                  required
                                  value={updatePassword.old_password}
                                  onChange={handleChangePassword}
                                />
                                <div
                                  className={`font-icons ${faEyeSlash}`}
                                  onClick={oldPasswordVisiblity}
                                >
                                  <FontAwesomeIcon
                                    icon={oldPasswordShown ? faEye : faEyeSlash}
                                  />
                                </div>
                                {errors.old_password && (
                                  <div
                                    className="help text-danger text-start mt-1"
                                    aria-live="assertive"
                                  >
                                    {errors.old_password}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4} xl={4} md={12}>
                              <Form.Group className="mb-4 password-input">
                                <Form.Label>New Password</Form.Label>

                                <FormControl
                                  type={newPasswordShown ? "text" : "password"}
                                  placeholder="New password"
                                  autoComplete="new_password"
                                  name="new_password"
                                  required
                                  value={updatePassword.new_password}
                                  onChange={handleChangePassword}
                                />
                                <div
                                  className={`font-icons ${faEyeSlash}`}
                                  onClick={togglePasswordVisiblity}
                                >
                                  <FontAwesomeIcon
                                    icon={newPasswordShown ? faEye : faEyeSlash}
                                  />
                                </div>
                                {errors.new_password && (
                                  <div
                                    className="help text-danger text-start mt-1"
                                    aria-live="assertive"
                                  >
                                    {errors.new_password}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4} xl={4} md={12}>
                              <Form.Group className="mb-4 password-input">
                                <Form.Label>Confirm Password</Form.Label>

                                <FormControl
                                  type={cnfPasswordShown ? "text" : "password"}
                                  placeholder="Confirm new password"
                                  autoComplete="new_password"
                                  name="confirm_password"
                                  required
                                  value={updatePassword.confirm_password}
                                  onChange={handleChangePassword}
                                />
                                <div
                                  className={`font-icons ${faEyeSlash}`}
                                  onClick={toggleConfirmPassword}
                                >
                                  <FontAwesomeIcon
                                    icon={cnfPasswordShown ? faEye : faEyeSlash}
                                  />
                                </div>
                                {errors.confirm_password && (
                                  <div
                                    className="help text-danger text-start mt-1"
                                    aria-live="assertive"
                                  >
                                    {errors.confirm_password}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              lg={{ span: 4 }}
                              md={{ span: 6 }}
                              xl={{ span: 4 }}
                              className="text-center mb-5"
                            >
                              <Button type="submit" className="btn-form">
                                Submit
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Container>
                    </div>
                  </div>
                </Tab.Pane>
                {/* KYC Verification */}
                <Tab.Pane eventKey="kycVerification">
                  <div className="editForm-sec">
                    <h5 className="editForm-head">KYC Verification</h5>
                    <div className="editForm-body">
                      <VerificationComponent />
                    </div>
                  </div>

                </Tab.Pane>

                {/* Users Profile */}

                <Tab.Pane eventKey="usersProfile">
                  <div className="editForm-sec">
                    <h5 className="editForm-head">Users</h5>
                    <Users />
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="logout"></Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>

      <ToastContainer />
    </>
  );
};

export default Profile;
