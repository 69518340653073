import { CalenderIcon, VideoPlusIcon } from "../../SVGIcon"
import AddPost from "./AddPost"
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUsersPostAPI } from '../../slices/video&postSlice.js';
import { baseURL } from "../../config";
import { timeAgo } from "../../util/common";
const LatestPost = () => {
    let locacluserid = window.localStorage.getItem("userid");

    const [getPostList, setPostList] = useState([]);
    const dispatch = useDispatch();
    const handleAllPost = async () => {

        await dispatch(getUsersPostAPI({ userId: locacluserid }))
            .unwrap()
            .then((res) => {
                let postlist = res.data;
                setPostList(postlist);

            })
    }
    useEffect(() => {
        handleAllPost();
    }, [dispatch])


    const [modalShow, setModalShow] = useState(false);



    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(false);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text : text.slice(0, 150)}
                <span onClick={toggleReadMore} className="read-or-hide">
                    {isReadMore ? " show less" : "...read more"}
                </span>
            </p>
        );
    };
    return (
        <>
            <div className="card card-custom gutter-b post-height">
                <div className="custom-card-header">
                    <div className='d-flex justify-content-end align-items-center card-title'>
                        <div className='mx-auto '><h4 className='text-center text-uppercase custom-heading'>LATEST POSTS</h4></div>
                        <button className="btn fs-5 fw-bolder" onClick={() => setModalShow(true)}><span className="me-2"><VideoPlusIcon /></span>Add Post</button>
                    </div>
                </div>
                <div className="main-post-container p-0 p-sm-3">
                    <div className="d-flex p-1 p-sm-3">
                        <div className="row post-width">
                            {
                                getPostList?.length > 0 ? getPostList?.map((postItem, id) =>
                                    <div key={id} className="card-post fw-normal col-lg-12">
                                        <div className="card p-1 p-sm-2 p-md-2 mb-3">
                                            <div className="d-flex align-items-center" id={postItem.postid} >
                                                <div className="col-1 px-0 me-2 text-start">
                                                    {postItem.avatar !== "" ? (<>  <img src={`${baseURL}${postItem.avatar}`} className="img-fluid post-profile-image" /></>) : (<>  <img src="/icon/default-profile.png" className="img-fluid post-profile-image" /></>)}

                                                </div>
                                                <div className="col-11 text-start dark-blue p-2">
                                                    <h6>{postItem.user_name}</h6>
                                                    <p className="bg-gray mb-1"><span className="me-2"><CalenderIcon /></span>{timeAgo(postItem.post_date)}</p>
                                                    {postItem.description.length > 150 ? (<> <p className="mb-0"><ReadMore>{postItem.description}</ReadMore></p></>) : (<> <p className="mb-0">{postItem.description}</p> </>)}


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (<> <div className="vh-100 "><p className="bg-gray fw-500 text-center" >There is no latest post data available yet!</p></div></>)
                            }
                        </div>
                    </div>
                </div>

                <AddPost show={modalShow} onHide={() => setModalShow(false)} handleallpost={handleAllPost} />
            </div>
        </>
    )
}

export default LatestPost