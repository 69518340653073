
import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { FollowIcon, FriendIcon } from "../../SVGIcon";

import { Link } from "react-router-dom";
import {singleUserList} from '../../slices/userSlice';
import { useSelector,useDispatch } from "react-redux";
import AnotherUser from "../AnotherUser/AnotherUser";
const FriendsUserCard = ({friendlist,handleunfrnd}) => {
  const [singleUser,setSingleUser] = useState([]);
  const [friendCount,SetFriendCount] = useState();
  const [showModel, setShowModel] = useState(false);
const [anotherUserId, setAnotherUserId] = useState('');

  const handleShowModel = () => setShowModel(!showModel);
  const dispatch = useDispatch();
    const handleSingleUser = (user_id) => {
      setAnotherUserId(user_id);  
      dispatch(singleUserList({userId:user_id})).unwrap()
        .then((res) => {
          let newData = res;
          setSingleUser(...newData)
         
      }).catch( (err) => console.log(err));
      handleShowModel();
    }
  return (
   <>
     <Row className="card-user">
        {friendlist?.map((c, i) => {
          return (
            <>
              <Col md={4} lg={4} sm={12}>
                <div className="card user-card p-3 mb-3" id={c.friend_id} key={c.id}>
                  <Link  className="user-link disable-links">
                    {c.friend_user_avatar !== "" ? (
                      <img src={c.friend_user_avatar} className="card-img-top" alt="..." />
                    ) : (
                      <img
                        src="/icon/default-profile.png"
                        className="card-img-top"
                        alt="..."
                      />
                    )}

                    <h5
                      className="mt-3 mb-4 text-center  pink-text text-in-tablet"
                      onClick={() => handleSingleUser(c.friend_id)}
                    >
                      {c.friend_user_name}
                    </h5>
                  </Link>
                  <div className="card-body">
                    <button className="btn rounded-pill btn-outline-danger unfriend-btn fs-6 px-4 btn-tablet" id={c.friend_id} onClick={ () => { handleunfrnd(c.friend_id) }}>
                    
                      UnFriend
                    </button>
                 
                  </div>
                </div>
              </Col>
            </>
          );
        })}
        {friendlist?.length === 0 && <p className="pink-text fs-5 fw-bolder">Friends Not Found</p>}
      </Row> 
      <AnotherUser show={showModel} handleClose={handleShowModel} friendCount={friendCount} singleUser={singleUser} anotherUserId={anotherUserId}/>
       
   </>
  )
}

export default FriendsUserCard