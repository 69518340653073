import { EmailIcon } from "../../SVGIcon";
import authHeader from "../../util/Authheader";
import Footer from "../MainLayout/Footer/Footer";
import Navbar from "../MainLayout/Navbar/Navbar";
import NavbarStatic from "../MainLayout/Navbar/NavbarStatic";
import ContactUsForm from "./ContactUsForm";
const ContactUs = () => {
    let getToken = authHeader();

    return (
        <>
        {getToken === null ? <NavbarStatic/> : <Navbar />}
            <div className='main-layout'>
                <div className="container-fluid px-md-4 py-3 main-contact-container">
                    <div className="card-custom">
                        <div className='card-title text-center text-lg-start'>
                            <h4 className="dark-blue mb-0">Contact Us</h4>
                        </div>
                        <div className="p-2 p-sm-4">
                            <div className="row mt-3 mb-3">
                                <div className="col-lg-6 col-md-12 text-center text-lg-start">
                                    <p className="fs-4 pink-text fw-normal">Get in touch with us</p>
                                    <p className="bg-gray fw-light">Fill up the form and our team will get back to you within 24 hours</p>
                                </div>
                                <div className="col-lg-6 col-md-12 d-flex align-items-center justify-content-lg-end justify-content-md-center justify-content-center">
                                    <span className="email-icon me-3">
                                        <EmailIcon />
                                    </span>
                                    <p className="mb-0 pink-text fw-normal">no-reply@moonleague.com</p>
                                </div>
                            </div>
                            <ContactUsForm />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs