import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "../config";
import authHeader from "../util/Authheader";
import axios from "axios";
import { toast } from "react-toastify";

//api/wallet/add-funds

export const addFundAPI = createAsyncThunk(
    "api/wallet/add-funds",
    async (args, thunkAPI) => {
      const urlname = `${baseURL}api/wallet/add-funds`;
      return await axios
        .post(urlname, args.AddAmountData, { headers: authHeader() })
        .then((response) => {
          let data = response.data;
          // return data;
         
          if (data.status_code === 400 || data.status_code === 404) {
          
            toast.error(data.message);
            return Promise.reject(data);
          } else {
            toast.success("Wallet amount added successfully");
            return data;
          }
        })
        .catch((err) => {
          console.log(err, "Add fund");
        });
    }
  );

  //api/wallet/get-wallet-history
export const getWalletHistoryAPI = createAsyncThunk( 
    "api/wallet/get-wallet-history", async (args, thunkAPI) => {
      
    const url = `${baseURL}api/wallet/get-wallet-history/${args.user_id}`;
    return await axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        let data = response?.data;
    
        return data;
      })
      .catch((err) => {
        console.log(err, "get bloodbath battle list");
      });
  });

  // api/wallet/withdraw-fund
  export const withdrawFundAPI = createAsyncThunk(
    "api/wallet/withdraw-funds",
    async (args, thunkAPI) => {
      const urlname = `${baseURL}api/wallet/withdraw-funds`;
      return await axios
        .post(urlname, args, { headers: authHeader() })
        .then((response) => {
          let data = response.data;
          // return data;
       
          if (data.status_code === 400 || data.status_code === 404) {
          
            toast.error(data.message);
            return Promise.reject(data);
          } else {
            toast.success("Amount withdraw successfully");
            return data;
          }
        })
        .catch((err) => {
          console.log(err, "withdraw fund");
        });
    }
  );

const walletScreenSlice = createSlice({
    name: "wallet_Screen",
    initialState: {
        addFundAPI: [],
      status: null,
    },
    extraReducers: (builder) => {
      builder
        //addFundAPI
        .addCase(addFundAPI.pending, (state, action) => {
          state.status = "loading";
        })
        .addCase(addFundAPI.fulfilled, (state, action) => {
          state.addFundAPI = action.payload;
          state.status = "success";
        })
        .addCase(addFundAPI.rejected, (state, action) => {
          state.status = "failed";
        })

        //getWalletHistoryAPI
        .addCase(getWalletHistoryAPI.pending, (state, action) => {
            state.status = "loading";
          })
          .addCase(getWalletHistoryAPI.fulfilled, (state, action) => {
            state.getWalletHistoryAPI = action.payload;
            state.status = "success";
          })
          .addCase(getWalletHistoryAPI.rejected, (state, action) => {
            state.status = "failed";
          })

          //withdrawFundAPI
          .addCase(withdrawFundAPI.pending, (state, action) => {
            state.status = "loading";
          })
          .addCase(withdrawFundAPI.fulfilled, (state, action) => {
            state.withdrawFundAPI = action.payload;
            state.status = "success";
          })
          .addCase(withdrawFundAPI.rejected, (state, action) => {
            state.status = "failed";
          })
    },
  });

export default walletScreenSlice.reducer;
