import React, { useState, useEffect, Suspense,useImmer } from "react";
import { useParams ,useNavigate, useLocation} from "react-router-dom";
import { Row, Col, Button, ButtonGroup } from "react-bootstrap";
import Footer from "../MainLayout/Footer/Footer";
import Header from "../MainLayout/Header/Header";
import Navbar from "../MainLayout/Navbar/Navbar";

import { useDispatch, useSelector } from "react-redux";
import { enterDuelById, getBloodBathCoinsAPI, getCoinsAPI,joinDuelSymbolsList,joinDuelsAPI } from "../../slices/enterJoinSlice";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import JoinDuelsSelectionList from "../JoinDuels/JoinDuelsSelectionList";
import { MinuesIcon, SearchIcon } from "../../SVGIcon";
// import ConfirmModel from "./ConfirmModel";
// import { all } from "axios";
// import CheckLocationModel from "./CheckLocationModel";
// import Location from "./Location";
// import PaymentConfirm from "./PaymentConfirm";
import LoadingSpinner from "../../Pages/LoadingSpinner";
import { editDuelById, getEditBloodbathCoinsAPI, getEditCoinsAPI, updatejoinDuelsAPI } from "../../slices/editJoinSlice";
import EditDuelsComponent from "./EditDuelsComponent";
import EditDuelsSelectionList from "./EditDuelsSelectionList";
// import { Suspense } from "react";
// const JoinDuelsComponent = React.lazy(() => import('./JoinDuelsComponent'))
const JoinDuelsComponent = React.lazy(() => import('../JoinDuels/JoinDuelsComponent'))
const JoinDuels = () => {
    // const {getStatus,msg,loading,setLoading,getLocation} = Location();
     let isUser = localStorage.getItem("userid");
    const { contestId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    
    const [loading,setLoading] = useState(false);
    const [btnselect, setBtnSelected] = useState(true);
    console.log(btnselect,"btnselect");
    //Get duel by ID

    const dispatch = useDispatch();
    const [duelByID, setDuelByID] = useState([]);
    const [editCoinList, setEditCoinList] = useState([]);

  useEffect(() => {
    dispatch(editDuelById({ loggedin_user_id: isUser, duel_id: contestId, unique_id: location.state?.uniqueId }))
        .unwrap()
        .then((res) => {
            setDuelByID(res);
            setEditCoinList(res.data);

            // setDuel_guid(res.duel_guid);
        });
}, [dispatch]);

    const { duelByIdList, exchangeCoinsList } = useSelector(
        (state) => state.enter_Join
    );
   // console.log(duelByIdList, "duelByIdList");
   

    let startDate = moment.utc(duelByID?.duel_start_time_utc).toDate();
    let endDate = moment.utc(duelByID?.duel_end_time_utc).toDate();
    startDate = moment(startDate).format("ddd M/D/YY h:mm a");
    endDate = moment(endDate).format("ddd M/D/YY h:mm a");
    let getDuel_guid = duelByID?.duel_guid;
    let CreatedBy = duelByID?.created_by;
    let DuelEntryType = duelByID?.duel_entry_type;
   
   // console.log(duelByID?.duel_exchange, "exchange IDD");
    let exchangeID1 = duelByID?.exchange_id;
  //  let exchangeVal = exchangeID1.split('').join('');


   // console.log(exchangeVal, "exchangeVal");

    let exchangeId = {
        loggedin_user_id: isUser,
        duel_id: contestId,
        unique_id: location.state?.uniqueId,
        exchange_id: exchangeID1,
    };

    const [getEachCoin, setEachCoin] = useState([]);
    // const [items, setItems] = useState(getEachCoin);
    const [filterResult, setFilterResult] = useState([])

    const activeMode = duelByID?.duel_mode;
    useEffect(() => {
        if (activeMode === "Standard" || activeMode === "Standard & Battle") {
            setLoading(true)
            dispatch(getEditCoinsAPI({ exchangeId }))
                .unwrap()
                .then((res) => {
                    //console.log("get coins", res);
                    let result = res.data
                    //  console.log(result, "result")
                    let filterD = res.data.filter((d) => d.tier !== "D")
                    // console.log(filterD, "filterD")
                    setEachCoin(filterD);
                    setFilterResult(filterD)
                    SetSelectedCoins(filterD);
                    setActiveTier('All');
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err, "errors");
                });
        }
        else if (activeMode === "Bloodbath" || activeMode === "Bloodbath & Battle") {
            setLoading(true)
            dispatch(getEditBloodbathCoinsAPI({ exchangeId }))
                .unwrap()
                .then((res) => {
                    let result = res.data
                    let filterD = res.data.filter((d) => d.tier !== "D")
                    setEachCoin(filterD);
                    setFilterResult(filterD)
                    SetSelectedCoins(filterD);
                    setActiveTier('All');
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err, "errors");
                });
        }
    }, [exchangeID1]);

   // console.log(getEachCoin, "getEachCoin");
   

    const filterItem = (cateItem) => { 
        const updatedItem = getEachCoin.filter((curElem) =>
            curElem.tier === cateItem
        )
        setFilterResult(updatedItem);
        setActiveTier(cateItem);
       // console.log(updatedItem, "Updated Item")
    }

   



    const searchHandler = (event) => {
        // console.log(event.target.value,"eventssssssssss");
        event.preventDefault();
        const inputSearch = event.target.value
        let inputSearchNew = inputSearch.toUpperCase()
 
        let filterSym = getEachCoin.filter((elem) =>
            elem.base_symbol.toUpperCase().includes(inputSearchNew))
         if (inputSearch !== " ") {
            setFilterResult(filterSym);
            setActiveTier('All');
        } else {
            setFilterResult(getEachCoin)
        }
    }
   
  //Make button Active
  const tierList = [ 
    {
    id:'1', tierval:'A'

   },
   {
    id:'2', tierval:'B'

   },
   {
    id:'3', tierval:'C'

   },
]

const [activeTier,setActiveTier] = useState();

    const [selectCoins, SetSelectedCoins] = useState([]);
    const [selectCoinsSetA, SetSelectedCoinsSetA] = useState([]);
    const [selectCoinsSetB, SetSelectedCoinsSetB] = useState([]);
    const [selectCoinsSetC, SetSelectedCoinsSetC] = useState([]);
    const [getClicked, setClicked] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [selectCoinCount, SetselectCoinCount] = useState();
    const [totalSelectCoins, SetTotalSelectedCoins] = useState([]);
    const [editselectCoins, SeteditselectCoins] = useState([]);

   
        // exchangeCoinsList getCoinsAPI
    const addSelection = (coins, id) => { 
        // debugger
    //    console.log("select button on click", coins)
        const findId = getEachCoin.find((c) => c.id === coins);
        const selectCoinsA = getEachCoin.filter((a) => a.tier === "A")
        const selectCoinsC = getEachCoin.filter((c) => c.tier === "C")
        const selectCoinsD = getEachCoin.filter((d) => d.tier === "D")

      ///  console.log("select coin ", findId.tier, selectCoinsA);
      //  console.log(findId.tier, 'findId.tier');

        switch (findId.tier) { 
            case "A": 
               // console.log("Hello A")
               // console.log(" check number A", selectCoinsA)
                if (selectCoinsSetA.length < 2) { 
                 //   console.log(" check number A", selectCoinsA)
                    let curSelectedA = [];
                    curSelectedA = selectCoinsSetA.map((a) => {
                        return a;
                    });
                    curSelectedA.push(findId);
                    SetSelectedCoinsSetA(curSelectedA);
                    // SetSelectedCoinsSetA([...selectCoinsSetA, curSelectedA]);

                    //SetSelectedCoins(curSelectedA);
                    setBtnSelected(true);
                    //SetSelectedCoins(selectCoinsSetA);
                    

                } else {
                    //alert("Maximum number of selections 2");
                    filterItem('B');
                  //  setActiveTier('B');
                   // setFilterResult(getEachCoin);
                    return false;
                    //toast.error("Maximum number of selections made");
                }
                break;
            case "B": 
               // console.log("Hello B")
               // console.log(" check number D", selectCoinsD)
                if (selectCoinsSetB.length < 3) {
                    // console.log(" check number B", selectCoinsB)
                    let curSelectedB = [];
                    curSelectedB = selectCoinsSetB.map((s) => {
                        return s;
                    });
                    curSelectedB.push(findId);
                    SetSelectedCoinsSetB(curSelectedB);
                  //  SetSelectedCoins(selectCoinsSetB);
                  // SetSelectedCoins(curSelectedB)
                } else {
                   // alert("Maximum number of selections 3");
                    filterItem('C');
                    return false;
                    //toast.error("Maximum number of selections made");
                }
                break;
            case "C": 
               // console.log("Hello C")
              //  console.log(" check number C", selectCoinsD)
                if (selectCoinsSetC.length < 4) {
                   // console.log(" check number B", selectCoinsC)
                    let curSelectedC = [];
                    curSelectedC = selectCoinsSetC.map((s) => {
                        return s;
                    });
                    curSelectedC.push(findId);
                    SetSelectedCoinsSetC(curSelectedC);
                    //SetSelectedCoins(selectCoinsSetC);
                    //SetSelectedCoins(curSelectedC)
                } else {
                   // alert("Maximum number of selections 4");
                    filterItem('A');
                    return false;
                    //toast.error("Maximum number of selections made");
                }
                break;
        }
       // console.log(selectCoinsSetA.length, "selectCoinsSetA")

        let lenthSelectCoin = [...selectCoinsSetA, ...selectCoinsSetB, ...selectCoinsSetC]
        SetselectCoinCount(lenthSelectCoin.length + 1);
        
        //console.log(lenthSelectCoin,'lenthSelectCoin');
        
   //     SetSelectedCoins(lenthSelectCoin);
        // let selectedSum = [];
        // SetTotalSelectedCoins(selectedSum);
    };
    useEffect(() => {

        const filterTierA = editCoinList?.filter((c) => c.tier === "A");
        SetSelectedCoinsSetA(filterTierA);
        const filterTierB = editCoinList?.filter((c) => c.tier === "B");
        SetSelectedCoinsSetB(filterTierB);
        const filterTierC = editCoinList?.filter((c) => c.tier === "C");
        SetSelectedCoinsSetC(filterTierC);

    }, [editCoinList])
   console.log("states",selectCoinsSetA);
   let JoinDuelId = editCoinList[0]?.join_duel_id;

   const removeSelectionA = (id,symbolId) => {  
    const removeItemA = selectCoinsSetA.filter((coinsId) => {
                return coinsId.id !== id
        });
      SetSelectedCoins(removeItemA);
        SetSelectedCoinsSetA(removeItemA);
    SeteditselectCoins(removeItemA);

}
   
    // const removeSelectionA = (id) => {  
    //     const removeItemA = selectCoinsSetA.filter((coinsId) => {
    //         return coinsId.id !== id;
    //     });
    //   SetSelectedCoins(removeItemA);
    //     SetSelectedCoinsSetA(removeItemA);
    //     SeteditselectCoins(removeItemA);

    // }
    const removeSelectionB = (id) => { 
        const removeItemB = selectCoinsSetB.filter((coinsId) => {
            return coinsId.id !== id
        });
      SetSelectedCoins(removeItemB);
        SetSelectedCoinsSetB(removeItemB);
        SeteditselectCoins(removeItemB);

     
    }
    const removeSelectionC = (id) => { 
        const removeItemC = selectCoinsSetC.filter((coinsId) => {
            return coinsId.id !== id
        });
       SetSelectedCoins(removeItemC);
        SetSelectedCoinsSetC(removeItemC);
        SeteditselectCoins(removeItemC);

        // let lenthSelectCoin = [...selectCoinsSetC]
        // SetselectCoinCount(lenthSelectCoin.length - 1)

    }
    const [disabled,setDisabled] = useState(true);
    
    useEffect(() => {
        let removeSelectCoin = [...selectCoinsSetA, ...selectCoinsSetB, ...selectCoinsSetC]
        // console.log("hello",removeSelectCoin)
        SetselectCoinCount(removeSelectCoin.length)

      
    },[])

   // console.log(selectCoinCount,'selectCoinCount');
    useEffect( () => { 
        //selectCoins
        if(selectCoins === 9) {
       // if(selectCoinCount === 9) { 
           // toast.error(selectCoinCount);
            setDisabled(false);
        }
        else {
            setDisabled(true);
            // console.log(selectCoinCount);
        }
    },[selectCoins])
  
useEffect( () => {
    let removeSelectCoin = [...selectCoinsSetA, ...selectCoinsSetB, ...selectCoinsSetC];
    SetSelectedCoins(removeSelectCoin);
    SeteditselectCoins(removeSelectCoin);
   // let joinSymbolsList = [];

//console.log(selectCoins,'selectCoins');

},[selectCoinsSetA,selectCoinsSetB,selectCoinsSetC])


    // end selection made




//Check values
let languages = duelByID?.duel_exchange;
let iteratorObject = languages?.values();
// console.log(languages,'languages');
// console.log(iteratorObject,'iteratorObject');

const [currentExchange,setcurrentExchange] = useState([]);
// looping through iterator
useEffect( () => {
    if(languages) {
        for (let value of iteratorObject) {
            setcurrentExchange(value);
        //    console.log(value,'each value ');
        }
        
    }
},[languages])

// console.log(currentExchange,'currentExchange');
let currentExchange1 = languages;
    // console.log(currentExchange1,'currentExchange1');
    // console.log(languages,'currentExchange1 languages');
   // return true;
   

// console.log(selectCoins,'selectCoins list');

//Join Duels API List Selected Coins Filter
const navigate = useNavigate();
const handleContest = async (e) => {
  e.preventDefault();
 
  setIsLoading(true);
//   await getLocation();

//     console.log(getStatus,'getStatus getStatus');

//     if(getStatus !== "ALLOWED") {
//         setIsLoading(false);    
//     setModalGeoShow(true);
//   }
//   else {
   //setModalShow(true);
    JoinDuel();

   
//       setTimeout( () => {
//     navigate("/dashboard");
//   },1000)

 // }

 
}



async function JoinDuel() {
  //  return false;
    let data = [];
    data.push(selectCoins);
    let joinDuelData = {
        join_duel_id: JoinDuelId,
        data: [
            ...selectCoins.map((item) => {
                // console.log(item,"itemmm");
                return {
                    exchange_id: item.exchange_id,
                    base_symbol: item.base_symbol,
                    tier: item.tier,
                    symbol: item.symbol
                }
            })
        ]

    };

 

 //call joinDuelsAPI
    await dispatch(updatejoinDuelsAPI({joinDuelData }))
    .unwrap()
    .then((res) => {
        // console.log(res,'res join')
        let result = res.message;
        setIsLoading(false);    
        toast.success(result);
       // setModalShow(true);
       setTimeout( () => {
        navigate("/dashboard");
      },5000)
       // setDuelByID(res);
       // setDuel_guid(res.duel_guid);
    }).catch((err) => {
        setIsLoading(false);    
        toast.error(err.message);
    });
  
}

const renderArray = (array) => {
    const letters = {1: "NYSE", 2: "NASDAQ", 3: "CRYPTO"};
    const renderedArray = [];
    for (const number of Object.values(array || {})) {
        renderedArray.push(letters[number]);
    }
    
    return renderedArray.join("-");
  };
  const array = duelByID?.duel_exchange;
  const renderedArray = renderArray(array);


    return (
        <>
            <Navbar />
            <div className="main-layout">
                <Header />
            
                <div className="join-duels-container card my-3">
                {isLoading ?  <LoadingSpinner/> : ""}
               
                    <div className="card card-custom gutter-b">
                        <div className="custom-card-header ">
                            <div className="d-flex justify-content-end card-title">
                                <div className="mx-auto ">
                                    <h4 className="text-center text-uppercase custom-heading">
                                        Edit Duels
                                    </h4>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between flex-nowrap w-100 p-3 align-items-center">
                                <div className="left-container text-start w-75">
                                    <h4 className="pink-text text-uppercase">
                                        {duelByID?.duel_name} - {duelByID?.duel_type} {""}
                                        {/* {duelByID?.duel_name}  */}
                                    </h4>
                                    
                                    <p className='text-dark fw-500 w-100'><strong>Description:</strong> {duelByID?.duel_description}</p>
                                    
                                    <div className="d-flex justify-content-between flex-wrap w-100">
                                    <p className='text-dark fw-500'><strong>Entry Fee:</strong>{" "}{duelByID?.duel_entry_fee === "" ? "$0.00" : (` ${duelByID?.duel_entry_fee}`) }</p>
                                    <p className="mb-1 text-dark  fw-500">
                                    <strong>Duration:</strong>
                                        {" "}
                                        {startDate} - {endDate}
                                        {/* {duelByID?.duel_start_time_utc} -  {duelByID?.duel_end_time_utc} */}
                                        {/* Wed, 03/01/2023 12:00 AM */}
                                    </p>
                                 
                                    </div>
                                   
                               
                                </div>
                              
                               
                                <div className="right-container">

                                  {/* <button type="button" className={`btn  ${disabled ? "btn duel-btn btn-purple" : "activebtn btn-dark duel-btn"}`} onClick={handleContest}
                                  disabled ={disabled}
                                  >
                                        Join Duel
                                    </button>   */}

                                       
                                     {selectCoins?.length === 9 ? (<>
                                        <button type="button" className="activebtn btn-dark duel-btn" onClick={handleContest}>
                                        Edit Duel
                                    </button>
                                    
                                    </>): (<>
                                        <button type="button" className="btn duel-btn btn-purple"  disabled ={disabled}>
                                        Edit Duel
                                    </button>
                                    
                                    </>)}       
                                
                                </div>

                            </div>

                                

                        </div>
                        <div className="p-3">
                            <Row className="gy-3">
                                <Col md={12} lg={6} sm={12}>
                                    <div className="card-custom p-3" style={{ height: "100%" }}>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
                                            <div className="heading-text pink-text fw-bold">
                                            {renderedArray}
                                            </div>
                                            <div className="serach-exchange">
                                                <input type="search" placeholder="Search...." onChange={searchHandler} />
                                                <span className="search-icon">
                                                    <SearchIcon />
                                                </span>
                                            </div>
                                            <ButtonGroup aria-label="First group">
                                                <Button
                                            className={`btn btn-group active1 ${activeTier === 'All' ? "activeBtn":""}`}
                                                    onClick={() => { setFilterResult(getEachCoin); setActiveTier('All'); }}
                                                >
                                                    All
                                                </Button>

                                                {tierList.map((c,i) => {
                                                    return (
                                                    <>
                                                      <Button key={i} id={c.tierval}
                                                    className={`btn btn-group ${activeTier === c.tierval ?  "activeBtn" : ""}`}
                                                    onClick={ (e) => { filterItem(c.tierval); 
                                                      //  setActiveTier(e.target.id); 
                                                    }}
                                                >
                                                    {c.tierval}
                                                </Button>
                                                    </>
                                                   )
                                                }) } 
                                            </ButtonGroup>
                                        </div>

                                        {/* <div className='auto-scroll'>
                                     {
                                        getEachCoin.map((elem, index) => {
                                            return (
                                                <>
                                                    <JoinDuelsComponent
                                                       logo={elem.logo}
                                                       baseSymbol={elem.base_symbol}
                                                       currentPrice={elem.current_price}
                                                       nameShort={elem.name_short}
                                                        id={index}
                                                        key={index}
                                                        // name={elem.name}
                                                        // category={elem.category}
                                                       // onSelect={addListBtn}
                                                    />

                                                </>
                                            )
                                        }
                                        )
                                    } 
                                     </div> */}

                                        <div className="auto-scroll" style={{height:"1356px"}}>
                                            {loading ? (<><p className='pink-text fw-semibold'>Please wait...</p></>)
                                            : <Suspense fallback={<div className="pink-text fw-bold">Wait...</div>}>
                                                  <div className="d-flex justify-content-between align-items-center row-bg mt-3 mb-3 join-duel-component" >
                                                    <div className="d-flex align-items-center join-duel-component-symbol-img-container">
                                                        <img src='' className="img-fluid img-coins" style={{ visibility: "hidden" }} />
                                                    </div>
                                                    <div className="d-flex align-items-start flex-column fix-width text-start join-duel-component-symbol">
                                                        <p className="mb-0 join-heading ms-0 ms-sm-4">Symbol</p>
                                                    </div>
                                                    <p className="mb-0 join-heading text-start">Price</p>
                                                    <p className="mb-0 join-heading text-start">% +- </p>
                                                    <p className="mb-0 join-heading">Tier</p>
                                                    <button className="btn btn-primary duel-btn p-2" disabled style={{ visibility: "hidden" }}
                                                    ><MinuesIcon /></button>

                                                </div>
                                                {filterResult.map((elem, index) => { 
                                                    if (selectCoins.some(s => s.id === elem.id)) {
                                                        return (
                                                            <>

                                                                <EditDuelsComponent
                                                                    logo={elem.logo}
                                                                    baseSymbol={elem.base_symbol}
                                                                    currentPrice={elem.current_price}
                                                                    nameShort={elem.name_short}
                                                                    id={elem.id}
                                                                    key={index}
                                                                    pct_change_factor={elem.pct_change_factor}
                                                                    tier={elem.tier}
                                                                    is_active={elem.is_active}
                                                                    getClicked={getClicked}
                                                                    setClicked={setClicked}
                                                                    selectCoins={selectCoins}
                                                                    buttonProps={(selectCoinsSetA.find(item => item.id === elem.id) || selectCoinsSetB.find(item => item.id === elem.id) || selectCoinsSetC.find(item => item.id === elem.id) || elem.is_already_selected)  && 'Minus'}
                                                                    setBtnSelected={setBtnSelected}
                                                                    // buttonSelected = {elem.is_already_selected}
                                                                    onSelect={addSelection}
                                                                />

                                                            </>

                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <EditDuelsComponent
                                                                logo={elem.logo}
                                                                baseSymbol={elem.base_symbol}
                                                                currentPrice={elem.current_price}
                                                                nameShort={elem.name_short}
                                                                id={elem.id}
                                                                pct_change_factor={elem.pct_change_factor}
                                                                key={index}
                                                                tier={elem.tier}
                                                                is_active={elem.is_active}
                                                                getClicked={getClicked}
                                                                setClicked={setClicked}
                                                                selectCoins={selectCoins}
                                                                // buttonProps={selectCoinsSetA.find(item => item.id === elem.id && 'Minus')}
                                                                // buttonProps = {elem.is_already_selected}
                                                                onSelect={addSelection}
                                                            />
                                                        )
                                                    }
                                                })}
                                            </Suspense>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} lg={6} sm={12}>
                                    <div className="card-custom p-3" style={{ height: "100%" }}>
                                        <div className="d-flex justify-content-between mt-3">
                                            <h6 className="pink-text">SELECTIONS</h6>
                                         
                                            <p className="pink-text fw-semibold">
                                                {editselectCoins?.length}/9
                                                {/* {selectCoinCount}/9{" "} */}
                                            </p>
                                        </div>
                                        <div className="container-tierA mb-4">
                                        <div className="d-flex justify-content-between align-items-center row-bg mt-3 mb-3 join-duel-component" >
                                                    <div className="d-flex align-items-center join-duel-component-symbol-img-container">
                                                        <img src='' className="img-fluid img-coins" style={{ visibility: "hidden" }} />
                                                    </div>
                                                    <div className="d-flex align-items-start flex-column fix-width text-start join-duel-component-symbol">
                                                        <p className="mb-0 join-heading ms-0 ms-sm-4">Symbol</p>
                                                    </div>
                                                    <p className="mb-0 join-heading text-start">Price</p>
                                                    <p className="mb-0 join-heading text-start">% +-</p>
                                                    <p className="mb-0 join-heading">Tier</p>
                                                    <button className="btn btn-primary duel-btn p-2" disabled style={{ visibility: "hidden" }}
                                                    ><MinuesIcon /></button>

                                                </div>
                                            <h6 className="bg-gray">TIER A</h6>
                                         

                                            {selectCoinsSetA.map((elem, i) => {
                                                // console.log(elem,"join delete elementttt");

                                                return (
                                                    <>
                                                        <div key={i}>
                                                            <EditDuelsSelectionList
                                                                logo={elem.logo}
                                                                baseSymbol={elem.base_symbol}
                                                                currentPrice={elem.current_price}
                                                                nameShort={elem.name_short}
                                                                pct_change_factor={elem.pct_change_factor}
                                                                tier={elem.tier}
                                                                id={elem.id}
                                                                handleRemove={removeSelectionA}
                                                                key={i}
                                                                symbolId={elem.symbol_id}
                                                            />
                                                        </div>
                                                    </>
                                                );
                                            })
                                            }

                                        </div>
                                        <div className="container-tierB mb-4">
                                            <h6 className="bg-gray">TIER B</h6>
                                            {selectCoinsSetB.map((elem, i) => {
                                                return (
                                                    <>
                                                        <div key={i}>
                                                            <EditDuelsSelectionList
                                                                logo={elem.logo}
                                                                baseSymbol={elem.base_symbol}
                                                                currentPrice={elem.current_price}
                                                                nameShort={elem.name_short}
                                                                pct_change_factor={elem.pct_change_factor}
                                                                tier={elem.tier}
                                                                id={elem.id}
                                                                handleRemove={removeSelectionB}
                                                                key={i}
                                                                symbolId={elem.symbol_id}

                                                            />
                                                        </div>
                                                    </>
                                                );
                                             })
                                            }
                                        </div>
                                        <div className="container-tierC">
                                            <h6 className="bg-gray">TIER C</h6>
                                            {selectCoinsSetC.map((elem, i) => {
                                                return (
                                                    <>
                                                        <div key={i}>
                                                            <EditDuelsSelectionList
                                                                logo={elem.logo}
                                                                baseSymbol={elem.base_symbol}
                                                                currentPrice={elem.current_price}
                                                                nameShort={elem.name_short}
                                                                tier={elem.tier}
                                                                pct_change_factor={elem.pct_change_factor}
                                                                id={elem.id}
                                                                handleRemove={removeSelectionC}
                                                                key={i}
                                                                symbolId={elem.symbol_id}

                                                            />
                                                        </div>
                                                    </>
                                                );
                                            })
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
          
            {/* <ConfirmModel show={modalShow} onHide={() => setModalShow(false)}  /> */}
            {/* <CheckLocationModel show={modalGeoShow} onHide={() => setModalGeoShow(false)} getstatus={getStatus} msg={msg} loading={loading} setloading={setLoading}/>
            <PaymentConfirm show={modalShow} onHide={() => setModalShow(false)} />*/}
            <ToastContainer/> 
            <Footer />
        </>
    );
};

export default JoinDuels;
