import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../services/auth-services";
import { toast } from "react-toastify";
import { baseURL } from "../config";
import axios from "axios";
const loginDefaultState = {
  email: null,
  password: null,
};

//Login Thunk
export const login = createAsyncThunk(
  "api/authenticate_user",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await fetch(`${baseURL}api/authenticate_user`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      let data = await response.json();

      if (data.status_code === 400) {
        return Promise.reject(data);
      } else {
        return data;
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
//Register Thunk

export const register = createAsyncThunk(
  "api/create_user",
  async (user, thunkAPI) => {
    const url = `${baseURL}api/create_user`;
    try {
      const response = await axios.post(url, {
        user_name: user.username,
        email: user.email,
        phone: user.mobile,
        terms: user.terms,
        password: user.password,
        confirm_password: user.cnfpassword,
        country_code: "+1",
        birth_date: user.birth_date
      });

      let data = response.data;
      if (data.status_code === 200 && data.status === true) {
        toast.success(data.message);

        return data;
      } else {
        toast.error(data.message);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

//Forgot Password

export const forgotEmail = createAsyncThunk(
  "api/user/forgot-password-send-otp",
  async (forgetPassword, thunkAPI) => {
    const url = `${baseURL}api/user/forgot-password-send-otp`;
    try {
      const response = await axios.post(url, {
        email: forgetPassword.email,
      });
      let data = response.data;
      if (data.status_code === 200) {
        toast.success(data.message);

        return data;
      } else {
        toast.error(data.message);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

//Verify OTP

export const verifyOTP = createAsyncThunk(
  "api/user/forgot-password-verify-otp",
  async (otpValue, thunkAPI) => {
    const url = `${baseURL}api/user/forgot-password-verify-otp`;
    try {
      const response = await axios.post(url, otpValue);
      let data = response.data;
      if (data.status_code === 200) {
        toast.success(data.message);

        return data;
      } else {
        toast.error(data.message);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

//Resend OTP
export const resendOTP = createAsyncThunk(
  "api/user/forgot-password-resend-otp",
  async (newOTP, thunkAPI) => {
    const url = `${baseURL}api/user/forgot-password-resend-otp`;

    try {
      const response = await axios.post(url, newOTP);
      let data = response.data;
      if (data.status_code === 200) {

        return data;
      } else {
        toast.error(data.message);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);


//Reset Password
export const resetPassword = createAsyncThunk(
  "api/user/forgot-password",
  async (resetPwd, thunkAPI) => {
    const url = `${baseURL}api/user/forgot-password`;

    try {
      const response = await axios.post(url, resetPwd);
      let data = response.data;
      if (data.status_code === 200) {
        toast.success(data.message);

        return data;
      } else {
        toast.error(data.message);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);
//Logout Thunk
export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});

//Get User by ID

const authSlice = createSlice({
  name: "auth",
  initialState: {
    email: "",
    password: "",
    user_name: "",
    first_name: "",
    last_name: "",
    phone: "",
    birth_date: "",
    confirm_password: "",
    country_code: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    loginData: loginDefaultState,
    isLoggedIn: false,
  },
  reducers: {
    //Reducers come here

    addOTP: (state, { payload }) => {
      state.otpList.push(payload);
    },
  },

  // "builder callback API", recommended for TypeScript users
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.email = payload.email;
      state.password = payload.password;
      state.isFetching = false;
      state.isSuccess = true;
      state.isLoggedIn = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.isLoggedIn = false;
    });
    builder.addCase(login.pending, (state, { payload }) => {
      console.log("pending....", payload);
      state.isFetching = true;
      state.isLoggedIn = false;
    });

    //Register ExtraReducers Builder
    builder.addCase(register.pending, function (state) {

      state.isFetching = true;
    });
    builder.addCase(register.fulfilled, (state, { payload }) => {

      state.email = payload.email;
      state.password = payload.password;
      state.fname = payload.fname;
      state.user_name = payload.user_name;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    });
    builder.addCase(register.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
    });

    //Forgot Password Builder
    // forgotEmail
    builder.addCase(forgotEmail.fulfilled, (state, action) => {
      state.email = action.payload;

      state.isFetching = false;
      state.isSuccess = true;
    });
    builder.addCase(forgotEmail.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
    });
    builder.addCase(forgotEmail.pending, (state, { payload }) => {
      state.isFetching = true;
    });

    //verfy OTP Builder
    builder.addCase(verifyOTP.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
    });
    builder.addCase(verifyOTP.pending, (state, action) => {
    });
    builder.addCase(verifyOTP.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
    });

    //Reset OTP Builder
    builder.addCase(resendOTP.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
    });
    builder.addCase(resendOTP.pending, (state, action) => {
    });
    builder.addCase(resendOTP.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
    });

    //Reset Password
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
    });
    builder.addCase(resetPassword.pending, (state, action) => {
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isFetching = false;
      state.isError = true;
    });



    //Logout Builder
    builder.addCase(logout.fulfilled, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    });
  },
});

const { reducer } = authSlice;
export const { addOTP } = authSlice.actions;
export const userSelector = (state) => state.auth;
export default reducer;
