import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { FollowIcon, FriendIcon } from "../../SVGIcon";
import AnotherUser from "../AnotherUser/AnotherUser";
import { VirtualScroller } from "primereact/virtualscroller";
import { Skeleton } from "primereact/skeleton";
import { classNames } from "primereact/utils";
import { Link } from "react-router-dom";
import {singleUserList} from '../../slices/userSlice';
import { useSelector,useDispatch } from "react-redux";

const FollowersUserCard = ({followerslist,handlefollow,handleunfollow}) => {

    const [showModel, setShowModel] = useState(false);
    const handleShowModel = () => setShowModel(!showModel);
    const [singleUser,setSingleUser] = useState([]);
const [friendCount,SetFriendCount] = useState();
const [anotherUserId, setAnotherUserId] = useState('');

const dispatch = useDispatch();
  const handleSingleUser = (user_id) => {
setAnotherUserId(user_id);
    dispatch(singleUserList({userId:user_id})).unwrap()
      .then((res) => {
        let newData = res;
        setSingleUser(...newData)
       
    }).catch( (err) => console.log(err));
    handleShowModel();
     
  }
 
  return (
   <>
    

      <Row className="card-user">
        {followerslist?.map((c, i) => {
          return (
            <>
              <Col md={4} lg={4} sm={12}>
                <div className="card user-card p-3 mb-3" id={c.follower_user_id} key={c.id}>
                  <Link className="user-link disable-links">
                    {c.follower_user_avatar !== "" ? (
                      <img src={c.follower_user_avatar} className="card-img-top" alt="..." />
                    ) : (
                      <img
                        src="/icon/default-profile.png"
                        className="card-img-top"
                        alt="..."
                      />
                    )}

                    <h5
                      className="mt-3 mb-4 text-center  pink-text text-in-tablet"
                      onClick={() => handleSingleUser(c.follower_user_id)}
                    >
                      {c.follower_user_name}
                    </h5>
                  </Link>
                  <div className="card-body">
                   
                    {c.is_also_following === 1 ? (<>
                      <button className="btn pink-text btn-outline  ms-3 fs-6 btn-selected btn-tablet" id={c.follower_user_id} onClick={ () => handleunfollow(c.follower_user_id)}>
                     
                      UNFOLLOW
                      
                    </button>
                    
                    </>) : (<>
                    
                      <button className="btn pink-text btn-outline follow-btn ms-3 fs-6 btn-tablet" id={c.follower_user_id} onClick={ () => handlefollow(c.follower_user_id)}>
                     
                      FOLLOW 
                      
                    </button>
                    </>)}
                   
                  </div>
                </div>
              </Col>
            </>
          );
        })}
        {followerslist?.length === 0 && <p className="pink-text fs-5 fw-bolder">Followers Not Found</p>}
      </Row> 
      <AnotherUser show={showModel} handleClose={handleShowModel} friendCount={friendCount} singleUser={singleUser} anotherUserId={anotherUserId}/>
   </>
  )
}

export default FollowersUserCard