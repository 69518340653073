import { DataTable } from "primereact/datatable";
import { CancelIcon, ConfirmIcon, SwapIcon } from "../../SVGIcon"
import { Column } from "primereact/column";
import React from "react";

const StockComponent = ({ duelersList, duelInfo, setSelectedBlocker, selectedBlocker, selected, setSelected, isEditing, setShowBlocker, showBlocker,
  handleSwapDuel, loading, handlecancel, handlegetBlockerBattle, show, Trader, tipHide }) => {

  const logoBodyTemplate = (rowData) => {
    return (
      <>{rowData.logo ? (<img src={rowData.logo} className="img-fluid img-coins" />) : (<img src='/images/bitcoin-icon.png' />)}</>
    )
  }
  const SymbolBodyTemplate = (rowData) => {
    return (
      <>
        <p className="mb-0 duel-heading cfs-6">{rowData?.join_duel_base_symbol}</p>
      </>
    )
  }

  const priceBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.percentage_change !== '' ? (
          <>  {rowData.percentage_change >= '0.00' ? <>{rowData.percentage_change === '0.00' ? <p className="text-warning fw-bold cfs-6 mb-0">{rowData.percentage_change}</p> : <p className="green-text fw-bold cfs-6 mb-0">{rowData.percentage_change}{rowData.percentage_change === '0.00' ? "" : "%"}</p>}</>
            : <p className="text-danger fw-bold cfs-6 mb-0">{rowData.percentage_change}{rowData.percentage_change === '0.00' ? "" : "%"}</p>}</>)

          : "-"

        }

      </>
    )
  }

  const isBlocker = () => {
    return duelersList.some((item) => item.before_apply_blocker && item.is_blocker_apply)
  }

  const isBlockerRemove = () => {
    return duelersList.some((item) => item.battle_person_id === 6 && item.before_apply_blocker === 0 && item.is_blocker_apply === 0)
  }



  const nameBodyTemplate = (rowData) => {
    const hide = rowData?.is_blocker_apply === 1 && rowData?.before_apply_blocker === 1
    return (
      <>
        {isEditing?.id === rowData?.base_symbol_id ? <div className="d-flex justify-content-evenly align-items-center">
          <button className="btn duel-btn p-2" onClick={() => {
            handleSwapDuel(selected?.join_duel_symbol_id, isEditing?.exchange_id, isEditing?.base_symbol, isEditing?.tier, isEditing?.current_price)
          }}><ConfirmIcon /></button>
          <button className="btn  duel-btn cancelIcon p-2" onClick={() => { handlecancel(); setSelected(false) }}><CancelIcon style={{ stroke: "#dc3545!important" }} /></button>
        </div>
          : <>  <button className={`btn duel-btn p-2 ${rowData?.base_symbol_id === selected?.base_symbol_id ? "btn-swap" : 'btn-primary'}`} onClick={() => { setSelected(rowData); }}><SwapIcon /></button> </>
        }

      </>
    )
  }


  const battleBodyTemplate = (rowData) => {
    return (
      <>

        <> {rowData?.base_symbol_id === selectedBlocker?.base_symbol_id ? <div className="d-flex justify-content-evenly align-items-center">
          <button className="btn duel-btn p-2" onClick={() => { handlegetBlockerBattle(rowData) }}><ConfirmIcon /></button>
          <button className="btn  duel-btn cancelIcon p-2" onClick={() => { setSelectedBlocker(false) }}><CancelIcon style={{ stroke: "#dc3545!important" }} /></button></div>
          : <>{rowData?.battle_person_id && rowData?.before_apply_blocker && rowData?.is_blocker_apply
            ? <button className={`btn btn-blocker-before disabled`}>T</button>
            : isBlocker() ? ""
              : <> {isBlockerRemove() ? "" : <button className={`btn btn-blocker-after`} onClick={() => { setSelectedBlocker(rowData); }}>T</button>} </>
          }</>
        }</>

      </>
    )
  }

  const rowClass = (rowData) => {
    return {
      'bg-clicked': rowData.base_symbol_id === selected?.base_symbol_id || isEditing?.id === rowData?.base_symbol_id
    };
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold text-primary">{duelersList?.length}/9</span>
    </div>
  );

  const headerB = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      {isBlockerRemove() ? "" : <span className="text-xl text-900 font-bold ">T</span>}
    </div>

  );

  console.log(duelersList.length === 0, "loading");

  return (
    <>



      <div className="custom-active">

        <DataTable
          value={duelersList} rows={10}
          scrollable
          loading={loading}
          emptyMessage="There is no symbol data available yet!"
          rowClassName={rowClass}
          tableStyle={{ minHeight: '31.8rem', minWidth: "1rem" }}

        >
          <Column field="logo" body={logoBodyTemplate}></Column>
          <Column field="join_duel_base_symbol"
            header="Symbol"
            body={SymbolBodyTemplate}></Column>
          <Column field="join_duel_symbol_tier" header="Tier"
          ></Column>
          <Column
            body={priceBodyTemplate} header="%"></Column>
          {showBlocker && <Column header={headerB} body={battleBodyTemplate} style={{ padding: "0px 6px 0px 5px" }}></Column>}
          {Trader === "Day Trader" && (duelInfo?.duel_mode === "Standard & Battle" || duelInfo?.duel_mode === "Bloodbath & Battle" || duelInfo?.duel_mode === "Standard" || duelInfo?.duel_mode === "Bloodbath") ? <Column body={nameBodyTemplate} header={header}></Column> : ''}
        </DataTable>
      </div>

      <div className="row py-3 px-3 d-md-flex blocker-score-container">

        <div className={`${show ? 'col-md-6' : 'col-md-3'} d-flex justify-content-start blocker-btn-container`}>
         {duelersList.length === 0 ? "" :<>{duelInfo?.duel_mode === "Standard" || duelInfo?.duel_mode === "Bloodbath"
            ? "" : <> {isBlockerRemove() ? "" : <>{tipHide ? '' : <button type="button" className="btn btn-success d-flex justify-content-start align-items-center mx-2 blocker-tablet-btn" style={{ fontWeight: 400, fontSize: "12px" }} onClick={() => setShowBlocker(!showBlocker)}>TIP</button>}</>} </>}
            </>}
        </div>

        <div className={`${duelInfo?.duel_mode === "Standard" || duelInfo?.duel_mode === "Bloodbath" ? 'col-md-12' : 'col-md-6'} d-flex justify-content-center align-items-center score-text blocker-score-container`} >
          {duelInfo?.score !== '' ? (
            <>  {duelInfo?.score >= "0%" ? <>{duelInfo?.score === "0%" ? <p className="text-warning fw-bold cfs-6 mb-0 ms-2 px-4 score-tablet-mode"><span className="score">Score:</span> {duelInfo?.score}</p> : <p className="green-text fw-bold cfs-6 mb-0 ms-2"><span className="score">Score:</span> {duelInfo?.score}</p>}</>
              : <p className="text-danger fw-bold cfs-6 mb-0 ms-2 px-4 d-flex justify-content-center align-items-center"><span className="score">Score:</span> {duelInfo?.score}</p>}</>)

            : "-"

          }
        </div>
      </div>
    </>
  )
}

export default StockComponent