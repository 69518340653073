import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const HotPickComponent = ({ hot, handleUnfriend, handleSendRequest, handleCancelRequest, handleCnfRjcFrnd, handleUnFollow, handleFollow }) => {

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    user_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  });
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <div className="d-flex m-1">

        <span className="p-input-icon-left w-100 px-1">
          <i className="pi pi-search" />
          <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Search by name" className="w-100" />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  const avatarTemplate = (rowData) => {
    return (
      <div className="community-pic me-3 flex-grow-0">
        {rowData.avatar !== "" ? (
          <img src={rowData.avatar} alt="profile image" />
        ) : (
          <>
            <img src="/icon/default-profile.png" alt="profile image" />
          </>
        )}
      </div>
    );
  };

  const nameTemplate = (rowData) => {
    return (
      <div className="user bg-gray align-items-start flex-grow-1 text-truncate" style={{width:"75px"}}>
        {rowData.user_name}

      </div>
    );
  };
  let locacluserid = window.localStorage.getItem("userid");

  const acceptRejectTemplate = (rowData) => {
    const userLogin = parseInt(locacluserid)
    return (<>
      <div className="d-flex justify-content-evenly">

        {userLogin === rowData?.userid ? "" : <> {rowData.request_received?.find((n) => { return n.loggedin_user_id === parseInt(locacluserid) }) ? (<> <button className="btn rounded-pill btn-outline-danger btn-community-hot fs-6 px-4" id={rowData.userid}
          onClick={() => { handleCancelRequest(rowData.userid) }}
        >

          Cancel
        </button></>) : rowData.request_sent?.find((n) => { return n.friend_id === parseInt(locacluserid) }) ? (<><button className="btn rounded-pill btn-outline-success btn-community-hot  fs-6 px-4" id={rowData.userid}
          onClick={() => { handleCnfRjcFrnd(rowData.userid, 1) }}
        >

          Confirm
        </button></>) : rowData.friends_by?.find((n) => n.friend_id === parseInt(locacluserid)) || rowData.friends_to?.find((n) => n.loggedin_user_id === parseInt(locacluserid)) ? (<><button className="btn rounded-pill btn-outline-danger btn-community-hot  fs-6 px-3" id={rowData.userid}
          onClick={() => { handleUnfriend(rowData.userid) }}
        >

          Unfriend
        </button></>)


          : (<> <button className="btn pink-text btn-outline fs-6 px-2 btn-community-hot" id={rowData.userid} onClick={() => { handleSendRequest(rowData.userid) }}>

            Add Friend
          </button> </>)}

          {rowData.followed_by?.find((n) => n.user_id === parseInt(locacluserid)) ? (<> <button className=" btn rounded-pill btn-outline btn-selected btn-community-hot fs-6 px-3" id={rowData.userid} onClick={() => handleUnFollow(rowData.userid)}>

            Unfollow
          </button></>)

            : (<> <button className="btn pink-text btn-outline fs-6 px-4 btn-community-hot" id={rowData.userid} onClick={() => handleFollow(rowData.userid)}>

              Follow
            </button>


            </>)}

        </>}
      </div>
    </>)
  }

  return (
    <>
      <DataTable
        className="community-hot-table"
        value={hot}
        emptyMessage="There is no hot users data available yet!"
        scrollable
        scrollHeight="470px"
        style={{overflowX: "hidden"}}
        header={header} filters={filters} onFilter={(e) => setFilters(e.filters)} globalFilterFields={['user_name']}
      >
        <Column
          field="avatar"
          body={avatarTemplate}
          header="Profile" style={{ minWidth: '60px' }} headerStyle={{ width: '60px' }}
        ></Column>
        <Column
          field="user_name"
          body={nameTemplate}
          header="Name"
          filter
          filterField="user_name"
          filterPlaceholder="Search by name"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
        ></Column>
        <Column body={acceptRejectTemplate}></Column>
      </DataTable>
    </>
  )
}

export default HotPickComponent