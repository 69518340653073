import { Modal, Button, } from "react-bootstrap";
import { InsufficientBalance } from "../../SVGIcon";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { deleteVideoAPI } from '../../slices/video&postSlice.js';
const DeleteVideo = (props) => {
  const dispatch = useDispatch();
  const handleConfirmDelete = async (video_id) => {



    await dispatch(deleteVideoAPI({ videoId: video_id }))
      .unwrap()
      .then((res) => {
        toast.success(res.message);
        setTimeout(() => {
          props.handlevideolist();
          props.onHide();
        }, 1000)
      })
  }
  return (
    <>

      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered keyboard={false} backdrop="static"
      >
        <Modal.Header closeButton >
        </Modal.Header>
        <Modal.Body className="p-5 delete-modal-body">
          <div className="d-flex justify-content-center my-3">
            <InsufficientBalance />
          </div>
          <p className="bg-gray fw-bolder text-center fs-5 mt-0">Do you want to delete this video ?</p>
          <div className="text-center video-delete-modal-phone">
            <Button className="mt-3 me-3 outline-btn" type="button" onClick={props.onHide}>Cancel</Button>
            <Button className="mt-3" type="button" onClick={() => { handleConfirmDelete(props.getvideoId) }}>
              Confirm</Button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  )
}

export default DeleteVideo;





