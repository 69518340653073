
import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Container, Row, Col, Form, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAnotherUserActiveGames, getAnotherUserUpcomingGames, dashboardSelector } from '../../slices/dashboardSlice';
import AnotherActiveGames from './AnotherActiveGames';
import AnotherUpcomingGames from './AnotherUpcomingGames';
const AnotherGames = ({ anotherUserId }) => {
    console.log(anotherUserId,"anotherUserId");

    const { anotherUpcomingResponse, anotherActiveResponse, isFetching } = useSelector(dashboardSelector);
    const dispatch = useDispatch();
    const [getUpcoming, setUpcoming] = useState([]);
    const [getActive, setActive] = useState([]);
    const handleAllUpcoming = () => {

        dispatch(getAnotherUserUpcomingGames({ user_id: anotherUserId }))
            .unwrap()
            .then((res) => {
                setUpcoming(res?.data);
            }).catch((err) => console.log(err));
    }
    

    useEffect(() => {
        dispatch(getAnotherUserActiveGames({ user_id: anotherUserId })).unwrap().then((res) => {
            setActive(res?.data);
            handleAllUpcoming();
        })

    }, [dispatch])


    return (
        <div className="card card-custom gutter-b community-card">
            <div className="custom-card-header">
                <div className='card-title text-center mb-0'>
                    <h4 className="card-label align-items-center custom-heading d-inline">Games</h4>
                </div>
                <div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="active">
                        <Col className="custom-tabs-community">
                            <Nav variant="pills" className='user-tab d-flex justify-content-lg-around justify-content-md-around justify-content-sm-around gamesDual-nav-tab-phone' >
                                <Nav.Item>
                                    <Nav.Link eventKey="active" title="ACTIVE">ACTIVE</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="upcoming" title="UPCOMING">UPCOMING</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col lg={12}>
                            <Tab.Content className='p-0'>
                                <Tab.Pane eventKey="active">
                                    <AnotherActiveGames getActive={getActive} anotherActiveResponse={anotherActiveResponse} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="upcoming">
                                    <AnotherUpcomingGames getUpcoming={getUpcoming} anotherUpcomingResponse={anotherUpcomingResponse} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}

export default AnotherGames