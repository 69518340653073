// QA URL
//export const baseURL = "https://moonleaguellc-qa.chetu.com/";
import axios from "axios";

// LIVE BASE URL
//export const baseURL = "https://moonleague-backend.chetu.com/";
export const baseURL = "https://api.moonleague.com/";
//export const baseURL = "http://127.0.0.1:8000/";

export const KycURL = "https://web.idologylive.com/";

// cancel useeffect 
// export  const source = axios.CancelToken.source();

