import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDuelExchange,
  getDuelMode,
  getTraders,
  getShowMeMoney,
  createDuelAPI,
  getStocksHolidays,
} from "../../slices/createDuelSlice";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import getDay from "date-fns/getDay";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { convertToMinutes } from "../../util/common";
const CreateDuelsForm = (callback) => {
  const dispatch = useDispatch();
  const [duelType, setDuelType] = useState([]);
  const [duelVisibil, setDuelVisibil] = useState("");
  const [duelEntryType, setDuelEntryType] = useState([]);
  const [noTraders, setNoTraders] = useState([]);
  const [duelMode, setDuelMode] = useState([]);
  const [duelExchange, setDuelExchange] = useState([]);
  const [showMoney, setShowMoney] = useState([]);
  const [selectAll, setSelectAll] = useState([]);
  const [prizePoolValue, setPrizePool] = useState();
  const [EntryLimit, setEntryLimit] = useState();
  const [entryAmount, setEntryAmount] = useState();
  const [count, setCount] = useState("0:0");
  const [stocksCount, setStocksCount] = useState("0:0");
  const [startDate, setStartDate] = useState();
  const [stocksDisabled, setStockDisabled] = useState(true);
  const [tStartDate, tSetStartDate] = useState();
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const [errors, setErrors] = useState({});
  const [bool, setBool] = useState("");
  const [getExchangeName, setExchangeName] = useState([]);
  const [exchangeMode, setExchangeMode] = useState(null);
  const [getoptionID, setOptionId] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [inValidDate, setInvalidDate] = useState([]);
  const [duelCreate, setDuelCreate] = useState({
    duel_name: "",
    duel_type: "Trader",
    trader: "",
    show_me_money: "",
    duel_visibility: "",
    duel_mode: "",
    duel_exchange: "",
    duel_entry_type: "",
    duel_entry_fee: "",
    duel_duration: 0,
    duel_start: "",
    duel_description: "",
    duel_exchange_crypto: "",
    duel_duration_stock: "",
    duel_exchange_mode: "",
    duel_start_trader: "",
    // uploadImage: "",
  });

  const handleChangeRaw = (value) => {
    if (value !== undefined) {
      let pickedDate = moment(value, "MM/DD/YYYY h:i:s");
      if (moment(value, "MM/DD/YYYY h:i:s", true).isValid()) {
        setValid(true);

        setStartDate(pickedDate);
      } else {
        setValid(false);
        // setStartDate(null);
        setStartDate(null);
      }

      let date = moment(value);
    } 
   
  };
  const handleChangeRaw1 = (value) => {
    if (value !== undefined) {
      let pickedDate = moment(value, "MM/DD/YYYY h:i:s");
      if (moment(value, "MM/DD/YYYY h:i:s", true).isValid()) {
        setValid(true);

        tSetStartDate(pickedDate);
      } else {
        setValid(false);

        tSetStartDate(null);
      }

      let date = moment(value);
    } 
   
  };

  const onChangeDuelHandler = (event) => {
    setDuelCreate(() => ({
      ...duelCreate,
      [event.target.name]: event.target.value,
    }));
  };

  //Duel Type
  const handleDuelType = (e) => {
    e.preventDefault();

    setDuelType(e.value);

    setDuelCreate({ ...duelCreate, duel_type: e.value.name });
  };

  //Total No. of Trade
  const handleTotalTrade = (e) => {
    e.preventDefault();
    setNoTraders(e.value);
    setDuelCreate({
      ...duelCreate,
      trader: e.value.no_of_trades,
      show_me_money: "",
    });
  };

  //Duel Visibility
  //   duel_visibility
  const handleDuelVisibility = (e) => {
    e.preventDefault();
    setDuelVisibil(e.value);
    setDuelCreate({
      ...duelCreate,
      duel_visibility: e.value.name === "Public" ? 1 : 0,
    });
  };

  //Handle Duel Mode
  const handleDuelMode = (e) => {
    e.preventDefault();
    setDuelMode(e.value);

    setDuelCreate({ ...duelCreate, duel_mode: e.value.name });
  };

  //Handle Entry List

  const handleEntryType = (e) => {
    e.preventDefault();
    setDuelEntryType(e.value);
    setBool(e.value.name);
    setDuelCreate({
      ...duelCreate,
      duel_entry_type: e.value.name === "Paid" ? 0 : 1,
    });
  };

  const handleonFocus = async () => {
    await dispatch(getDuelExchange());
  };
  const handleStocksMode = async (e) => {
    // debugger;
    e.preventDefault();
    setExchangeMode(e.value);
    setDuelCreate({ ...duelCreate, duel_exchange_mode: e.value });
  };

  useEffect(() => {
    if (exchangeMode === "Crypto") {
      setDuelCreate({ ...duelCreate, duel_exchange_crypto: [3] });

      setStartDate(null);
      setDuelCreate({ ...duelCreate, duel_duration_stock: "" });
      setDuelCreate({ ...duelCreate, duel_start_trader: "" });
    } else if (exchangeMode === "Stocks") {
      setDuelCreate({ ...duelCreate, duel_start: "" });
    }
  }, [exchangeMode !== null]);
  //Handle Duel Exchange
  const handleDuelExchange = async (e) => {
    e.preventDefault();

    setDuelExchange(e.value);

    setDuelCreate({ ...duelCreate, duel_exchange: e.value });
  };

  //Handle Show Money

  const handleShowMoney = (e) => {
    e.preventDefault();
    setShowMoney(e.value);

    setDuelCreate({ ...duelCreate, show_me_money: e.value, trader: "" });
  };

  //Handle Prize Pool
  const handlePrizePool = (e) => {
    e.preventDefault();
    setPrizePool(e.value);
    setDuelCreate({ ...duelCreate, duel_max_prize_pool: e.value });
  };

  //Handle Entry Limit

  const handleEntryLimit = (e) => {
    e.preventDefault();
    setEntryLimit(e.value);
    setDuelCreate({ ...duelCreate, duel_entry_limit: e.value });
  };

  //Handle Entry Amount
  const handleEntryAmount = (e) => {
    e.preventDefault();
    setEntryAmount(e.value);

    setDuelCreate({ ...duelCreate, duel_entry_fee: e.value });
  };

  useEffect(() => {
    if (duelEntryType.name === "Free") {
      setEntryAmount("");
      setDuelCreate({ ...duelCreate, duel_entry_fee: "" });
    }
  }, [duelEntryType]);

  const selectedExchangeOption = (option) => {
    if (option) {
      return (
        <>
          <div className="country-item country-item-value">
            <div>{option.exchange_name}</div>
          </div>
        </>
      );
    }

    return "Select duel exchange";
  };

  const ExchangeOptionTemplate = (option) => {
    return (
      <>
        <div className="flex align-items-center">
          <div>
            <span>{option.exchange_name}</span>
          </div>
        </div>
      </>
    );
  };

  const ExchangeOptionDisable = (option) => {
    return (
      <>
        <div>
          {option.is_trading_open === 0 && (
            <span className="text-danger"> {option.exchange_name}</span>
          )}
        </div>
      </>
    );
  };
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>
            {option.currency_symbols}
            {option.amount}
          </div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>
          {option.currency_symbols}
          {option.amount}
        </div>
      </div>
    );
  };

  //Duel Count

  const newhandle = (e) => {
    var time1 = count;

    var totalInMinutes =
      parseInt(time1.split(":")[0]) * 60 + parseInt(time1.split(":")[1]);

    var otherMinutes = 15;

    var grandTotal = otherMinutes + totalInMinutes;

    //Now using your own code

    var bookH = Math.floor(grandTotal / 60);
    var bookM = grandTotal % 60;
    var bookingDurationToHour = bookH + ":" + bookM;

    let duelLimit = "12:15";

    // if (bookH > duelLimit && mLimit) {
    if (bookingDurationToHour === duelLimit) {
      toast.error("Maxm duel duration will be 12hrs.");
      return false;
    } else {
      setCount(bookingDurationToHour);
    }
  };

  const decrementCount = (e) => {
    var decreseTime = count;
    var totalInMinutes =
      parseInt(decreseTime.split(":")[0]) * 60 +
      parseInt(decreseTime.split(":")[1]);

    var otherMinutes = 15;

    var grandTotal = totalInMinutes - otherMinutes;

    var bookH = Math.floor(grandTotal / 60);
    var bookM = grandTotal % 60;
    var MinusDurationToHour = `${bookH}:${bookM}`;

    if (count !== "0:0") {
      setCount(MinusDurationToHour);
    } else {
      return false;
    }
  };

  function timeDiffInHours(inputTime, max4PM) {
    const now = inputTime;
    const fourPM = max4PM;

    const diffInMs = fourPM - now; // get the difference in milliseconds
    const diffInHours = diffInMs / (1000 * 60 * 60); // convert to hours

    return diffInHours;
  }

  // handle Stocks Durations
  const incrementStocks = (e) => {
    var time1 = stocksCount;

    var totalInMinutes =
      parseInt(time1.split(":")[0]) * 60 + parseInt(time1.split(":")[1]);

    var otherMinutes = 15;

    var grandTotal = otherMinutes + totalInMinutes;

    //Now using your own code

    var bookH = Math.floor(grandTotal / 60);
    var bookM = grandTotal % 60;
    var bookingDurationToHour = bookH + ":" + bookM;

    let duelLimit = "12:15";

    // if (bookH > duelLimit && mLimit) {
    let checkstockTime = moment(tStartDate).format("h:mm A");
    let maxTime = "16:00";

    if (bookingDurationToHour === duelLimit) {
      toast.error("Maxm duel duration will be 12hrs.");
      return false;
    } else if (checkstockTime === "12:00 AM") {
      toast.error("Please select time between 9:30 AM to 16:00 PM.");
      return false;
    } else {
      let checkstockTime2 = moment(tStartDate).format("HH:mm");

      let checkDiff = diffTime(checkstockTime2, maxTime);

      const totalDiffMinutes = convertToMinutes(checkDiff);

      let tradingTimeString = bookingDurationToHour;
      const totalTradingMinutes1 = convertToMinutes(tradingTimeString);

      var startTime = moment(checkstockTime2, "HH:mm");
      var endTime = moment(maxTime, "HH:mm");
      var minutesDiff = endTime.diff(startTime, "minutes");

      if (totalTradingMinutes1 > minutesDiff) {
        toast.error(`you can't select more than ${minutesDiff} minutes`);
        return false;
      } else if (totalDiffMinutes !== 0) {
        setStocksCount(bookingDurationToHour);
      } else {
        toast.error(`you can't select more than ${totalDiffMinutes} minutes`);
        return false;
      }
    }
  };

  const decrementStocks = (e) => {
    // stocksCount,setStocksCount
    var decreseTime = stocksCount;
    var totalInMinutes =
      parseInt(decreseTime.split(":")[0]) * 60 +
      parseInt(decreseTime.split(":")[1]);

    var otherMinutes = 15;

    var grandTotal = totalInMinutes - otherMinutes;

    var bookH = Math.floor(grandTotal / 60);
    var bookM = grandTotal % 60;
    var MinusDurationToHour = `${bookH}:${bookM}`;

    if (stocksCount !== "0:0") {
      setStocksCount(MinusDurationToHour);
    } else {
      return false;
    }
  };

  // Remove colon
  function removeColon(s) {
    if (s.length === 4) {
      s = s.replace(":", "");
    }

    if (s.length === 5) {
      s = s.replace(":", "");
    }

    return parseInt(s);
  }
  function diffTime(s1, s2) {
    // change string (eg. 2:21 --> 221, 00:23 --> 23)
    let time1 = removeColon(s1);

    let time2 = removeColon(s2);

    // difference between hours
    let hourDiff = parseInt(time2 / 100 - time1 / 100 - 1);

    // difference between minutes
    let minDiff = parseInt((time2 % 100) + (60 - (time1 % 100)));

    if (minDiff >= 60) {
      hourDiff++;
      minDiff = minDiff - 60;
    }

    // convert answer again in string with ':'
    let res = hourDiff.toString() + ":" + minDiff.toString();
    return res;
  }

  let s1 = "14:15";
  let s2 = "16:00";

  let timeString = count;
  const totalMinutes = convertToMinutes(timeString);

  let tradingTimeString = stocksCount;
  const totalTradingMinutes = convertToMinutes(tradingTimeString);

  useEffect(() => {
    setDuelCreate({ ...duelCreate, duel_duration: totalMinutes });
  }, [count]);
  useEffect(() => {
    setDuelCreate({ ...duelCreate, duel_duration_stock: totalTradingMinutes });
  }, [stocksCount]);

  //Validate EditForm

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  //Validation On Submit
  //  console.log(duelCreate.duel_description,duelCreate.duel_description.length,'duelCreate.duel_description.length');
  const validateProfileSubmit = () => {
    // debugger;

    let validationError = { ...errors };
    let TraderStartTime = tStartDate;
    let check12hr = moment(TraderStartTime).format("h:mm:ss A");

    //const reg = new RegExp("^[A-Za-z]+$");
    if (!duelCreate.duel_name) {
      validationError.duel_name = "Duel name is required";
    }
    else if(duelCreate.duel_name.length > 50) { 
      validationError.duel_name = "Max 50 characters.";
    } 
    else {
      validationError.duel_name = "";
    }
    if (!duelCreate.duel_type) {
      validationError.duel_type = "Duel type is required";
    } else {
      validationError.duel_type = "";
    }
    if (
      duelCreate.duel_type === "Show Me The Money" &&
      duelCreate.show_me_money === ""
    ) {
      validationError.show_me_money = "Show me money is required";
    } else {
      validationError.show_me_money = "";
    }
    if (duelCreate.duel_type === "Trader" && duelCreate.trader === "") {
      validationError.trader = "No. of trader is required";
    } else {
      validationError.trader = "";
    }

    if (!duelVisibil) {
      validationError.duel_visibility = "Duel visibility is required";
    } else {
      validationError.duel_visibility = "";
    }
    if (!duelCreate.duel_mode) {
      validationError.duel_mode = "Duel mode is required";
    } else {
      validationError.duel_mode = "";
    }

    if (!bool) {
      validationError.duel_entry_type = "Duel entry type is required";
    } else {
      validationError.duel_entry_type = "";
    }

    if (bool === "Paid" && !duelCreate.duel_entry_fee) {
      validationError.duel_entry_fee = "Amount is required";
    } else {
      validationError.duel_entry_fee = "";
    }

    if (!duelCreate.duel_exchange) {
      validationError.duel_exchange = "Duel exchange is required";
    } else {
      validationError.duel_exchange = "";
    }

    if (duelExchange.length === 1 && duelExchange.includes(3)) {
      //duel_duration for Crypto
      validationError.duel_exchange = "";
      validationError.duel_duration_stock = "";
      validationError.duel_start_trader = "";
      if (duelCreate.duel_duration === 0) {
        validationError.duel_duration = "Duration is required";
      } else {
        validationError.duel_duration = "";
      }
      //duel_start
      if (!duelCreate.duel_start) {
        validationError.duel_start = "Start date and time is required";
      } else {
        validationError.duel_start = "";
      }
    } else {
      //duel_duration_stock
      if (!duelCreate.duel_duration_stock) {
        validationError.duel_duration_stock = "Duration is required";
      } else {
        validationError.duel_duration_stock = "";
      }

      //duel_start_trader
      if (!duelCreate.duel_start_trader) {
        validationError.duel_start_trader = "Start date and time is required";
      } else if (duelCreate.duel_start_trader && check12hr === "12:00:00 AM") {
        validationError.duel_start_trader =
          "Start time must be between 9:30 AM to 4:30 PM";
      } else {
        validationError.duel_start_trader = "";
      }
    }

    //duel_description
    if (!duelCreate.duel_description) {
      validationError.duel_description = "Description is required";
    } 
    else if(duelCreate.duel_description.length > 200) { 
      validationError.duel_description = "Max 200 characters.";
    }
    else {
      validationError.duel_description = "";
    }

    setErrors(validationError);

    return validationError;
  };
  let locacluserid = window.localStorage.getItem("userid");

  let duelForm = {
    user_id: locacluserid,
    duel_name: duelCreate.duel_name,
    duel_type: duelCreate.duel_type,
    show_me_money: duelCreate.show_me_money,
    trader: duelCreate.trader,
    duel_start_time_utc:
      duelExchange.length === 1 && duelExchange.includes(3)
        ? duelCreate.duel_start
        : duelCreate.duel_start_trader,
    duel_duration_minutes:
      duelExchange.length === 1 && duelExchange.includes(3)
        ? duelCreate.duel_duration
        : duelCreate.duel_duration_stock,
    // duel_start_time_utc: duelCreate.duel_exchange_mode ==="Crypto" ? duelCreate.duel_start : duelCreate.duel_start_trader,
    // duel_duration_minutes: duelCreate.duel_exchange_mode ==="Crypto" ? duelCreate.duel_duration : duelCreate.duel_duration_stock,
    duel_visibility: duelCreate.duel_visibility,
    duel_mode: duelCreate.duel_mode,
    duel_entry_type: duelCreate.duel_entry_type,
    // duel_entry_limit: duelCreate.duel,
    //duel_max_prize_pool: 0,
    duel_entry_fee: duelCreate.duel_entry_fee,
    duel_description: duelCreate.duel_description,
    duel_exchange:
      duelCreate.duel_exchange_mode === "Crypto"
        ? [3]
        : duelCreate.duel_exchange,
  };

  //Function to hrs and minutes
  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  } // { hours: 1, minutes: 40 }

  //OnSumbit Duel

  async function handleCreateDuel(e) {
    e.preventDefault();

    const validationError = validateProfileSubmit();

    try {
      if (validateForm(validationError)) {
        setIsSending(true);
        callback();
        setIsSent(true);
        setIsSending(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const showNewList = [
    {
      id: 1,
      amount: "1000",
      currency_symbols: "$",
      created_at: "2023-03-15T15:21:47.000000Z",
      updated_at: null,
    },
    {
      id: 2,
      amount: "10,000",
      currency_symbols: "$",
      created_at: "2023-03-15T15:21:47.000000Z",
      updated_at: null,
    },
    {
      id: 3,
      amount: "100,000",
      currency_symbols: "$",
      created_at: "2023-03-15T15:21:47.000000Z",
      updated_at: null,
    },
  ];
  const duelTypeList = [{ name: "Trader" },{ name: "Show Me The Money coming soon" }];
  const duelTypeComingsonn =  [{
    items: [{ name: "Trader" }],
    label: 'Show Me The Money'
    }];
    // const duelTypeTradeComingsonn = [{ name: "Trader" }];
// ******this is to enable show me my money obj.
  // const [type, setType] = useState(duelTypeList);

  // ******for disabling the show me my money we create duelTypecominsonn obj.
  const [type, setType] = useState(duelTypeComingsonn);
  // const [type, setType] = useState(duelTypeTradeComingsonn);


  const duelVisibilList = [{ name: "Public" }, { name: "Private" }];

  const duelExchangeModeList = [{ name: "Stocks" }, { name: "Crypto" }];

  const duelModeList = [
    { name: "Standard" },
    { name: "Bloodbath" },
    { name: "Standard & Battle" },
    { name: "Bloodbath & Battle" },
  ];

  const duelExchangeList = [
    { name: "NYSE & NASDAQ" },
    { name: "NYSE & CRYPTO" },
    { name: "NASDAQ & CRYPTO" },
  ];
  const duelEntryList = [{ name: "Paid" }, { name: "Free" }];

  //getDuelExchange
  const {
    exchangeList,
    loading,
    noTradersList,
    showMoneyList,
    stocksHolidayList,
  } = useSelector((state) => state.createDuel);

  let filterExchangeist = [];
  filterExchangeist = exchangeList?.filter((c) => c.exchange_name !== "CRYPTO");

  useEffect(() => {
    dispatch(getStocksHolidays());
  }, [dispatch]);

  useEffect(() => {
    let StockHolidayDates = stocksHolidayList?.map((c, i) => c.holiday_date);
    const excludedDatesAsDates = StockHolidayDates?.map(
      (dateString) => new Date(dateString)
    );

    setInvalidDate(excludedDatesAsDates);
  }, [stocksHolidayList?.length > 0]);

  useEffect(() => {
    dispatch(getDuelMode());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTraders());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getShowMeMoney());
  }, [dispatch]);

  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  
  return {
    duelCreate,
    isSending,
    isSent,
    setDuelCreate,
    onChangeDuelHandler,
    duelType,
    duelVisibil,
    duelEntryType,
    noTraders,
    duelMode,
    duelExchange,
    duelTypeList,
    duelTypeComingsonn,
    noTradersList,
    showMoneyList,
    duelVisibilList,
    duelModeList,
    duelExchangeList,
    duelEntryList,
    showMoney,
    setShowMoney,
    setDuelType,
    setDuelVisibil,
    setDuelEntryType,
    setDuelExchange,
    setDuelMode,
    setNoTraders,
    handleDuelType,
    handleTotalTrade,
    exchangeList,
    handleDuelVisibility,
    handleDuelMode,
    handleEntryType,
    handleDuelExchange,
    handleShowMoney,
    handleStocksMode,
    selectedCountryTemplate,
    ExchangeOptionTemplate,
    startDate,
    setStartDate,
    handleChangeRaw,
    handleChangeRaw1,
    errors,
    ExchangeOptionDisable,
    getExchangeName,
    setExchangeName,
    filterExchangeist,
    duelExchangeModeList,
    tStartDate,
    tSetStartDate,
    countryOptionTemplate,
    prizePoolValue,
    handlePrizePool,
    EntryLimit,
    handleEntryLimit,
    entryAmount,
    handleEntryAmount,
    selectedExchangeOption,
    newhandle,
    decrementCount,
    count,
    handleCreateDuel,
    exchangeMode,
    incrementStocks,
    decrementStocks,
    setStockDisabled,
    stocksDisabled,
    inValidDate,
    setExchangeMode,
    handleonFocus,
    disabled,
    setDisabled,
    stocksCount,
    isWeekday,
    filterPassedTime,
    duelForm,
    type,
    showNewList,
    setStocksCount,
  };
};

export default CreateDuelsForm;
