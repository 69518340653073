import { timeAgo } from "../../util/common";

const UserChatComponent = ({ getFrndsChat }) => {

    return (
        <>
            {getFrndsChat?.length > 0 ? getFrndsChat?.map((c, i) => {
                return (<>
                    <div className="p-1" key={c.chat_id} id={c.user_id}>
                        <div className="d-flex align-items-center  px-3">
                            <div className="me-3">
                                {c.avatar !== "" ? (<> <img src={c.avatar} className="img-fluid user-img" /></>) : (<> <img src="/icon/default-profile.png" className="img-fluid user-img" /></>)}

                            </div>
                            <h4 className="blue-text me-2 mb-0 cfs-6">{c.user_name}</h4>
                            <span className="me-2 custom">
                                {timeAgo(c.date)}
                            </span>
                        </div>
                        <p className="text-justify ms-5 fw-medium ps-3 mb-0">{c.message}</p>

                    </div>

                </>)
            }) : (<><p className="bg-gray fw-500 text-center">You havent talked with any of your friends yet. Start a conversation</p></>)}

        </>
    )
}

export default UserChatComponent