import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import OTPForm from "./otpForm";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { verifyOTP, resendOTP } from "../../slices/authSlice";

const OTP = () => {
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    otp,
    minutes,
    seconds, resendOTP1,
  } = OTPForm(() => verifyOTPAPI(values));

  const ValidateOTP = async () => {
    toast.success("OTP validated");
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // Using the useDispatch hook to send payload back to redux



  const verifyOTPAPI = async (values) => {
    try {
      const res = await dispatch(verifyOTP(values)).unwrap();

      if (res.status_code === 200) {

        setTimeout(() => {
          navigate("/reset-password");
        }, 1500)


      }
    }
    catch (error) {
      console.log("error tr", error);
    }


  };

  const localemail = window.localStorage.getItem("email");

  const [emailResend, setResendEmail] = useState({ email: localemail });

  const resendOTPAPI = async () => {
    try {
      const res = await dispatch(resendOTP(emailResend)).unwrap();
      console.log(res, 'Forgot Password res');
      if (res.status_code === 200) {
        console.log(res, 'Login res');



      }
    }
    catch (error) {
      console.log("error tr", error);
    }

  }


  const handleResendOTP = () => {
    resendOTP1();
    resendOTPAPI();
  }
  return (
    <div className="vid-container">
      <video muted autoPlay loop className="video">
        <source src="images/Moonleague.webm" type="video/webm" />
      </video>

      <div className="login-form">
        <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4">
          <div className="form-box">
            <Link to="/">
              <div className="logo">
                <img
                  src="images/logo-white.png"
                  className="img-fluid img-responsive"
                  alt="moon logo"
                />
              </div>
            </Link>
            <div className="text-center">
              <h3 className="fs-4 fw-normal mt-2 form-tag">Verify-OTP</h3>
              <div className="sign-font">
                Already have an account?&nbsp;&nbsp;{" "}
                <Link to="/">Sign in here</Link>
              </div>
            </div>
            <Form className="mt-4" noValidate onSubmit={handleSubmit}>
              <Form.Group
                className="mb-3 text-start"
                controlId="formBasicEmail"
              >
                <Form.Label className="formlabel">
                  Entered OTP sent to your registered Email ID.
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  className="form-input"
                  name="otp"
                  autoComplete="off"
                  value={values.otp || ""}
                  onChange={handleChange}
                />
                {errors.otp && (
                  <div
                    className="help text-danger text-start"
                    aria-live="assertive"
                  >
                    {errors.otp}
                  </div>
                )}
              </Form.Group>
              <div className="d-flex justify-content-between countdown-text">
                {seconds > 0 || minutes > 0 ? (
                  <div className="">
                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </div>
                ) : (
                  <p>Did not receive code?</p>
                )}

                <div className="">
                  <button type="button" className="btn btn-link"
                    disabled={seconds > 0 || minutes > 0}
                    style={{
                      color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#7a52fa",
                    }}
                    onClick={handleResendOTP}
                  >
                    Resend OTP
                  </button>

                </div>
              </div>

              <Button
                variant="primary"
                type="submit"
                className="mt-3 custom-btn"
                size="lg"
              >
                Verify
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default OTP;
