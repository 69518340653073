import React from 'react'

import { Link } from "react-router-dom";

const Unauthorized = () => (
  <>
    <div className='vid-container'>
      <video muted autoPlay loop className="video">
        <source src="images/Moonleague.webm" type="video/webm" />
      </video>
      <div className='login-form  flex-column'>
        <div className='form-box'>
          <h1>Unauthorized page</h1>
          <p>You do not have permission to access this page.</p>
          <Link to="/" className='link-btn d-inline-block '>Go back to login</Link>
        </div>

      </div>
    </div>



  </>
);

export default Unauthorized;