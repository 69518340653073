import React from 'react'
import { NavLink } from 'react-router-dom'

const NavbarStatic = () => {
  return (
    <>
      <div className="main-background">
        <div className=" " id="container-main">
          <nav className="nav-main">
            <ul className="mb-0 ps-md-0 px-2">
              <li>
                <NavLink to="/">
                  <img
                    src="/images/logo-white.png"
                    className="img-fluid w250"
                    alt="moonleague logo"
                  />

                </NavLink>
              </li>

              <ul className="mb-0 ps-0 profile-nav">
                <li>
                  <NavLink to="/" className="fs-5">
                    <span className="userImage">
                      <img src="https://api.moonleague.com/images/email_template/user.png" className="img-fluid " alt="profile-Image" /></span>
                  </NavLink>
                </li>
              </ul>
            </ul>
          </nav>
        </div>
      </div>
    </>
  )
}

export default NavbarStatic