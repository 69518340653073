import React from 'react'
import { Carousel } from "primereact/carousel";

import { baseURL } from "../../config";
const AnotherVideoScroll = ({ anotherVideolist }) => {

  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const productTemplate = (product) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center hot-Active-card" id={product.id}>
        <div className='d-flex justify-content-evenly innter-carousel'>
          <video controls className="d-block w-100 another-users-video">
            <source src={`${baseURL}${product.video}`} type="video/mp4" />
          </video>

        </div>
      </div>
    );
  };
  return (
    <>


      {anotherVideolist?.length > 0 ? (
        <>
          <Carousel
            value={anotherVideolist}
            numVisible={1}
            numScroll={1}
            responsiveOptions={responsiveOptions}
            itemTemplate={productTemplate}
            showIndicators={false}
          />
        </>
      ) : (
        <>
          <p className="bg-gray fw-500 text-center" style={{ height: "208px" }}>There is no video data available yet!</p>
        </>
      )}
    </>
  )
}

export default AnotherVideoScroll