import React, { useState } from 'react'
import { Modal, Button, Form } from "react-bootstrap";
import { InputTextarea } from 'primereact/inputtextarea';
import { useSelector, useDispatch } from 'react-redux';
import { insertPostAPI } from '../../slices/video&postSlice';
import moment from 'moment/moment';

const AddPost = (props) => {
  const [values, setValue] = useState({ description: '' });
  let locacluserid = window.localStorage.getItem("userid");
  const dispatch = useDispatch();
  const { list } = useSelector((state) => state.userProfile);
 
  let formValues = {
    post_user_id: locacluserid,
    description: values.description,
    post_date: moment(new Date()).format('YYYY-MM-DD'),
  };

  const handleAddPost = async (e) => {
    debugger
    e.preventDefault();
    dispatch(insertPostAPI({ formValues })).unwrap().then((res) => console.log(res, 'Post upload response')).catch((err) => console.log(err));
    setValue({ ...values, description: '' })

    props.onHide();
    props.handleallpost();

  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered keyboard={false} backdrop="static"
      >
        <Modal.Header closeButton onClick={() => { setValue('') }}>
          <h3 className='bg-gray fw-bolder text-center fs-4 ms-4 mt-2'>Please add the post content</h3>
        </Modal.Header>
        <Modal.Body className="px-5 add-post-modal-body">

          <Form onSubmit={handleAddPost} autoComplete="off" noValidate>
            <InputTextarea value={values.description} onChange={(e) => setValue({ ...values, description: e.target.value })} rows={5} cols={30} />

            <div className="text-center">
              <Button className="mt-3 me-3 " type="submit" >Submit</Button>
            </div>
          </Form>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddPost