import React, { useState, useEffect } from 'react';
import { getHotPickAllCryptoAPI, getHotPickCryptoAPI, getHotPickNasdaqAPI, getHotPickNyseAPI } from "../../slices/hotpickSlice";
import { useDispatch } from "react-redux";
import { Carousel } from "primereact/carousel";
import { Dropdown } from 'primereact/dropdown';


const MostPick = () => {
  const [hotpick, setHotpick] = useState();
  const dispatch = useDispatch();
  const [activeExchange, setActiveExchange] = useState('1');
  const [symbols, setSymbol] = useState([{ name: 'All', exchange_id: '1' }]);
  const acExchage = [
    { name: 'All', exchange_id: '1' },
    { name: 'NYSE', exchange_id: '2' },
    { name: 'NASDAQ', exchange_id: '3' },
    { name: 'CRYPTO', exchange_id: '4' }
  ];



  const handleCoins = () => {
    if (activeExchange === "1") {
      dispatch(getHotPickAllCryptoAPI())
        .unwrap()
        .then((res) => {
          const data = res?.data;
          const newData = data?.map((item, index) => {
            return { ...item, serialNumber: index + 1 };
          });
          setHotpick(newData);
        });
    }
    else if (activeExchange === "2") {
      dispatch(getHotPickNyseAPI())
        .unwrap()
        .then((res) => {
          const data = res?.data;
          const newData = data?.map((item, index) => {
            return { ...item, serialNumber: index + 1 };
          });
          setHotpick(newData);
        });
    }
    else if (activeExchange === "4") {
      dispatch(getHotPickCryptoAPI())
        .unwrap()
        .then((res) => {
          const data = res?.data;
          const newData = data?.map((item, index) => {
            return { ...item, serialNumber: index + 1 };
          });
          setHotpick(newData);
        });
    }
    else if (activeExchange === "3") {
      dispatch(getHotPickNasdaqAPI())
        .unwrap()
        .then((res) => {
          const data = res?.data;
          const newData = data?.map((item, index) => {
            return { ...item, serialNumber: index + 1 };
          });
          setHotpick(newData);
        });
    }
  };

  useEffect(() => {
    setSymbol(acExchage);
    handleCoins()

  }, [activeExchange])

  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];


  const hotpickTemplate = (hotPick) => {

    return (
      <div className="border-1 surface-border border-round m-2 text-center hot-Active-card">
        <div className="p-3 text-center">
          <div className='row d-flex justify-content-between'>
            <div className="hotpickNumber bg-light-blue" >{hotPick?.serialNumber}</div>

            <div className="ribbon-tier">TIER {hotPick?.tier}</div>
          </div>
          <img src={hotPick?.symbol_logo} className="img-fluid" />
          <h4 className="dark-blue my-3">{hotPick?.join_base_symbol}</h4>
          <p className="dark-blue fs-9 mb-0 fw-500">{hotPick?.symbol_short_name}</p>
          <p className="fs-6 mb-0 pink-text">{hotPick?.exchange_id}</p>
          {hotPick?.symbol_current_price >= "0%" ? <>{hotPick?.symbol_current_price === "0%" ? <p className="text-warning fw-bold cfs-6 mb-0 ms-2">{hotPick?.symbol_current_price} </p>
            : <p className="green-text fw-bold cfs-6 mb-0 mt-3">+ {hotPick?.symbol_current_price}</p>}</> : <p className="text-danger fw-bold cfs-6 mb-0 ms-2">- {hotPick?.symbol_current_price} </p>}
        </div>
      </div>
    );
  };


  return (
    <>
      <div className="card card-custom gutter-b ">
        <div className="custom-card-header">
          <div className="row card-title mx-auto">
            <div className="col-md-7 col-xs-12 d-flex justify-content-end align-items-center most-picked-title">
              <h4 className="text-uppercase custom-heading mx-3">
                Most Picked
              </h4>
            </div>
            <div className="col-md-5 col-xs-12 d-flex justify-content-end">
              <Dropdown value={activeExchange} onChange={(e) => { setActiveExchange((prev) => (prev = e.value)); }} options={symbols}
                optionLabel="name" placeholder="Select Exchange" optionValue="exchange_id" style={{ width: "159px" }} className="w-50 most-picked-dropdown" />
            </div>
          </div>
        </div>
        <div className="hot-active-body">
          {hotpick?.length > 0 ? (
            <>
              <Carousel
                value={hotpick}
                className="active-duels-carasoul"
                numVisible={3}
                numScroll={3}
                responsiveOptions={responsiveOptions}
                autoplayInterval={3000}
                itemTemplate={hotpickTemplate}
                showIndicators={false}
              />
            </>
          )
            : (
              <>
                <p className="bg-gray fw-500" style={{ height: "275px" }}>There is no hotpick data available yet!</p>
              </>
            )}


        </div>
      </div>
    </>
  )
}

export default MostPick