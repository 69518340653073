import React , {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import { Unity, useUnityContext } from "react-unity-webgl";
import { useDispatch } from 'react-redux';
import { is, sl } from 'date-fns/locale';
import { clearAllListeners } from '@reduxjs/toolkit';
import { set } from 'date-fns';


const UnityGame = () => {
  const { unityProvider, loadingProgression, isLoaded,requestFullscreen, sendMessage, unload } = useUnityContext({
    loaderUrl: "../unity/Build/WebGL_Desktop_1.13.loader.js",
    dataUrl: "../unity/Build/WebGL_Desktop_1.13.data.unityweb",
    frameworkUrl: "../unity/Build/WebGL_Desktop_1.13.framework.js.unityweb",
    codeUrl: "../unity/Build/WebGL_Desktop_1.13.wasm.unityweb",
    streamingAssetsUrl: "../unity/StreamingAssets/UnityServicesProjectConfiguration.json",
    companyName: "CyberDreamStudio",
    productName: "MoonDuel",
    productVersion: "0.1.13",
    webGLContextAttributes: {
      alpha: true,
      antialias: true,
      depth: true,
      failIfMajorPerformanceCaveat: true,
      powerPreference: "high-performance",
      premultipliedAlpha: true,
      preserveDrawingBuffer: true,
      stencil: true,
      desynchronized: true,
      xrCompatible: true,
    },
  });
  const location = useLocation();
  const dispatch = useDispatch(); 
  const handleClickEnterFullscreen = () => {
    requestFullscreen(true);
  }
  // Game Object Name: GameacManager
  // Method Name: SetRoomData
  const [prevLocation, setPrevLocation] = useState(location);
 

  useEffect(() => {
    if (isLoaded) {
      const intervalId = setInterval(() => {
        sendMessage("GameManager", "UpdateRoomData", location?.state?.duel_guid);
      }, 30000); 
      setTimeout(() => {
        sendMessage("GameManager", "SetRoomData", location?.state?.duel_guid);
      }, 3000);  
    return () => {
    clearInterval(intervalId);
    if (prevLocation.current !== location) {
        clearInterval(intervalId);
        dispatch(clearAllListeners());
        unload();
        }
        setPrevLocation(location);
      };
    }
  }, [isLoaded, location?.state?.duel_guid, dispatch, sendMessage, unload, prevLocation.current, location]);

  return (
    <>
      {!isLoaded && (
        <p className='fs-3 mb-0  font-size-bold text-dark mt-5'>Loading Application... <span className='pink-text'>{Math.round(loadingProgression * 100)}%</span></p>
        )}

      <Unity unityProvider={unityProvider} className="unity-game"/>
      <button onClick={handleClickEnterFullscreen} className='glowing-btn'><span className='glowing-txt'>Play in FullScreen</span></button>
    </>
  )
}

export default UnityGame