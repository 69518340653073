import { baseURL } from "../config";
import axios from "axios";
import { toast } from "react-toastify";
import authHeader from "../util/Authheader";

let localStorage;

const login = async (email, password) => {
  const url = `${baseURL}api/authenticate_user`;
  return axios
    .post(url, {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("userToken", response.data.token);
      }

      return response.data;
    })
    .catch((error) => {
      toast.error(error.message);
    });   
};



const registerUser = async (userData) => {
  const url = `${baseURL}api/create_user`;

  return axios
    .post(url, {
      userData,
    })
    .then((response) => {
      if (response.data.status_code === 200) {
        toast.success("Register successfully");

      } else {
        toast.error(response.data.message);
      }
    });
};

//Get userById

let locacluseid = window.localStorage.getItem("userid");

const getUserById = async () => {
  const url = `${baseURL}api/user/i/${locacluseid}`;

  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((err) => {
      console.log(err, "get UserDetails error");
    });
};

//Forgot Password


//Verify OTP
//User Logout
const logout = () => {
  window.localStorage.removeItem("userToken");
  window.localStorage.clear();
};

const authService = {
  login,
  registerUser,
  getUserById,
  logout,
};

export default authService;
