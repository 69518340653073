import { Row, Form, Col, FormControl, Button, } from "react-bootstrap";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from 'primereact/dropdown';
import { useState } from "react";

import { omit } from 'lodash';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { contactApi } from "../../slices/contactSlice";
import { ToastContainer } from "react-toastify";
import { EmailIcon1, PhoneIcon, SubjectIcon, UserName } from "../../SVGIcon";


const ContactUsForm = () => {
  const dispatch = useDispatch();
  const [ReasonType, setReasonType] = useState("select")
  const duelTypeList = [
    { name: 'Payment' },
    { name: 'Suggestion' },
    { name: 'Sale' },
    { name: 'Support' },
    { name: 'Advertising' }
  ];

  const [data, setData] = useState({
    name: '',
    email: '',
    phone_number: '',
    subject: '',
    ReasonType: '',
    message: ''
  })


  const handleMobile = (value, data1, event) => {
    let name = event.target.name;
    let val = event.target.value;
    onChangeValidate(name, event, val);
    setData({ ...data, phone_number: value });
  }

  const [formError, setFormError] = useState({})

  const onChangeValidate = (name, data, value) => {
    switch (name) {
      // start name case 
      case "name":
        if (!data) {
          setFormError({
            ...formError,
            name: "Name is required.",
          });
        }
        else {
          let newObj = omit(formError, "name");
          setFormError(newObj);
        }
        // end name case
        break;
      // start email case
      case "email":
        if (!data) {
          setFormError({
            ...formError,
            email: "Email address is required",
          });
        } else if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(data)
        ) {
          setFormError({
            ...formError,
            email: "Enter a valid email address"
          })
        } else {
          let newObj = omit(formError, "email");
          setFormError(newObj);
        }
        // end email case
        break;
      // start phone number case

      case "phone_number":
        if (!value) {
          setFormError({
            ...formError,
            phone_number: "Phone number is required",
          });
        }
        else if ((value || "").replace(/\ /g, "").length < 11) {
          setFormError({
            ...formError,
            phone_number: "Invalid phone number, must be 10 digits",
          });
        }
        else {
          let newObj = omit(formError, "phone_number");
          setFormError(newObj);
        }
        // end phone number case
        break;
      // start subject case
      case "subject":
        if (!data) {
          setFormError({
            ...formError,
            subject: "Subject is required"
          })
        } else {
          let newObj = omit(formError, "subject");
          setFormError(newObj);
        }
        // end subject case
        break;

      case "ReasonType":
        if (!data) {
          setFormError({
            ...formError,
            ReasonType: "Reason is required"
          })
        } else {
          let newObj = omit(formError, "ReasonType");
          setFormError(newObj);
        }
        // end subject case
        break;
      // start message case
      case "message":
        if (!data) {
          setFormError({
            ...formError,
            message: "Message is required"
          })
        } else {
          let newObj = omit(formError, "message");
          setFormError(newObj);
        }
        break;
    }

  }

  const onSubmitValidation = () => {
    let err = { ...formError }

    if (data.name.length <= 2) {
      err.name = 'Name is required!'
    }
    if (!data.email) {
      err.email = "Email address is required";
    } else if (data.email !== "undefined") {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(data.email)) {
        err.email = "Please enter valid email address";
      }
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      err.email = "Email address is invalid";
    }
    let phoneReg = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (data.phone_number === '') {
      err.phone_number = 'Phone number is required';
    }
    else if (!phoneReg.test(data.phone_number)) {
      err.phone_number = "Phone number is invalid";
    }
    if (data.ReasonType === '') {
      err.ReasonType = 'Reason is required'
    }
    if (data.subject === '') {
      err.subject = 'Subject is required'
    }
    if (data.message === '') {
      err.message = "Message is required"
    }

    setFormError(err);
    console.log(err, 'formErrorformErrorformError');
    return err;
  }

  // on submit handler
  const onContactHandler = (event) => {
    let name = event.target.name
    let dataVal = event.target.value
    setReasonType(event.target.value)
    //  console.log("Hello on Change Handler", ReasonType)

    setData({
      ...data,

      [name]: dataVal,
    });

    setData(() => ({
      ...data,

      [event.target.name]: event.target.value
    }))
    //  console.log(dataVal, "data Val")
    onChangeValidate(name, dataVal)
  }

  let contactData = {
    name: data.name,
    email: data.email,
    phone_number: data.phone_number,
    subject: data.subject,
    reasons: data.ReasonType,
    messages: data.message
  }

  const onSubmitHandler = async (event) => {
    event.preventDefault()


    let errs = onSubmitValidation(data);

    if (Object.keys(errs).length === 0 && Object.keys(data).length !== 0) {
      // console.log("errs", data)

      await dispatch(contactApi({ contactData }))
        .unwrap()
        .then((res) => {
          setData({ name: '', email: '', phone_number: '', subject: '', ReasonType: '', message: '' });
          //  console.log(res, "res1")
        })
        .catch((errs) => {

          // console.log("New Erro",)
          setFormError(onSubmitValidation);
        });
    }
    else {
      setFormError(errs);
    }
  }

  return (
    <>
      <div className="contact-us">
        <Form noValidate onSubmit={onSubmitHandler}>
          <Row>
            <Col lg={6}>
              <Row>
                <Col lg={12} className="mb-3">
                  <div className="contact-icon">
                    <FormControl
                      type="text"
                      placeholder="Name"
                      autoComplete="off"
                      id="name"
                      name="name"
                      required
                      onChange={onContactHandler}
                      value={data.name || ""}
                    />

                    <span className="input-icon"><UserName />
                    </span>
                  </div>
                  {formError.name && (
                    <div
                      className="help text-danger text-start mt-1"
                      aria-live="assertive"
                    >
                      {formError.name}
                    </div>
                  )}
                </Col>
                <Col lg={12} className="mb-3" >
                  <div className="contact-icon">
                    <FormControl
                      type="email"
                      placeholder="Email"
                      autoComplete="off"
                      id="email"
                      name="email"
                      required
                      onChange={onContactHandler}
                      value={data.email || ""}
                    />
                    <span className="input-icon">
                      <EmailIcon1 />
                    </span>
                  </div>
                  {formError.email && (
                    <div
                      className="help text-danger text-start mt-1"
                      aria-live="assertive"
                    >
                      {formError.email}
                    </div>
                  )}
                </Col>
                <Col lg={12} className="mb-3">
                  <div className="mobile-phone contact-icon">
                    <PhoneInput
                      country={"us"}
                      placeholder="(702) 123-4567"
                      value={data.phone_number || ''}
                      onChange={handleMobile}
                      disableCountryCode
                      disableDropdown="true"
                      disableAreaCodes
                      defaultCountry="us"
                      onlyCountries={['us']}
                      inputProps={{
                        name: "phone_number",
                        autoFocus: true,
                      }}

                    />
                    <span className="input-icon">
                      <PhoneIcon />
                    </span>
                  </div>
                  {formError.phone_number && (
                    <div
                      className="help text-danger text-start mt-1"
                      aria-live="assertive"
                    >
                      {formError.phone_number}
                    </div>
                  )}
                </Col>
                <Col lg={12} className="mb-3">
                  <div className="contact-icon">
                    <FormControl
                      type="text"
                      placeholder="Subject"
                      autoComplete="off"
                      id="subject"
                      name="subject"
                      required
                      onChange={onContactHandler}
                      value={data.subject || ""}
                    />
                    <span className="input-icon">
                      <SubjectIcon />
                    </span>
                  </div>
                  {formError.subject && (
                    <div
                      className="help text-danger text-start mt-1"
                      aria-live="assertive"
                    >
                      {formError.subject}
                    </div>
                  )}
                </Col>
                <Col lg={12} >
                  <Form.Group className="mb-4">
                    <div className="text-start">
                      <Dropdown
                        name="ReasonType"
                        value={ReasonType}
                        onChange={onContactHandler}
                        options={duelTypeList}
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select Reason" />
                    </div>
                    {formError.ReasonType && (
                      <div
                        className="help text-danger text-start mt-1"
                        aria-live="assertive"
                      >
                        {formError.ReasonType}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col lg={12} className="mb-3">
                  <div >
                    <InputTextarea name="message" rows={11} cols={30} placeholder="Message" id="message" className="p-4"
                      value={data.message || ""}
                      onChange={onContactHandler} />
                    {formError.message && (
                      <div
                        className="help text-danger text-start mt-1"
                        aria-live="assertive"
                      >
                        {formError.message}
                      </div>
                    )}
                  </div>
                </Col>
                <Col className="d-grid">
                  <Button type="submit" className="btn-form btn-contact py-3" onClick={() => (data)} >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        <ToastContainer />
      </div>
    </>
  )
}

export default ContactUsForm