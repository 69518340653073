import React from 'react'
import {  Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

const CheckLocationModel = (props) => {
    return (
        <>
            <Modal {...props} size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered keyboard={false} backdrop="static">
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body className="p-5">
                    <div className="d-flex justify-content-center text-black-50 align-items-center flex-column fw-semibold">
                    
                        {props.loading && (<><p className='text-info fw-semibold'>Please wait...</p></>)}
                        {props.getstatus === "DENIED" && (<>  <h2 className="text-danger fw-semibold m-3">{props.getstatus} </h2>

                            <p className="text-dark m-3 text-center">We have detected that you are attempting to wager from a state that Fantasy Gaming/Skill Based Gaming is not allowed. This conduct is in violation of the state that you’re currently located in and their state laws. You should immediately cease and desist from attempting to wager from an unauthorized state.</p>
                            <div className=''><Link to="/dashboard" className='btn duel-btn btn-dark m-3'>Go to home</Link></div>
                        </>)}
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default CheckLocationModel