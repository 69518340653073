import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { changeLocation, currentYear } from "../../../util/common";
import ScrollTopButton from "../../ScrollTopButton/ScrollTopButton";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <>
      <div className="b-example-divider"></div>
      <ScrollTopButton />
      <div className="container-fluid px-md-4 footer">
        <div className="footer-box">
          <footer className="row py-3 align-items-center">
            <div className="col-lg-4 col-12 text-lg-start text-sm-center d-flex justify-content-around">
              <div>
              <span className="text-white">{currentYear()} &copy; MoonLeague</span>
              </div>
              <div>
              <span className="ms-3"><FontAwesomeIcon icon="fa-brands fa-facebook-f" /></span>
              <span className="ms-3"><FontAwesomeIcon icon="fa-brands fa-twitter" /></span>
              <span className="ms-3"><FontAwesomeIcon icon="fa-brands fa-instagram" /></span>
              <span className="ms-3"><FontAwesomeIcon icon="fa-brands fa-discord" /></span>
              <span className="ms-3"><FontAwesomeIcon icon="fa-brands fa-tiktok" /></span>

              </div>
            </div>
            <ul className="nav col-lg-8 col-12 text-md-end text-sm-center list-unstyled d-flex justify-content-lg-end justify-content-md-center justify-content-center">
              <Nav.Link
                className="li"
                as={NavLink}
                exact="true"
                to="/about-us"
                onClick={() => changeLocation("/about-us")}
              >
                About Us
              </Nav.Link>

              <Nav.Link
                className="li"
                as={NavLink}
                exact="true"
                to="/terms-conditions"
                onClick={() => changeLocation("/terms-conditions")}
              >
                Terms & Conditions
              </Nav.Link>
              <Nav.Link
                className="li"
                as={NavLink}
                exact="true"
                to="/legal-notice"
                onClick={() => changeLocation("/legal-notice")}
              >
               Legal Notice
              </Nav.Link>
              <Nav.Link
                className="li"
                as={NavLink}
                exact="true"
                to="/how-it-works"
                onClick={() => changeLocation("/how-it-works")}
              >
               How It Works
              </Nav.Link>
              <Nav.Link
                className="li"
                as={NavLink}
                exact="true"
                to="/rules-scoring"
                onClick={() => changeLocation("/rules-scoring")}
              >
                Rules & Scoring
              </Nav.Link>
              <Nav.Link
                className="li"
                as={NavLink}
                exact="true"
                to="/faq"
                onClick={() => changeLocation("/faq")}
              >
                FAQ
              </Nav.Link>
              <Nav.Link
                className="li"
                as={NavLink}
                exact="true"
                to="/privacy-policy"
                onClick={() => changeLocation("/privacy-policy")}
              >
                Privacy Policy
              </Nav.Link>
              <Nav.Link
                className="li"
                as={NavLink}
                exact="true"
                to="/contact-us"
                onClick={() => changeLocation("/contact-us")}
              >
                Contact Us
              </Nav.Link>
            </ul>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Footer;
