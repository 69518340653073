import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Container, Row, Col, Form, Nav } from 'react-bootstrap';
import ActiveGames from './ActiveGames';
import UpcomingGames from './UpcomingGames';
import JoinedGames from './JoinedGames';
import Invitees from './Invitees';


//upcomingGamesList,getUpcomingGames
const GamesDuel = () => {


    
    return (
        <>
            <div className="card card-custom gutter-b game-card">
                <div className="custom-card-header">
                    <div className='card-title text-center mb-0'>
                        <h4 className="card-label align-items-center custom-heading d-inline">Games</h4>
                    </div>
                    <div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="active">
                            <Col className="custom-tabs-community">
                                <Nav variant="pills" className='user-tab d-flex justify-content-lg-around justify-content-md-around justify-content-sm-around gamesDual-nav-tab-phone' >
                                    <Nav.Item>
                                        <Nav.Link eventKey="active" title="ACTIVE">ACTIVE</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="joined" title="Joined">UPCOMING</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="upcoming" title="UPCOMING">MY DUELS</Nav.Link>
                                    </Nav.Item>
                                  
                                    <Nav.Item>
                                        <Nav.Link eventKey="invites" title="INVITES " className=''>INVITES</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col lg={12}>
                                <Tab.Content className='p-0'>
                                    <Tab.Pane eventKey="active">
                                        <ActiveGames />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="upcoming">
                                        
                                         <UpcomingGames />
                                       
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="joined">
                                        
                                        <JoinedGames />
                                      
                                   </Tab.Pane>
                                    <Tab.Pane eventKey="invites">
                                      <Invitees/>
                                         </Tab.Pane>
                                </Tab.Content>
                            </Col>

                        </Tab.Container>
                    </div>
                </div>

            </div>
        </>
    )
}

export default GamesDuel