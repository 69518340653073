import React from "react";
import { Form, Button } from "react-bootstrap";
import { Link,useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer,toast } from "react-toastify";
import ForgotForm from "./forgotForm";
import { forgotEmail,userSelector} from "../../slices/authSlice";

const ForgotPassword = () => {
  const { values, handleChange, handleSubmit, errors } = ForgotForm(() => forgotEmailAPI(values));

 
  
  
  //API Call
  let navigate = useNavigate();
  const dispatch = useDispatch();

  
  const { isFetching} = useSelector(userSelector);


  const forgotEmailAPI = async(values) => { 
    try {
      const res = await dispatch(forgotEmail(values)).unwrap();
   
      if (res.status_code === 200) {
     
        window.localStorage.setItem("userid", res.data.user_id);
        window.localStorage.setItem("email",res.data.email);
       
          setTimeout( () => {
      navigate("/verify-otp");
     },1500)
       
      
      } 
    } catch (error) {
   
      console.log("error tr", error);
    }

 
  };
  return (
    <>
      <div className="vid-container">
        <video muted autoPlay loop className="video">
          <source src="images/Moonleague.webm" type="video/webm" />
        </video>

        <div className="login-form">
          <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4">
            <div className="form-box">
              <Link to="/">
                <div className="logo">
                  <img
                    src="images/logo-white.png"
                    className="img-fluid img-responsive"
                    alt="moon logo"
                  />
                </div>
              </Link>
              <div className="text-center">
                <h3 className="fs-4 fw-normal mt-2 form-tag">Forgot Password</h3>
                <div className="sign-font">
                  Already have an account?&nbsp;&nbsp;{" "}
                  <Link to="/">Sign in here</Link>
                </div>
              </div>
              <Form className="mt-4" onSubmit={handleSubmit} noValidate>
                <Form.Group
                  className="mb-3 text-start"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="formlabel">Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    className="form-input"
                    name="email"
                    autoComplete="email"
                    value={values.email || ""}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div
                      className="help text-danger text-start"
                      aria-live="assertive"
                    >
                      {errors.email}
                    </div>
                  )}
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3 custom-btn"
                  size="lg"
                >
                  Submit
                </Button>
                {isFetching ? (<><p className="text-info">Please wait</p></>) : ""}
              </Form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default ForgotPassword;
