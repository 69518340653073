import React from 'react';
import Footer from '../MainLayout/Footer/Footer';
import Header from '../MainLayout/Header/Header';
import Navbar from '../MainLayout/Navbar/Navbar';
import authHeader from '../../util/Authheader';
import NavbarStatic from '../MainLayout/Navbar/NavbarStatic';

const HowItWorks = () => {
  let getToken = authHeader();

    return (
        <>
            {getToken === null ? <NavbarStatic /> : <Navbar />}
            <div className='main-layout'>
            {getToken === null ? '' : <Header />}
                <div className="py-3 text-start">
                    <div className="card-custom">
                        <div className='card-title text-center text-lg-start'>
                            <h4 className="dark-blue mb-0">How MoonLeague Works: </h4>
                        </div>
                        <div className="container-fluid px-md-4 py-3 main-contact-container term-box text-lg-start text-center">
                            <div className='d-flex align-items-center my-4'>
                                <div className='step-box me-3'>
                                    <span className='shadow-lg'>1</span>
                                </div>
                                <div>
                                    <h4 className='pink-text'>Step 1: Create an Account</h4>
                                    <p className='lh-lg'>Register for an account. You must be at least 14 years old to have a MoonLeague account and access our free to play games. Setting it up is easy, all you need is a current email address to start the process.</p>
                                </div>
                            </div>
                            <hr className='color-blue' />
                            <div className='d-flex align-items-center my-4'>
                                <div className='step-box me-3'>
                                    <span>2</span>
                                </div>
                                <div>
                                    <h4 className='pink-text'>Step 2: Customize your Account </h4>
                                    <p className='lh-lg'>After setting up your account feel free to customize your avatars and profile page.</p>
                                </div>
                            </div>
                            <hr className='color-blue' />
                            <div className='d-flex align-items-center my-4'>
                                <div className='step-box me-3'>
                                    <span>3</span>
                                </div>
                                <div>
                                    <h4 className='pink-text'>Step 3: Checkout our Site</h4>
                                    <p className='lh-lg'>Besides offering “Free” & “Paid” contests MoonLeague also try’s to offer a social aspect to our site. Once registered you can follow any MoonLeaguer or Friend request someone interesting. All publicly hosted games have comment sections for anyone to view in Spectate mode so you can see all the action. From your profile page where you can post comments or short videos users have an easy way to interact with each other. Or head over to our “Hot Picks” page to see what tickers are moving the most in general or specifically on our site. This sky’s the limit with what MoonLeague has to offer.</p>
                                </div>
                            </div>
                            <hr className='color-blue' />
                            <div className='d-flex align-items-center my-4'>
                                <div className='step-box me-3'>
                                    <span>4</span>
                                </div>
                                <div>
                                    <h4 className='pink-text'>Step 4: Registering for a Verified Account </h4>
                                    <p className='lh-lg'>To play in our Paid games. This requires KYC verification and depositing funds into your verified account. MoonLeague also offers free to play games that do not require a verified account. </p>
                                </div>
                            </div>
                            <hr className='color-blue' />
                            <div className='d-flex align-items-center my-4'>
                                <div className='step-box me-3'>
                                    <span>5</span>
                                </div>
                                <div>
                                    <h4 className='pink-text'>Step 5: Play in a Duel</h4>
                                    <p className='lh-lg'>Search through our list of “Paid” or “Free” daily contests or Create your own Duel with friends you’ve made on MoonLeague.</p>
                                </div>
                            </div>
                            <hr className='color-blue' />
                            <div className='d-flex align-items-center my-4'>
                                <div className='step-box me-3'>
                                    <span>6</span>
                                </div>
                                <div>
                                    <h4 className='pink-text'>Step 6: Make your Picks</h4>
                                    <p className='lh-lg'>This depends on which type of game your playing, though all our games do require you to make 9 selections from whatever exchange (NYSE, Nasdaq or Binance for crypto) the game is being played from. We try to list all current tickers for each so with thousands to choose from we give you the most real engagement for a fantasy game</p>
                                </div>
                            </div>
                            <hr className='color-blue' />
                            <div className='d-flex align-items-center my-4'>
                                <div className='step-box me-3'>
                                    <span>7</span>
                                </div>
                                <div>
                                    <h4 className='pink-text'>Step 7: Watch and interact </h4>
                                    <p className='lh-lg'>Once you’ve made your selections and the contest has started you can now spectate that contest. In spectate mode not only can you see your place in the contest with our new style placement screen built in Unity with your own customized rocket you can also compare your picks with any competitor but also chat with them. We have built in game comment boxes into every gaming screen so competitors can talk and engage with each other from asking questions to funny banter. It’s up to you.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default HowItWorks