
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import moment from "moment-timezone";
import { Col, Nav, Tab } from "react-bootstrap";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { getPaidParticipantsGames, getPaidPauoytGames } from '../../slices/dashboardSlice';
import Participants from '../TrendingDuels/Participants';
import { useDispatch } from 'react-redux';
import Payout from '../TrendingDuels/Payout';

const AnotherUpcomingGames = ({ getUpcoming }) => {
  const dispatch = useDispatch();
  const cars = Array.from({ length: 100000 }).map((_, i) => getUpcoming);
  const [virtualCars, setVirtualCars] = useState(Array.from({ length: 5 }));
  const [lazyLoading, setLazyLoading] = useState(false);
  let loadLazyTimeout = null;

  const loadCarsLazy = (event) => {
    !lazyLoading && setLazyLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //simulate remote connection with a timeout
    loadLazyTimeout = setTimeout(() => {
      let _virtualCars = [...virtualCars];
      let { first, last } = event;

      //load data of required page
      const loadedCars = cars.slice(first, last);

      //populate page of virtual cars
      Array.prototype.splice.apply(_virtualCars, [
        ...[first, last - first],
        ...loadedCars,
      ]);

      setVirtualCars(_virtualCars);
      setLazyLoading(false);
    }, Math.random() * 1000 + 250);
  };

  const loadingTemplate = (options) => {
    return (
      <div
        className="flex align-items-center"
        style={{ height: "17px", flexGrow: "1", overflow: "hidden" }}
      >
        <Skeleton
          width={
            options.cellEven
              ? options.field === "year"
                ? "30%"
                : "40%"
              : "60%"
          }
          height="1rem"
        />
      </div>
    );
  };
  let timeInterval;
  const [sTime, setStartTime] = useState("");
  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownMinutes: "",
    countdownSeconds: "",
  });

  const eventTimer = (evntString) => {
    let countdownDateTime = new Date(`${evntString}`).getTime();
    let nowDate = new Date().toISOString();
    let newtime = new Date(nowDate);
    let currentTime = new Date(nowDate).getTime();

    const remainingDayTime = countdownDateTime - currentTime;
    const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));

    const totalHours = Math.floor(remainingDayTime / (1000 * 60 * 60));

    const totalMinutes = Math.floor((remainingDayTime / 1000 / 60) % 60);
    const totalSeconds = Math.floor((remainingDayTime / 1000) % 60);

    let remainingHours = `${totalHours}H ${totalMinutes}M ${totalSeconds}S`;
    setCountdownTime(remainingHours);
    if (remainingDayTime <= -countdownDateTime) {
      clearInterval(timeInterval);
      return;
    }
    if (remainingDayTime <= 0) {


      clearInterval(timeInterval);
      return "Event started";
    }

    return remainingHours;
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      eventTimer();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [sTime]);

  const timerTemplate = (rowData) => {
    const startTime = rowData.duel_start_time_utc;
    let araryofTime = rowData;

    let check12hr = moment(startTime).format("MM/DD/YYYY h:mm:ss A");
    setStartTime(startTime);
    return (
      <>
        <div id={check12hr}>
          <span className="timer">
            {eventTimer(rowData.duel_start_time_utc)}
          </span>
        </div>
      </>
    );
  };
  const NameBodyTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center gap-2 justify-content-center">
        <p className="fs-5 bg-light-blue p-2 mb-0 pro-head">
          {rowData.duel_mode === "Blood Bath"
            ? "B"
            : rowData.duel_mode === "Standard"
              ? "S"
              : rowData.duel_mode === "Bloodbath & Battel" ? "BB" : "SB"
          }
        </p>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    const startTime = rowData.duel_start_time_utc;
    let araryofTime = rowData;

    let check12hr = moment(startTime).format("MM/DD/YYYY h:mm:ss A");
    setStartTime(startTime);
    return (
      <>
        <div className="flex align-items-center gap-2">
          <p className="fs-6 mb-0 text-uppercase text-truncate">{rowData.duel_name}</p>
          <span className="bg-gray duel-summary text-truncate">{rowData.duel_description}</span>
        </div>
        <div id={check12hr}>
          <span className="timer">
            {eventTimer(rowData.duel_start_time_utc)}
          </span>
        </div>
      </>
    );
  };

  const buttonBodyTemplate = (rowData) => {
    return (
      <div id={rowData.id}>
        {rowData.is_duel_join === 1 && rowData.duel_entry_type === "Free" ? (<>
          <Link className="duel-btn text-decoration-none white-space disable-link" to={`/enter-duel/${rowData.id}`} onClick={topScroll} >Joined</Link>
        </>) : (<>
          <Link className="duel-btn text-decoration-none white-space" to={`/enter-duel/${rowData.id}`} onClick={topScroll}>ENTER DUEL</Link>
        </>)
        }
      </div>
    );
  };

  const [dialogVisible, setDialogVisible] = useState(false);
  const [participant, setParticipant] = useState([])
  const [payout, setPayout] = useState([]);


  const entranceClick = (duelId) => {
    dispatch(getPaidParticipantsGames({ duel_guid: duelId }))
      .unwrap()
      .then((res) => {
        setParticipant(res?.data);
      });
  }

  const payoutApi = (payoutDuel) => {
    const duelDataId = {
      duel_guid: payoutDuel
    }
    dispatch(getPaidPauoytGames({ duelDataId }))
      .unwrap()
      .then((res) => {
        setPayout(res?.data);
      });
  }



  const dialogFooterTemplate = () => {
    return <Button label="Cancel" onClick={() => setDialogVisible(false)} className="duel-btn text-decoration-none white-space cancel-btn" />;
  };

  const entryBodyTemplate = (rowData) => {
    return (
      <div id={rowData.id}>
        <Button label={`${rowData?.entries_count} Entries`} className="duel-btn text-decoration-none white-space" onClick={() => entranceClick(rowData?.duel_guid, setDialogVisible(true), payoutApi(rowData?.duel_guid))} />
      </div>
    );
  };

  const pay = payout?.filter((v) => v?.payout_prizes !== '0');


  const EntrantsList = () => {
    return (
      <>
        <p className="text-uppercase" style={{ color: "#835efd" }}>Entrants List</p>
        <p className="entriesno">No. of Entries = {pay?.length}/{participant?.length}</p>

      </>
    )
  }

  const topScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  let activeMode = payout.every((c) => c.payout_prizes === 0);

  return (
    <>
      <DataTable

        value={getUpcoming}

        scrollable
        scrollHeight="100%"
        className="another-user-upcoming-games-table"
        emptyMessage="There is no upcoming duel data available yet!"
      >
        <Column
          body={countryBodyTemplate} filterField="duel_name" showFilterMatchModes={false}
          showFilterMenuOptions={false} filter filterPlaceholder="Search by duel name"
          className="another-user-upcoming-duel-column"
          style={{ width: '40%' }}
          header="Duel Name" field="duel_name"></Column>

        <Column
          field="duel_type" sortable
          header="Duel Type"
        />

        <Column body={buttonBodyTemplate}></Column>
        <Column body={entryBodyTemplate}></Column>
        <Column field="name" filterPlaceholder="Search"></Column>
      </DataTable>

      <Dialog header={EntrantsList} visible={dialogVisible} style={{ width: '47vw' }}
        modal contentStyle={{ height: '300px' }} onHide={() => setDialogVisible(false)} footer={dialogFooterTemplate}>

        <Tab.Container id="left-tabs-example" defaultActiveKey="active">
          <Col className="custom-tabs-community">
            <Nav variant="pills" className='user-tab d-flex justify-content-around' >
              <Nav.Item>
                <Nav.Link eventKey="active" title="ACTIVE">PARTICIPANTS</Nav.Link>
              </Nav.Item>
              <Nav.Item className={(activeMode) ? "d-none" : ""}>
                <Nav.Link eventKey="upcoming" title="UPCOMING">PAYOUT</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={12}>
            <Tab.Content className='p-0'>
              <Tab.Pane eventKey="active">
                <Participants participant={participant} />
              </Tab.Pane>
              <Tab.Pane eventKey="upcoming">
                <Payout payout={payout} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Dialog>
    </>
  )
}

export default AnotherUpcomingGames