import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { AddFriendIcon, CancelFriendIcon, FollowIcon, FriendIcon } from "../../SVGIcon";
import AnotherUser from "../AnotherUser/AnotherUser";
import { VirtualScroller } from "primereact/virtualscroller";
import { Skeleton } from "primereact/skeleton";
import { classNames } from "primereact/utils";
import { Link } from "react-router-dom";
import { singleUserList } from '../../slices/userSlice';
import { useSelector, useDispatch } from "react-redux";
const UserCard = ({ getuser, handlefollow, followtrue, handleunfollow, handlesendrequest, handlecancelrequest, handlecnfRjcfrnd, handleunfrnd }) => {

  const [showModel, setShowModel] = useState(false);
  const handleShowModel = () => setShowModel(!showModel);
  let locacluserid = window.localStorage.getItem("userid");
  const [cars] = Array.from({ length: 100000 }).map((_, i) => getuser);

  const [lazyItems, setLazyItems] = useState([]);
  const [lazyLoading, setLazyLoading] = useState(false);
  const [templateItems] = useState(
    Array.from({ length: 10000 }).map((_, i) => `Item #${i}`)
  );
  const loadLazyTimeout = useRef(null);

  useEffect(() => {
    setLazyItems(Array.from({ length: 100000 }));
    setLazyLoading(false);
  }, []);

  const onLazyLoad = (event) => {
    !lazyLoading && setLazyLoading(true);
    //setLazyLoading(true);

    if (loadLazyTimeout.current) {
      clearTimeout(loadLazyTimeout.current);
    }

    //imitate delay of a backend call
    loadLazyTimeout.current = setTimeout(() => {
      const { first, last } = event;
      const _lazyItems = [...lazyItems];



      //load data of required page
      const loadedCars = cars.slice(first, last);

      //populate page of virtual cars
      Array.prototype.splice.apply(_lazyItems, [
        ...[first, last - first],
        ...loadedCars,
      ]);
      setLazyItems(_lazyItems);
      setLazyLoading(false);
    }, Math.random() * 1000 + 250);
  };

  //console.log(lazyItems,'lazy Itemss');

  const itemTemplate = (item, options) => {
    const className = classNames(
      "flex align-items-center p-2 text-black fw-bolder",
      {
        "surface-hover": options.odd,
      }
    );

    return (
      <div
        className={className}
        style={{ height: options.props.itemSize + "px" }}
      >
        {item.user_name} Test
      </div>
    );
  };

  const loadingTemplate = (options) => {
    const className = classNames("flex align-items-center p-2", {
      odd: options.odd,
    });

    return (
      <div className={className} style={{ height: "50px" }}>
        <Skeleton width={options.even ? "60%" : "50%"} height="1.3rem" />
      </div>
    );
  };



  let getFollowedBy = getuser?.map((c, i) => { return c.followed_by });
  // console.log(getFollowedBy,'getFollowedBY');

  let filterCurrentUser = getuser?.filter((a) => a.userid !== parseInt(locacluserid));

  const [singleUser, setSingleUser] = useState([]);
  const [friendCount, SetFriendCount] = useState();
  const [followCount, setFollowerCount] = useState();
  const [anotherUserId, setAnotherUserId] = useState('');
  const dispatch = useDispatch();
  const handleSingleUser = (user_id) => {
    setAnotherUserId(user_id);
    dispatch(singleUserList({ userId: user_id })).unwrap()
      .then((res) => {
        let newData = res;
        setSingleUser(...newData)
        // console.log(newData,"Single User API");

      }).catch((err) => console.log(err));
    handleShowModel();


  }



  return (
    <>


      <Row className="card-user">
        {getuser?.filter((a) => a.userid !== parseInt(locacluserid))?.map((c, i) => {
          return (
            <>
              <Col md={4} lg={4} sm={12} key={c.id}>
                <div className="card user-card p-3 mb-3" id={c.userid} key={i}>
                  <Link className="user-link">
                    {c.avatar !== "" ? (
                      <img src={c.avatar} className="card-img-top" alt="..." />
                    ) : (
                      <img
                        src="/icon/default-profile.png"
                        className="card-img-top"
                        alt="..."
                      />
                    )}

                    <h5
                      className="mt-3 text-center  pink-text text-in-tablet"
                      onClick={() => handleSingleUser(c.userid)}
                    >
                      {c.user_name}
                    </h5>
                  </Link>
                  <div className="card-body card-tablet">

                    {c.request_received?.find((n) => { return n.loggedin_user_id === parseInt(locacluserid) }) ? (<> <button className="btn rounded-pill btn-outline-danger unfriend-btn fs-6 px-4 btn-tablet" id={c.userid}
                      onClick={() => { handlecancelrequest(c.userid) }}
                    >
                      Cancel
                    </button></>) : c.request_sent?.find((n) => { return n.friend_id === parseInt(locacluserid) }) ? (<><button className="btn rounded-pill btn-outline-success  fs-6 px-4 btn-tablet" id={c.userid}
                      onClick={() => { handlecnfRjcfrnd(c.userid, 1) }}
                    >

                      Confirm
                    </button></>) : c.friends_by?.find((n) => { return n.friend_id === parseInt(locacluserid) }) || c.friends_to?.find((n) => { return n.loggedin_user_id === parseInt(locacluserid) }) ? (<><button className="btn rounded-pill btn-outline-danger unfriend-btn fs-6 px-4 btn-tablet" id={c.userid}
                      onClick={() => { handleunfrnd(c.userid) }}
                    >
                      Unfriend
                    </button></>)

                      : (<> <button className="btn pink-text btn-outline fs-6 btn-tablet" id={c.userid} onClick={() => { handlesendrequest(c.userid) }}>

                        Add Friend
                      </button> </>)}

                    {c.followed_by?.find((n) => { return n.user_id === parseInt(locacluserid) }) ? (<> <button className="btn pink-text btn-outline btn-selected ms-2 btn-tablet" id={c.userid} onClick={() => handleunfollow(c.userid)}>


                      UNFOLLOW
                    </button></>)

                      : (<> <button className="btn pink-text btn-outline follow-btn ms-2 btn-tablet" id={c.userid} onClick={() => handlefollow(c.userid)}>


                        FOLLOW
                      </button>
                      </>)}
                  </div>
                </div>
              </Col>
            </>
          );
        })
        }
        {getuser?.length === 0 && <p className="pink-text fs-5 fw-bolder">User Not Found</p>}
      </Row>
      <AnotherUser show={showModel} handleClose={handleShowModel} friendCount={friendCount} singleUser={singleUser} anotherUserId={anotherUserId} />
    </>
  );
};

export default UserCard;
