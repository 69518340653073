import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "../config";
import authHeader from "../util/Authheader";
import axios from "axios";

//Get FreeGames
export const getFreeGames = createAsyncThunk( "api/duel/get-free-games-list", async (args, thunkAPI) => {
    
    const url = `${baseURL}api/duel/get-free-games-list`;
    return await axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        let data = response.data.Data;
    //  console.log(response.data, "get-free-games");
        return data;
      })
      .catch((err) => {
        console.log(err, "get-free-games");
      });
})
//Get Paid Games / Use 0 for paid
export const getPaidGames = createAsyncThunk( "api/duel/get-paid-games-list", async (args, thunkAPI) => { 
    
    // const url = `${baseURL}api/duel/get-paid-duel/${args.paid}`;
    const url = `${baseURL}api/duel/get-paid-games-list`;
    return await axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        let data = response.data.Data;
    //  console.log(response.data, "get-paid-games");
        return data;
      })
      .catch((err) => {
        // console.log(err, "get-paid-games");
        if (err.response) {
            const message = err.response.data.message;
            return thunkAPI.rejectWithValue(message);
          }
      });
})


//Get  participants Games / Use 0 for paid

  export const getPaidParticipantsGames = createAsyncThunk( "api/duel/get-duel-participants", async (args, thunkAPI) => {
    
    const url = `${baseURL}api/duel/get-duel-participants/${args.duel_guid}`;
    return await axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        let data = response?.data;
    
        return data;
        
      })
      .catch((err) => {
        console.log(err, "edit duel by id");
      });
})

//Get  Payout Games / Use 0 for paid

export const getPaidPauoytGames = createAsyncThunk(
  "api/payout/calculate-payout-prizes-by-duelguid",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/payout/calculate-payout-prizes-by-duelguid`;
    return await axios
      .post(urlname, args.duelDataId, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
        return data;
       
      })
      .catch((err) => {
        console.log(err,"Unity data is not receiving");
      });
  }
);



//Get Upcoming Duel Data for Free/Paid and System generated
//api/duel/get-upcoming-duel
export const getUpcomingGames = createAsyncThunk( "api/duel/get-myduel-list-byid", async (args, thunkAPI) => { 
    
  const url = `${baseURL}api/duel/get-myduel-list-byid/${args.userId}`;
  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response.data.Data;
  //  console.log(response.data, "get-upcoming-games");
      return data;
    })
    .catch((err) => {
      // console.log(err, "get-upcoming-games");
      if (err.response) {
          const message = err.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    });
})

//Trending Duel list
//api/duel/trending-duel-list
export const getTrendingGames = createAsyncThunk( "api/duel/trending-duel-list", async (args, thunkAPI) => { 
    
  const url = `${baseURL}api/duel/trending-duel-list`;
  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response.data.data;
  //  console.log(response.data, "get-upcoming-games");
      return data;
    })
    .catch((err) => {
      // console.log(err, "get-trending-games");
      if (err.response) {
          const message = err.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    });
})

//Joined Duels list
//api/duel/get-joined-duel-list
export const getJoinedGames = createAsyncThunk("api/duel/get-upcoming-duel-list-by-user-id/", async (args,thunkAPI) => {
  const url = `${baseURL}api/duel/get-upcoming-duel-list-by-user-id/${args.userId}`;
  return await axios.get(url, {headers : authHeader()})
  .then((response) => {
    let data = response.data.data;
    return data;
  })
  .catch((err) => {
    console.log(err,"get joined duels list");
    if(err.response) {
      const message = err.message.data.message;
      return thunkAPI.rejectWithValue(message);
    }
  })
})

//invitesUserList api/user/get-invitation-data-by-user-id/198
export const invitesUserList = createAsyncThunk("api/user/get-invitation-data-by-user-id/", async (args,thunkAPI) => { 
  const url = `${baseURL}api/user/get-invitation-data-by-user-id/${args.userId}`;
  return await axios.get(url, {headers : authHeader()})
  .then((response) => {
    let data = response.data.data;
    return data;
  })
  .catch((err) => {
    console.log(err,"get invited duels list");
    if(err.response) {
      const message = err.message.data.message;
      return thunkAPI.rejectWithValue(message);
    }
  })
})

//Active Duel List:

//api/duel/get-active-duel-list

export const getActiveGames = createAsyncThunk("api/duel/get-active-duel-list", async (args, thunkAPI) => {
  const url = `${baseURL}api/duel/get-active-duel-list/${args.userId}`;
  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response.data.data;
  //  console.log(response.data, "get-active-games");
      return data;
    })
    .catch((err) => {
      console.log(err, "get-active-games");
      if (err.response) {
          const message = err.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    });
})


//Active Duel for Games sections
//api/duel/get-active-duels-by-user-id/61
export const getActiveLoggedInUserGames = createAsyncThunk("api/duel/get-active-duels-by-user-id", async (args, thunkAPI) => {
  const url = `${baseURL}api/duel/get-active-duels-by-user-id/${args.userId}`;
  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response.data.data;
      return data;
    })
    .catch((err) => {
      // console.log(err, "get-activeDuels-games");
      if (err.response) {
          const message = err.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    });
})

//Another User Upcoming Games
//api/duel/get-upcoming-duels-by-user-id/

export const getAnotherUserUpcomingGames = createAsyncThunk("api/duel/get-upcoming-duels-by-user-id/", async (args,thunkAPI) => {
  const url = `${baseURL}api/duel/get-upcoming-duels-by-user-id/${args.user_id}`;
  return await axios.get(url, {headers : authHeader()})
  .then((response) => {
    let data = response.data;
    return data;
  })
  .catch((err) => {
    console.log(err,"get joined duels list");
    if(err.response) {
      const message = err.message.data.message;
      return thunkAPI.rejectWithValue(message);
    }
  })
})



//Another User Active Games
//api/duel/get-active-duels-by-user-id/1
export const getAnotherUserActiveGames = createAsyncThunk("api/duel/get-active-duels-by-user-id/", async (args,thunkAPI) => {
  const url = `${baseURL}api/duel/get-active-duels-by-user-id/${args.user_id}`;
  return await axios.get(url, {headers : authHeader()})
  .then((response) => {
    let data = response.data;
    return data;
  })
  .catch((err) => {
    console.log(err,"get joined duels list");
    if(err.response) {
      const message = err.message.data.message;
      return thunkAPI.rejectWithValue(message);
    }
  })
})


const dashboardGamesSlice = createSlice({
    name: "dashboardGames",
    initialState: {
      freeGamesList: [],
      paidGamesList:[],
      upcomingGamesList:[],
      activeGamesList:[],
      activeDuelsGamesList:[],
      isFetching: false,
      status: null,
      trendingGamesList:[],
      joinedGamesList:[],
      inviteUserResponse:[],
      anotherUpcomingResponse:[],
      anotherActiveResponse:[],
      getPaidParticipantsGames:[],
      getPaidPauoytGames:[]
    },
    extraReducers: (builder) => {
      builder
      //Get FreeGames List
        .addCase(getFreeGames.pending, (state, action) => {
          state.status = "loading";
        })
        .addCase(getFreeGames.fulfilled, (state, action) => {
          state.freeGamesList = action.payload;
          state.status = "success";
        })
        .addCase(getFreeGames.rejected, (state, action) => {
          state.status = "failed";
        })
  
        // Get paidGames List 
        .addCase(getPaidGames.pending, (state, action) => {
          state.status = "loading";
          state.isFetching = "true";
        })
        .addCase(getPaidGames.fulfilled, (state, action) => {
          state.paidGamesList = action.payload;
          state.status = "success";
          state.isFetching = "false";
        })
        .addCase(getPaidGames.rejected, (state, action) => {
          state.status = "failed";
          state.isFetching = "false";
        })
          // Get getPaidParticipantsGames List 
          .addCase(getPaidParticipantsGames.pending, (state, action) => {
            state.status = "loading";
            state.isFetching = "true";
          })
          .addCase(getPaidParticipantsGames.fulfilled, (state, action) => {
            state.getPaidParticipantsGames = action.payload;
            state.status = "success";
            state.isFetching = "false";
          })
          .addCase(getPaidParticipantsGames.rejected, (state, action) => {
            state.status = "failed";
            state.isFetching = "false";
          })

           // Get getPaidPauoytGames List 
           .addCase(getPaidPauoytGames.pending, (state, action) => {
            state.status = "loading";
            state.isFetching = "true";
          })
          .addCase(getPaidPauoytGames.fulfilled, (state, action) => {
            state.getPaidPauoytGames = action.payload;
            state.status = "success";
            state.isFetching = "false";
          })
          .addCase(getPaidPauoytGames.rejected, (state, action) => {
            state.status = "failed";
            state.isFetching = "false";
          })


        //upcoming
        .addCase(getUpcomingGames.pending, (state,action) => {
          state.status = "loading";
          state.isFetching = true;
        })
        .addCase(getUpcomingGames.fulfilled, (state, action) => {
          state.upcomingGamesList = action.payload;
          state.status = "success";
          state.isFetching = false;
        })
        .addCase(getUpcomingGames.rejected, (state, action) => {
          state.status = "failed";
          state.isFetching = false;
        })

        //getActiveGames
        .addCase(getActiveGames.pending, (state,action) => {
          state.status = "loading";
          state.isFetching = true;
        })
        .addCase(getActiveGames.fulfilled, (state, action) => {
          state.activeGamesList = action.payload;
          state.status = "success";
          state.isFetching = false;
        })
        .addCase(getActiveGames.rejected, (state, action) => {
          state.status = "failed";
          state.isFetching = false;
        })
      
       //getTrendingGames
         .addCase(getTrendingGames.pending, (state,action) => {
          state.status = "loading";
          state.isFetching = true;
        })
        .addCase(getTrendingGames.fulfilled, (state, action) => {
          state.trendingGamesList = action.payload;
          state.status = "success";
          state.isFetching = false;
        })
        .addCase(getTrendingGames.rejected, (state, action) => {
          state.status = "failed";
          state.isFetching = false;
        })

        //getJoinedGames
        .addCase(getJoinedGames.pending, (state,action) => {
          state.status = "loading";
          state.isFetching = true;
        })
        .addCase(getJoinedGames.fulfilled, (state, action) => {
          state.joinedGamesList = action.payload;
          state.status = "success";
          state.isFetching = false;
        })
        .addCase(getJoinedGames.rejected, (state, action) => {
          state.status = "failed";
          state.isFetching = false;
        })


  //invitesUserList
.addCase(invitesUserList.pending, (state, action) => {
  state.status = "loading";
})
.addCase(invitesUserList.fulfilled, (state, action) => {
  state.inviteUserResponse = action.payload;
  state.status = "success";
})
.addCase(invitesUserList.rejected, (state, action) => {
  state.userList = "rejected";
})

//getAnotherUserUpcomingGames
.addCase(getAnotherUserUpcomingGames.pending, (state, action) => {
  state.status = "loading";
})
.addCase(getAnotherUserUpcomingGames.fulfilled, (state, action) => {
  state.anotherUpcomingResponse = action.payload;
  state.status = "success";
})
.addCase(getAnotherUserUpcomingGames.rejected, (state, action) => {
  state.userList = "rejected";
})

//getAnotherUserActiveGames  //anotherActiveResponse
.addCase(getAnotherUserActiveGames.pending, (state, action) => {
  state.status = "loading";
})
.addCase(getAnotherUserActiveGames.fulfilled, (state, action) => {
  state.anotherActiveResponse = action.payload;
  state.status = "success";
})
.addCase(getAnotherUserActiveGames.rejected, (state, action) => {
  state.userList = "rejected";
})

  //getActiveDuelGames
  .addCase(getActiveLoggedInUserGames.pending, (state,action) => {
    state.status = "loading";
    state.isFetching = true;
  })
  .addCase(getActiveLoggedInUserGames.fulfilled, (state, action) => {
    state.activeDuelsGamesList = action.payload;
    state.status = "success";
    state.isFetching = false;
  })
  .addCase(getActiveLoggedInUserGames.rejected, (state, action) => {
    state.status = "failed";
    state.isFetching = false;
  })
    },
  });
  
  export default dashboardGamesSlice.reducer;
  export const dashboardSelector = (state) => state.dashboardGames;