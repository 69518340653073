import React, { useState, Suspense } from 'react';
import { Tab, Row, Nav, Col } from 'react-bootstrap';
import HotPicks from '../Community/HotPicks';
import AnotherFriends from './AnotherFriends';
import AnotherFollowings from './AnotherFollowings';
import AnotherFollowers from './AnotherFollowers';

const AnotherCommunity = ({ friendslist, followinglist, followerlist }) => {
    const [key, setKey] = useState('hotPicks');

    return (
        <>
            <div className="card card-custom gutter-b community-card">
                <div className="custom-card-header">
                    <div className='card-title text-center mb-0'>
                        <h4 className="card-label align-items-center custom-heading d-inline">COMMUNITY</h4>
                    </div>
                    <div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="hotPicks">
                            <div className='custom-tabs-community'>
                                <Row>
                                    <Col xxl={12} lg={12} sm={12}  >
                                        <Nav variant="pills" className='user-tab d-flex justify-content-around' >
                                            <Nav.Item>
                                                <Nav.Link eventKey="hotPicks" title="HOTPICKS">HOT</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="friends" title="FRIENDS">FRIENDS</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="follwings" title="FOLLOWING">FOLLOWING</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="followers" title="FOLLOWERS">FOLLOWERS</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>

                                </Row>
                            </div>
                            <Col lg={12}>
                                <Tab.Content className=''>
                                    <Tab.Pane eventKey="hotPicks" className='hot-picks'>
                                        <Suspense fallback={<div className='pink-text fw-bold'>Wait...</div>}>
                                            <HotPicks />
                                        </Suspense>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="friends">
                                        <AnotherFriends
                                            friendslist={friendslist}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="follwings">
                                        <AnotherFollowings followinglist={followinglist} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="followers">
                                        <AnotherFollowers followerlist={followerlist} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>

                        </Tab.Container>
                    </div>

                </div>

            </div>

        </>
    )
}

export default AnotherCommunity