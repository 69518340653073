import React, { useState, useEffect } from 'react'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useDispatch } from "react-redux";
import { friendReuestsReceviedAPI, AcceptRejectFrndAPI } from '../../slices/userSlice';
const FriendRequests = ({ friendRequestsReceivedList }) => {
  const [frndRequstResponse, setFrndReqstResponse] = useState([]);
  const dispatch = useDispatch();
  let locacluserid = window.localStorage.getItem("userid");
  const handleFriendRequestsRcvd = async () => {
    await dispatch(friendReuestsReceviedAPI({ userId: locacluserid }))
      .unwrap()
      .then((res) => {

        setFrndReqstResponse(res?.data);

      })
  }
  useEffect(() => {

    handleFriendRequestsRcvd();

  }, [dispatch])

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    friend_user_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  });
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <div className="d-flex m-1">

        <span className="p-input-icon-left w-100 px-1">
          <i className="pi pi-search" />
          <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Search by name" className="w-100" />
        </span>
      </div>
    );
  };

  const header = renderHeader();
  //confirm accept frnd
  const handleCnfRjcFrnd = (friend_id, isconfirrm) => {
    let frndId = friend_id;
    let isConfirm = isconfirrm;
    let frndConfirmData = {
      loggedin_user_id: locacluserid,
      friend_id: frndId,
      is_request_accepted_or_cancelled: isConfirm
    }
    dispatch(AcceptRejectFrndAPI({ frndConfirmData })).unwrap()
      .then((res) => {
        let newData = res.data;



        handleFriendRequestsRcvd();

      }).catch((err) => console.log(err));
  }


  useEffect(() => {
    setFrndReqstResponse(friendRequestsReceivedList?.data);
  }, [friendRequestsReceivedList])




  const avatarTemplate = (rowData) => {
    return (
      <div className="community-pic me-3 flex-grow-0">
        {rowData.friend_user_avatar !== "" ? <img src={rowData.friend_user_avatar} alt="profile image" /> : (<><img src="/icon/default-profile.png" alt="profile image" /></>)}


      </div>
    );
  };
  const nameTemplate = (rowData) => {
    return (
      <div className="user bg-gray align-items-start flex-grow-1 text-truncate" style={{width:"75px"}}>{rowData.friend_user_name}</div>
    );
  };

  const acceptRejectTemplate = (rowData) => {
    return (<>
      <div>
        <button className='btn pink-text btn-outline me-3 me-md-0' id={rowData.friend_id} onClick={() => { handleCnfRjcFrnd(rowData.friend_id, 1) }}>

          <span className="content">Accept</span></button>
        <button className='btn pink-text btn-outline ms-0 ms-md-3' id={rowData.friend_id} onClick={() => { handleCnfRjcFrnd(rowData.friend_id, 2) }}>

          <span className="content">
            Decline</span></button>
      </div>

    </>)
  }
  return (

    <>
      <DataTable value={frndRequstResponse} emptyMessage="There is no friend request data available yet!"
        header={header}
        filters={filters} onFilter={(e) => setFilters(e.filters)} globalFilterFields={['friend_user_name']}
        scrollable
        scrollHeight="470px">

        <Column field="friend_user_avatar" body={avatarTemplate} header="Profile" style={{ minWidth: '60px' }} headerStyle={{ width: '60px' }} ></Column>
        <Column field="friend_user_name" body={nameTemplate} header="Name" filter filterField="friend_user_name" filterPlaceholder="Search by name" showFilterMatchModes={false} showFilterMenuOptions={false}></Column>
        <Column body={acceptRejectTemplate}></Column>
      </DataTable>

    </>






  )
}

export default FriendRequests