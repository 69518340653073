import React from 'react'
import ActiveDuels from '../components/ActiveDuels/ActiveDuels'
import Community from '../components/Community/Community'
import Footer from '../components/MainLayout/Footer/Footer'
import FreeGames from '../components/FreeGames/FreeGames'
import Header from '../components/MainLayout/Header/Header'
import PaidGames from '../components/PaidGames/PaidGames'
import TrendingDuels from '../components/TrendingDuels/TrendingDuels'
import UserInfo from '../components/UserInfo/UserInfo'
import MyActiveDuels from '../components/YourActiveDuels/MyActiveDuels'
import Navbar from '../components/MainLayout/Navbar/Navbar'
import ScrollTopButton from '../components/ScrollTopButton/ScrollTopButton'
import authHeader from '../util/Authheader';
import { Navigate } from 'react-router-dom'
import ProfileDashboard from '../components/Profile/ProfileDashboard'
import ActiveExchange from '../components/ActiveExchange/ActiveExchange'
import FeaturedAds from '../components/FeaturedAds/FeaturedAds'
import HotActiveDuels from '../components/HotActiveDuels/HotActiveDuels'
import Games from '../components/Games/GamesDuel'

const Layout = () => {


  let getToken = authHeader();

  if (getToken === null) {
    return <Navigate to="/unauthorizedPage" />;
  } else {
    <Navigate to="/dashboard" />;
  }

  return (
    <>
      <Navbar />
      <div className='main-layout'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='mb-3'>
              <Header />
            </div>
            <div className='mb-3'>
              <ProfileDashboard />
            </div>
            <div className='mb-3'>
              <FeaturedAds />
            </div>

            {/* Paid Games */}
            <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
              <div className='col-md-12 mb-3'>
                <HotActiveDuels />
              </div>
              <div className='col-md-12 mb-3'>
                <PaidGames />
              </div>
              <div className='col-md-12 mb-3'>
                <FreeGames />
              </div>
              <div className='col-md-12 mb-3'>
                <TrendingDuels />
              </div>
            </div>
            <div className='col-xl-4 col-lg-12 col-md-12 col-sm-12'>
              <div className='row'>
                <div className='col-12 mb-3 order-3 order-lg-3 order-xl-1'>
                  <ActiveExchange />
                </div>
                <div className='col-12 mb-3 order-2'>
                  <Community />
                </div>
                <div className='col-12 mb-3 order-1 order-lg-1 order-xl-3'>
                  <Games />
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      <Footer />

    </>
  )
}

export default Layout