
import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { FollowIcon, FriendIcon } from "../../SVGIcon";
import { Link } from "react-router-dom";
import { singleUserList } from '../../slices/userSlice';
import { useSelector, useDispatch } from "react-redux";
import AnotherUser from "../AnotherUser/AnotherUser";
const FriendRequestsCard = ({ friendrequestrcvdlist, friendrequestrcvdcount, friendrequestsentlist, friendrequestcentcount, handlecnfRjcfrnd, handlecancelrequest }) => {
  const [singleUser, setSingleUser] = useState([]);
  const [friendCount, SetFriendCount] = useState();
  const [showModel, setShowModel] = useState(false);
const [anotherUserId, setAnotherUserId] = useState('');

  const handleShowModel = () => setShowModel(!showModel);
  const dispatch = useDispatch();
  const handleSingleUser = (user_id) => {
    setAnotherUserId(user_id);  
    dispatch(singleUserList({ userId: user_id })).unwrap()
      .then((res) => {
        let newData = res;
        setSingleUser(...newData)
        

      }).catch((err) => console.log(err));
    handleShowModel();
  
  }
  return (
    <>
      <p className="bg-gray fw-500">Received Friend Requests: <span className="pink-text">{friendrequestrcvdcount ? friendrequestrcvdcount : 0}</span></p>
      <Row className="">
        {friendrequestrcvdlist?.map((c, i) => {
          return (
            <>
              <Col md={4} lg={4} sm={12}>
                <div className="card user-card p-3 mb-3" id={c.friend_id} key={c.id}>
                  <Link className="user-link disable-link">
                    {c.friend_user_avatar !== "" ? (
                      <img src={c.friend_user_avatar} className="card-img-top" alt="..." />
                    ) : (
                      <img
                        src="/icon/default-profile.png"
                        className="card-img-top"
                        alt="..."
                      />
                    )}

                    <h5
                      className="mt-3 mb-4 text-center  pink-text"
                      onClick={() => handleSingleUser(c.friend_id)}
                    >
                      {c.friend_user_name}
                    </h5>
                  </Link>
                  <div className="card-body ">
                    <button className="btn rounded-pill btn-outline-danger fs-6 px-4 btn-tablet" id={c.friend_id} onClick={() => { handlecnfRjcfrnd(c.friend_id, 1) }}>

                      Accept
                    </button>
                    <button className="ms-3 btn  pink-text btn-outline fs-6 px-4 btn-tablet" id={c.friend_id} onClick={() => { handlecnfRjcfrnd(c.friend_id, 2) }}>

                      Decline
                    </button>

                  </div>
                </div>
              </Col>
            </>
          );
        })}
        {friendrequestrcvdlist?.length === 0 && <p className="pink-text fs-5 fw-bolder">Received Friend Requests Not Found</p>}
      </Row>

      <p className="bg-gray fw-500">Sent Friend Requests: <span className="pink-text">{friendrequestcentcount ? friendrequestcentcount : 0}</span> </p>
      <Row className="friend-card-user">
        {friendrequestsentlist?.map((c, i) => {
          return (
            <>
              <Col md={4} lg={4} sm={12}>
                <div className="card user-card p-3 mb-3" id={c.friend_id} key={c.id}>
                  <Link className="user-link disable-links">
                    {c.friend_user_avatar !== "" ? (
                      <img src={c.friend_user_avatar} className="card-img-top" alt="..." />
                    ) : (
                      <img
                        src="/icon/default-profile.png"
                        className="card-img-top"
                        alt="..."
                      />
                    )}

                    <h5
                      className="mt-3 mb-4 text-center  pink-text text-in-tablet"
                      onClick={() => handleSingleUser(c.friend_id)}
                    >
                      {c.friend_user_name}
                    </h5>
                  </Link>
                  <div className="card-body ">
                    <button className="btn rounded-pill btn-outline-danger fs-6 px-4 btn-tablet text-in-tablet" id={c.friend_id} onClick={() => { handlecancelrequest(c.friend_id) }}>

                      Cancel
                    </button>

                  </div>
                </div>
              </Col>
            </>
          );
        })}
         {friendrequestsentlist?.length === 0 && <p className="pink-text fs-5 fw-bolder">Sent Friend Requests Not Found</p>}
      </Row>
      <AnotherUser show={showModel} handleClose={handleShowModel} friendCount={friendCount} singleUser={singleUser} anotherUserId={anotherUserId}/>

    </>
  )
}

export default FriendRequestsCard