import { useLocation } from "react-router-dom";
import { UpIcon } from "../../SVGIcon";
import SlotList from "./SlotList";
import { baseURL } from "../../config";
import { useSelector } from "react-redux";

const YourPosition = ({
  duelInfo
}) => {

  const { userInfolist } = useSelector((state) => state.userProfile);
  const userLoginInfo = userInfolist?.data;


  return (
    <>
      <div className="card card-custom gutter-b ">
        <div className="custom-card-header">
          <div className="d-flex justify-content-CENTER card-title pb-0">
            <div className="row card-title mx-auto">
              <div className="col-md-12 d-flex justify-content-center align-items-center">
                <h4 className="text-uppercase custom-heading mx-3">
                  Your Position
                </h4>

              </div>
            </div>
          </div>
          <div className="custom-alldulers">

            <div
              className="d-flex justify-content-evenly align-items-center py-3"
              key={userLoginInfo?.id}
            >
              {userLoginInfo?.avatar !== "" ? (
                <>
                  <img
                    src={userLoginInfo?.avatar}
                    className="img-fluid img-symbols"
                  />
                </>
              ) : (
                <>
                  <img
                    src="/icon/default-profile.png"
                    className="img-fluid img-symbols"
                  />
                </>
              )}

              <h4 className="dark-blue mb-0 cfs-6">{userLoginInfo?.user_name}</h4>
              <p className="green-text fw-bold mb-0 cfs-6">
                {duelInfo?.score >= "0.00" ? <>{duelInfo?.score === "0.00" ? <p className="text-warning fw-bold cfs-6 mb-0 ms-2"> {duelInfo?.score} </p>
                  : <p className="green-text fw-bold cfs-6 mb-0 ms-2">{duelInfo?.score}</p>}</> : <p className="text-danger fw-bold cfs-6 mb-0 ms-2"> {duelInfo?.score} </p>}
              </p>
            </div>


          </div>
        </div>
      </div>
    </>
  );
};
export default YourPosition;
