import React, { useState, useEffect } from 'react';
import omit from 'lodash';

const OTPForm = (callback) => {
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  let emailForOtp = window.localStorage.getItem("email");
  const [values, setValues] = useState({ email: emailForOtp, otp: '' });
  const [errors, setErrors] = useState({})
  const validate = (name, values) => {
    switch (name) {
      case "otp":
        if (!values) {
          setErrors({
            ...errors,
            otp: "OTP is required.",
          });
          console.log(errors.otp, 'otp');
        } else {
          let newObj = omit(errors, "otp");
          setErrors(newObj);
        }
        break;


      default:
        break;
    }
  }

  const handleChange = (event) => {


    let name = event.target.name;
    let val = event.target.value;

    validate(event, name, val);

    setValues({
      ...values,
      [name]: val,
    });


    console.log(values, 'valueee');
  };

  //Submit validaion
  const validateFormOnSubmit = (values) => {
    let errors = {};
    if (!values.otp) {
      errors.otp = "OTP is required";
    } else if (values.otp.length <= 3) {
      errors.otp = "OTP requires 4 digit."
    }
    return errors;
  };
  const handleSubmit = (event) => {
    if (event) event.preventDefault();

    if (Object.keys(errors).length === 0 && Object.keys(values).length !== 0) {
      callback();

      setErrors(validateFormOnSubmit(values));
    } else {
      setErrors(validateFormOnSubmit(values));
    }
  };


  //OTP Timer


  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds])



  const resendOTP1 = () => {
    setMinutes(4);
    setSeconds(59);
  };

  return ({
    values, errors, handleSubmit, handleChange, setValues, otp, minutes, seconds, resendOTP1
  })
}

export default OTPForm
