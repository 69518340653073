import { CalenderIcon } from "../../SVGIcon"
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUsersLikeAPI, likeUnlikePostAPI } from "../../slices/video&postSlice";

const AnotherLatestPost = ({ postlist, username, avatar, anotherUserId }) => {

  const localuserid = window.localStorage.getItem("userid");
  const [likedPost, setLikedPost] = useState([]);
  const dispatch = useDispatch();
  const handleAllLikeUnLike = () => {
    dispatch(getUsersLikeAPI({ userId: localuserid, anotherUserId: anotherUserId })).unwrap().then((res) => {
      setLikedPost(res.data)
    }).catch((err) => console.log(err));
  }

  useEffect(() => {
    handleAllLikeUnLike();
  }, [dispatch])

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(false);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text : text.slice(0, 150)}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? " show less" : "...read more"}
        </span>
      </p>
    );
  };


  const [isLiked, updateLike] = useState(false);



  const filterPost = likedPost?.filter((e) => e.post_id === 5 && e.user_id === 61)
    ?.map((c) => {
      return (
        <> <p className="bg-gray">Post ID{c.post_id} {c.like_or_unlike
        }</p></>
      )
    });



  const filterLikedId = likedPost?.filter((e) => e.post_id !== 6)?.map((c) => {
    return (

      <>
        <p className="bg-gray">Button</p>
      </>
    )
  });

  const [allpost, setAllPost] = useState([]);




  //Like Unlike Post API

  const handlePostLikeUnLike = async (post_id, CheckLike) => {

    let postId = post_id;
    let likeStatus = CheckLike;

    let LikePostData = {
      post_id: postId,
      user_id: localuserid,
      like_or_unlike: likeStatus
    }


    await dispatch(likeUnlikePostAPI({ ...LikePostData })).unwrap()
      .then((res) => {
        console.log(res);
        if (!isLiked) {
          updateLike(true);
        }

      }).catch((err) => console.log(err));
    await handleAllLikeUnLike();

  }

  return (
    <>
      <div className="card card-custom gutter-b post-height-another">
        <div className="custom-card-header">
          <div className='d-flex justify-content-end card-title'>
            <div className='mx-auto '><h4 className='text-center text-uppercase custom-heading'>LATEST POSTS</h4></div>
          </div>
        </div>
        <div className="main-post-container p-0 p-sm-1">

          <div className="d-flex p-1 p-sm-3">
            <div className="row post-width">

              {postlist?.length > 0 ? postlist?.map((postItem, id) =>
                <div key={postItem?.post_id} className="card-post fw-normal col-lg-12" id={postItem?.post_id}>
                  <div className="card p-1 p-sm-2 p-md-2 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="col-1 px-0 me-2 text-start" >
                        {avatar !== "" ? (<> <img src={`${avatar}`} className="img-fluid post-profile-image" /> </>) : (<> <img src="/icon/default-profile.png" className="img-fluid post-profile-image" /> </>)}

                      </div>
                      <div className="col-11 text-start dark-blue p-2">
                        <h6>{username}</h6>
                        <p className="bg-gray mb-1"><span className="me-2"><CalenderIcon /></span>{new Date(
                          postItem?.post_date
                        ).toLocaleDateString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit'
                        })}</p>
                        {postItem?.description?.length > 150 ? (<> <p className="mb-0"><ReadMore>{postItem?.description}</ReadMore></p></>) : (<> <p className="mb-0">{postItem.description}</p> </>)}



                      </div>
                    </div>
                  </div>
                </div>
              ) : (<> <div className="vh-100 "><p className="bg-gray fw-500 text-center" >There is no latest post data available yet!</p></div></>)
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AnotherLatestPost