import React, { useState, useEffect } from 'react';
import HotPickChatComponent from './HotPickChatComponent'
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from "react-redux";
import { getchatAllUsersAPI, chatWithALLAPI } from "../../slices/chatSlice";
import { Button, Form } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
const HotPickChat = () => {

  const dispatch = useDispatch();
  let locacluserid = window.localStorage.getItem("userid");
  const [getAllChat, setAllChats] = useState([]);

  const handleAllUsersChat = () => {
    dispatch(getchatAllUsersAPI({ userId: locacluserid })).unwrap()
      .then((res) => {
        // console.log(res);
        let friendsData = res.data;
        setAllChats(friendsData);
      })
  }

  useEffect(() => {
    handleAllUsersChat();
  }, [])
  //   console.log(getAllChat,'getAllChat');

  const [value, setValue] = useState("");
  //   console.log(value,'message value');
  const onChange = (e) => {
    const html = e.target.innerHTML;
    setValue(html);
  };

  const specialCharacters = ['#', '%', '^', '&', '*', '`', '~', '_', '=', '+','<','>'];
  const regexPattern = new RegExp('[' + specialCharacters.join('') + ']', 'g');
  const matches = value.match(regexPattern);

  const handleSubmitChat = async (e) => {
    e.preventDefault();
    if (value?.length < 250 && matches === null) {
      await dispatch(chatWithALLAPI({ user_id: parseInt(locacluserid), message: value })).unwrap().then((res) => {
        let chat_res = res;
        // console.log(res,chat_res,'chat_res'); 
        setValue('')
      }).catch((err) => console.log(err));
      await handleAllUsersChat();
    }
  }

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="custom-card-header">
          <div className='card-title mb-0 '>
            <div className="d-flex  justify-content-end card-title">
              <h4 className="card-label mx-auto text-center custom-heading d-inline">USER CHAT</h4>
            </div>
          </div>
        </div>
        <div className="card-body-dark">
          <Form onSubmit={handleSubmitChat}>
            <div className="d-flex align-items-center p-3">
              <div className="me-3">
                <img src="/icon/default-profile.png" className="img-fluid" style={{ width: "50px" }} />
              </div>
              <div className='position-relative w-100'>
                <InputText type="text" placeholder="Add a public comment" className="w-100" value={value} onChange={(e) => setValue(e.target.value)} />
                <Button type="submit" className='chat-submit'>Submit</Button>
              </div>
            </div>
            <div className='text-start mb-3' style={{ marginLeft: "5rem" }}>
              <strong className={(value?.length >= 250) ? "p-invalid text-bold" : "p-invalid d-none"} style={{ color: "#e24c4c" }}>Please Enter less than 250 Characters.</strong>
              <strong className={(matches !== null) ? "p-invalid text-bold" : "p-invalid d-none"} style={{ color: "#e24c4c" }}>Special Characters are not allowed</strong>
            </div>
          </Form>
          <p className="text-start ms-5">All Users</p>
          <div className='auto-scroll-chat'>
            <HotPickChatComponent getAllChat={getAllChat} />
          </div>
        </div>
        <ToastContainer />

      </div>
    </>
  )
}

export default HotPickChat