import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../slices/authSlice';
import getuserSlice from '../slices/profileSlice';
import createDuelSlice from '../slices/createDuelSlice';
import contactSlice from '../slices/contactSlice';
import dashboardSlice from '../slices/dashboardSlice';

import userSlice from '../slices/userSlice';

import enterJoinSlice from '../slices/enterJoinSlice';
import videoPostSlice from '../slices/video&postSlice';
import chatSlice from '../slices/chatSlice';
import editJoinSlice from '../slices/editJoinSlice';
import gameSlice from '../slices/gameSlice';
import walletSlice from '../slices/walletSlice';
import hotpickSlice from '../slices/hotpickSlice';

const reducer = {
  auth: authSlice,
  userProfile: getuserSlice,
  createDuel: createDuelSlice,

  createContact: contactSlice,
  dashboardGames: dashboardSlice,

  
  userLists: userSlice,

  enter_Join: enterJoinSlice,
  videoPost:videoPostSlice,
  chat:chatSlice,
  edit_Join :editJoinSlice,
  game_Spectate :gameSlice,
  wallet_Screen:walletSlice,
  enter_Hotpick:hotpickSlice,

  devTools: true,

};
const store = configureStore({ reducer: reducer, devTools: true });
export default store;
 