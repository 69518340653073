import React from "react";
import { Routes, Route } from "react-router-dom";
import AboutUs from "../About/AboutUs";
import ContactUs from "../components/ContactUs/ContactUs";
import CreateDuels from "../components/CreateDuels/CreateDuels";
import Dashboard from "../components/Dashboard/Dashboard";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import JoinDuels from "../components/JoinDuels/JoinDuels";
import Login from "../components/Login/Login";
import Error from "../components/Page404/Error";
import Policy from "../components/Policy/Policy";
import Register from "../components/Register/Register";
import ResetPwd from "../components/ResetPassword/ResetPwd";
import TermsCondition from "../components/TermsConditions/TermsCondition";
import Unauthorized from "../components/Unauthorized/Unauthorized";
import OTP from "../components/VerifyOTP/OTP";
import Wallet from "../components/Wallet/Wallet";
import ProfilePage from "../Pages/ProfilePage";
import PrivateRoute from "./PrivateRoute";
import User from "../components/User/User";
import HotPick from "../components/HotPick/HotPick";
import Geolocation from "../Geolocation";
import MainGameComponent from "../components/MainGame/MainGameComponent";
import Faq from "../components/Faq.jsx/Faq";
import { LegalNotice } from "../components/LegalNotice/LegalNotice";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import RulesAndScoring from "../components/RulesAndScoring/RulesAndScoring";
import Notification from "../components/Notification/Notification";
import EditSandyLatest from "../components/EditDuels/EditSandyLatest";
import Contact from "../components/ContactUs/Contact";
import About from "../About/About";
import Terms from "../components/TermsConditions/Terms";
import { Legal } from "../components/LegalNotice/Legal";
import Rules from "../components/RulesAndScoring/Rules";
import FaqNew from "../components/Faq.jsx/FaqNew";
import PolicyNew from "../components/Policy/PolicyNew";
import HowIt from "../components/HowItWorks/HowIt";
import Expire from "../components/Page404/Expire";

const Routings = () => {


  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-otp" element={<OTP />} />
        <Route path="/reset-password" element={<ResetPwd />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms-conditions" element={<TermsCondition />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<Policy />} />
        <Route path="/privacy-policy-new" element={<PolicyNew />} />
        <Route path="/legal-notice" element={<LegalNotice />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/how-it" element={<HowIt />} />
        <Route path="/rules-scoring" element={<RulesAndScoring />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/faq-new" element={<FaqNew />} />
        <Route path="*" element={<Error />} />
        <Route path="/expire" element={<Expire />} />
        <Route path="/unauthorizedPage" element={<Unauthorized />} />

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/hotpick" element={<HotPick />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/join-duels" element={<JoinDuels />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/user-profile" element={<User />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/main-game/:contestId" element={<MainGameComponent />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/createduels" element={<CreateDuels />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/wallet" element={<Wallet />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/profile" element={<ProfilePage />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/notification" element={<Notification />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/hotpick" element={<HotPick />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/join-duels" element={<JoinDuels />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/enter-duel/:contestId" element={<JoinDuels />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/edit-duel/:contestId" element={<EditSandyLatest />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/user-profile" element={<User />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/getlocation" element={<Geolocation />} />
        </Route>

      </Routes>


    </>
  );
};

export default Routings;
