import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "../config";
import authHeader from "../util/Authheader";
import axios from "axios";
import { toast } from 'react-toastify'

export const contactApi = createAsyncThunk(
  "api/contactUs",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/contact-us`;
    try {
      const response = await axios({
        method: "post",
        url: urlname,
        data: args.contactData,
        headers: {
          ...authHeader(),
        },
      });

      let data = response.data;
      // console.log(data, "data");
      if (data.status_code === 400) {
        // console.log("eror thow", data);
        toast.error(data.message);
        return Promise.reject(data);
      } else {
        toast.success(data.message);
        return data;
      }

    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

const contactSlice = createSlice({
  name: "getContact",
  initialState: {
    data: null,
  },
  extraReducers: (builder) => {
    builder.addCase(contactApi.pending, (state, action) => {
      state.isLoading = "loading";
      state.data = action.payload;
    });
    builder.addCase(contactApi.fulfilled, (state, action) => {
      state.isLoading = "success";
      state.data = action.payload
    });
    builder.addCase(contactApi.rejected, (state, action) => {
      state.isLoading = "failed";
      state.data = action.payload;
    })
  }
})

export default contactSlice.reducer