import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react'

const Payout = ({ payout }) => {



  const entryPayoutTemplate = (rowData) => {
    return (
      <>
      {rowData?.payout_prizes === '0' ? "" :  `${rowData?.payout_prizes}` }
       
      </>
    )
  }

  const indexData = (rowData, options) =>{
    return (
      <>
     {rowData?.payout_prizes === '0' ? "" :  options.rowIndex+1 }
       
      </>
    )

  }

 
  return (
    <> 
    {payout?.length > 0
      ?  <DataTable value={payout} scrollable scrollHeight="flex" className='trending-modal-datatble'>
      <Column header="Position" className='trending-modal-table-profile-column' headerStyle={{ width: '50%'}}  body={indexData}></Column>
        <Column field="payout_prizes" className='trending-modal-table-name-column' headerStyle={{ width: '50%' }} header="Payout" body={entryPayoutTemplate}></Column>
      </DataTable>
      : <p className='noDatamessage'>There are no position and payout available yet!</p>}
    </>
  )
}

export default Payout