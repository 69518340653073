import { Carousel, CarouselItem } from "react-bootstrap"

const GameLevel = () => {
    const GameData = [
        {
            imageData: "./images/Type-1-blue.png",
            level: "Level 1"
        },
        {
            imageData: "./images/Type-2-blue.png",
            level: "Level 2"
        },
        {
            imageData: "./images/Type-3-blue.png",
            level: "Level 3"
        },
        {
            imageData: "./images/Type-4-blue.png",
            level: "Level 4"
        },
        {
            imageData: "./images/Type-5-blue.png",
            level: "Level 5"
        },
        {
            imageData: "./images/Type-6-blue.png",
            level: "Level 6"
        }

    ]
    return (
        <>
            <div className="card card-custom gutter-b ">
                <div className="custom-card-header">
                    <div className='d-flex justify-content-end card-title'>
                        <div className='mx-auto '><h4 className='text-center text-uppercase custom-heading'>LEVELS</h4></div>
                    </div>
                </div>
                <div className="main-level-container overflow-hidden" style={{padding:"1rem"}}>
                    <Carousel>                 
                        <Carousel.Item>
                            <div className="d-flex justify-content-evenly">
                                <div className="level-container">
                                    <img src={GameData[0].imageData} className="img-fluid levelimg" />
                                    <h4 className="dark-blue mt-1">{GameData[0].level}</h4>
                                </div>
                                <div className="level-container">
                                    <img src={GameData[1].imageData} className="img-fluid levelimg" />
                                    <h4 className="dark-blue mt-1">{GameData[1].level}</h4>
                                </div>  
                                <div className="level-container">
                                    <img src={GameData[2].imageData} className="img-fluid levelimg" />
                                    <h4 className="dark-blue mt-1">{GameData[2].level}</h4>
                                </div> 
                            </div>  
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="d-flex justify-content-evenly">
                                <div className="level-container">
                                    <img src={GameData[3].imageData} className="img-fluid levelimg" />
                                    <h4 className="dark-blue mt-1">{GameData[3].level}</h4>
                                </div>
                                <div className="level-container">
                                    <img src={GameData[4].imageData} className="img-fluid levelimg" />
                                    <h4 className="dark-blue mt-1">{GameData[4].level}</h4>
                                </div>  
                                <div className="level-container">
                                    <img src={GameData[5].imageData} className="img-fluid levelimg" />
                                    <h4 className="dark-blue mt-1">{GameData[5].level}</h4>
                                </div>    
                            </div>
                        </Carousel.Item>
                        
                        {/* {
                            GameData.map((item, index) =>
                            <Carousel.Item key={index}>
                                <div className="level-container">
                                    <img src={item.imageData} className="img-fluid levelimg" />
                                    <h4 className="dark-blue mt-1">{item.level}</h4>
                                </div>    
                            </Carousel.Item>
                            )
                        } */}                      
                    </Carousel>                    
                </div>
            </div>
        </>
    )
}

export default GameLevel