import React, { useState } from 'react'
import { omit } from 'lodash';

const ResetForm = (callback) => {
  let emailForOtp = window.localStorage.getItem("email");
  const [values, setValues] = useState({ email: emailForOtp, new_password: '', confirm_new_password: '' });
  const [errors, setErrors] = useState({})
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setNewPasswordShown(newPasswordShown ? false : true);
  };
  const [cnfPasswordShown, setcnfPasswordShown] = useState(false);
  const toggleConfirmPassword = () => {
    setcnfPasswordShown(cnfPasswordShown ? false : true);
  };

  const validate = (event, name, value) => {
    //A function to validate each input values

    switch (name) {
      case "new_password":
        if (!value) {
          setErrors({
            ...errors, new_password: "Password is required"
          })
        }

        else if (
          !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
        ) {
          setErrors({
            ...errors,
            new_password:
              "Password should be at least 8 characters containing uppercase, lowercase and numbers",
          });
        }
        else if (values.confirm_new_password && value !== values.confirm_new_password) {
          setErrors({
            ...errors,
            new_password: "Password and Confirm Password does not match.",
          });


        } else {
          let newObj = omit(errors, "new_password");
          setErrors(newObj);
        }
        break;
      case "confirm_new_password":
        if (!value) {
          setErrors({
            ...errors, confirm_new_password: "Confirm Password is required"
          })
        }
        else if (values.new_password && value !== values.new_password) {
          setErrors({
            ...errors,
            confirm_new_password: "Password does not match.",
          });
        } else {
          let newObj = omit(errors, "confirm_new_password");
          setErrors(newObj);
        }

        break;

      default:
        break;
    }
  };

  const handleChange = (event) => {


    let name = event.target.name;
    let val = event.target.value;

    validate(event, name, val);

    setValues({
      ...values,
      [name]: val,
    });


    // console.log(values,'valueee');
  };

  const validateForm = errors => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      val => val.length > 0 && (valid = false)
    );
    return valid;
  };

  //Submit validaion
  const validateFormOnSubmit = () => {
    let validationError = { ...errors };
    const reg = new RegExp("/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/");
    if (!values.new_password) {
      validationError.new_password = "Password is required";
    } else if (values.new_password.length < 8) {
      validationError.new_password = "Password must be 8 or more characters";
    } else if (reg.test(values.new_password)) {
      validationError.new_password =
        "Password should be at least 8 characters containing uppercase, lowercase, number and special characters";
    }

    if (!values.confirm_new_password) {
      validationError.confirm_new_password = "Confirm Password is required";
    } else if (values.new_password !== values.confirm_new_password) {
      validationError.confirm_new_password = "Confirmed password is not matching with password";

    }
    setErrors(validationError);
    return validationError;

  };
  const handleSubmit = (event) => {

    if (event) event.preventDefault();
    const validationError = validateFormOnSubmit();
    if (validateForm(validationError)) {
      callback();

    } else {
      console.error("Invalid Form", errors);
    }


  };
  return (
    {
      values, setValues, handleSubmit, handleChange, errors, newPasswordShown, togglePasswordVisiblity, cnfPasswordShown, toggleConfirmPassword,
    }
  )
}

export default ResetForm