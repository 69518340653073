const ActiveGamesComp = () => {


    
    return (
        <>
            <div className='d-flex justify-content-between align-items-center row-bg mb-3'>
                <p className='fs-3 bg-light-blue p-2 mb-0 pro-head'>S</p>

                <p className='mb-0 cfs-5'>Duel Name</p>

                <p className='mb-0'>Moon Duel</p>
                <div className='duel-btn'>ENTER DUEL</div>
            </div>
        </>
    )
}

export default ActiveGamesComp