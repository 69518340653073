import React from "react";
import Layout from "../../Pages/Layout";
import { Navigate } from "react-router-dom";
import authHeader from "../../util/Authheader";

const Dashboard = () => {
  let getToken = authHeader();

  if (getToken === null) {
    return <Navigate to="/" />;
  } else {
     <Navigate to="/dashboard" />;
  }

  return (
    <>
      <Layout />
    </>
  );
};

export default Dashboard;
