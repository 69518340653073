import { Modal, Button, Row, Col, Form,Nav } from "react-bootstrap";
import { InsufficientBalance, SucessIcon } from "../../SVGIcon"
import { NavLink} from "react-router-dom";
const PaymentConfirm = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered  keyboard={false} backdrop="static"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="p-5">
                    {/* success */}
                    {/* <div className="d-flex justify-content-center my-3">
                    <SucessIcon />
                                      </div>
                    <p className="pink-text fw-bolder text-center fs-4">Payment of $0 made successfully.</p>
                    <div className="text-center">
                        <Button className="mt-3" type="button">Done</Button>
                    </div> */}

                    {/* Fail */}
                    <div className="d-flex justify-content-center my-3">
                        <InsufficientBalance />
                    </div>
                    <p className="pink-text text-center fs-5 mb-0 fw-500" style={{color:"#212529"}}>Insufficient balance</p>
                    <p className="bg-gray fw-bolder text-center fs-5 mt-2"> {props?.error}</p>
                    <div className="text-center d-flex justify-content-evenly">
                        <Button className="mt-3 outline-btn btn btn-primary" type="button" onClick={props.onHide}>Cancel</Button>
                        <Nav.Link className="mt-3 add_fund" as={NavLink}  to="/wallet"  > Add Fund </Nav.Link>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PaymentConfirm