import React, { useState, useEffect } from "react";
import { omit } from "lodash";
import moment from "moment";
import "moment-timezone";
import { useDispatch } from "react-redux";
import { getUserDetails, updatePwdAPI } from "../../slices/profileSlice";
import { useNavigate } from "react-router-dom";

const UserProfileForm = (callback) => {
  let locacluserid = window.localStorage.getItem("userid");
  const [errors, setErrors] = useState({});
  const [editProfileData, setEditprofileData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    birth_date: "",
    zip: "",
    address_1: ""
  });
  const [valid, setValid] = useState(true);
  // File State

  let cdate = new Date(editProfileData.birth_date);
  let formatted = moment(cdate).format("YYYY MM DD");

  let stringDate = editProfileData.birth_date;
  let newDate = new Date(stringDate);
  let newDateToString = newDate.toString();
  let pickedDate1 = moment(stringDate).toDate();
  const [startDate, setStartDate] = useState();
  const [getFileName, setGetFileName] = useState("Upload Image");
  const [getupolad, setUpload] = useState(false);
  const [selectedFile, setSelectedFile] = React.useState(
    editProfileData.avatar
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let locacluseid = window.localStorage.getItem("userid");
  const format2 = "YYYY-MM-DD";
  let selectedDate = moment(startDate).format(format2);
  let dobVal;
  if (startDate) {
    dobVal = selectedDate;
  } else {
    dobVal = editProfileData.birth_date;
  }

  const EditUserProfileAPI = async (values) => {
    await dispatch(getUserDetails({ userId: locacluseid }))
      .unwrap()
      .then((res) => {
        setEditprofileData({
          ...editProfileData,

          first_name: res.data.first_name,
          last_name: res.data.last_name,
          phone: res.data.phone,

          birth_date: res.data.birth_date,
          avatar: res.data.avatar,
          zip_code: res.data.zip,
          address: res.data.address_1,
        });
        setEditprofileData(res.data);
      })

      .catch((error) => {
        if (
          error.response &&
          (error.response.status >= 400) & (error.response.status <= 500)
        ) {
          console.log(" Error in EditUserProfileAPI API");
        }
      });
  };

  useEffect(() => {
    EditUserProfileAPI();
  }, []);

  //Validate EditForm

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  const validateProfile = (name, value) => {
    let validationError = { ...errors };
    switch (name) {
      case "first_name":
        const reg = new RegExp("^[A-Za-z]+$");

        validationError.first_name =
          value && !reg.test(value)
            ? "whiteSpace and number are not allowed"
            : "";

        break;

      case "last_name":
        const reg1 = new RegExp("^[A-Za-z]+$");

        validationError.last_name =
          value && !reg1.test(value)
            ? "whiteSpace and number are not allowed"
            : "";

        break;
      case "phone":
        if (!value) {
          validationError.phone = value ? "" : "Mobile number is required";
        }


        else if ((value || "").replace(/\ /g, "").length < 11) {
          validationError.phone = "Invalid phone number, must be 10 digits";
        } else {
          validationError.phone = "";
        }

        break;
      default:
        break;
    }

    setErrors(validationError);

  };

  const handleMobile = (value, data, event) => {
    let name = event.target.name;
    let val = event.target.value;
    validateProfile(name, val, event);
    setEditprofileData({ ...editProfileData, phone: value });
  };

  const handleEdit = (event) => {
    let val = event.target.value;
    let name = event.target.name;

    setEditprofileData({
      ...editProfileData,
      [name]: val,
    });

    validateProfile(name, val);
  };

  const handleChangeRaw = (value) => {
    if (value !== undefined) {
      let pickedDate = moment(value, "MM/DD/YYYY");
      if (moment(value, "MM/DD/YYYY", true).isValid()) {
        setValid(true);
        setStartDate(pickedDate);
      } else {
        setValid(false);
        setStartDate(null);
      }

      let date = moment(value);
      console.log(date, "date");
    } else {
      console.log(value);
    }
  };

  //Image Upload
  const imageUploader = React.useRef(null);
  const uploadedImage = React.useRef(null);

  const handleFileSelect = async (event, avtarimage) => {
    const file = event.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png|svg)$/)) {
      setGetFileName("Upload Image");
      setSelectedFile("");

      return false;
    } else {
      if (file.size > 3e6) {
        window.alert("Please upload a file smaller than 3 MB");

        setGetFileName("Upload Image");
        setSelectedFile("");
        return false;
      } else {
        setSelectedFile(file);

        let getFileName = event.target.files[0].name;
        setGetFileName(getFileName);
      }
      let reader;
      if (file) {
        setUpload(true);
        /* global FileReader */

        reader = new FileReader();
        reader.readAsDataURL(file);
        const { current } = uploadedImage;
        current.file = file;
        reader.onload = (e) => {
          current.src = e.target.result;
        };

        reader.onerror = function () {
          console.log(reader.error);
        };
      }
    }
  };

  const validateProfileSubmit = () => {
    let validationError = { ...errors };
    const reg = new RegExp("^[A-Za-z]+$");
    if (editProfileData.first_name && !reg.test(editProfileData.first_name)) {
      validationError.first_name = "whiteSpace and number are not allowed";
    }
    const reg1 = new RegExp("^[A-Za-z]+$");
    if (editProfileData.last_name && !reg1.test(editProfileData.last_name)) {
      validationError.last_name = "whiteSpace and number are not allowed";
    }

    setErrors(validationError);
    return validationError;
  };
  const handlePrfoile = (e) => {
    e.preventDefault();

    const validationError = validateProfileSubmit();
    if (validateForm(validationError)) {
      callback();
    } else {
      console.error("Invalid Form", errors);
    }
  };

  //Change Password

  const [oldPasswordShown, setoldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [cnfPasswordShown, setcnfPasswordShown] = useState(false);
  const oldPasswordVisiblity = () => {
    setoldPasswordShown(oldPasswordShown ? false : true);
  };

  const togglePasswordVisiblity = () => {
    setNewPasswordShown(newPasswordShown ? false : true);
  };

  const toggleConfirmPassword = () => {
    setcnfPasswordShown(cnfPasswordShown ? false : true);
  };

  const initialUpdateValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  }
  const [updatePassword, setUpdatePassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const validateChangePassword = (event, name, value) => {

    switch (name) {

      case 'old_password':
        if (!value) {
          setErrors({
            ...errors,
            old_password: 'Old password is required'
          })
        }

        else if (
          !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
        ) {
          setErrors({
            ...errors,
            old_password: 'Password should be at least 8 characters containing uppercase, lowercase, number and special characters'
          })
        }


        else {

          let newObj = omit(errors, "old_password");
          setErrors(newObj);

        }
        break;
      case 'new_password':

        if (!value) {
          setErrors({
            ...errors,
            new_password: 'New password is required'
          })
        }

        else if (
          !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
        ) {
          setErrors({
            ...errors,
            new_password: 'Password should be at least 8 characters containing uppercase, lowercase, number and special characters'
          })
        }
        else if (updatePassword.cnfpassword && value !== updatePassword.cnfpassword) {
          setErrors({
            ...errors,
            new_password: 'Password and Confirm Password does not match.'
          })
        }

        else {

          let newObj = omit(errors, "new_password");
          setErrors(newObj);

        }
        break;
      case 'confirm_password':

        if (updatePassword.new_password && value !== updatePassword.new_password) {
          setErrors({
            ...errors,
            confirm_password: 'Password does not match.'

          })
        }
        else {

          let newObj = omit(errors, "confirm_password");
          setErrors(newObj);

        }


        break;

      default:
        break;
    }


  }

  const handleChangePassword = (event) => {
    event.persist();

    let name = event.target.name;
    let val = event.target.value;

    validateChangePassword(event, name, val);

    setUpdatePassword({
      ...updatePassword,
      [name]: val,
    })
  }

  let updateForm = {
    old_password: updatePassword.old_password,
    new_password: updatePassword.new_password,
    confirm_password: updatePassword.confirm_password
  }


  const validatePasswordOnSubmit = (values, value) => {
    let errors = {};
    const reg = new RegExp("/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/");
    if (!values.old_password) {
      errors.old_password = 'Old password is required';
    }
    else if (values.old_password.length < 8) {
      errors.old_password = 'Password must be 8 or more characters';
    }

    if (!values.new_password) {
      errors.new_password = 'New password is required';
    }
    else if (values.new_password.length < 8) {
      errors.new_password = 'Password must be 8 or more characters';
    }
    if (!values.confirm_password) {
      errors.confirm_password = 'Confirm password is required';
    }
    else if (values.confirm_password.length < 8) {
      errors.confirm_password = 'Password must be 8 or more characters';
    }

    else if (values.new_password !== values.confirm_password) {
      errors.confirm_password = "Password does not match.";
      if (values.new_password === values.confirm_password) {
        errors.confirm_password = "";
      }
    }

    return errors;
  };

  const handlePassword = async (event) => {
    // debugger
    if (event) event.preventDefault();


    let errs = validatePasswordOnSubmit(updatePassword);
    if (Object.keys(errs).length === 0 && Object.keys(updatePassword).length !== 0) {
      setErrors(validatePasswordOnSubmit(updatePassword));
      await dispatch(updatePwdAPI({ updateForm, userId: locacluserid }))
        .unwrap()
        .then((res) => {
          // debugger
          setUpdatePassword({ old_password: '', new_password: '', confirm_password: '' });


        })
        .catch((err) => {
          console.log(err, "errors");
        });

    }
    else {
      setErrors(errs);
    }
  }
  return {
    errors,
    setErrors,
    handleEdit,
    setEditprofileData,
    editProfileData,
    setStartDate,
    handleChangeRaw,
    startDate,
    valid,
    handleFileSelect,
    imageUploader,
    uploadedImage,
    getFileName,
    setGetFileName,
    selectedFile,
    setSelectedFile,
    getupolad,
    setUpload,
    dobVal,
    handlePrfoile,
    handleMobile,
    cnfPasswordShown,
    newPasswordShown,
    oldPasswordShown,
    toggleConfirmPassword,
    togglePasswordVisiblity,
    oldPasswordVisiblity, handleChangePassword, updatePassword, handlePassword
  };
};

export default UserProfileForm;
