import React from "react";

export const FollowIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
      >
        <path
          id="Icon_material-add"
          data-name="Icon material-add"
          d="M24.5,17.214H17.214V24.5H14.786V17.214H7.5V14.786h7.286V7.5h2.429v7.286H24.5Z"
          transform="translate(-7.5 -7.5)"
          fill="#835efd"
        />
      </svg>
    </>
  );
};
export const SwapIcon = () => {
  return (
    <>
    <svg height="14px" version="1.1" viewBox="0 0 18 14" width="18px" xmlns="http://www.w3.org/2000/svg"><title/><desc/><defs/><g fill="none" id="Page-1" stroke="#835efd" ><g fill="#835efd" id="Core" transform="translate(-549.000000, -425.000000)"><g id="swap-horiz" transform="translate(549.000000, 425.000000)"><path d="M4,6 L0,10 L4,14 L4,11 L11,11 L11,9 L4,9 L4,6 L4,6 Z M18,4 L14,0 L14,3 L7,3 L7,5 L14,5 L14,8 L18,4 L18,4 Z" id="Shape"/></g></g></g></svg>
    </>
  );
};

export const ConfirmIcon = () => {
  return (
   

   
    <><svg data-name="Capa 1" id="Capa_1" width="25px" viewBox="0 0 20 19.84" xmlns="http://www.w3.org/2000/svg"><path d="M15.39,5.66a.37.37,0,0,0-.52,0L8,13.39,5.09,11.06a.38.38,0,1,0-.47.59L7.85,14.2a.38.38,0,0,0,.52,0l7.06-8A.38.38,0,0,0,15.39,5.66Z" fill="#835efd"/></svg></>
  );
};

export const CancelIcon = () => {
  return (
    <>
    <svg data-name="Capa 1" id="Capa_1" className="edit-icons" viewBox="0 0 20 19.84" xmlns="http://www.w3.org/2000/svg"><path d="M10.17,10l3.89-3.89a.37.37,0,1,0-.53-.53L9.64,9.43,5.75,5.54a.37.37,0,1,0-.53.53L9.11,10,5.22,13.85a.37.37,0,0,0,0,.53.34.34,0,0,0,.26.11.36.36,0,0,0,.27-.11l3.89-3.89,3.89,3.89a.34.34,0,0,0,.26.11.35.35,0,0,0,.27-.11.37.37,0,0,0,0-.53Z" fill="#dc3545"/></svg>
    </>
  );
};

export const BattleIcon = () => {
  return (
    <>
    <svg viewBox="0 0 640 512" className="edit-iconsBattle" xmlns="http://www.w3.org/2000/svg"><path d="M506.1 127.1c-17.97-20.17-61.46-61.65-122.7-71.1c-22.5-3.354-45.39 3.606-63.41 18.21C302 60.47 279.1 53.42 256.5 56.86C176.8 69.17 126.7 136.2 124.6 139.1c-7.844 10.69-5.531 25.72 5.125 33.57c4.281 3.157 9.281 4.657 14.19 4.657c7.406 0 14.69-3.375 19.38-9.782c.4062-.5626 40.19-53.91 100.5-63.23c7.457-.9611 14.98 .67 21.56 4.483L227.2 168.2C214.8 180.5 207.1 196.1 207.1 214.5c0 17.5 6.812 33.94 19.16 46.29C239.5 273.2 255.9 279.1 273.4 279.1s33.94-6.813 46.31-19.19l11.35-11.35l124.2 100.9c2.312 1.875 2.656 5.251 .5 7.97l-27.69 35.75c-1.844 2.25-5.25 2.594-7.156 1.063l-22.22-18.69l-26.19 27.75c-2.344 2.875-5.344 3.563-6.906 3.719c-1.656 .1562-4.562 .125-6.812-1.719l-32.41-27.66L310.7 392.3l-2.812 2.938c-5.844 7.157-14.09 11.66-23.28 12.6c-9.469 .8126-18.25-1.75-24.5-6.782L170.3 319.8H96V128.3L0 128.3v255.6l64 .0404c11.74 0 21.57-6.706 27.14-16.14h60.64l77.06 69.66C243.7 449.6 261.9 456 280.8 456c2.875 0 5.781-.125 8.656-.4376c13.62-1.406 26.41-6.063 37.47-13.5l.9062 .8126c12.03 9.876 27.28 14.41 42.69 12.78c13.19-1.375 25.28-7.032 33.91-15.35c21.09 8.188 46.09 2.344 61.25-16.47l27.69-35.75c18.47-22.82 14.97-56.48-7.844-75.01l-120.3-97.76l8.381-8.382c9.375-9.376 9.375-24.57 0-33.94c-9.375-9.376-24.56-9.376-33.94 0L285.8 226.8C279.2 233.5 267.7 233.5 261.1 226.8c-3.312-3.282-5.125-7.657-5.125-12.31c0-4.688 1.812-9.064 5.281-12.53l85.91-87.64c7.812-7.845 18.53-11.75 28.94-10.03c59.75 9.22 100.2 62.73 100.6 63.29c3.088 4.155 7.264 6.946 11.84 8.376H544v175.1c0 17.67 14.33 32.05 31.1 32.05L640 384V128.1L506.1 127.1zM48 352c-8.75 0-16-7.245-16-15.99c0-8.876 7.25-15.99 16-15.99S64 327.2 64 336.1C64 344.8 56.75 352 48 352zM592 352c-8.75 0-16-7.245-16-15.99c0-8.876 7.25-15.99 16-15.99s16 7.117 16 15.99C608 344.8 600.8 352 592 352z" fill="#835efd"/></svg>
    </>
  );
};

export const DateIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.113"
        height="18.798"
        viewBox="0 0 16.113 18.798"
      >
        <path
          id="timer"
          d="M10.742.4V2.2H5.371V.4h5.371ZM7.175,12.06V6.689H8.938V12.06H7.175ZM14.35,6.143a7.725,7.725,0,0,1,1.762,4.993,7.769,7.769,0,0,1-2.35,5.707,7.769,7.769,0,0,1-5.707,2.35,7.769,7.769,0,0,1-5.707-2.35A7.745,7.745,0,0,1,0,11.158,7.815,7.815,0,0,1,2.35,5.451,7.734,7.734,0,0,1,8.056,3.08a7.78,7.78,0,0,1,5.035,1.8l1.259-1.3a8.912,8.912,0,0,1,1.259,1.259ZM8.056,17.431a5.993,5.993,0,0,0,4.427-1.846,6.1,6.1,0,0,0,1.825-4.448A6.029,6.029,0,0,0,12.483,6.71,6.025,6.025,0,0,0,8.056,4.885,6.031,6.031,0,0,0,3.63,6.71,6.027,6.027,0,0,0,1.8,11.137,6.1,6.1,0,0,0,3.63,15.584,6,6,0,0,0,8.056,17.431Z"
          transform="translate(0 -0.395)"
          fill="#00b69b"
        />
      </svg>
    </>
  );
};

export const FriendIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.52"
        height="20.087"
        viewBox="0 0 24.52 20.087"
      >
        <g
          id="Icon_feather-user-check"
          data-name="Icon feather-user-check"
          transform="translate(-0.5 -3.5)"
        >
          <path
            id="Path_47"
            data-name="Path 47"
            d="M16.572,28.529v-2.01A4.019,4.019,0,0,0,12.553,22.5H5.519A4.019,4.019,0,0,0,1.5,26.519v2.01"
            transform="translate(0 -5.942)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_48"
            data-name="Path 48"
            d="M14.788,8.519A4.019,4.019,0,1,1,10.769,4.5,4.019,4.019,0,0,1,14.788,8.519Z"
            transform="translate(-1.733)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_49"
            data-name="Path 49"
            d="M25.5,15.51l2.01,2.01L31.529,13.5"
            transform="translate(-7.923 -2.971)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </>
  );
};

export const PlusIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.745"
        height="20.231"
        viewBox="0 0 21.745 20.231"
        className="duel-icon"
      >
        <g
          id="Icon_feather-plus"
          data-name="Icon feather-plus"
          transform="translate(1.5 1.5)"
        >
          <path
            id="Path_29"
            data-name="Path 29"
            d="M18,7.5V24.731"
            transform="translate(-8.627 -7.5)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_30"
            data-name="Path 30"
            d="M7.5,18H26.245"
            transform="translate(-7.5 -9.385)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
      </svg>
    </>
  );
};
export const MinuesIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.389"
        height="3.043"
        viewBox="0 0 27.389 3.043"
        className="duel-icon"
      >
        <path
          id="minus"
          d="M6.022,16.5H30.367a1.522,1.522,0,0,1,0,3.043H6.022a1.522,1.522,0,0,1,0-3.043Z"
          transform="translate(-4.5 -16.5)"
          fill="#835efd"
          fillRule="evenodd"
        />
      </svg>
    </>
  );
};

export const delIcon = () =>{
  return (
    <>
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" fill="#df4b59"/></svg>
    </>
  )
};

export const DeleteIcon = () => {
  return (
    <>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21.889"
        viewBox="0 0 20 21.889"
        className="duel-icon"
      >
        <g
          id="Icon_feather-trash-2"
          data-name="Icon feather-trash-2"
          transform="translate(-3 -1.5)"
        >
          <path
            id="Path_31"
            data-name="Path 31"
            d="M4.5,9h17"
            transform="translate(0 -2.222)"
            fill="none"
            stroke="#c47aff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_32"
            data-name="Path 32"
            d="M20.722,6.778V20a1.889,1.889,0,0,1-1.889,1.889H9.389A1.889,1.889,0,0,1,7.5,20V6.778m2.833,0V4.889A1.889,1.889,0,0,1,12.222,3H16a1.889,1.889,0,0,1,1.889,1.889V6.778"
            transform="translate(-1.111 0)"
            fill="none"
            stroke="#c47aff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_33"
            data-name="Path 33"
            d="M15,16.5v5.667"
            transform="translate(-3.889 -5)"
            fill="none"
            stroke="#c47aff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_34"
            data-name="Path 34"
            d="M21,16.5v5.667"
            transform="translate(-6.111 -5)"
            fill="none"
            stroke="#c47aff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
      </svg>
    </>
  );
};

export const ArrowIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.753"
        height="24.752"
        viewBox="0 0 15.753 24.752"
      >
        <g id="arrow-down" transform="translate(-10.123 -5.625)">
          <path
            id="Path_812"
            data-name="Path 812"
            d="M10.454,21.7a1.125,1.125,0,0,1,1.593,0L18,27.659,23.954,21.7A1.126,1.126,0,1,1,25.547,23.3l-6.75,6.75a1.125,1.125,0,0,1-1.593,0l-6.75-6.75a1.125,1.125,0,0,1,0-1.593Z"
            fillRule="evenodd"
          />
          <path
            id="Path_813"
            data-name="Path 813"
            d="M18,5.625A1.125,1.125,0,0,1,19.125,6.75V27a1.125,1.125,0,0,1-2.25,0V6.75A1.125,1.125,0,0,1,18,5.625Z"
            fillRule="evenodd"
          />
        </g>
      </svg>
    </>
  );
};

export const DiamondIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.454"
        height="16.609"
        viewBox="0 0 18.454 16.609"
      >
        <path
          id="diamond"
          d="M16.218,3.375H4.487L1.125,8.418v.953L8.177,19.984h4.35L19.579,9.371V8.418Zm-.658,1.23L18,8.262H13.879l-1.15-3.656Zm-6.294,0h2.173l1.15,3.656H8.115Zm-4.121,0H7.976L6.825,8.262H2.707ZM8.836,18.753,2.683,9.492H6.789l2.172,9.262Zm1.388,0L8.052,9.492h4.6l-2.172,9.262Zm1.643,0h-.124l2.172-9.262h4.106Z"
          transform="translate(-1.125 -3.375)"
          fill="#fff"
        />
      </svg>
    </>
  );
};

export const ThumpDownIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.994"
        height="19.994"
        viewBox="0 0 19.994 19.994"
      >
        <path
          id="like"
          d="M18.745,7.5h-1.25a1.209,1.209,0,0,0,.889-.361,1.193,1.193,0,0,0,.361-.879,1.232,1.232,0,0,0-.361-.889A1.19,1.19,0,0,0,17.495,5a1.209,1.209,0,0,0,.889-.361,1.193,1.193,0,0,0,.361-.879,1.232,1.232,0,0,0-.361-.889,1.19,1.19,0,0,0-.889-.371h-1.25a1.233,1.233,0,0,0,1.25-1.25,1.211,1.211,0,0,0-.361-.889A1.207,1.207,0,0,0,16.245,0h-10a10.225,10.225,0,0,0-2.5.625,10.225,10.225,0,0,1-2.5.625,1.278,1.278,0,0,0-.9.322A1.2,1.2,0,0,0,0,2.5v5A4.043,4.043,0,0,0,.361,9.04q.361.859.81.938A.114.114,0,0,0,1.25,10a7.456,7.456,0,0,1,2.05.283,7.7,7.7,0,0,1,1.67.664,8.913,8.913,0,0,1,1.26.859,7.493,7.493,0,0,1,.859.791,4.565,4.565,0,0,1,.41.527,8.825,8.825,0,0,0,1.1,1.3q.713.713,1.26,1.211a5.518,5.518,0,0,1,.966,1.171,2.492,2.492,0,0,1,.42,1.318v.625a1.192,1.192,0,0,0,.371.889,1.25,1.25,0,0,0,1.757,0,1.19,1.19,0,0,0,.371-.889v-2.5a4.821,4.821,0,0,0-.2-1.455,2.629,2.629,0,0,0-.429-.889,3.65,3.65,0,0,1-.429-.635,1.6,1.6,0,0,1-.2-.771.963.963,0,0,1,.2-.605,1.169,1.169,0,0,1,.469-.371,3.6,3.6,0,0,1,.547-.186,1.98,1.98,0,0,1,.469-.068l.2-.019a9.355,9.355,0,0,0,3.954-.771q1.67-.771,1.669-1.727a1.217,1.217,0,0,0-.361-.879A1.191,1.191,0,0,0,18.745,7.5Z"
          fill="#835efd"
          // fill="#606060"
        />
      </svg>
    </>
  );
};

export const ThumpUpIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.994"
        height="19.994"
        viewBox="0 0 19.994 19.994"
      >
        <path
          id="like"
          d="M18.745,12.5h-1.25a1.209,1.209,0,0,1,.889.361,1.193,1.193,0,0,1,.361.879,1.232,1.232,0,0,1-.361.889,1.19,1.19,0,0,1-.889.371,1.209,1.209,0,0,1,.889.361,1.193,1.193,0,0,1,.361.879,1.232,1.232,0,0,1-.361.889,1.19,1.19,0,0,1-.889.371h-1.25a1.25,1.25,0,1,1,0,2.5h-10a10.225,10.225,0,0,1-2.5-.625,10.225,10.225,0,0,0-2.5-.625,1.278,1.278,0,0,1-.9-.322A1.2,1.2,0,0,1,0,17.495v-5a4.043,4.043,0,0,1,.361-1.542q.361-.859.81-.938A.114.114,0,0,1,1.25,10,7.456,7.456,0,0,0,3.3,9.714a7.7,7.7,0,0,0,1.67-.664,8.913,8.913,0,0,0,1.26-.859A7.493,7.493,0,0,0,7.088,7.4a4.565,4.565,0,0,0,.41-.527,8.825,8.825,0,0,1,1.1-1.3q.713-.713,1.26-1.211a5.518,5.518,0,0,0,.966-1.171,2.492,2.492,0,0,0,.42-1.318V1.25a1.192,1.192,0,0,1,.371-.889,1.25,1.25,0,0,1,1.757,0,1.19,1.19,0,0,1,.371.889v2.5a4.821,4.821,0,0,1-.2,1.455,2.63,2.63,0,0,1-.429.889,3.65,3.65,0,0,0-.429.635,1.6,1.6,0,0,0-.2.771.963.963,0,0,0,.2.605,1.169,1.169,0,0,0,.469.371,3.6,3.6,0,0,0,.547.186,1.98,1.98,0,0,0,.469.068l.2.019a9.355,9.355,0,0,1,3.954.771q1.67.771,1.669,1.727a1.217,1.217,0,0,1-.361.879A1.191,1.191,0,0,1,18.745,12.5Z"
          fill="#835efd"
        />
      </svg>
    </>
  );
};

export const CalenderIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.693"
        height="10.339"
        viewBox="0 0 9.693 10.339"
      >
        <path
          id="Icon_metro-calendar"
          data-name="Icon metro-calendar"
          d="M5.8,5.805H7.094V7.1H5.8Zm1.939,0H9.033V7.1H7.741Zm1.939,0h1.292V7.1H9.679ZM3.863,9.683H5.156v1.292H3.863Zm1.939,0H7.094v1.292H5.8Zm1.939,0H9.033v1.292H7.741ZM5.8,7.744H7.094V9.037H5.8Zm1.939,0H9.033V9.037H7.741Zm1.939,0h1.292V9.037H9.679Zm-5.816,0H5.156V9.037H3.863Zm7.109-5.816v.646H9.679V1.928H5.156v.646H3.863V1.928H2.571v10.34h9.693V1.928H10.972Zm.646,9.693h-8.4V4.513h8.4Z"
          transform="translate(-2.57 -1.928)"
          fill="#606060"
        />
      </svg>
    </>
  );
};

export const VideoPlusIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
      >
        <path
          id="add"
          d="M18,3A15,15,0,1,0,33,18,15,15,0,0,0,18,3Zm7.5,16.5h-6v6h-3v-6h-6v-3h6v-6h3v6h6Z"
          transform="translate(-3 -3)"
          fill="#835efd"
        />
      </svg>
    </>
  );
};

export const CrossIcon = () => {
  return (
    <>
      <svg
        id="x-circle-fill"
        xmlns="http://www.w3.org/2000/svg"
        width="23.438"
        height="23.438"
        viewBox="0 0 23.438 23.438"
      >
        <path
          id="Path_816"
          data-name="Path 816"
          d="M23.438,11.719A11.719,11.719,0,1,1,11.719,0,11.719,11.719,0,0,1,23.438,11.719ZM17.365,7.11a.733.733,0,0,0-1.037-1.037l-4.608,4.61L7.11,6.074A.733.733,0,0,0,6.073,7.111l4.61,4.608-4.61,4.609A.733.733,0,0,0,7.11,17.365l4.609-4.61,4.609,4.61a.733.733,0,1,0,1.037-1.037l-4.61-4.609,4.61-4.609Z"
          fill="#fff"
          fillRule="evenodd"
        />
      </svg>
    </>
  );
};
export const AddIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.25"
        height="29.25"
        viewBox="0 0 29.25 29.25"
        className="icon"
      >
        <g
          id="Icon_ionic-ios-add-circle-outline"
          data-name="Icon ionic-ios-add-circle-outline"
          transform="translate(-3.375 -3.375)"
        >
          <path
            id="Path_14"
            data-name="Path 14"
            d="M24.363,16.875H19.125V11.637a1.125,1.125,0,0,0-2.25,0v5.238H11.637A1.077,1.077,0,0,0,10.512,18a1.089,1.089,0,0,0,1.125,1.125h5.238v5.238A1.09,1.09,0,0,0,18,25.488a1.119,1.119,0,0,0,1.125-1.125V19.125h5.238a1.125,1.125,0,0,0,0-2.25Z"
            fill="#606060"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M18,5.344A12.651,12.651,0,1,1,9.049,9.049,12.573,12.573,0,0,1,18,5.344m0-1.969A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375Z"
            fill="#606060"
          />
        </g>
      </svg>
    </>
  );
};

export const WithdrawIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="21.875"
        viewBox="0 0 25 21.875"
        className="icon"
      >
        <path
          id="Icon_open-transfer"
          data-name="Icon open-transfer"
          d="M18.75,0V3.125H0V6.25H18.75V9.375L25,4.688ZM6.25,12.5,0,17.188l6.25,4.688V18.75H25V15.625H6.25Z"
          fill="#606060"
        />
      </svg>
    </>
  );
};

export const TranIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.75"
        height="23.625"
        viewBox="0 0 24.75 23.625"
        className="icon"
      >
        <g
          id="Icon_ionic-md-list"
          data-name="Icon ionic-md-list"
          transform="translate(-5.625 -6.188)"
        >
          <g id="Group_13" data-name="Group 13">
            <path
              id="Path_16"
              data-name="Path 16"
              d="M5.625,19.688h18v3.375h-18Z"
              fill="#606060"
            />
            <path
              id="Path_17"
              data-name="Path 17"
              d="M5.625,12.938h22.5v3.375H5.625Z"
              fill="#606060"
            />
            <path
              id="Path_18"
              data-name="Path 18"
              d="M5.625,6.188h24.75V9.563H5.625Z"
              fill="#606060"
            />
          </g>
          <path
            id="Path_19"
            data-name="Path 19"
            d="M5.625,26.438h20.25v3.375H5.625Z"
            fill="#606060"
          />
        </g>
      </svg>
    </>
  );
};

export const UpIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.196"
        height="12.125"
        viewBox="0 0 16.196 12.125"
      >
        <path
          id="bxs-up-arrow"
          d="M3.809,18.166H18.386a.811.811,0,0,0,.666-1.27L11.764,6.368a.842.842,0,0,0-1.332,0L3.143,16.9a.809.809,0,0,0,.666,1.27Z"
          transform="translate(-2.998 -6.041)"
          fill="#00b69b"
        />
      </svg>
    </>
  );
};

export const SwtichArrowIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="19.123"
        viewBox="0 0 27 19.123"
      >
        <g
          id="Group_548"
          data-name="Group 548"
          transform="translate(-4.5 -8.445)"
        >
          <path
            id="Path_820"
            data-name="Path 820"
            d="M28.54,20.257a.99.99,0,0,0-.7-.288.973.973,0,0,0-.7.288.961.961,0,0,0-.288.7,1,1,0,0,0,.288.7l1.146,1.139H22.985l-8.719-10.9-.007-.007a1.01,1.01,0,0,0-.759-.352H5.484a.984.984,0,0,0,0,1.968h7.552l3.691,4.641-3.691,4.641H5.484a.984.984,0,1,0,0,1.968H13.5a.981.981,0,0,0,.752-.352l.007-.007L18,19.693l3.741,4.7.007.007a.983.983,0,0,0,.752.352h5.934l-1.146,1.139a.95.95,0,0,0-.288.7,1,1,0,0,0,.288.7.99.99,0,0,0,.7.288.973.973,0,0,0,.7-.288l2.363-2.348a1.528,1.528,0,0,0,0-2.18l-2.5-2.5Z"
            fill="#1b0945"
          />
          <path
            id="Path_821"
            data-name="Path 821"
            d="M19.645,16.552a.4.4,0,0,0,.323.148h.007a.407.407,0,0,0,.323-.155l2.665-3.326h5.33l-1.146,1.139a.95.95,0,0,0-.288.7,1,1,0,0,0,.288.7.99.99,0,0,0,.7.288.973.973,0,0,0,.7-.288l2.5-2.489a1.528,1.528,0,0,0,0-2.18L28.679,8.733a.99.99,0,0,0-.7-.288.973.973,0,0,0-.7.288.961.961,0,0,0-.288.7,1,1,0,0,0,.288.7l1.146,1.139H22.5a.981.981,0,0,0-.752.352l-.007.007-2.834,3.53a.413.413,0,0,0,.007.534Z"
            fill="#1b0945"
          />
        </g>
      </svg>
    </>
  );
};

export const CopyIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.481"
        height="23.768"
        viewBox="0 0 20.481 23.768"
      >
        <path
          id="copy"
          d="M15.121.375V2.55H2.175V17.67H0V2.55A2.1,2.1,0,0,1,.632,1.007,2.1,2.1,0,0,1,2.175.375H15.121Zm3.237,4.349a2.039,2.039,0,0,1,1.491.632,2.044,2.044,0,0,1,.632,1.491V21.969a2.1,2.1,0,0,1-.632,1.543,2.038,2.038,0,0,1-1.491.632H6.473A2.158,2.158,0,0,1,4.3,21.969V6.848a2.039,2.039,0,0,1,.632-1.491,2.1,2.1,0,0,1,1.542-.632Zm0,17.244V6.848H6.473V21.969Z"
          transform="translate(0 -0.375)"
          fill="#835efd"
        />
      </svg>
    </>
  );
};

export const EmailIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.423"
        height="15.302"
        viewBox="0 0 21.423 15.302"
      >
        <path
          id="email"
          d="M22.143,6.75H3.78A1.53,1.53,0,0,0,2.25,8.28V20.522a1.53,1.53,0,0,0,1.53,1.53H22.143a1.53,1.53,0,0,0,1.53-1.53V8.28A1.53,1.53,0,0,0,22.143,6.75ZM20.459,8.28l-7.5,5.187L5.463,8.28ZM3.78,20.522V8.977l8.745,6.052a.765.765,0,0,0,.872,0l8.745-6.052V20.522Z"
          transform="translate(-2.25 -6.75)"
          fill="#835efd"
        />
      </svg>
    </>
  );
};

export const EditProfile = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27.18"
        height="27.18"
        viewBox="0 0 27.18 27.18"
      >
        <g id="feather-edit" transform="translate(-2 -1.818)">
          <path
            id="Path_7"
            data-name="Path 7"
            d="M14,6H5.444A2.444,2.444,0,0,0,3,8.444v17.11A2.444,2.444,0,0,0,5.444,28h17.11A2.444,2.444,0,0,0,25,25.554V17"
            transform="translate(0 0)"
            fill="none"
            stroke="#606060"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_8"
            data-name="Path 8"
            d="M28.494,3.794a3.332,3.332,0,0,1,4.712,4.712L18.283,23.429,12,25l1.571-6.283Z"
            transform="translate(-6.002 0)"
            fill="none"
            stroke="#606060"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </>
  );
};

export const ChangepasswordIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="28.667"
        viewBox="0 0 26 28.667"
      >
        <g
          id="Icon_feather-lock"
          data-name="Icon feather-lock"
          transform="translate(-3.5 -2)"
        >
          <path
            id="Path_9"
            data-name="Path 9"
            d="M7.167,16.5H25.833a2.848,2.848,0,0,1,2.667,3V30a2.848,2.848,0,0,1-2.667,3H7.167A2.848,2.848,0,0,1,4.5,30V19.5a2.848,2.848,0,0,1,2.667-3Z"
            transform="translate(0 -3.333)"
            fill="none"
            stroke="#606060"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_10"
            data-name="Path 10"
            d="M10.5,16.5v-6c0-4.142,2.686-7.5,6-7.5s6,3.358,6,7.5v6"
            fill="none"
            stroke="#606060"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </>
  );
};

export const KycVerificationIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="33"
        viewBox="0 0 27 33"
      >
        <g
          id="Group_466"
          data-name="Group 466"
          transform="translate(-1558 -787)"
        >
          <path
            id="Icon_feather-shield"
            data-name="Icon feather-shield"
            d="M18,33s12-6,12-15V7.5L18,3,6,7.5V18C6,27,18,33,18,33Z"
            transform="translate(1553.5 785.5)"
            fill="none"
            stroke="#606060"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Icon_feather-check"
            data-name="Icon feather-check"
            d="M19,9l-8.937,8.938L6,13.875"
            transform="translate(1559.5 789.5)"
            fill="none"
            stroke="#606060"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
      </svg>
    </>
  );
};

export const UserIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="27.676"
        viewBox="0 0 33 27.676"
      >
        <g
          id="Icon_feather-users"
          data-name="Icon feather-users"
          transform="translate(0 -2.87)"
        >
          <path
            id="Path_37"
            data-name="Path 37"
            d="M25.5,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3"
            transform="translate(0 -2.455)"
            fill="none"
            stroke="#606060"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_38"
            data-name="Path 38"
            d="M19.5,10.5a6,6,0,1,1-6-6A6,6,0,0,1,19.5,10.5Z"
            transform="translate(-0.857)"
            fill="none"
            stroke="#606060"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_39"
            data-name="Path 39"
            d="M34.5,31.5v-3A6,6,0,0,0,30,22.7"
            transform="translate(-3 -2.455)"
            fill="none"
            stroke="#606060"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_40"
            data-name="Path 40"
            d="M24,4.7A6,6,0,0,1,24,16.32"
            transform="translate(-2.369)"
            fill="none"
            stroke="#606060"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
      </svg>
    </>
  );
};

export const LogoutIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="24.5"
        viewBox="0 0 28 24.5"
      >
        <path
          id="Icon_open-account-logout"
          data-name="Icon open-account-logout"
          d="M10.5,0V3.5h14V21h-14v3.5H28V0ZM7,7,0,12.25,7,17.5V14H21V10.5H7Z"
          fill="#606060"
        />
      </svg>
    </>
  );
};

export const SearchIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11.55"
        height="11.553"
        viewBox="0 0 11.55 11.553"
      >
        <path
          id="Icon_ionic-ios-search"
          data-name="Icon ionic-ios-search"
          d="M15.914,15.209,12.7,11.967a4.578,4.578,0,1,0-.695.7L15.2,15.892a.494.494,0,0,0,.7.018A.5.5,0,0,0,15.914,15.209ZM9.1,12.71a3.615,3.615,0,1,1,2.557-1.059A3.592,3.592,0,0,1,9.1,12.71Z"
          transform="translate(-4.5 -4.493)"
          fill="rgba(254,254,254,0.36)"
        />
      </svg>
    </>
  );
};

export const UserName = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.55"
        height="17.26"
        viewBox="0 0 14.55 17.26"
      >
        <path
          id="user"
          d="M13.55,13.455a3.067,3.067,0,0,1-.662,1.98,1.983,1.983,0,0,1-1.593.826H2.255a1.982,1.982,0,0,1-1.593-.826A3.071,3.071,0,0,1,0,13.455a15.23,15.23,0,0,1,.09-1.7,9.126,9.126,0,0,1,.333-1.609A5.354,5.354,0,0,1,1.042,8.76a2.966,2.966,0,0,1,1-.942,2.746,2.746,0,0,1,1.424-.365A4.575,4.575,0,0,0,6.775,8.808a4.575,4.575,0,0,0,3.314-1.355,2.748,2.748,0,0,1,1.424.365,2.966,2.966,0,0,1,1,.942,5.354,5.354,0,0,1,.619,1.387,9.126,9.126,0,0,1,.333,1.609,15.23,15.23,0,0,1,.09,1.7Zm-2.71-9.39A3.915,3.915,0,0,1,9.649,6.939,3.919,3.919,0,0,1,6.775,8.13,3.914,3.914,0,0,1,3.9,6.939,3.917,3.917,0,0,1,2.71,4.065,3.917,3.917,0,0,1,3.9,1.191,3.914,3.914,0,0,1,6.775,0,3.919,3.919,0,0,1,9.649,1.191,3.915,3.915,0,0,1,10.84,4.065Z"
          transform="translate(0.5 0.5)"
          fill="none"
          stroke="#835efd"
          strokeWidth="1"
        />
      </svg>
    </>
  );
};

export const EmailIcon1 = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.688"
        height="12.634"
        viewBox="0 0 17.688 12.634"
      >
        <path
          id="email"
          d="M18.674,6.75H3.513A1.263,1.263,0,0,0,2.25,8.013V18.121a1.263,1.263,0,0,0,1.263,1.263H18.674a1.263,1.263,0,0,0,1.263-1.263V8.013A1.263,1.263,0,0,0,18.674,6.75Zm-1.39,1.263L11.094,12.3,4.9,8.013ZM3.513,18.121V8.588l7.22,5a.632.632,0,0,0,.72,0l7.22-5v9.532Z"
          transform="translate(-2.25 -6.75)"
          fill="#835efd"
        />
      </svg>
    </>
  );
};

export const PhoneIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.217"
        height="16.211"
        viewBox="0 0 16.217 16.211"
      >
        <path
          id="phone"
          d="M17.711,19.586h-.106C5.353,18.882,3.614,8.544,3.37,5.389A1.871,1.871,0,0,1,5.091,3.375H8.527a1.247,1.247,0,0,1,1.16.785l.947,2.332a1.247,1.247,0,0,1-.274,1.347L9.032,9.18a5.842,5.842,0,0,0,4.726,4.739l1.353-1.341a1.247,1.247,0,0,1,1.353-.256l2.351.942a1.247,1.247,0,0,1,.768,1.159v3.292A1.871,1.871,0,0,1,17.711,19.586ZM5.241,4.622a.624.624,0,0,0-.624.624V5.3C4.9,8.987,6.743,17.716,17.674,18.339a.624.624,0,0,0,.661-.586v-3.33l-2.351-.942-1.79,1.777-.3-.038c-5.424-.679-6.16-6.1-6.16-6.16l-.038-.3,1.771-1.79-.936-2.35Z"
          transform="translate(-3.364 -3.375)"
          fill="#835efd"
        />
      </svg>
    </>
  );
};

export const SubjectIcon = () => {
  return (
    <>
      <svg
        id="note-edit-line"
        xmlns="http://www.w3.org/2000/svg"
        width="22.132"
        height="22.132"
        viewBox="0 0 22.132 22.132"
      >
        <path
          id="Path_84"
          data-name="Path 84"
          d="M20.9,22.9H5.408V7.408h9.306L16.122,6H5.408A1.408,1.408,0,0,0,4,7.408V22.9A1.408,1.408,0,0,0,5.408,24.3H20.9A1.408,1.408,0,0,0,22.3,22.9V12.336L20.9,13.744Z"
          transform="translate(-4 -2.171)"
          fill="#835efd"
        />
        <path
          id="Path_85"
          data-name="Path 85"
          d="M28.753,4.944,26.168,2.36a1.235,1.235,0,0,0-1.749,0L13.9,12.936l-.851,3.689a1.235,1.235,0,0,0,1.2,1.48,1.3,1.3,0,0,0,.284,0l3.72-.821L28.753,6.693a1.235,1.235,0,0,0,0-1.749ZM17.463,15.866l-2.807.621.652-2.784,7.915-7.969L25.386,7.9Zm8.79-8.836L24.089,4.868l1.189-1.212,2.178,2.178Z"
          transform="translate(-6.983 -1.997)"
          fill="#835efd"
        />
      </svg>
    </>
  );
};

export const SucessIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="83.88"
        height="83.88"
        viewBox="0 0 83.88 83.88"
      >
        <g id="Group_65" data-name="Group 65" transform="translate(-870 -389)">
          <g
            id="Ellipse_3"
            data-name="Ellipse 3"
            transform="translate(873 393)"
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
          >
            <ellipse cx="37.5" cy="38" rx="37.5" ry="38" stroke="none" />
            <ellipse cx="37.5" cy="38" rx="37" ry="37.5" fill="none" />
          </g>
          <path
            id="Icon_awesome-check-circle"
            data-name="Icon awesome-check-circle"
            d="M84.442,42.5A41.94,41.94,0,1,1,42.5.563,41.94,41.94,0,0,1,84.442,42.5ZM37.651,64.709,68.768,33.593a2.706,2.706,0,0,0,0-3.827L64.942,25.94a2.706,2.706,0,0,0-3.827,0L35.738,51.316,23.89,39.468a2.706,2.706,0,0,0-3.827,0l-3.827,3.827a2.706,2.706,0,0,0,0,3.827L33.825,64.709A2.706,2.706,0,0,0,37.651,64.709Z"
            transform="translate(869.438 388.438)"
            fill="#a0c97c"
          />
        </g>
      </svg>
    </>
  );
};

export const InsufficientBalance = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="82.88"
        height="82.88"
        viewBox="0 0 82.88 82.88"
      >
        <g
          id="Group_65"
          data-name="Group 65"
          transform="translate(-870.755 -389.503)"
        >
          <g
            id="Ellipse_3"
            data-name="Ellipse 3"
            transform="translate(873 393)"
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
          >
            <ellipse cx="37.5" cy="38" rx="37.5" ry="38" stroke="none" />
            <ellipse cx="37.5" cy="38" rx="37" ry="37.5" fill="none" />
          </g>
          <path
            id="Icon_awesome-exclamation-circle"
            data-name="Icon awesome-exclamation-circle"
            d="M83.442,42A41.44,41.44,0,1,1,42,.563,41.438,41.438,0,0,1,83.442,42ZM42,50.357a7.686,7.686,0,1,0,7.686,7.686A7.686,7.686,0,0,0,42,50.357ZM34.7,22.729l1.24,22.725a2.005,2.005,0,0,0,2,1.9h8.112a2.005,2.005,0,0,0,2-1.9L49.3,22.729a2.005,2.005,0,0,0-2-2.114H36.707A2.005,2.005,0,0,0,34.7,22.729Z"
            transform="translate(870.193 388.94)"
            fill="#ce4d4d"
          />
        </g>
      </svg>
    </>
  );
};

export const CancelFriendIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.52"
        height="20.087"
      //  viewBox="0 0 24.52 20.087"
        viewBox="0 0 36.621 30"
      >
        <g
          id="Icon_feather-user-x"
          data-name="Icon feather-user-x"
          transform="translate(0 -3)"
        >
          <path
            id="Path_820"
            data-name="Path 820"
            d="M24,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_821"
            data-name="Path 821"
            d="M18.75,10.5a6,6,0,1,1-6-6A6,6,0,0,1,18.75,10.5Z"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_822"
            data-name="Path 822"
            d="M27,12l7.5,7.5"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_823"
            data-name="Path 823"
            d="M34.5,12,27,19.5"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
      </svg>
    </>
  );
};


export const AddFriendIcon = () => {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 36 30">
  <g id="Icon_feather-user-plus" data-name="Icon feather-user-plus" transform="translate(0 -3)">
    <path id="Path_824" data-name="Path 824" d="M24,31.5v-3a6,6,0,0,0-6-6H7.5a6,6,0,0,0-6,6v3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
    <path id="Path_825" data-name="Path 825" d="M18.75,10.5a6,6,0,1,1-6-6A6,6,0,0,1,18.75,10.5Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
    <path id="Path_826" data-name="Path 826" d="M30,12v9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
    <path id="Path_827" data-name="Path 827" d="M34.5,16.5h-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
  </g>
</svg>

        </>
    )
}

export const CheckIcon = () => {
  return (<>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.243" height="12.559" viewBox="0 0 17.243 12.559">
  <path id="Icon_feather-check" data-name="Icon feather-check" d="M19,9l-8.937,8.938L6,13.875" transform="translate(-3.879 -6.879)" fill="none" stroke="#a0c97c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
</svg>

  </>)
}


export const CancleIcon = () => {
  return(<>
  <svg xmlns="http://www.w3.org/2000/svg" width="13.789" height="13.789" viewBox="0 0 13.789 13.789">
  <path id="Icon_metro-cross" data-name="Icon metro-cross" d="M16.234,13.006h0L12.051,8.822l4.183-4.183h0a.432.432,0,0,0,0-.609L14.258,2.054a.432.432,0,0,0-.609,0h0L9.465,6.237,5.282,2.054h0a.432.432,0,0,0-.609,0L2.7,4.03a.432.432,0,0,0,0,.609h0L6.88,8.822,2.7,13.006h0a.432.432,0,0,0,0,.609l1.976,1.976a.432.432,0,0,0,.609,0h0l4.183-4.183,4.183,4.183h0a.432.432,0,0,0,.609,0l1.976-1.976a.432.432,0,0,0,0-.609Z" transform="translate(-2.571 -1.928)" fill="#d80f0f"/>
</svg>

  </>)
}