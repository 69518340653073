import React from 'react'
import { Link } from 'react-router-dom'

const UserInfo = () => {
  return (
    <>
   <div className='container-fluid p-5'>
    <div className='row'>
    <div className='col-lg-3 col-xl-3'>
    
    </div>
    <div className='col-lg-3 col-xl-3'>
    <div className='d-flex flex-column'>
    <div className=''>Gain% : 50%</div>
    <div className=''>Friends</div>
    </div>
    </div>
    <div className='col-lg-3 col-xl-3'>
      <div className='d-flex flex-row'>
      <div className='avail'>
        <label className='avail-label'>Avail. Bal.</label>
        <div className='avail-amount'>$1000</div>
      </div>
      <div className='avail'>
        <label className='avail-label'>Followers</label>
        <div className='avail-amount'>2000</div>
      </div>
    </div>
      </div>
    
   <div className='col-lg-3 col-xl-3'>
      <div className='row'>
      <div className='d-flex'>
        <div className='menu-tab'>
          <Link to="/profile">Profile</Link>
        </div>
       
        <div className='menu-tab'>
          <Link to="/hotPick">Hot Picks </Link>
        </div>
        <div className='menu-tab'>
          <Link to="/league">League</Link>
        </div>
      </div>
      </div>

       <div className='row'>
        <button type="button" value="Create Duels" className='btn btn-primary btn-block'>Create Duels</button>
       </div>
  
   </div>
   </div>
   </div>
    </>
  )
}

export default UserInfo