import React from 'react'
import { Link } from 'react-router-dom'

const Expire = () => {
  return (
    <>
    <div className="expired mt-5">
  <img src="https://api.moonleague.com/images/email_template/expire.gif"/>

  <div className="message">
    <h1 className='pink-text'>Oops, this link is expired</h1>
    <p className='text-dark font-size-bold'>This URL is not valid anymore.</p>
    <Link to="/" >Go to moonleague.com</Link>
  </div>

</div>
    </>
  )
}

export default Expire