import { Nav, Tab, Row, Col, Container, Form, } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Navbar from "../MainLayout/Navbar/Navbar";
import Header from "../MainLayout/Header/Header";
import { InputNumber } from "primereact/inputnumber";
import Footer from "../MainLayout/Footer/Footer";
import { InputText } from 'primereact/inputtext';
import { AddIcon, WithdrawIcon, TranIcon } from "../../SVGIcon";
import { addFundAPI, getWalletHistoryAPI, withdrawFundAPI } from "../../slices/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { RadioButton } from "primereact/radiobutton";

const Wallet = () => {
  const [history, setHistory] = useState([]);
  const [statuses] = useState(['SUCCESS']);
  const [addFund, setaddFund] = useState([]);
  const dispatch = useDispatch();
  const [ingredient, setIngredient] = useState();
  const [value, setValue] = useState();
  const [withdrawAmount, setWithdrawAmount] = useState(null);
  const [emailId, setEmail] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let isUser = window.localStorage?.getItem("userid");

  const { userInfolist } = useSelector((state) => state.userProfile);
  const wallet_Amount = userInfolist?.data?.wallet_balance
  const KYCStatus = userInfolist?.data?.kyc_verify_status


  const mobile = window.matchMedia('(max-width: 400px)').matches;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const formatDate = (value) => {
    return new Date(value).toLocaleString('en-US',
    );
  };


  const dataBodyTemplate = (rowData) => {
    return (formatDate(rowData.created_at))
  };

  const transcationTypeTemplate = (rowData) => {
    return (
      <><p className=" mb-0 fw-500">{rowData?.transaction_id}</p></>
    )
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>{rowData?.transaction_type === "deposit"
        ? <p className="text-decoration-none white-space mb-0 danger-btn" >{rowData?.transaction_type}</p>
        : <> {rowData?.transaction_type === "purchase" ? <p className="text-decoration-none white-space mb-0 warning-btn" >{rowData?.transaction_type}</p>
          : <p className="text-decoration-none white-space mb-0 succes-btn" >{rowData?.transaction_type}</p>} </>}
      </>
    )
  };
  const amountTemplate = (rowData) => {
    return (
      <p>${rowData?.transaction_amount}</p>
    )
  };


  // Add funds****************************
  const addFundSubmit = () => {
    const AddAmountData = {
      user_id: isUser,
      wallet_amount: value || ingredient

    };
    dispatch(addFundAPI({ AddAmountData }))
      .unwrap()
      .then((res) => {
        setaddFund(res);
        setValue('');
        setIngredient('');


      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };


  // withdraw funds **********************
  const withdrawAmountHandler = () => {

    dispatch(withdrawFundAPI({
      user_id: isUser,
      email: emailId,
      withdraw_amount: withdrawAmount
    })).then(() => {
      setWithdrawAmount(null);
      setEmail("")
    }).catch(() => {
      console.log("error while withdraw funds")
    })
  }

  //  Transcation history *******************
  useEffect(() => {
    dispatch(getWalletHistoryAPI({ user_id: isUser }))
      .unwrap()
      .then((res) => {
        setHistory(res?.data);

      });
  }, [dispatch]);


  return (
    <>
      <Navbar />
      <div className='main-layout'>
        <div className='row mx-0 mb-3'>
          <Header />
        </div>
        <div className='mb-3 d-flex justify-content-end'>
          <h5 className='pink-text'>Wallet : <span>${wallet_Amount}</span></h5>
        </div>
        <div className="my-3 min-vh">
          <Tab.Container id="left-tabs-example" defaultActiveKey="addFund">
            <Row>
              <Col xxl={3} xl={3} lg={12}>
                <Nav variant="pills"
                  className="flex-md-row flex-xl-column uprofile-sidebar tab-header-tablet">
                  <Nav.Item>
                    <Nav.Link eventKey="addFund" className="d-flex align-items-center">
                      {" "}
                      <span className="me-3">
                        <AddIcon className="icon" />
                      </span>
                      Add Funds
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="withdrawFund" className="d-flex align-items-center">
                      {" "}
                      <span className="me-3">
                        <WithdrawIcon className="icon" />
                      </span>
                      Withdraw Funds
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="transaction" className="d-flex align-items-center">
                      {" "}
                      <span className="me-3">
                        <TranIcon className="icon" />
                      </span>
                      Transactions
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col xxl={9} xl={9} lg={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="addFund">
                    <div className="editForm-sec wallet-container">
                      <h5 className="editForm-head">Enter Deposit Amount</h5>
                      <div>
                        <Container className="p-4">
                          <div className='bg-container dark-blue fw-bolder add-fund-btn-amount d-flex'>

                            <div className="button">
                              <RadioButton inputId="a25" name="check-substitution-2" value={10.00} onChange={(e) => setIngredient(e.value)} checked={ingredient === 10.00} />
                              <label className="btn-default" htmlFor="a25">$10.00</label>
                            </div>
                            <div className="button">
                              <RadioButton inputId="a50" name="check-substitution-2" value={25.00} onChange={(e) => setIngredient(e?.value)} checked={ingredient === 25.00} />
                              <label className="btn-default" htmlFor="a50">$25.00</label>
                            </div>
                            <div className="button">
                              <RadioButton inputId="a75" name="check-substitution-2" value={50.00} onChange={(e) => setIngredient(e?.value)} checked={ingredient === 50.00} />
                              <label className="btn-default" htmlFor="a75">$50.00</label>
                            </div>
                            <div className="button">
                              <RadioButton inputId="a80" name="check-substitution-2" value={100.00} onChange={(e) => setIngredient(e?.value)} checked={ingredient === 100.00} />
                              <label className="btn-default" htmlFor="a80">$100.00</label>
                            </div>
                            <div className="button">
                              <RadioButton inputId="a95" name="check-substitution-2" value={500.00} onChange={(e) => setIngredient(e?.value)} checked={ingredient === 500.00} />
                              <label className="btn-default" htmlFor="a95">$500.00</label>
                            </div>
                          </div>
                          <Row className='mt-3'>
                            <Col lg={4} xl={4} md={6}>
                              <Form.Group >
                                <Form.Label>Enter Amount</Form.Label>
                                <div className="p-inputgroup flex-1">
                                  <span className="p-inputgroup-addon">$</span>
                                  <InputNumber
                                    placeholder="Enter Amount"
                                    value={value || ingredient} onChange={(e) => setValue(e.value)} mode="decimal" minFractionDigits={2} maxFractionDigits={2} maxLength="10"
                                  />
                                </div>

                              </Form.Group>
                            </Col>
                            <Col lg={3} xl={3} md={6} className='btn-paypal'>
                              <button className='btn btn-primary py-2 wallet-btn' onClick={() => { addFundSubmit() }}>Add Funds</button>
                            </Col>
                          </Row>
                          <p className='bg-gray pink-text'>Note: Successful deposits might take few minutes to show in wallet.</p>
                        </Container>

                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="withdrawFund">
                    <div className="editForm-sec wallet-container">
                      <h5 className="editForm-head">Withdraw Funds</h5>
                      <div >
                        {KYCStatus === "approved" ? <Container className="p-4 ">
                          <Col lg={4} xl={4} md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label>Enter Amount</Form.Label>
                              <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">$</span>
                                <InputNumber
                                  name="withdraw_amount"
                                  className="p-invalid"
                                  value={withdrawAmount}
                                  placeholder="Enter Amount"
                                  onValueChange={(e) => setWithdrawAmount(e.value)} mode="decimal" minFractionDigits={2} maxFractionDigits={2} maxLength="10" />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={4} xl={4} md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label>Enter Email ID</Form.Label>
                              <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">@</span>

                                <InputText
                                  placeholder="Email Address"
                                  value={emailId}
                                  onChange={(e) => setEmail(e.target.value)} />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={4} xl={4} md={6} className='mb-3'>
                            <button onClick={withdrawAmountHandler} className='btn btn-primary py-2 wallet-btn'>Withdraw Amount</button>
                          </Col>
                          <p className='bg-gray'>Note: Successful deposits might take few minutes to show in wallet.</p>
                        </Container> : (<Container className="p-4 "><h5 className="pink-text">Please Verify Your KYC before withdraw funds</h5></Container>)}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="transaction">
                    <div className="editForm-sec">
                      <h5 className="editForm-head">Transaction History</h5>
                      <div>
                        <Container className="wallet-container">
                          <DataTable value={history} editMode="row" dataKey="id"
                            scrollable
                            scrollHeight="550px"
                            emptyMessage="No Transaction found.">
                            <Column field="created_at" header="Date" body={dataBodyTemplate}></Column>
                            <Column field="transaction_id" header={`${mobile ? "Id" : "Transaction Id"}`} body={transcationTypeTemplate}></Column>
                            <Column field="transaction_amount" header={`${mobile ? "$" : "Amount"}`} body={amountTemplate}></Column>
                            <Column field="transaction_type" header={`${mobile ? "Type" : "Transaction Type"}`} body={statusBodyTemplate} ></Column>


                          </DataTable>
                        </Container>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

        </div>
      </div>
      <ToastContainer />

      <Footer />

    </>
  )
}

export default Wallet