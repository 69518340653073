
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "../config";
import authHeader from "../util/Authheader";
import axios from "axios";


//api/duel/get-edit-symbol-list/,/,/,
//Enter Duel to edit by Unique ID and Duel Id
export const editDuelById = createAsyncThunk( "api/duel/get-edit-symbol-list/", async (args, thunkAPI) => {
    
    const url = `${baseURL}api/duel/get-edit-symbol-list/${args.loggedin_user_id}/${args.duel_id}/${args.unique_id}`;
    return await axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        let data = response?.data;
    
        return data;
        
      })
      .catch((err) => {
        console.log(err, "edit duel by id");
      });
})

//api/duel/edit-enter-duel
export const getEditCoinsAPI = createAsyncThunk(
    "api/duel/edit-enter-duel",
    async (args, thunkAPI) => {   
      const urlname = `${baseURL}api/duel/edit-enter-duel`;
     try {
        const response = await axios({ 
          method: "post",
          url: urlname,
          data: args.exchangeId,
          headers: {
            
            ...authHeader(),
            
          },
        });
        
        let data = response.data;
      
        if (data.status_code === 400) {
        
          return Promise.reject(data);
        } else {
        //  toast.success(data.message);
        //  console.log(data,'success data');
          return data;
        }
    
     
      } catch (error) {
        // console.log(error)
     
          if (error.response) {
            const message = error.response.data.message;
            return thunkAPI.rejectWithValue(message);
          }
      
      }
    }
    );
  
//api/duel/edit-enter-duel
export const getEditBloodbathCoinsAPI = createAsyncThunk(
  "api/duel/edit-bloodbath-duel",
  async (args, thunkAPI) => {   
    const urlname = `${baseURL}api/duel/edit-bloodbath-duel`;
   try {
      const response = await axios({ 
        method: "post",
        url: urlname,
        data: args.exchangeId,
        headers: {
          
          ...authHeader(),
          
        },
      });
      
      let data = response.data;
    
      if (data.status_code === 400) {
      
        return Promise.reject(data);
      } else {
      //  toast.success(data.message);
      //  console.log(data,'success data');
        return data;
      }
  
   
    } catch (error) {
       if (error.response) {
          const message = error.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    
    }
  }
  );

    //Update Duels API
//  api/duel/join-duel
export const updatejoinDuelsAPI = createAsyncThunk(
  "api/duel/update-joined-duel",
  async (args, thunkAPI) => {   
    const urlname = `${baseURL}api/duel/update-joined-duel`;
   try {
      const response = await axios({ 
        method: "post",
        url: urlname,
        data: args.joinDuelData,
         headers: {
        
          ...authHeader(),
            
         },
      });
  
      let data = response.data;
    
      if (data.status_code === 400) {
        // toast.error(data.message);
        return Promise.reject(data);
      } else {
      //  toast.success(data.message);
      //  console.log(data,'success data');
        return data;
      }
  
   
    } catch (error) {
      console.log(error)
   
        if (error.response) {
          const message = error.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    
    }
  }
  );

const editDuelSlice = createSlice({
    name: "edit_Join",
    initialState: {
      editDuelByIdList: [],
      editExchangeCoinsList:[],
      isFetching: false,
      getEditBloodbathCoins:[],
      updatejoinDuelSymbolsList:[],
    //   bloodBathExchangeCoinsList:[],
      
      status: null,
    },
    extraReducers: (builder) => {
      builder
      //Get FreeGames List
        .addCase(editDuelById.pending, (state, action) => {
          state.status = "loading";
          state.isFetching = "true";
        })
        .addCase(editDuelById.fulfilled, (state, action) => {
          state.editDuelByIdList = action.payload;
          state.status = "success";
          state.isFetching = "false";
        })
        .addCase(editDuelById.rejected, (state, action) => {
          state.status = "failed";
          state.isFetching = "false";
        })
          // getEditCoinsAPI Symbols list
        .addCase(getEditCoinsAPI.pending, (state,action) => {
          state.status = "loading";
          state.isFetching = "true";
        })
        .addCase(getEditCoinsAPI.fulfilled, (state, action) => {
          state.editExchangeCoinsList = action.payload;
          state.status = "success";
          state.isFetching = "false";
        })
        .addCase(getEditCoinsAPI.rejected, (state, action) => {
          state.status = "failed";
          state.isFetching = "false";
        })

        // getEditBloodbathCoinsAPI Symbols list
        .addCase(getEditBloodbathCoinsAPI.pending, (state,action) => {
          state.status = "loading";
          state.isFetching = "true";
        })
        .addCase(getEditBloodbathCoinsAPI.fulfilled, (state, action) => {
          state.getEditBloodbathCoins = action.payload;
          state.status = "success";
          state.isFetching = "false";
        })
        .addCase(getEditBloodbathCoinsAPI.rejected, (state, action) => {
          state.status = "failed";
          state.isFetching = "false";
        })

        // updatejoinDuelsAPI
        .addCase(updatejoinDuelsAPI.pending, (state,action) => {
          state.status = "loading";
        })
        .addCase(updatejoinDuelsAPI.fulfilled, (state, action) => {
          state.updatejoinDuelSymbolsList = action.payload;
          state.status = "success";
        })
        .addCase(updatejoinDuelsAPI.rejected, (state, action) => {
          state.status = "failed";
        })

        //BloodBath Coins
       //getBloodBathCoinsAPI
    //    .addCase(getBloodBathCoinsAPI.pending, (state,action) => {
    //     state.status = "loading";
    //   })
    //   .addCase(getBloodBathCoinsAPI.fulfilled, (state, action) => {
    //     state.bloodBathExchangeCoinsList = action.payload;
    //     state.status = "success";
    //   })
    //   .addCase(getBloodBathCoinsAPI.rejected, (state, action) => {
    //     state.status = "failed";
    //   })
  
     
    
    },
  });
  
  export default editDuelSlice.reducer;
  export const editDuelSelector = (state) => state.edit_Join;
