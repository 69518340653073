import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import ProfileService from "../services/profile-services";

import { baseURL } from "../config";
import authHeader from "../util/Authheader";
import axios from "axios";
import {toast} from 'react-toastify'

// let locacluseid = window.localStorage.getItem("userid");
export const getUserDetails = createAsyncThunk(
  "getUser/getUserDetails",
  async (args, { dispatch, getState }) => {
    //thunkApi: { dispatch, getState }
    //getState gives us access to "all" the state in the store
    //we can use the dispatch to 'dispatch' another action
    // const { posts } = getState();
    // console.log(posts);
    const url = `${baseURL}api/user/i/${args.userId}`;
    return await axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
      //  console.log(response.data, "get UserDetails");
        return data;
      })
      .catch((err) => {
        console.log(err, "get UserDetails error");
      });
  }
);

//Edit Profile Slice
const token = window.localStorage.getItem("userToken");
//console.log(token,'Profile Token');
//let locacluseid = window.localStorage.getItem("userid");
//console.log(locacluseid,'Local User ID')
export const editProfile = createAsyncThunk(
  "api/user/update-profile",
  async (args, thunkAPI) => {  
    const urlname = `${baseURL}api/user/update-profile/${args.userId}`;
    // const urlname = `${baseURL}api/user/update-profile/${locacluseid}`;
    const formdata = new FormData();
    Object.keys(args.formValues).forEach((key) => {
      formdata.append(key, args.formValues[key]);
    });

    try {
      const response = await axios({
        method: "post",
        url: urlname,
        data: args.formValues,
        
        // headers: authHeader(),
         headers: {
         "Content-Type": "multipart/form-data",
          ...authHeader(),
            // Authorization: `Bearer ${token}`,
         },
      });

      let data = response.data;
     // console.log(data, "data");
      if (data.status_code === 400) {
       // console.log("eror thow", data);
        toast.error(data.message);
        return Promise.reject(data);
      } else {
        toast.success(data.message);
        return data;
      }

   
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);



//Update Profile


export const updatePwdAPI = createAsyncThunk(
"api/user/change-password/",
async (args, thunkAPI) => {  
  const urlname = `${baseURL}api/user/change-password/${args.userId}`;
 try {
    const response = await axios({
      method: "post",
      url: urlname,
      data: args.updateForm,
       headers: {
      
        ...authHeader(),
          
       },
    });

    let data = response.data;
   // console.log(data, "data");
    if (data.status_code === 400) {
     // console.log("eror thow", data);
      toast.error(data.message);
      return Promise.reject(data);
    } else {
      toast.success(data.message);
      return data;
    }

  } catch (error) {
    if (error.response) {
      const message = error.response.data.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
}
);


//api/user/all-list

export const getAllUsers = createAsyncThunk(
  "api/user/all-list",
  async (args, { dispatch, getState }) => {
    const url = `${baseURL}api/user/all-list`;
    return await axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        let data = response.data.data;

        return data;
      })
      .catch((err) => {
        console.log(err, "get all users error");
      });
  }
);




const getuserSlice = createSlice({
  name: "getUser",
  initialState: {
    userInfolist: [],
    status: null,
    getUsersList:[],
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.userInfolist = action.payload;
        state.status = "success";
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.status = "failed";
      })

      //editProfile
      .addCase(editProfile.pending, (state, action) => {
        state.status = "loading";
       // console.log("payload pending", action.payload);
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.status = "success";
      //  console.log("payload fulfilled", action.payload);
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.status = "failed";
       // console.log("payload fulfilled", action.payload);
      })
      //Update Password
      
      .addCase(updatePwdAPI.pending, (state, action) => {
        state.status = "loading";
        // console.log("payload pending", action.payload);
      })
      .addCase(updatePwdAPI.fulfilled, (state, action) => {
        state.status = "success";
      //  console.log("payload fulfilled", action.payload);
      })
      .addCase(updatePwdAPI.rejected, (state, action) => {
        state.status = "failed";
        // console.log("payload fulfilled", action.payload);
      })

      //Get all users list
      
      .addCase(getAllUsers.pending, (state, action) => {
        state.status = "loading";
        // console.log("payload pending", action.payload);
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.status = "success";
        state.getUsersList = action.payload;
      //  console.log("payload fulfilled", action.payload);
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.status = "failed";
        // console.log("payload fulfilled", action.payload);
      });
  },
});

export default getuserSlice.reducer;
