import { Carousel } from "primereact/carousel";
import { CrossIcon } from '../../SVGIcon'
import DeleteVideo from "./DeleteVideo";
import { useState } from "react";

const VideoScroll = ({ getusersvideolist, handlevideolist }) => {

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [getVideoId, setVideoId] = useState('')
  const handleDelete = (product_id) => {
    setDeleteModalShow(true);
    setVideoId(product_id);
  }

  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const productTemplate = (product) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center hot-Active-card" id={product.id}>
        <button className="btn video-cross" type="button" onClick={() => handleDelete(product.id)}><CrossIcon /></button>
        <div className='d-flex justify-content-evenly innter-carousel'>
          <video controls className="d-block w-100" style={{ height: "195px" }}>
            <source src={`${product.video}`} type="video/mp4" />
          </video>
        </div>
      </div>
    );
  };
  return (
    <>
      {getusersvideolist?.length > 0 ? (
        <>
          <Carousel
            value={getusersvideolist}
            numVisible={1}
            numScroll={1}
            responsiveOptions={responsiveOptions}
            itemTemplate={productTemplate}
            showIndicators={false}
          />
        </>
      ) : (
        <>
          <p className="bg-gray fw-500" style={{ height: "200px" }}>There is no video data available yet!</p>
        </>
      )}
      <DeleteVideo show={deleteModalShow} onHide={() => setDeleteModalShow(false)} getvideoId={getVideoId} handlevideolist={handlevideolist} />
    </>
  )
}

export default VideoScroll