import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "../config";
import authHeader from "../util/Authheader";
import axios from "axios";
import { toast } from "react-toastify";

//api/spectate/get-join-duel-symbol-list-byid

export const gameScreenByDuelId = createAsyncThunk(
  "api/spectate/get-all-duelers-list-by-duelid",
  async (args, thunkAPI) => {
    const url = `${baseURL}api/spectate/get-all-duelers-list-by-duelid`;
    return await axios
      .post(url,args.duelerData, { headers: authHeader() })
      .then((response) => {
        let data = response?.data;

        return data;
      })
      .catch((err) => {
        console.log(err, "Spectate duel by id");
      });
  }
);

// api/spectate/get-all-slot-duelers-by-guid
export const getAllSlotDuelerByDuelId = createAsyncThunk(
  "api/spectate/get-all-slot-duelers-by-guid",
  async (args, thunkAPI) => {
    const url = `${baseURL}api/spectate/get-all-slot-duelers-by-guid`;
    return await axios
      .post(url,args.duelerData, { headers: authHeader() })
      .then((response) => {
        let data = response?.data;

        return data;
      })
      .catch((err) => {
        console.log(err, "Spectate duel by id");
      });
  }
);

//api/spectate/get-join-duel-symbol-list-byid
export const specateDuelsSymbolByIdAPI = createAsyncThunk(
  "api/spectate/get-join-duel-symbol-list-byid",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/spectate/get-join-duel-symbol-list-byid`;
    return await axios
      .post(urlname, args.JoinedDuelData, { headers: authHeader() })
      .then((response) => {
        let data = response.data;

        return data;
      })
      .catch((err) => {
        console.log(err, "get spectate Duel response");
      });
  }
);

//api/spectate/get-bloodbath-and-battle-join-duel-symbol-list-byid
export const specateBloodbathDuelsSymbolByIdAPI = createAsyncThunk(
  "api/spectate/get-bloodbath-and-battle-join-duel-symbol-list-byid",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/spectate/get-bloodbath-and-battle-join-duel-symbol-list-byid`;
    return await axios
      .post(urlname, args.JoinedDuelData, { headers: authHeader() })
      .then((response) => {
        let data = response.data;

        return data;
      })
      .catch((err) => {
        console.log(err, "get spectate Duel response");
      });
  }
);

//api/spectate/api/duel/get-duel-slot-by-duel-id
export const specateGetduelSlotbyduelId = createAsyncThunk( 
  "api/duel/get-duel-slot-by-duel-id/", async (args, thunkAPI) => {
    
  const url = `${baseURL}api/duel/get-duel-slot-by-duel-id/${args.duel_id}/${args.loggedin_user_id}`;
  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response?.data;
  
      return data;
    })
    .catch((err) => {
      console.log(err, "get duel by id");
    });
})

//api/spectate/get-another-duelers-symbols-list

export const specateAnotherDuelsSymbolListAPI = createAsyncThunk(
  "api/spectate/get-another-duelers-symbols-list",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/spectate/get-another-duelers-symbols-list`;
    return await axios
      .post(urlname, args.AnotherSymbolsData, { headers: authHeader() })
      .then((response) => {
        let data = response.data;

        return data;
      })
      .catch((err) => {
        console.log(err, "get spectate Duel response");
      });
  }
);

//api/spectate/api/spectate/get-standard-battle-list
export const specateGetStandardBattleList = createAsyncThunk( 
  "/api/spectate/get-standard-battle-list", async (args, thunkAPI) => {
    
  const url = `${baseURL}/api/spectate/get-standard-battle-list`;
  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response?.data;
  
      return data;
    })
    .catch((err) => {
      console.log(err, "get standard battle list");
    });
});

//api/spectate/api/spectate/get-bloodbath-battle-list
export const specateGetBloodbathBattleList = createAsyncThunk( 
  "/api/spectate/get-bloodbath-battle-list", async (args, thunkAPI) => {
    
  const url = `${baseURL}/api/spectate/get-bloodbath-battle-list`;
  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response?.data;
  
      return data;
    })
    .catch((err) => {
      console.log(err, "get bloodbath battle list");
    });
});


//api/spectate/symbol-swapping-by-id

export const specateSymbolSwappingIdAPI = createAsyncThunk(
  "api/spectate/symbol-swapping-by-id",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/spectate/symbol-swapping-by-id`;
    return await axios
      .post(urlname, args.SwapSymbolsData, { headers: authHeader() })
      .then((response) => {
        let data = response.data;

        if (data.status_code === 400 || data.status_code === 404) {
        
          toast.error(data.message);
          return Promise.reject(data);
        } else {
          toast.success(data.message);
          //console.log(data,'success data');
          return data;
        }
      })
      .catch((err) => {
        console.log(err, "get Swapping Duel response");
      });
  }
);

//api/spectate/bloodbath-with-symbol

export const specateBattleSymbolSwappingIdAPI = createAsyncThunk(
  "api/battle/bloodbath-with-symbol",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/battle/bloodbath-with-symbol`;
    return await axios
      .post(urlname, args.BattleSwapSymbolsData, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
        // return data;
       
        if (data.status_code === 400 || data.status_code === 404) {
        
          toast.error(data.message);
          return Promise.reject(data);
        } else {
          toast.success(data.message);
          //console.log(data,'success data');
          return data;
        }
      })
      .catch((err) => {
        console.log(err, "get Battle Duel response");
      });
  }
);

//api/spectate/standard-with-symbol

export const specateStandardSymbolSwappingIdAPI = createAsyncThunk(
  "api/battle/standard-battle-with-symbol",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/battle/standard-battle-with-symbol`;
    return await axios
      .post(urlname, args.StandardBattleSymbolsData, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
        // return data;
       
        if (data.status_code === 400 || data.status_code === 404) {
        
          toast.error(data.message);
          return Promise.reject(data);
        } else {
          toast.success(data.message);
          //console.log(data,'success data');
          return data;
        }
      })
      .catch((err) => {
        console.log(err, "get Battle Duel response");
      });
  }
);

//api/spectate/blocker-with-symbol

export const specateStandardBlockerBattleSymbolSwappingIdAPI = createAsyncThunk(
  "api/battle/blocker-apply-on-symbols",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/battle/blocker-apply-on-symbols`;
    return await axios
      .post(urlname, args.StandardBlockerBattleSwapSymbolsData, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
        // return data;
       
        if (data.status_code === 400 || data.status_code === 404) {
        
          toast.error(data.message);
          return Promise.reject(data);
        } else {
          toast.success(data.message);
          //console.log(data,'success data');
          return data;
        }
      })
      .catch((err) => {
        console.log(err, "get Battle Duel response");
      });
  }
);

export const specateBloodbathBlockerBattleSymbolSwappingIdAPI = createAsyncThunk(
  "api/battle/blocker-apply-on-symbolss",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/battle/blocker-apply-on-symbolss`;
    return await axios
      .post(urlname, args.BloodbathBlockerBattleSwapSymbolsData, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
        // return data;
       
        if (data.status_code === 400 || data.status_code === 404) {
        
          toast.error(data.message);
          return Promise.reject(data);
        } else {
          toast.success(data.message);
          //console.log(data,'success data');
          return data;
        }
      })
      .catch((err) => {
        console.log(err, "get Battle Duel response");
      });
  }
);


//api/unity/joined-duelers-by-duelguid

export const spectateUnity = createAsyncThunk(
  "api/unity/joined-duelers-by-duelguid",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/unity/joined-duelers-by-duelguid`;
    return await axios
      .post(urlname, args.duelDataId, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
        return data;
       
      })
      .catch((err) => {
        console.log(err,"Unity data is not receiving");
      });
  }
);

const gameScreenSlice = createSlice({
  name: "game_Spectate",
  initialState: {
    duelersListByDuelID: [],
    AnotherDuelsSymbolList: [],
    specateDuelsSymbolByIdList: [],
    specateGetduelSlotbyduelId:[],
    specateGetStandardBattleList:[],
    specateBloodbathDuelsSymbolByIdList:[],
    specateStandardSymbolSwappingIdAPI:[],
    specateSymbolSwappingIdAPI:[],
    specateStandardBlockerBattleList:[],
    specateBloodbathBlockerBattleList:[],
    spectateUnity:[],
    status: null,
  },
  extraReducers: (builder) => {
    builder
      //gameScreenByDuelId
      .addCase(gameScreenByDuelId.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(gameScreenByDuelId.fulfilled, (state, action) => {
        state.duelersListByDuelID = action.payload;
        state.status = "success";
      })
      .addCase(gameScreenByDuelId.rejected, (state, action) => {
        state.status = "failed";
      })
      // specateAnotherDuelsSymbolListAPI
      .addCase(specateAnotherDuelsSymbolListAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(specateAnotherDuelsSymbolListAPI.fulfilled, (state, action) => {
        state.editExchangeCoinsList = action.payload;
        state.status = "success";
      })
      .addCase(specateAnotherDuelsSymbolListAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //specateDuelsSymbolByIdAPI
      .addCase(specateDuelsSymbolByIdAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(specateDuelsSymbolByIdAPI.fulfilled, (state, action) => {
        state.specateDuelsSymbolByIdList = action.payload;
        state.status = "success";
      })
      .addCase(specateDuelsSymbolByIdAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //specateBloodbathDuelsSymbolByIdAPI
      .addCase(specateBloodbathDuelsSymbolByIdAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(specateBloodbathDuelsSymbolByIdAPI.fulfilled, (state, action) => {
        state.specateBloodbathDuelsSymbolByIdList = action.payload;
        state.status = "success";
      })
      .addCase(specateBloodbathDuelsSymbolByIdAPI.rejected, (state, action) => {
        state.status = "failed";
      })

       //specateGetduelSlotbyduelId
       .addCase(specateGetduelSlotbyduelId.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(specateGetduelSlotbyduelId.fulfilled, (state, action) => {
        state.specateGetduelSlotbyduelId = action.payload;
        state.status = "success";
      })
      .addCase(specateGetduelSlotbyduelId.rejected, (state, action) => {
        state.status = "failed";
      })

       //specateGetStandardBattleList
       .addCase(specateGetStandardBattleList.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(specateGetStandardBattleList.fulfilled, (state, action) => {
        state.specateGetStandardBattleList = action.payload;
        state.status = "success";
      })
      .addCase(specateGetStandardBattleList.rejected, (state, action) => {
        state.status = "failed";
      })

      //specateGetBloodbathBattleList
      .addCase(specateGetBloodbathBattleList.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(specateGetBloodbathBattleList.fulfilled, (state, action) => {
        state.specateGetBloodbathBattleList = action.payload;
        state.status = "success";
      })
      .addCase(specateGetBloodbathBattleList.rejected, (state, action) => {
        state.status = "failed";
      })

      //specateSymbolSwappingIdAPI
      .addCase(specateSymbolSwappingIdAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(specateSymbolSwappingIdAPI.fulfilled, (state, action) => {
        state.specateSymbolSwappingIdAPI = action.payload;
        state.status = "success";
      })
      .addCase(specateSymbolSwappingIdAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //specateBattleSymbolSwappingIdAPI
      .addCase(specateBattleSymbolSwappingIdAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(specateBattleSymbolSwappingIdAPI.fulfilled, (state, action) => {
        state.specateBattleSymbolSwappingIdAPI = action.payload;
        state.status = "success";
      })
      .addCase(specateBattleSymbolSwappingIdAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //specateBattleSymbolSwappingIdAPI
      .addCase(spectateUnity.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(spectateUnity.fulfilled, (state, action) => {
        state.spectateUnity = action.payload;
        state.status = "success";
      })
      .addCase(spectateUnity.rejected, (state, action) => {
        state.status = "failed";
      })

      
      //specateStandardSymbolSwappingIdAPI
      .addCase(specateStandardSymbolSwappingIdAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(specateStandardSymbolSwappingIdAPI.fulfilled, (state, action) => {
        state.specateStandardSymbolSwappingIdAPI = action.payload;
        state.status = "success";
      })
      .addCase(specateStandardSymbolSwappingIdAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //specateStandardBlockerBattleSymbolSwappingIdAPI
      .addCase(specateStandardBlockerBattleSymbolSwappingIdAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(specateStandardBlockerBattleSymbolSwappingIdAPI.fulfilled, (state, action) => {
        state.specateStandardBlockerBattleList = action.payload;
        state.status = "success";
      })
      .addCase(specateStandardBlockerBattleSymbolSwappingIdAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //specateBlockerBattleSymbolSwappingIdAPI
      .addCase(specateBloodbathBlockerBattleSymbolSwappingIdAPI.pending, (state, action) => {
      state.status = "loading";
      })
      .addCase(specateBloodbathBlockerBattleSymbolSwappingIdAPI.fulfilled, (state, action) => {
      state.specateBloodbathBlockerBattleList = action.payload;
      state.status = "success";
      })
      .addCase(specateBloodbathBlockerBattleSymbolSwappingIdAPI.rejected, (state, action) => {
      state.status = "failed";
      })
       //getAllSlotDuelerByDuelId
       .addCase(getAllSlotDuelerByDuelId.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllSlotDuelerByDuelId.fulfilled, (state, action) => {
        state.getAllSlotDuelerByDuelId = action.payload;
        state.status = "success";
      })
      .addCase(getAllSlotDuelerByDuelId.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default gameScreenSlice.reducer;
