import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import RegisterForm from "./RegisterForm";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
//Redux call
import { register } from "../../slices/authSlice";
const Register = () => {
  const {
    values, 
    errors,
    handleChange,
    handleSubmit,
    passwordShown,
    togglePasswordVisiblity,
    cnfpasswordShown,
    toggleCnfPasswordVisiblity,
    handleMobile, handleTerms,
  } = RegisterForm(() => registerUserApi(values));

  const eye = <FontAwesomeIcon icon={faEye} />;

  //API Call
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const registerUserApi = async (values) => {
    dispatch(register(values))
      .unwrap()
      .then(() => {
        setTimeout(() => {
          navigate("/");
        }, 1500);
      })
      .catch((err) => {
        console.log(err, "errors");
      });
  };



  return (
    <>
      <div className="vid-container">
        <video muted autoPlay loop className="video">
          <source src="images/Moonleague.webm" type="video/webm" />
        </video>

        <div className="login-form">
          <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4">
            <div className="form-box">
              <Link to="/">
                <div className="logo">
                  <img
                    src="images/logo-white.png"
                    className="img-fluid img-responsive"
                    alt="moon logo"
                  />
                </div>
              </Link>
              <div className="text-center">
                <h3 className="create-account mt-2 mb-0">Create an Account</h3>
                <div className="sign-font">
                  Already have an account?&nbsp;&nbsp;{" "}
                  <Link to="/">Sign in here</Link>
                </div>
              </div>
              <Form className="mt-2" onSubmit={handleSubmit} noValidate>
                <Row>
                  <Col md={12} lg={6}>
                    <Form.Group
                      className="mb-2 mb-sm-3 text-start"
                      controlId="formBasicUserName"
                    >
                      <Form.Label className="formlabel">Username <span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter username"
                        className="form-input"
                        name="username"
                        autoComplete="off"
                        value={values.username || ""}
                        onChange={handleChange}
                      />
                      {errors.username && (
                        <div
                          className="help text-danger text-start mt-1"
                          aria-live="assertive"
                        >
                          {errors.username}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6}>
                    <Form.Group
                      className="mb-2 mb-sm-3 text-start"
                      controlId="formBasicPassword"
                    >
                      <Form.Label className="formlabel">
                        Contact Number
                      </Form.Label>

                      <PhoneInput
                        country={"us"}
                        placeholder="(702) 123-4567"
                        value={values.mobile || ''}
                        onChange={handleMobile}
                        disableCountryCode
                        disableDropdown="true"
                        disableAreaCodes
                        defaultCountry="us"
                        onlyCountries={['us']}
                        inputProps={{
                          name: "mobile",

                          autoFocus: true,
                        }}

                      />
                      {errors.mobile && (
                        <div
                          className="help text-danger text-start mt-1"
                          aria-live="assertive"
                        >
                          {errors.mobile}
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                </Row>
                <Row>
                  <Col md={12} lg={6}>
                    <Form.Group
                      className="mb-2 mb-sm-3 text-start"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="formlabel">
                        Email address <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        className="form-input"
                        autoComplete="email"
                        name="email"
                        value={values.email || ""}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div
                          className="help text-danger text-start mt-1"
                          aria-live="assertive"
                        >
                          {errors.email}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6}>
                    <Form.Group
                      className="mb-2 mb-sm-3 text-start"
                      controlId="formBasicDob"
                    >
                      <Form.Label className="formlabel">
                        Date of Birth <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Enter date of birth"
                        className="form-input"
                        autoComplete="birth_date"
                        name="birth_date"
                        value={values.birth_date || ""}
                        onChange={handleChange}
                      />
                      {errors.birth_date && (
                        <div
                          className="help text-danger text-start mt-1"
                          aria-live="assertive"
                        >
                          {errors.birth_date}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} lg={6}>
                    <Form.Group className="mb-2 mb-sm-3 text-start password-input">
                      <Form.Label className="formlabel">Password <span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type={passwordShown ? "text" : "password"}
                        placeholder="Password"
                        className="form-input"
                        name="password"
                        autoComplete="new-password"
                        value={values.password || ""}
                        onChange={handleChange}
                      />
                      <div
                        className={`font-icons ${faEyeSlash}`}
                        onClick={togglePasswordVisiblity}
                      >
                        <FontAwesomeIcon
                          icon={passwordShown ? faEye : faEyeSlash}
                        />
                      </div>
                      {errors.password && (
                        <div
                          className="help text-danger text-start mt-1"
                          aria-live="assertive"
                        >
                          {errors.password}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6}>
                    <Form.Group className="mb-2 mb-sm-3 text-start password-input">
                      <Form.Label className="formlabel">
                        Confirm Password <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type={cnfpasswordShown ? "text" : "password"}
                        placeholder="Confirm Password"
                        className="form-input"
                        name="cnfpassword"
                        autoComplete="new-password"
                        value={values.cnfpassword || ""}
                        onChange={handleChange}
                      />
                      <div
                        className={`font-icons ${faEyeSlash}`}
                        onClick={toggleCnfPasswordVisiblity}
                      >
                        <FontAwesomeIcon
                          icon={cnfpasswordShown ? faEye : faEyeSlash}
                        />
                      </div>
                      {errors.cnfpassword && (
                        <div
                          className="help text-danger text-start mt-1"
                          aria-live="assertive"
                        >
                          {errors.cnfpassword}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-2 mb-sm-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    name="terms"
                    label="I Agree Terms & Conditions"
                    className="text-start w-100"
                    onChange={handleTerms}


                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-1 mt-sm-3 custom-btn"
                  size="lg"
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Register;
