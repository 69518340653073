import React, { useState, useEffect } from 'react'
import { baseURL } from '../../config';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
const AnotherFollowings = ({ followinglist }) => {
  const [followerResponse, setFollowerResponse] = useState([]);
  useEffect(() => {
    setFollowerResponse(followinglist);
  }, [followinglist])



  const avatarTemplate = (rowData) => {
    return (
      <div className="community-pic me-3 flex-grow-0">
        {rowData.avatar !== "" && rowData.avatar !== null ? <img src={`${baseURL}images/avatar/${rowData.avatar}`} alt="profile image" /> : (<><img src="/icon/default-profile.png" alt="profile image" /></>)}


      </div>
    );
  };
  const nameTemplate = (rowData) => {
    return (
      <div className="user bg-gray align-items-start flex-grow-1" id={rowData.following_user_id}>{rowData.user_name}</div>
    );
  };

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    user_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  });
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <div className="d-flex m-1">

        <span className="p-input-icon-left w-100 px-1">
          <i className="pi pi-search" />
          <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Search by name" className="w-100" />
        </span>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>



      <DataTable
        value={followerResponse}
        emptyMessage="There is no followings data available yet!"
        scrollable
        scrollHeight="470px"
        header={header} filters={filters} onFilter={(e) => setFilters(e.filters)} globalFilterFields={['user_name']}
      >
        <Column
          field="avatar"
          body={avatarTemplate}
          header="Profile" style={{ minWidth: '60px' }} headerStyle={{ width: '60px' }}
        ></Column>
        <Column
          field="user_name"
          body={nameTemplate}
          header="Name"
          filter
          filterField="user_name"
          filterPlaceholder="Search by name"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
        ></Column>
      </DataTable>

    </>
  )
}

export default AnotherFollowings