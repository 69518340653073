import React, { useState, useEffect, Suspense, useImmer } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Button, ButtonGroup } from "react-bootstrap";
import Footer from "../MainLayout/Footer/Footer";
import Header from "../MainLayout/Header/Header";
import Navbar from "../MainLayout/Navbar/Navbar";

import { useDispatch, useSelector } from "react-redux";
import { enterDuelById, getBloodBathCoinsAPI, getCoinsAPI, joinDuelSymbolsList, joinDuelsAPI } from "../../slices/enterJoinSlice";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import JoinDuelsSelectionList from "./JoinDuelsSelectionList";
import { MinuesIcon, SearchIcon, PlusIcon } from "../../SVGIcon";
import ConfirmModel from "./ConfirmModel";
import { all } from "axios";
import CheckLocationModel from "./CheckLocationModel";
import Location from "./Location";
import PaymentConfirm from "./PaymentConfirm";
import LoadingSpinner from "../../Pages/LoadingSpinner";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const JoinDuelsComponent = React.lazy(() => import('./JoinDuelsComponent'))

const JoinDuels = () => {
    const { getStatus, msg, loading, setLoading, getLocation } = Location();
    let isUser = window.localStorage.getItem("userid");
    const { contestId } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    const [select, setSelected] = useState(false);
    const [compCount, setcompCount] = useState(0);

    const [error, seterror] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalGeoShow, setModalGeoShow] = useState(false);
    const { userInfolist } = useSelector((state) => state.userProfile);

    const wallet_Amount = userInfolist?.data?.wallet_balance

    //Get duel by ID

    const dispatch = useDispatch();
    const [duelByID, setDuelByID] = useState([]);
    //  const [getDuel_guid,setDuel_guid] = useState();
    useEffect(() => {
        dispatch(enterDuelById({ duel_id: contestId }))
            .unwrap()
            .then((res) => {
                setDuelByID(res);
                // setDuel_guid(res.duel_guid);
            });
    }, [dispatch]);

    const { duelByIdList, exchangeCoinsList } = useSelector(
        (state) => state.enter_Join
    );
    // console.log(duelByIdList, "duelByIdList");
    // console.log(duelByID, "duelByID");

    let startDate = moment?.utc(duelByID[0]?.duel_start_time_utc)?.toDate();
    let endDate = moment?.utc(duelByID[0]?.duel_end_time_utc)?.toDate();
    startDate = moment(startDate)?.format("ddd M/D/YY h:mm a");
    endDate = moment(endDate)?.format("ddd M/D/YY h:mm a");
    let getDuel_guid = duelByID[0]?.duel_guid;
    let CreatedBy = duelByID[0]?.created_by;
    let DuelEntryType = duelByID[0]?.duel_entry_type;
    let DuelModeType = duelByID[0]?.duel_mode;
    const entry_fee = duelByID[0]?.duel_entry_fee?.replace('$', '').replace(/,/g, '');


    

    // exchangeCoinsList getCoinsAPI
    // console.log(duelByID[0]?.duel_exchange, "exchange IDD");
    let exchangeID1 = duelByID[0]?.duel_exchange;
    //  let exchangeVal = exchangeID1.split('').join('');


    // console.log(exchangeVal, "exchangeVal");

    let exchangeId = {
        exchange_id: exchangeID1,
    };

    const [getEachCoin, setEachCoin] = useState([]);
    // const [items, setItems] = useState(getEachCoin);
    const [filterResult, setFilterResult] = useState([])

    const activeMode = duelByID[0]?.duel_mode;
    useEffect(() => {
        if (activeMode === "Standard" || activeMode === "Standard & Battle") {
            dispatch(getCoinsAPI({ exchangeId }))
                .unwrap()
                .then((res) => {
                    //console.log("get coins", res);
                    let result = res.data
                    //  console.log(result, "result")
                    let filterD = res.data.filter((d) => d.tier !== "D")
                    // console.log(filterD, "filterD")
                    setEachCoin(filterD);
                    setFilterResult(filterD)
                    setActiveTier('All')
                })
                .catch((err) => {
                    console.log(err, "errors");
                });
        }
        else if (activeMode === "Bloodbath" || activeMode === "Bloodbath & Battle") {
            dispatch(getBloodBathCoinsAPI({ exchangeId }))
                .unwrap()
                .then((res) => {
                    let result = res.data
                    let filterD = res.data.filter((d) => d.tier !== "D")
                    setEachCoin(filterD);
                    setFilterResult(filterD)
                    // console.log(filterD,"filterResultkk");
                    setActiveTier('All')
                })
                .catch((err) => {
                    console.log(err, "errors");
                });
        }
    }, [exchangeID1]);

    // console.log(getEachCoin, "getEachCoin");


    const filterItem = (cateItem) => {
        const updatedItem = getEachCoin.filter((curElem) =>
            curElem.tier === cateItem
        )
        setFilterResult(updatedItem);
        setActiveTier(cateItem);
        // console.log(updatedItem, "Updated Item")
    }





    const searchHandler = (event) => {
        // console.log(event.target.value,"eventssssssssss");
        event.preventDefault();
        const inputSearch = event.target.value
        let inputSearchNew = inputSearch.toUpperCase()

        let filterSym = getEachCoin.filter((elem) =>
            elem.base_symbol.toUpperCase().includes(inputSearchNew))
        if (inputSearch !== "") {
            setFilterResult(filterSym);
            setActiveTier('All');
        } else {
            setFilterResult(getEachCoin)
        }
    }

    //Make button Active
    const tierList = [
        {
            id: '1', tierval: 'A'

        },
        {
            id: '2', tierval: 'B'

        },
        {
            id: '3', tierval: 'C'

        },
    ]

    const [activeTier, setActiveTier] = useState();

    const [selectCoins, SetSelectedCoins] = useState([]);
    const [selectCoinsSetA, SetSelectedCoinsSetA] = useState([]);
    const [selectCoinsSetB, SetSelectedCoinsSetB] = useState([]);
    const [selectCoinsSetC, SetSelectedCoinsSetC] = useState([]);
    const [getClicked, setClicked] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [selectCoinCount, SetselectCoinCount] = useState([0]);
    const [totalSelectCoins, SetTotalSelectedCoins] = useState([]);

    const addSelection = (coins, id) => {
        // debugger
        //  console.log("select button on click", coins)
        const findId = getEachCoin.find((c) => c.id === coins);

        ///  console.log("select coin ", findId.tier, selectCoinsA);
        //  console.log(findId.tier, 'findId.tier');
        switch (findId.tier) {
            case "A":
                // console.log("Hello A")
                // console.log(" check number A", selectCoinsA)
                if (selectCoinsSetA.length < 2) {
                    //   console.log(" check number A", selectCoinsA)
                    let curSelectedA = [];
                    curSelectedA = selectCoinsSetA.map((a) => {
                        return a;
                    });
                    curSelectedA.push(findId);
                    SetSelectedCoinsSetA(curSelectedA);
                    // SetSelectedCoins(curSelectedA);

                    //SetSelectedCoins(selectCoinsSetA);


                } 
                else {
                    //alert("Maximum number of selections 2");
                    filterItem(getNextUnfilledTier("A"));
                    //  setActiveTier('B');
                    // setFilterResult(getEachCoin);
                    return false;
                    //toast.error("Maximum number of selections made");
                }

                if(selectCoinsSetA.length === 1) {
                    filterItem(getNextUnfilledTier("A"));
                }
                
                break;
            case "B":
                // console.log("Hello B")
                // console.log(" check number D", selectCoinsD)
                if (selectCoinsSetB.length < 3) {
                    // console.log(" check number B", selectCoinsB)
                    let curSelectedB = [];
                    curSelectedB = selectCoinsSetB.map((s) => {
                        return s;
                    });
                    curSelectedB.push(findId);
                    SetSelectedCoinsSetB(curSelectedB);
                    //  SetSelectedCoins(selectCoinsSetB);
                    // SetSelectedCoins(curSelectedB)
                } else {
                    // alert("Maximum number of selections 3");
                    filterItem(getNextUnfilledTier("B"));
                    return false;
                    //toast.error("Maximum number of selections made");
                }
                if(selectCoinsSetB.length === 2) {
                    filterItem(getNextUnfilledTier("B"));
                }
                break;
            case "C":
                // console.log("Hello C")
                //  console.log(" check number C", selectCoinsD)
                if (selectCoinsSetC.length < 4) {
                    // console.log(" check number B", selectCoinsC)
                    let curSelectedC = [];
                    curSelectedC = selectCoinsSetC.map((s) => {
                        return s;
                    });
                    curSelectedC.push(findId);
                    SetSelectedCoinsSetC(curSelectedC);
                    //SetSelectedCoins(selectCoinsSetC);
                    //SetSelectedCoins(curSelectedC)
                } else {
                    // alert("Maximum number of selections 4");
                    filterItem(getNextUnfilledTier("C"));
                    return false;
                    //toast.error("Maximum number of selections made");
                }
                if(selectCoinsSetC.length === 3) {
                    filterItem(getNextUnfilledTier("C"));
                }
                break;
        }
        // console.log(selectCoinsSetA.length, "selectCoinsSetA")

        let lenthSelectCoin = [...selectCoinsSetA, ...selectCoinsSetB, ...selectCoinsSetC]
        SetselectCoinCount(lenthSelectCoin.length + 1);

        //console.log(lenthSelectCoin,'lenthSelectCoin');

        //     SetSelectedCoins(lenthSelectCoin);
        // let selectedSum = [];
        // SetTotalSelectedCoins(selectedSum);
    };

    // console.log("states", selectCoins, selectCoinsSetA, selectCoinsSetB, selectCoinsSetC);

    const getNextUnfilledTier = (currentTier) => {
        var aAug=0
        var bAug=0
        var cAug=0
        if(currentTier === "A") {
            aAug=1
        }
        if(currentTier === "B") {
            bAug=1
        }
        if(currentTier === "C") {
            cAug=1
        }

        if(selectCoinsSetA.length+aAug < 2) {
            return "A";
        }
        if(selectCoinsSetB.length+bAug < 3) {
            return "B";
        }
        if(selectCoinsSetC.length+cAug < 4) {
            return "C";
        }
        return "C"
    }

    const removeSelection = (id)=>{
        const findId = getEachCoin.find((c) => c.id === id);
        switch (findId.tier) {
            case "A":
                removeSelectionA(id);
                break;
            case "B":
                removeSelectionB(id);
                break;
            case "C":
                removeSelectionC(id);
                break;
            }
    }

    const removeSelectionA = (id) => {
        //   console.log(id,"remove join handle");
        const removeItemA = selectCoinsSetA.filter((coinsId) => {
            //   console.log(coinsId,"remove join handle coinsId");
            return coinsId.id !== id;
        });
        SetSelectedCoins(removeItemA);
        SetSelectedCoinsSetA(removeItemA)

    }
    const removeSelectionB = (id) => {
        console.log(id);
        const removeItemB = selectCoinsSetB.filter((coinsId) => {
            return coinsId.id !== id;
        });
        SetSelectedCoins(removeItemB);
        SetSelectedCoinsSetB(removeItemB)

    }
    const removeSelectionC = (id) => {
        const removeItemC = selectCoinsSetC.filter((coinsId) => {
            return coinsId.id !== id;
        });
        SetSelectedCoins(removeItemC);
        SetSelectedCoinsSetC(removeItemC)
        // let lenthSelectCoin = [...selectCoinsSetC]
        // SetselectCoinCount(lenthSelectCoin.length - 1)

    }
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        let removeSelectCoin = [...selectCoinsSetA, ...selectCoinsSetB, ...selectCoinsSetC]
        // console.log("hello",removeSelectCoin)
        SetselectCoinCount(removeSelectCoin.length)


    }, [])

    // console.log(selectCoinCount,'selectCoinCount');
    useEffect(() => {
        //selectCoins
        if (selectCoins === 9) {
            // if(selectCoinCount === 9) { 
            // toast.error(selectCoinCount);
            setDisabled(false);
        }
        else {
            setDisabled(true);
            // console.log(selectCoinCount);
        }
    }, [selectCoins])

    useEffect(() => {
        let removeSelectCoin = [...selectCoinsSetA, ...selectCoinsSetB, ...selectCoinsSetC];
        SetSelectedCoins(removeSelectCoin);

        // let joinSymbolsList = [];

        //console.log(selectCoins,'selectCoins');

    }, [selectCoinsSetA, selectCoinsSetB, selectCoinsSetC])


    // end selection made




    //Check values
    let languages = duelByID[0]?.duel_exchange;
    let iteratorObject = languages?.values();
    // console.log(languages,'languages');
    // console.log(iteratorObject,'iteratorObject');

    const [currentExchange, setcurrentExchange] = useState([]);
    // looping through iterator
    useEffect(() => {
        if (languages) {
            for (let value of iteratorObject) {
                setcurrentExchange(value);
                //    console.log(value,'each value ');
            }

        }
    }, [languages])

    // console.log(currentExchange,'currentExchange');
    let currentExchange1 = languages;
  

    //Join Duels API List Selected Coins Filter
    const navigate = useNavigate();

    //*************** */ without x-point function
    const handleContest = async (e) => {
        e.preventDefault();
        setIsLoading(true); 
            JoinDuel();
         
    }

    //*************** */ with x-point function
    // const handleContest = async (e) => {
    //   e.preventDefault();
    //     setIsLoading(true);
    //     await getLocation();
    //     if (getStatus === "ALLOWED") {
    //         setIsLoading(false);
    //         setModalGeoShow(false);
    //         JoinDuel();
    //     }
    //     else {
    //         setIsLoading(false);
    //         setModalGeoShow(true);
    //     }
    // }



    async function JoinDuel() {
        //  return false;
        let data = [];
        data.push(selectCoins);

        let joinDuelData = {
            user_id: isUser,
            created_by: CreatedBy,
            duel_entry_type: DuelEntryType,
            duel_id: contestId,
            duel_guid: getDuel_guid,
            duel_mode: DuelModeType,
            data: [
                ...selectCoins.map((item) => {
                    return {
                        exchange_id: item.exchange_id,
                        base_symbol: item.base_symbol,
                        tier: item.tier,
                        symbol: item.symbol
                    }
                })
            ]

        };




        //call joinDuelsAPI
        await dispatch(joinDuelsAPI({ joinDuelData }))
            .unwrap()
            .then((res) => {
                let result = res.message;
                setIsLoading(false);
                seterror(result);
                toast.success(result);


                setTimeout(() => {
                    navigate("/dashboard");
                }, 1000)



                // setDuelByID(res);

                // setDuel_guid(res.duel_guid);
            }).catch((err) => {
                setIsLoading(false);
                seterror(err?.message)
                setModalShow(true);
            });

    }

  
    const renderArray = (array) => {
        const letters = { 1: "NYSE", 2: "NASDAQ", 3: "CRYPTO" };
        const renderedArray = [];
        for (const number of Object.values(array || {})) {
            // console.log(number,"number");
            renderedArray.push(letters[number]);
        }

        return renderedArray.join("-");
    };
    const array = duelByID[0]?.duel_exchange;
    const renderedArray = renderArray(array);


    return (
        <>
            <Navbar />
            <div className="main-layout">
                <Header />
                {/* <div className="container-fluid px-md-4"> */}

                <div className="join-duels-container card my-3">
                    {isLoading ? <LoadingSpinner /> : ""}

                    <div className="card card-custom gutter-b">
                        <div className="custom-card-header ">
                            <div className="d-flex justify-content-end card-title">
                                <div className="mx-auto ">
                                    <h4 className="text-center text-uppercase custom-heading">
                                        Join Duels
                                    </h4>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between flex-nowrap w-100 p-3 align-items-center">
                                <div className="left-container text-start w-75">
                                    <h4 className="pink-text text-uppercase">
                                        {duelByID[0]?.duel_name} - {duelByID[0]?.duel_type} {""}
                                        {/* {duelByID[0]?.duel_name}  */}
                                    </h4>

                                    <p className='text-dark fw-500 w-100'><strong>Description:</strong> {duelByID[0]?.duel_description}</p>

                                    <div className="d-flex justify-content-between flex-wrap w-100">
                                        <p className='text-dark fw-500'><strong>Entry Fee:</strong>{" "}{duelByID[0]?.duel_entry_fee === "" ? "$0.00" : (` ${duelByID[0]?.duel_entry_fee}`)}</p>
                                        <p className="mb-1 text-dark  fw-500">
                                            <strong>Duration:</strong>
                                            {" "}
                                            {startDate} - {endDate}
                                            {/* {duelByID[0]?.duel_start_time_utc} -  {duelByID[0]?.duel_end_time_utc} */}
                                            {/* Wed, 03/01/2023 12:00 AM */}
                                        </p>

                                    </div>


                                </div>


                                <div className="right-container">

                                    {/* <button type="button" className={`btn  ${disabled ? "btn duel-btn btn-purple" : "activebtn btn-dark duel-btn"}`} onClick={handleContest}
                                  disabled ={disabled}
                                  >
                                        Join Duel
                                    </button>   */}


                                    {selectCoins.length === 9 ? (<>
                                        <button type="button" className="activebtn btn-dark duel-btn" onClick={handleContest}>
                                            Join Duel
                                        </button>

                                    </>) : (<>
                                        <button type="button" className="btn duel-btn btn-purple" disabled={disabled}>
                                            Join Duel
                                        </button>

                                    </>)}

                                </div>

                            </div>



                        </div>
                        <div className="p-3">
                            <Row className="gy-3">
                                <Col md={12} lg={6} sm={12}>
                                    <div className="card-custom p-3" style={{ height: "100%" }}>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
                                            <div className="heading-text pink-text fw-bold">
                                                {renderedArray}
                                            </div>
                                            <div className="serach-exchange">
                                                <input type="search" placeholder="Search...." onChange={searchHandler} />
                                                <span className="search-icon">
                                                    <SearchIcon />
                                                </span>
                                            </div>
                                            <ButtonGroup aria-label="First group">
                                                <Button
                                                    className={`btn btn-group active1 ${activeTier === 'All' ? "activeBtn" : ""}`}
                                                    onClick={() => { setFilterResult(getEachCoin); setActiveTier('All'); }}
                                                >
                                                    All
                                                </Button>

                                                {tierList.map((c, i) => {
                                                    return (
                                                        <>
                                                            <Button key={i} id={c.tierval}
                                                                className={`btn btn-group ${activeTier === c.tierval ? "activeBtn" : ""}`}
                                                                onClick={(e) => {
                                                                    filterItem(c.tierval);
                                                                    //  setActiveTier(e.target.id); 
                                                                }}
                                                            >
                                                                {c.tierval}
                                                            </Button>
                                                        </>
                                                    )
                                                })}
                                            </ButtonGroup>
                                        </div>

                                        {/* <div className='auto-scroll'>
                                     {
                                        getEachCoin.map((elem, index) => {
                                            return (
                                                <>
                                                    <JoinDuelsComponent
                                                       logo={elem.logo}
                                                       baseSymbol={elem.base_symbol}
                                                       currentPrice={elem.current_price}
                                                       nameShort={elem.name_short}
                                                        id={index}
                                                        key={index}
                                                        // name={elem.name}
                                                        // category={elem.category}
                                                       // onSelect={addListBtn}
                                                    />

                                                </>
                                            )
                                        }
                                        )
                                    } 
                                     </div> */}

                                        <div className="auto-scroll">
                                            <DataTable value={filterResult} rows={30}
                                                scrollable
                                                scrollHeight="1100px"
                                                virtualScrollerOptions={{
                                                  itemSize: 50,
                                                  delay: 10,
                                                }}
                                    
                                                emptyMessage="There is no active exchange data available yet!"
                                            >
                                            
                                                <Column
                                                    field="logo"
                                                    header="Logo"
                                                    body={(rowData) => (
                                                    <img
                                                        src={rowData.logo}
                                                        alt={rowData.name_short}
                                                        className="img-fluid img-coins"
                                                        style={{ visibility: rowData.logo ? 'visible' : 'hidden' }}
                                                    />
                                                    )}
                                                />
                                                <Column field="base_symbol" header="Symbol" />
                                                <Column field="current_price" header="Price" />
                                                <Column
                                                    field="pct_change_factor"
                                                    header="% +-"
                                                    body={(rowData) => <span>{rowData.pct_change_factor}%</span>}
                                                />
                                                <Column field="tier" header="Tier" />

                                                <Column
                                                    body={(rowData) => (
                                                    <button
                                                        className="btn btn-primary duel-btn p-2"
                                                        onClick={() => {
                                                            const isSelected = selectCoins.some((s) => s.id === rowData.id);
                                                            if (isSelected) {
                                                              removeSelection(rowData.id);
                                                            } else {
                                                              addSelection(rowData.id);
                                                            }
                                                          }}
                                                        
                                                    >
                                                        {selectCoins.some((s) => s.id === rowData.id) ? (
                                                        <MinuesIcon />
                                                        ) : (
                                                        <PlusIcon />
                                                        )}
                                                    </button>
                                                    )}
                                                />
                                                </DataTable>
                                            
                                             {/* <Suspense fallback={<div className="pink-text fw-bold">Wait...</div>}>
                                                <div className="d-flex justify-content-between align-items-center row-bg mt-3 mb-3 join-duel-component" >
                                                    <div className="d-flex align-items-center join-duel-component-symbol-img-container">
                                                        <img src='' className="img-fluid img-coins" style={{ visibility: "hidden" }} />
                                                    </div>
                                                    <div className="d-flex align-items-start flex-column fix-width text-start join-duel-component-symbol">
                                                        <p className="mb-0 join-heading ms-0 ms-sm-4">Symbol</p>
                                                    </div>
                                                    <p className="mb-0 join-heading text-start">Price</p>
                                                    <p className="mb-0 join-heading text-start">% +- </p>
                                                    <p className="mb-0 join-heading">Tier</p>
                                                    <button className="btn btn-primary duel-btn p-2" disabled style={{ visibility: "hidden" }}>
                                                    <MinuesIcon /></button>

                                                </div>
                                                {filterResult.map((elem, index) => {
                                                    // console.log(elem,"elemelem");
                                                    if (selectCoins.some(s => s.id === elem.id)) {
                                                        return (
                                                            <>

                                                                <JoinDuelsComponent
                                                                    logo={elem.logo}
                                                                    baseSymbol={elem.base_symbol}
                                                                    currentPrice={elem.current_price}
                                                                    nameShort={elem.name_short}
                                                                    id={elem.id}
                                                                    key={index}
                                                                    tier={elem.tier}
                                                                    pct_change_factor={elem.pct_change_factor}
                                                                    is_active={elem.is_active}
                                                                    getClicked={getClicked}
                                                                    setClicked={setClicked}
                                                                    selectCoins={selectCoins}
                                                                    buttonProps="Minus"
                                                                    onSelect={addSelection}
                                                                />

                                                            </>

                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <JoinDuelsComponent
                                                                logo={elem.logo}
                                                                baseSymbol={elem.base_symbol}
                                                                currentPrice={elem.current_price}
                                                                nameShort={elem.name_short}
                                                                id={elem.id}
                                                                key={index}
                                                                pct_change_factor={elem.pct_change_factor}
                                                                tier={elem.tier}
                                                                is_active={elem.is_active}
                                                                getClicked={getClicked}
                                                                setClicked={setClicked}
                                                                selectCoins={selectCoins}
                                                                onSelect={addSelection}
                                                            />
                                                        )
                                                    }
                                                })}
                                            </Suspense>  */}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} lg={6} sm={12}>
                                    <div className="card-custom p-3" style={{ height: "100%" }}>
                                        <div className="d-flex justify-content-between mt-3">
                                            <h6 className="pink-text">SELECTIONS</h6>
                                            <p className="pink-text fw-semibold">
                                                {selectCoins.length}/9
                                                {/* {selectCoinCount}/9{" "} */}
                                            </p>
                                        </div>
                                        <div className="container-tierA mb-4">
                                            <div className="d-flex justify-content-between align-items-center row-bg mt-3 mb-3 join-duel-component" >
                                                    <div className="d-flex align-items-center join-duel-component-symbol-img-container">
                                                        <img src='' className="img-fluid img-coins" style={{ visibility: "hidden" }} />
                                                    </div>
                                                    <div className="d-flex align-items-start flex-column fix-width text-start join-duel-component-symbol">
                                                        <p className="mb-0 join-heading ms-0 ms-sm-4">Symbol</p>
                                                    </div>
                                                    <p className="mb-0 join-heading text-start">Price</p>
                                                    <p className="mb-0 join-heading text-start">% +- </p>
                                                    <p className="mb-0 join-heading">Tier</p>
                                                    <button className="btn btn-primary duel-btn p-2" disabled style={{ visibility: "hidden" }}
                                                    ><MinuesIcon /></button>

                                                </div>
                                            <h6 className="bg-gray">TIER A</h6>

                                            {selectCoinsSetA.map((elem, i) => {

                                                return (
                                                    <>
                                                        <div key={i}>
                                                            <JoinDuelsSelectionList
                                                                logo={elem.logo}
                                                                baseSymbol={elem.base_symbol}
                                                                currentPrice={elem.current_price}
                                                                nameShort={elem.name_short}
                                                                tier={elem.tier}
                                                                pct_change_factor={elem.pct_change_factor}
                                                                id={elem.id}
                                                                handleRemove={removeSelectionA}
                                                                key={i}
                                                            />
                                                        </div>
                                                    </>
                                                );
                                            })
                                            }

                                        </div>
                                        <div className="container-tierB mb-4">
                                            <h6 className="bg-gray">TIER B</h6>
                                            {selectCoinsSetB.map((elem, i) => {
                                                return (
                                                    <>
                                                        <div key={i}>
                                                            <JoinDuelsSelectionList
                                                                logo={elem.logo}
                                                                baseSymbol={elem.base_symbol}
                                                                currentPrice={elem.current_price}
                                                                nameShort={elem.name_short}
                                                                pct_change_factor={elem.pct_change_factor}
                                                                tier={elem.tier}
                                                                id={elem.id}
                                                                handleRemove={removeSelectionB}
                                                                key={i}
                                                            />
                                                        </div>
                                                    </>
                                                );
                                            })
                                            }
                                        </div>
                                        <div className="container-tierC">
                                            <h6 className="bg-gray">TIER C</h6>
                                            {selectCoinsSetC.map((elem, i) => {
                                                return (
                                                    <>
                                                        <div key={i}>
                                                            <JoinDuelsSelectionList
                                                                logo={elem.logo}
                                                                baseSymbol={elem.base_symbol}
                                                                currentPrice={elem.current_price}
                                                                nameShort={elem.name_short}
                                                                tier={elem.tier}
                                                                pct_change_factor={elem.pct_change_factor}
                                                                id={elem.id}
                                                                handleRemove={removeSelectionC}
                                                                key={i}
                                                            />
                                                        </div>
                                                    </>
                                                );
                                            })
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>

            <CheckLocationModel show={modalGeoShow} onHide={() => setModalGeoShow(false)} getstatus={getStatus} msg={msg} loading={loading} setloading={setLoading} />
            <PaymentConfirm show={modalShow} onHide={() => setModalShow(false)} error={error} />
            <ToastContainer />
            <Footer />
        </>
    );
};

export default JoinDuels;
