import React from "react";
import Navbar from "../components/MainLayout/Navbar/Navbar";
import Header from "../components/MainLayout/Header/Header";
import Footer from "../components/MainLayout/Footer/Footer";
import { Row, Col, Button } from "react-bootstrap";

const About = () => {
  return (
    <>
      <div className="main-layout">
        <div className="py-3 text-start">
          <div className="card-custom">
            <div className="card-title text-center text-lg-start">
              <h4 className="dark-blue mb-0">About Us</h4>
            </div>
            <div className="container-fluid px-md-4 py-3 main-contact-container text-start">
            
              <Row>
                <Col md={12} lg={6} sm={12}>
                  <div className="text-lg-start text-sm-start  text-center">
                    {/* <p className="pink-text">Know about us</p> */}
                    <h2 className="text font-size-bold about-title pink-text">
                    Know about us:</h2>
                    {/* <p className="lh-lg">
                      Reprehenderit quibusdam curabitur. Mollis? Sollicitudin!
                      Pharetra accumsan fugit. Ducimus animi aliqua
                    </p> */}
                    <p className="lh-lg">
                      Hello, and welcome to MoonLeague! We are a fantasy gaming
                      startup that offers a unique twist on traditional daily
                      fantasy games. Instead of using professional athletes, our
                      platform uses companies from the NYSE & Nasdaq, crypto
                      currencies from Binance exchange, commodities & currencies
                      that are all tracked in real time off of US Exchanges &
                      Markets. Our proprietary algorithm breaks all picks into 3
                      separate tiers, and users select 9 picks from those
                      Exchanges & Markets. Then we track those percentage gains
                      or losses for the users placements in the contests. Our
                      games offer a chance for players to learn and understand
                      financial markets in a fun and easy environment while also
                      being able to interact with all the other people on the
                      platform while also being able compete for cash prizes and
                      titles of the Top MoonLeaguer’s on the platform.
                    </p>

                    <h2 className="text font-size-bold about-title pink-text">
                    MoonLeague’s Goal:</h2>
                    </div>
                </Col>
                <Col>
                  <div className="d-flex justify-content-end">
                    <img
                      src="./images/aboutus.jpg"
                      className="img-fluid img-about-us"
                    />
                  </div>
                </Col>
                <p className="text-lg-start text-sm-start  text-center lh-lg">
                Our goal is to create a unique daily fantasy gaming experience that combines financial markets with fantasy gaming, making it easy and fun for users to learn about and explore financial markets. We aim to provide an entertaining and engaging platform that allows users to compete for cash prizes and interact with other users, while also learning about the financial markets and developing valuable investment skills. 
                      Our goal is to democratize access to financial markets and to provide a safe and legal platform for our users to enjoy.
                </p>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;