import React, { useState, useEffect, Suspense, useImmer } from 'react'
import { Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import { MinuesIcon, PlusIcon, SearchIcon } from "../../SVGIcon";
import LoadingSpinner from "../../Pages/LoadingSpinner";
import EditDuelsComponent from '../EditDuels/EditDuelsComponent';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { editDuelById, editDuelSelector, getEditCoinsAPI } from '../../slices/editJoinSlice';
import { joinDuelsAPI } from '../../slices/enterJoinSlice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import TradeAddList from './TradeAddList';
import { Skeleton } from 'primereact/skeleton';

const MainTraderEdit = ({ duelByID, getEachCoin, filterResult, setFilterResult, selectCoins, activeTier, setActiveTier, isEditing, handleSwitch, setreSet,
  handleEdit, selected, loading }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isFetching } = useSelector(editDuelSelector);

  let startDate = moment.utc(duelByID?.duel_start_time_utc).toDate();
  let endDate = moment.utc(duelByID?.duel_end_time_utc).toDate();
  startDate = moment(startDate).format("ddd M/D/YY h:mm a");
  endDate = moment(endDate).format("ddd M/D/YY h:mm a");


  const filterItem = (cateItem) => {
    const updatedItem = getEachCoin.filter((curElem) =>
      curElem.tier === cateItem
    )
    setFilterResult(updatedItem);
    setActiveTier(cateItem);

  }


  const searchHandler = (event) => {
    event.preventDefault();
    const inputSearch = event.target.value
    let inputSearchNew = inputSearch.toUpperCase()

    let filterSym = getEachCoin.filter((elem) =>
      elem.base_symbol.toUpperCase().includes(inputSearchNew))
    if (inputSearch !== " ") {
      setFilterResult(filterSym);
      setActiveTier('All');
    } else {
      setFilterResult(getEachCoin)
    }
  }

  //Make button Active
  const tierList = [
    {
      id: '1', tierval: 'A'

    },
    {
      id: '2', tierval: 'B'

    },
    {
      id: '3', tierval: 'C'

    },
  ]

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {

    if (selectCoins === 9) {

      setDisabled(false);
    }
    else {
      setDisabled(true);
      //  console.log(selectCoinCount);
    }
  }, [selectCoins])

  // end selection made

  let languages = (duelByID || [])[0]?.duel_exchange;
  let iteratorObject = languages?.values();

  const [currentExchange, setcurrentExchange] = useState([]);
  // looping through iterator
  useEffect(() => {
    if (languages) {
      for (let value of iteratorObject) {
        setcurrentExchange(value);
        // console.log(value,'each value ');
      }

    }
  }, [languages])


  //Join Duels API List Selected Coins Filter

  const logoBodyTemplate = (rowData) => {
    // console.log(rowData.logo,"logo");
    return (
      <>{rowData.logo ? (<img src={rowData.logo} className="img-fluid img-coins" />) : (<img src='/images/bitcoin-icon.png' />)}</>
    )
  }
  const SymbolBodyTemplate = (rowData) => {
    return (
      <>
        <p className="mb-0 duel-heading cfs-6">{rowData.base_symbol}</p>
        <p className="mb-0 duel-heading cfs-6">{rowData.name_short}</p>
      </>
    )
  }

  const priceBodyTemplate = (rowData) => {
    // console.log(rowData.id,"pricee");
    return (
      <>

        <p className="mb-0  dark-blue">${rowData.current_price}</p>
      </>
    )
  }
  // const data = selectCoins?.some(s => s?.id === elem?.symbol_id);
  const nameBodyTemplate = (rowData) => {
    return (

      <>
        {selected?.base_symbol_id === rowData?.id ? <button className="btn  duel-btn p-2" onClick={() => { handleSwitch(rowData); handleEdit(rowData) }}><PlusIcon /></button>
          : <>{rowData?.is_already_selected === 1
            ? <button className="btn btn-primary duel-btn p-2" disabled><MinuesIcon /></button>
            : <button className="btn  duel-btn p-2" onClick={() => { handleSwitch(rowData); handleEdit(rowData) }}><PlusIcon /></button>}  </>
        }

      </>

    )
  }


  const renderArray = (array) => {
    const letters = { 1: "NYSE", 2: "NASDAQ", 3: "CRYPTO" };
    const renderedArray = [];
    for (const number of Object.values(array || {})) {
      // console.log(number,"number");
      renderedArray.push(letters[number]);
    }

    return renderedArray.join("-");
  };
  const array = duelByID?.exchange_id;
  const renderedArray = renderArray(array);

  const renderHeader = () => {
    return (
      <div className="row gy-3 active-exchange trade-search-tab">
        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-5 justify-content-between d-flex align-items-center">
          <div className="heading-text pink-text fw-bold">
            {renderedArray}
          </div>
          <div className="serach-exchange">
            <input type="search" placeholder="Search...." onChange={searchHandler} />
            <span className="search-icon">
              <SearchIcon />
            </span>
          </div>
        </div>

        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-4 justify-content-end d-flex">

          <ButtonGroup aria-label="First group">
            <Button
              className={`btn btn-group active ${activeTier === 'All' ? "activeBtn" : ""}`}
              onClick={() => { setFilterResult(getEachCoin); setActiveTier('All'); }}
            >
              All
            </Button>

            {tierList.map((c, i) => {
              return (
                <>
                  <Button key={i} id={c.id}
                    className={`btn btn-group ${activeTier === c.id ? "activeBtn" : ""}`}
                    onClick={(e) => { filterItem(c.tierval); setActiveTier(e.target.id); }}
                  >
                    {c.tierval}
                  </Button>
                </>
              )
            })}


          </ButtonGroup>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  //Lazy Loading
  const cars = Array.from({ length: 100000 }).map((_, i) => getEachCoin);

  const [virtualCars, setVirtualCars] = useState(Array.from({ length: 5 }));   //100000
  const [lazyLoading, setLazyLoading] = useState(false);


  let loadLazyTimeout = null;


  const loadCarsLazy = (event) => {
    !lazyLoading && setLazyLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //simulate remote connection with a timeout
    loadLazyTimeout = setTimeout(() => {
      let _virtualCars = [...virtualCars];
      let { first, last } = event;

      //load data of required page
      const loadedCars = cars.slice(first, last);

      //populate page of virtual cars
      Array.prototype.splice.apply(_virtualCars, [...[first, last - first], ...loadedCars]);

      setVirtualCars(_virtualCars);
      setLazyLoading(false);
    }, Math.random() * 1000 + 250);
  };

  const loadingTemplate = (options) => {
    return (
      <div className="flex align-items-center" style={{ height: '17px', flexGrow: '1', overflow: 'hidden' }}>
        <Skeleton width={options.cellEven ? (options.field === 'year' ? '30%' : '40%') : '60%'} height="1rem" />
      </div>
    );
  };
  // console.log(isFetching,"isFetching");
  return (
    <>
      <div className="card card-custom gutter-b tab-content">
        <div className="custom-card-header">
          <div className="card-title text-center mb-0">
            <h4 className="card-label align-items-center custom-heading d-inline">TRADE</h4>

          </div>

        </div>
        <div className="custom-active">
          {isFetching === true ? (<><p className="pink-text fw-bolder">Please wait</p></>)
            : <DataTable
              value={filterResult} rows={10}
              header={header}
              scrollable
              scrollHeight="706px"
              loading={loading}
              emptyMessage="No symbols found."
            >

              <Column field="logo" body={logoBodyTemplate} style={{ width: "12%" }}></Column>
              <Column field="base_symbol"
                header="Symbol"
                body={SymbolBodyTemplate}></Column>
              <Column field="tier" header="Tier"></Column>
              <Column
                body={priceBodyTemplate} header="Price"></Column>

              <Column
                body={nameBodyTemplate}></Column>
            </DataTable>
          }
        </div>

      </div>

    </>
  )
}

export default MainTraderEdit