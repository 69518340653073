import ActiveExchange from "../ActiveExchange/ActiveExchange";
import Community from "../Community/Community";
import FeaturedAds from "../FeaturedAds/FeaturedAds";
import Games from "../Games/GamesDuel";
import LatestPost from "../LatestPost/LatestPost";
import Footer from "../MainLayout/Footer/Footer";
import Header from "../MainLayout/Header/Header";
import Navbar from "../MainLayout/Navbar/Navbar";
import ProfileDashboard from "../Profile/ProfileDashboard";
import UploadVideos from "../UploadVideos/UploadVideos";
import UserChat from "../UserChat/UserChat";
import GameLevel from "./GameLevel";

const User = () => {
  return (
    <>
      <Navbar />
      <div className="main-layout">
      <div className='container-fluid'>
        <div className="row ">
          <div className="mb-3">
            <Header />
          </div>
          <div className="mb-3">
            <ProfileDashboard />
          </div>
          <div className="mb-3">
            <FeaturedAds />
          </div>

          <div className="col-xl-8 col-lg-12 col-sm-12 col-12">
            <div className="mb-3">
              <GameLevel />
            </div>
            <div className="mb-3">
              <LatestPost />
            </div>
          </div>
          <div className="col-xl-4 col-lg-12 col-sm-12 col-12">
            <div className="mb-3">
              <UploadVideos />
            </div>
            <div className="mb-3">
              <Community />
            </div>
            <div className="mb-3">
              <Games />
            </div>
          </div>

          <div className="mb-3">
            {" "}
           
              <UserChat />
           
          </div>
        </div>
      </div>

      </div>
      <Footer />
    </>
  );
};

export default User;
