import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { checkKYCStatus, saveKYCStatus, verifyKyc } from '../../slices/kycSlice';
import { getUserDetails } from '../../slices/profileSlice';
import { ToastContainer, toast } from "react-toastify";

const VerificationComponent = () => {
  const [kycResponse, setKycResponse] = useState({});
  const [kycStatus, setKycStatus] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [disableKYC, setDisableKYC] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [reasonFailure, setReasonFailure] = useState(false);
  const [pass, setPass] = useState({});
  const dispatch = useDispatch();
  const iframe = useRef();

  let locacluserid = window.localStorage.getItem("userid");
  useEffect(() => {
    const getUserAPI = async () => {
      await dispatch(getUserDetails({ userId: locacluserid }))
        .unwrap()
        .then((res) => {
          setUserDetails(res?.data);
        });
    };

    getUserAPI();
  }, [dispatch]);

  const handleVerification = () => {
    const { first_name, last_name, address_1, zip } = userDetails;
    setClicked(true);
    setIsLoading(true);
    setReasonFailure(false);
    setKycStatus(null);
    dispatch(verifyKyc({ firstName: first_name, lastName: last_name, address: address_1, zip: zip }))
      .unwrap()
      .then((res) => {
        const verify = res.response;
        const idScanUrl = "id-scan-url";
        const idScan = "id-scan";
        const idNumber = "id-number";
        const summaryResult = "summary-result";
        setPass({ idScan: verify[idScan], summaryResult: verify[summaryResult]?.message })
        setKycResponse({ idScanUrl: verify[idScanUrl], idScan: verify[idScan], idNumber: verify[idNumber] });
        setTimeout(() => {
          setDisabled(true);
          setIsLoading(false);
        }, 11000);
      })
      .catch((error) => console.log(error))
  };

  const checkVerificationStatus = () => {
    dispatch(checkKYCStatus(kycResponse?.idNumber))
      .unwrap()
      .then((res) => {
        console.log(res);
        const verify = res.response;
        const idScanResult = "id-scan-result";
        setKycStatus({ message: verify[idScanResult].message });
        if (verify[idScanResult].message === "Not readable") {
          setDisabled(false);
          setReasonFailure(true);
          saveVerificationStatus("Not readable")
        } else if (pass?.summaryResult === "PASS") {
          saveVerificationStatus("Approved")
        } else if (pass?.summaryResult === "Fail") {
          saveVerificationStatus("Not Approved")
        }
      }).catch((error) => console.log(error))
  }

  const saveVerificationStatus = (status) => {
    dispatch(saveKYCStatus({ user_id: Number(locacluserid), kyc_verify_status: status }))
      .unwrap()
      .then((res) => {
        console.log(res);
        toast.success(res.message);

      }).catch((error) => { toast.error(error) })
  }


  return (
    <div style={{ minHeight: "463px" }}>
      {/* Your UI elements and user interactions */}
      <p className='pink-text fw-bolder kyc-text-phone-size'><span>Please fill up your edit profile details first before KYC</span> <span className='mb-5' style={{ color: "red" }}>*</span></p>
      {disabled ? <Button className='kyc-btn-phone-size' disabled onClick={handleVerification}>Start Verification</Button> : <Button className='kyc-btn-phone-size' style={clicked ? { backgroundColor: "#291674" } : {}} onClick={handleVerification}>Start Verification</Button>}
      {kycResponse?.idScanUrl && disabled ? <Button onClick={checkVerificationStatus} className="ms-3 kyc-btn-phone-size">Check Status of Verification</Button> : null}
      {kycResponse?.idScanUrl && kycStatus && <div className='mt-4 text-black fw-bolder kyc-text-phone-size'><span>Your KYC Status: </span> <span className='pink-text fw-bolder kyc-text-phone-size'>{kycStatus?.message}</span></div>}
      {reasonFailure ? <p className='fw-bolder'><span className='text-black fw-normal kyc-text-phone-size'>Reason for Failure:</span><span className='text-pink fw-bolder kyc-text-phone-size'>{` ID ${kycStatus?.message}`}</span></p> : ""}
      {isLoading ? (<><p className="pink-text fw-bolder text-center kyc-text-phone-size" style={{ marginTop: "10%", fontSize: "1.8rem", marginBottom: "24%" }}>Please wait</p></>) : <>{kycResponse?.idScanUrl ? <iframe ref={iframe} width="100%" className='iframe-tab mt-4' src={kycResponse?.idScanUrl}></iframe> : null} </>}
      {pass?.idScan === "no" && pass?.summaryResult === "PASS" && isLoading === false && <div className='mt-4 text-black fw-bolder kyc-text-phone-size'><span>Your KYC Status: </span> <span className='pink-text fw-bolder kyc-text-phone-size'>Approved</span></div>}
      <ToastContainer />
    </div>
  );
};

export default VerificationComponent;