import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "../config";
import authHeader from "../util/Authheader";
import axios from "axios";
import { toast } from "react-toastify";


//Get FreeGames
export const enterDuelById = createAsyncThunk( "api/duel/get-duel-byid/", async (args, thunkAPI) => {
    
    const url = `${baseURL}api/duel/get-duel-byid/${args.duel_id}`;
    return await axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        let data = response.data?.data;
    
        return data;
      })
      .catch((err) => {
        console.log(err, "enter duel by id");
      });
})


//Enter duel with exchange ID:
export const getCoinsAPI = createAsyncThunk(
  "api/duel/enter-duel",
  async (args, thunkAPI) => {   
    const urlname = `${baseURL}api/duel/enter-duel`;
   try {
      const response = await axios({ 
        method: "post",
        url: urlname,
        data: args.exchangeId,
         headers: {
        
          ...authHeader(),
            
         },
      });
  
      let data = response.data;
    
      if (data.status_code === 400) {
      
        return Promise.reject(data);
      } else {
      //  toast.success(data.message);
      //  console.log(data,'success data');
        console.log(typeof window !== 'undefined');
      
        return data;
      }
  
   
    } catch (error) {
      console.log(error)
   
        if (error.response) {
          const message = error.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    
    }
  }
  );

//Enter BloodBath Duels
//api/duel/bloodbath-enter-duel
export const getBloodBathCoinsAPI = createAsyncThunk(
  "api/duel/bloodbath-enter-duel",
  async (args, thunkAPI) => {   
    const urlname = `${baseURL}api/duel/bloodbath-enter-duel`;
   try {
      const response = await axios({ 
        method: "post",
        url: urlname,
        data: args.exchangeId,
         headers: {
        
          ...authHeader(),
            
         },
      });
  
      let data = response.data;
    
      if (data.status_code === 400) {
      
        return Promise.reject(data);
      } else {
      //  toast.success(data.message);
      //  console.log(data,'success data');
        return data;
      }
  
   
    } catch (error) {
      console.log(error)
   
        if (error.response) {
          const message = error.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    
    }
  }
  );


  //Join Duels API
//  api/duel/join-duel
export const joinDuelsAPI = createAsyncThunk(
  "api/duel/join-duel",
  async (args, thunkAPI) => {   
    const urlname = `${baseURL}api/duel/join-duel`;
   try {
      const response = await axios({ 
        method: "post",
        url: urlname,
        data: args.joinDuelData,
         headers: {
        
          ...authHeader(),
            
         },
      });
  
      let data = response.data;
    
      if (data.status_code === 400) {
        // toast.error(data.message);
        return Promise.reject(data);
      } else {
      //  toast.success(data.message);
      //  console.log(data,'success data');
        return data;
      }
  
   
    } catch (error) {
      console.log(error)
   
        if (error.response) {
          const message = error.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    
    }
  }
  );

//Edit Duels List 
//api/duel/get-edit-symbol-list/61/4558/2023070717280433588564a84b245201c



const enterJoinDuelSlice = createSlice({
    name: "enter_Join",
    initialState: {
      duelByIdList: [],
      exchangeCoinsList:[],
      joinDuelSymbolsList:[],
      bloodBathExchangeCoinsList:[],
      
      status: null,
    },
    extraReducers: (builder) => {
      builder
      //Get FreeGames List
        .addCase(enterDuelById.pending, (state, action) => {
          state.status = "loading";
        })
        .addCase(enterDuelById.fulfilled, (state, action) => {
          state.duelByIdList = action.payload;
          state.status = "success";
        })
        .addCase(enterDuelById.rejected, (state, action) => {
          state.status = "failed";
        })

        .addCase(getCoinsAPI.pending, (state,action) => {
          state.status = "loading";
        })
        .addCase(getCoinsAPI.fulfilled, (state, action) => {
          state.exchangeCoinsList = action.payload;
          state.status = "success";
        })
        .addCase(getCoinsAPI.rejected, (state, action) => {
          state.status = "failed";
        })

        //joinDuelsAPI
        .addCase(joinDuelsAPI.pending, (state,action) => {
          state.status = "loading";
        })
        .addCase(joinDuelsAPI.fulfilled, (state, action) => {
          state.joinDuelSymbolsList = action.payload;
          state.status = "success";
        })
        .addCase(joinDuelsAPI.rejected, (state, action) => {
          state.status = "failed";
        })

        //BloodBath Coins
       //getBloodBathCoinsAPI
       .addCase(getBloodBathCoinsAPI.pending, (state,action) => {
        state.status = "loading";
      })
      .addCase(getBloodBathCoinsAPI.fulfilled, (state, action) => {
        state.bloodBathExchangeCoinsList = action.payload;
        state.status = "success";
      })
      .addCase(getBloodBathCoinsAPI.rejected, (state, action) => {
        state.status = "failed";
      })
  
     
    
    },
  });
  
  export default enterJoinDuelSlice.reducer;