import React from 'react';
import Footer from '../MainLayout/Footer/Footer';
import Header from '../MainLayout/Header/Header';
import Navbar from '../MainLayout/Navbar/Navbar';
import { Row, Col, Card, Table } from 'react-bootstrap';
import { red } from '@mui/material/colors';
import { capitalize } from '@mui/material';
import authHeader from '../../util/Authheader';
import NavbarStatic from '../MainLayout/Navbar/NavbarStatic';

const RulesAndScoring = () => {
    let getToken = authHeader();


    return (
        <>
            {getToken === null ? <NavbarStatic /> : <Navbar />}

            <div className='main-layout'>
            {getToken === null ? '' : <Header />}
                <div className="py-3 text-start">
                    <div className="card-custom">
                        <div className='card-title text-center text-lg-start'>
                            <h4 className="dark-blue mb-0">RULES, SCORING & GAMES</h4>
                        </div>

                        <div className="container-fluid px-md-4 py-3 main-contact-container term-box text-lg-start text-center">
                            <h4 className='pink-text'>RULES</h4>
                            <p className='lh-lg'>All of our contests follow the same basic rules as followed:</p>

                            <p className='lh-lg'>The Player starts by picking 9 different Stock’s/Crypto’s depending on which Financial
                                Exchanges are applicable to a particular contest, with the Pick’s being broken down into 3 tiers.
                                Our tiers are a proprietary based system that acts like MoonLeague’s version of a “Salary Cap”
                                from the Daily Fantasy Sports World that would place the top Stock’s/Crypto’s in tier “A”. Tier “B”
                                would be a more average performing Stock’s/Crypto’s and tier “C” Stock’s/Crypto’s would be
                                considered the most volatile of picks. The player is required to make 2 Picks from tier “A” then 3
                                Picks from tier “B” and finish with 4 Picks from tier “C”. To start each contest we take a
                                snapshot of the entire market at the beginning of each contest for a reference price starting
                                point for each of the Players Pick’s and the percentage gains or losses our calculated in Real
                                Time and based on that starting snapshot for a players original Pick’s and in the case of a
                                “Trade” then once the new pick is made a snapshot is taken of that Pick’s current price for an
                                accurate percentage gain or loss for that particular Pick’s scoring purpose. Once the
                                predetermined game time has been reached, all players scores/percentage %+- will be
                                determined by taking the average of all the players Stock’s/Crypto’s Pick’s percentage %+-
                                gains or Losses and rounding to the 5th decimal point for the placing all contestants… ie your
                                “SCORE”. For scoring purposes if a Pick finishes a contest or is traded when there real time
                                percentage %+- is at a True 0.00 then we do not count that Pick into the scoring for the Total #
                                of Pick’s in averaging out a player’s cumulative Percentage %+- for scoring.
                            </p>
                            <p className='lh-lg text-bold'>All MoonLeague contests are currently being played on these exchanges:</p>
                            <ul className='text-gray'>
                                <li>NYSE</li>
                                <li>NASDAQ</li>
                                <li>BINANCE</li>
                            </ul>
                            <hr className='color-blue' />

                            <h4 className='pink-text'>SCORING:</h4>
                            <p className='lh-lg'>Example of our scoring system:</p>
                            <Table striped bordered hover className='my-3 table-rules'>
                                <thead>
                                    <tr>
                                        <th>Selection </th>
                                        <th>Tier</th>
                                        <th>Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Pick #1</td>
                                        <td>A</td>
                                        <td>+3.56%</td>
                                    </tr>
                                    <tr>
                                        <td>Pick #2</td>
                                        <td>A</td>
                                        <td>+2.78%</td>
                                    </tr>
                                    <tr>
                                        <td>Pick #3</td>
                                        <td>B</td>
                                        <td>-1.67%</td>
                                    </tr>
                                    <tr>
                                        <td>Pick #4</td>
                                        <td>B</td>
                                        <td>-0.78%</td>
                                    </tr>
                                    <tr>
                                        <td>Pick #5</td>
                                        <td>B</td>
                                        <td>+3.12%</td>
                                    </tr>
                                    <tr>
                                        <td>Pick #6</td>
                                        <td>C</td>
                                        <td>+0.56%</td>
                                    </tr>
                                    <tr>
                                        <td>Pick #7</td>
                                        <td>C</td>
                                        <td>+2.43%</td>
                                    </tr>
                                    <tr>
                                        <td>Pick #8</td>
                                        <td>C</td>
                                        <td>-1.54%</td>
                                    </tr>
                                    <tr>
                                        <td>Pick #9</td>
                                        <td>C</td>
                                        <td>+0.93%</td>
                                    </tr>
                                    <tr>
                                        <th >Total </th>
                                        <th style={{ visibility: "hidden" }}>Total</th>
                                        <th>+9.39%</th>
                                    </tr>

                                </tbody>
                            </Table>

                            <p className='lh-lg'>Example of our trading system:</p>
                            <Table striped bordered hover className='my-3 table-rules'>
                                <thead>
                                    <tr>
                                        <th>Trade </th>
                                        <th>Tier</th>
                                        <th>Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Player trades Pick #3 “B” to make Pick #10 </td>
                                        <td>B</td>
                                        <td>+1.43%</td>
                                    </tr>
                                    <tr>
                                        <td>Player trades Pick #8 “C” to make Pick #11</td>
                                        <td>C</td>
                                        <td>+1.78%</td>
                                    </tr>
                                    <tr>
                                        <td>Player trades Pick #1 “A” to make Pick #12</td>
                                        <td>A</td>
                                        <td>-2.01%</td>
                                    </tr>
                                    <tr>
                                        <td>Player trades Pick #10 “B” to make Pick #13</td>
                                        <td>B</td>
                                        <td>0.00</td>
                                    </tr>
                                    <tr>
                                        <td>Player trades Pick #13 “B” to make Pick #14</td>
                                        <td>B</td>
                                        <td>+4.38%</td>
                                    </tr>
                                    <tr>
                                        <th >Total </th>
                                        <th style={{ visibility: "hidden" }}>Total</th>
                                        <th>+5.58%</th>
                                    </tr>

                                </tbody>
                            </Table>

                            <p className='lh-lg text-dark'>Original 9 Picks 9.39% + 5 Traded picks 5.58% = 14.97%
                                <br></br> 14.97% / 13 (# of selections not including 0.00 scores) = 1.15153% rounded to +1.15% 
                            </p>

                            <hr className='color-blue' />
                            <h4 className='pink-text'>GAMES:</h4>
                            <p>Base Game- “CLASSIC”</p>
                            <p>This game mode is our base game mode in that the 9 original Stock’s/Crypto’s picked before
                                the contest started are the only selections that can be made for the entire contest. There are
                                also multiple modes that can be played while playing “CLASSIC”
                            </p>
                            <p>2nd game- “DAY TRADER”</p>
                            <p>This game is the same as our base “CLASSIC” game but this game differs in that a player can
                                switch out at any time his/her selection with another selection of they’re choice as long as it
                                comes from the same tier as the selection that is being switched out and scoring will be a
                                cumulative average by adding the total number of selections by the total of all the percentage
                                %+- added together and dividing by the total number of selections and then rounding to the 5th
                                decimal point for scoring purposes</p>
                            <hr className='color-blue' />
                            <h4 className='pink-text'>Additional “Add On” Modes off our base games- </h4>
                            <p>1st Mode- BLOOD BATH </p>
                            <ul className='text-gray'>
                                <li>1st Mode- BLOOD BATH </li>
                                <p>This mode would be structured the same as our base games
                                    in layout and picking but this mode would differ in that this games objective is to see who can
                                    get the biggest losses in percentage drops which is the opposite of our Base “STANDARD”
                                    mode, ie… if a player chooses a pick and it has a -2.3% loss then for scoring purposes his
                                    score would be represented as +2.3% and if there was a percentage gain for a selection then it
                                    would be calculated as a negative for the “BLOOD-BATH” modes scoring purposes. Our tier’s
                                    algorithm is adjusted accordingly for Blood Bath Mode so that the “A” tier’s selections are the
                                    ones with the largest losses and the same with tier’s “B” and “C” as it’s the complete opposite of
                                    our “STANDARD” Tiers.
                                </p>
                                <li>2nd Mode- BATTLE MODE </li>
                                <p>
                                    This Mode is structured as an add on to either our “CLASSIC” or “DAY TRADER” games and it could also 
                                    be paired with “BLOOD-BATH” mode with its rules as followed. “BATTLE-MODE” is a mode where players can 
                                    interact and directly impact another players score by placing any of these pre-determined action penalties 
                                </p>
                            </ul>

                            <p className='lh-lg'>BATTLE-MODE:</p>
                            <Table striped bordered hover className='my-3 table-rules'>
                                <thead>
                                    <tr>
                                        <th>Battle </th>
                                        <th>Tier</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Crazy CEO  </td>
                                        <td>-1%</td>
                                    </tr>
                                    <tr>
                                        <td>Bad Publicity</td>
                                        <td>-1%</td>
                                    </tr>
                                    <tr>
                                        <td>Crooked Books</td>
                                        <td>-1%</td>
                                    </tr>
                                    <tr>
                                        <td>SEC Audit</td>
                                        <td>-2%</td>
                                    </tr>
                                    <tr>
                                        <td>Congress Action</td>
                                        <td>-2%</td>
                                    </tr>

                                </tbody>
                            </Table>
                            <p>
                                Against an opponent’s Pick’s to impact their opponent’s score in Real Time. 
                                These 5 separate penalties that a player may play on an opponent’s single Pick 
                                to cause a percentage loss or gain, depending on the game mode being played to 
                                that users Pick. (example).... If a Player is holding Bitcoin and it’s at +3.6% and 
                                their opponent plays the “Crazy CEO” -1% penalty on their Bitcoin Pick then as long 
                                as that Player holding that particular Pick the -1% penalty is added to that Picks 
                                total percentage %+- in real time making that Player’s Bitcoin pick +2.6 There is 
                                also 1 more action that can be played in this mode but it is a User action play and 
                                it’s called a “TIP” action. Whichever Pick the “TIP” action is placed on then that 
                                Pick will receive a 3X (Multiply by 3) to whatever that Picks current percentage %+- 
                                is in Real Time while not allowing an opponent’s penalty action to be played against it. 
                                Each player in “BATTLE-MODE” gets 1 “TIP” per contest.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default RulesAndScoring