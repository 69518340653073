export default function authHeader() {
  const user = window.localStorage.getItem('userToken');
  // console.log(user,'userToken value Authorization');
  if (user) {
    //  console.log(user, 'Bearer Token');
    return { Authorization: 'Bearer ' + user };
  } else {
    return null;
  }
}


export const authToken = window.localStorage.getItem('userToken');