import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseURL } from "../config";
import axios from "axios";
import authHeader from "../util/Authheader";


export const userList = createAsyncThunk("api/user/all-list", async (args, thunkAPI) => {
    const url = `${baseURL}api/user/all-list`;
    return await axios
        .get(url, { headers: authHeader() })
        .then((response) => {
            let data = response.data.data;
            // console.log(response.data, "Data user List")
            return data;
        })
        .catch((err) => {
            // console.log(err, "User List")
            if (err.response) {
                const message = err.message.data.message;
                return thunkAPI.rejectWithValue(message);
            }
        })
});


//api/user/user-data-by-id/
export const singleUserList = createAsyncThunk("api/user/user-data-by-id/", async (args,thunkAPI) => {
  const url = `${baseURL}api/user/user-data-by-id/${args.userId}`;
  return await axios
  .get(url, {headers: authHeader() })
  .then( (response) => {
    let data = response.data.data;
    // console.log(response.data, "Another user data")
    return data;
  })
  .catch((err) => {
    // console.log(err, "User List")
    if (err.response) {
        const message = err.message.data.message;
        return thunkAPI.rejectWithValue(message);
    }
})
})

//get Follow Users List
//api/user/get-following-users/61
export const getFollowingUserAPI = createAsyncThunk(
    "api/user/get-following-users/",
    async (args, thunkAPI) => {  
      const urlname = `${baseURL}api/user/get-following-users/${args.userId}`;
      return await axios
      .get(urlname, { headers: authHeader() })
      .then((response) => {
        let data = response.data;

        return data;
      })
      .catch((err) => {
        console.log(err, "get all users following");
      });
    }
    );
    

    //Followers User list:
    export const getFollowersAPI = createAsyncThunk(
        "api/user/get-users-followers/",
        async (args, thunkAPI) => {  
          const urlname = `${baseURL}api/user/get-users-followers/${args.userId}`;
          return await axios
          .get(urlname, { headers: authHeader() })
          .then((response) => {
            let data = response.data;
    
            return data;
          })
          .catch((err) => {
            console.log(err, "get all users following");
          });
        }
        );

        //Follow user:
        //api/user/follow-user
        export const FollowUserAPI = createAsyncThunk(
            "api/user/follow-user/",
            async (args, thunkAPI) => {  
              const urlname = `${baseURL}api/user/follow-user`;
              return await axios
              .post(urlname, args.FollowUserData,{ headers: authHeader() })
              .then((response) => {
                let data = response.data;
        
                return data;
              })
              .catch((err) => {
                console.log(err, "get all users following");
              });
            }
            );


            //Unfollow user
//api/user/unfollow-user

export const UnFollowUserAPI = createAsyncThunk("api/user/unfollow-user", async (args,thunkAPI) => {
    const urlname = `${baseURL}api/user/unfollow-user`;
    return await axios
    .post(urlname, args.UnFollowUserData,{ headers: authHeader() })
    .then((response) => {
      let data = response.data;

      return data;
    })
    .catch((err) => {
      console.log(err, "get all users following");
    });
})

//Get Friend List
//api/user/get-friends/61
export const getFriendListAPI = createAsyncThunk(
  "api/user/get-friends/",
  async (args, thunkAPI) => {  
    const urlname = `${baseURL}api/user/get-friends/${args.userId}`;
    return await axios
    .get(urlname, { headers: authHeader() })
    .then((response) => {
      let data = response.data;

      return data;
    })
    .catch((err) => {
      console.log(err, "get all frineds of users");
    });
  }
  );

  //api/user/get-request-received-users/1
  //GET Friend Requests Received
  export const friendReuestsReceviedAPI = createAsyncThunk(
    "api/user/get-request-received-users/",
    async (args, thunkAPI) => {  
      const urlname = `${baseURL}api/user/get-request-received-users/${args.userId}`;
      return await axios
      .get(urlname, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
  
        return data;
      })
      .catch((err) => {
        console.log(err, "get all frineds of users");
      });
    }
    );
           
//api/user/get-request-sent-users/61
//GET Friend Requests Sent
export const friendReuestsSentAPI = createAsyncThunk(
  "api/user/get-request-sent-users/",
  async (args, thunkAPI) => {  
    const urlname = `${baseURL}api/user/get-request-sent-users/${args.userId}`;
    return await axios
    .get(urlname, { headers: authHeader() })
    .then((response) => {
      let data = response.data;

      return data;
    })
    .catch((err) => {
      console.log(err, "get all frineds of users");
    });
  }
  );


  //api/user/all-friends-followers/61
  export const friendFollowersListAPI = createAsyncThunk(
    "api/user/all-friends-followers/",
    async (args, thunkAPI) => {  
      const urlname = `${baseURL}api/user/all-friends-followers/${args.userId}`;
      return await axios
      .get(urlname, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
  
        return data;
      })
      .catch((err) => {
        console.log(err, "all-friends-followers");
      });
    }
    );

    //Invite users to participate in a duel
    export const inviteDuelAPI = createAsyncThunk(
      "api/user/invite-users",
      async (args, thunkAPI) => {  
        const urlname = `${baseURL}api/user/invite-users`;
        return await axios
        .post(urlname, args.InviteDuelData,{ headers: authHeader() })
        .then((response) => {
          let data = response.data;
    
          return data;
        })
        .catch((err) => {
          console.log(err, "get all users following");
        });
      }
      );
    

  //Sent Friend Request
//  api/user/send-friend-request
export const addfriendReuestsAPI = createAsyncThunk(
  "api/user/send-friend-request",
  async (args, thunkAPI) => {  
    const urlname = `${baseURL}api/user/send-friend-request`;
    return await axios
    .post(urlname, args.SendfrndData,{ headers: authHeader() })
    .then((response) => {
      let data = response.data;

      return data;
    })
    .catch((err) => {
      console.log(err, "get all users following");
    });
  }
  );


  

//Cancel Sent Friend Requests 
//api/user/cancel-sent-friend-request
export const CancelSendRequestAPI = createAsyncThunk(
  "api/user/cancel-sent-friend-request",
  async (args, thunkAPI) => {  
    const urlname = `${baseURL}api/user/cancel-sent-friend-request`;
    return await axios
    .post(urlname, args.cancelfrndRequestData,{ headers: authHeader() })
    .then((response) => {
      let data = response.data;

      return data;
    })
    .catch((err) => {
      console.log(err, "get all AcceptRejectFrndAPI");
    });
  }
  );

//Accept or Cancel Recevied Friend Request
//api/user/accept-or-cancel-friend-request
export const AcceptRejectFrndAPI = createAsyncThunk(
  "api/user/accept-or-cancel-friend-request",
  async (args, thunkAPI) => {  
    const urlname = `${baseURL}api/user/accept-or-cancel-friend-request`;
    return await axios
    .post(urlname, args.frndConfirmData,{ headers: authHeader() })
    .then((response) => {
      let data = response.data;

      return data;
    })
    .catch((err) => {
      console.log(err, "get all AcceptRejectFrndAPI");
    });
  }
  );

//Unfriend User
//api/user/unfriend-user
export const UnfriendAPI = createAsyncThunk(
  "api/user/api/user/unfriend-user",
  async (args, thunkAPI) => {  
    const urlname = `${baseURL}api/user/unfriend-user`;
    return await axios
    .post(urlname, args.unfrndData,{ headers: authHeader() })
    .then((response) => {
      let data = response.data;

      return data;
    })
    .catch((err) => {
      console.log(err, "get all users following");
    });
  }
  );

  // api/community/get-hot-player-list
  export const hotPlayerList = createAsyncThunk("api/community/get-hot-player-list", async (args, thunkAPI) => {
    const url = `${baseURL}api/community/get-hot-player-list`;
    return await axios
        .get(url, { headers: authHeader() })
        .then((response) => {
            let data = response.data;
            // console.log(response.data.data, "Data user List")
            return data;
        })
        .catch((err) => {
            // console.log(err, "User List")
            if (err.response) {
                const message = err.message;
                return thunkAPI.rejectWithValue(message);
            }
        })
});

const userListSlice = createSlice({
    name: "userLists",
    initialState: {
        status: null,
        getUsersList:[],
        getFollowingUserList:[],
        getFollowersList:[],
        FollowUserResponse:[],
        getFriendList:[],
        FriendRequestsReceivedList:[],
        FriendRequestsSentList:[],
        SendFriendRequestResponse:[],
        unfrndResponse:[],
        acceptfrndResponse:[],
        cancelfrndResponse:[],
        singleUserResponse:[],
        frendFollowersResponse:[],
        hotPlayerListData:[]
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(userList.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(userList.fulfilled, (state, action) => {
                state.getUsersList = action.payload;
                state.status = "success";
            })
            .addCase(userList.rejected, (state, action) => {
                state.userList = "rejected";
            })

            //getFollowingUserAPI
            .addCase(getFollowingUserAPI.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(getFollowingUserAPI.fulfilled, (state, action) => {
                state.getFollowingUserList = action.payload;
                state.status = "success";
            })
            .addCase(getFollowingUserAPI.rejected, (state, action) => {
                state.userList = "rejected";
            })

            //getFollowersAPI
            .addCase(getFollowersAPI.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(getFollowersAPI.fulfilled, (state, action) => {
                state.getFollowersList = action.payload;
                state.status = "success";
            })
            .addCase(getFollowersAPI.rejected, (state, action) => {
                state.userList = "rejected";
            })

            //FollowUserAPI

            .addCase(FollowUserAPI.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(FollowUserAPI.fulfilled, (state, action) => {
                state.FollowUserResponse = action.payload;
                state.status = "success";
            })
            .addCase(FollowUserAPI.rejected, (state, action) => {
                state.userList = "rejected";
            })

            //Get friendsLIST getFriendListAPI
            .addCase(getFriendListAPI.pending, (state, action) => {
              state.status = "loading";
          })
          .addCase(getFriendListAPI.fulfilled, (state, action) => {
              state.getFriendList = action.payload;
              state.status = "success";
          })
          .addCase(getFriendListAPI.rejected, (state, action) => {
              state.userList = "rejected";
          })

          //friendReuestsReceviedAPI
          .addCase(friendReuestsReceviedAPI.pending, (state, action) => {
            state.status = "loading";
        })
        .addCase(friendReuestsReceviedAPI.fulfilled, (state, action) => {
            state.FriendRequestsReceivedList = action.payload;
            state.status = "success";
        })
        .addCase(friendReuestsReceviedAPI.rejected, (state, action) => {
            state.userList = "rejected";
        })

        //FriendRequestsSentList
        .addCase(friendReuestsSentAPI.pending, (state, action) => {
          state.status = "loading";
      })
      .addCase(friendReuestsSentAPI.fulfilled, (state, action) => {
          state.FriendRequestsSentList = action.payload;
          state.status = "success";
      })
      .addCase(friendReuestsSentAPI.rejected, (state, action) => {
          state.userList = "rejected";
      })

      //SendFriendRequestAPI
      .addCase(addfriendReuestsAPI.pending, (state, action) => {
        state.status = "loading";
    })
    .addCase(addfriendReuestsAPI.fulfilled, (state, action) => {
        state.SendFriendRequestResponse = action.payload;
        state.status = "success";
    })
    .addCase(addfriendReuestsAPI.rejected, (state, action) => {
        state.userList = "rejected";
    })

    //Get Unfriend Data
    
    .addCase(UnfriendAPI.pending, (state, action) => {
      state.status = "loading";
  })
  .addCase(UnfriendAPI.fulfilled, (state, action) => {
      state.unfrndResponse = action.payload;
      state.status = "success";
  })
  .addCase(UnfriendAPI.rejected, (state, action) => {
      state.userList = "rejected";
  })

  //AcceptRejectFrndAPI
  .addCase(AcceptRejectFrndAPI.pending, (state, action) => {
    state.status = "loading";
})
.addCase(AcceptRejectFrndAPI.fulfilled, (state, action) => {
    state.acceptfrndResponse = action.payload;
    state.status = "success";
})
.addCase(AcceptRejectFrndAPI.rejected, (state, action) => {
    state.userList = "rejected";
})

//CancelSendRequestAPI
.addCase(CancelSendRequestAPI.pending, (state, action) => {
  state.status = "loading";
})
.addCase(CancelSendRequestAPI.fulfilled, (state, action) => {
  state.cancelfrndResponse = action.payload;
  state.status = "success";
})
.addCase(CancelSendRequestAPI.rejected, (state, action) => {
  state.userList = "rejected";
})

//singleUserList 
.addCase(singleUserList.pending, (state, action) => {
  state.status = "loading";
})
.addCase(singleUserList.fulfilled, (state, action) => {
  state.singleUserResponse = action.payload;
  state.status = "success";
})
.addCase(singleUserList.rejected, (state, action) => {
  state.userList = "rejected";
})

//friendFollowersListAPI
.addCase(friendFollowersListAPI.pending, (state, action) => {
  state.status = "loading";
})
.addCase(friendFollowersListAPI.fulfilled, (state, action) => { 
  state.frendFollowersResponse = action.payload;
  state.status = "success";
})
.addCase(friendFollowersListAPI.rejected, (state, action) => {
  state.userList = "rejected";
})

//inviteDuelAPI
.addCase(inviteDuelAPI.pending, (state, action) => {
  state.status = "loading";
})
.addCase(inviteDuelAPI.fulfilled, (state, action) => {
  state.InviteDuelData = action.payload;
  state.status = "success";
})
.addCase(inviteDuelAPI.rejected, (state, action) => {
  state.userList = "rejected";
})

//hotPlayerList
.addCase(hotPlayerList.pending, (state, action) => {
  state.status = "loading";
})
.addCase(hotPlayerList.fulfilled, (state, action) => {
  state.hotPlayerListData = action.payload;
  state.status = "success";
})
.addCase(hotPlayerList.rejected, (state, action) => {
  state.userList = "rejected";
})

    }
})

export default userListSlice.reducer;
//export const dashboardSelector = (state) => state.dashboardGames;