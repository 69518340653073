import React, { useEffect, useState } from 'react'
import { CheckRequestStatus, WebSdkLite,JurisdictionArea, WebSdk } from "@xpointtech/xpoint-js";
import { useNavigate } from 'react-router-dom';

export default Location = () => {
  const navigate = useNavigate();
  const [getStatus, setStatus] = useState();
  const [msg, setMsg] = useState('');
  let locacluserid = window.localStorage.getItem("userid");
  let ClientKey = "moonleague5872stage";
  let userId = locacluserid; // denied


      useEffect( () => { 
        getLocation();
     }, []);

  const [loading, setLoading] = useState(false);

  async function getLocation() {
    setLoading(true);
    window.xpjs = new WebSdkLite();

    try {
      await xpjs.setUserId(userId, ClientKey);
      const checkRequest = await xpjs.liteCheck();
      setLoading(false);
      if (checkRequest?.status === "WAITING" && !checkInterval?.current
      ) {
        checkInterval.current = setInterval(() => {
           dispatch(applyForContest(JurisdictionArea ? JurisdictionArea : undefined));
        }, 30000);
      }
      else if (checkRequest?.status === "ALLOWED") {
        setStatus(checkRequest?.status);
      }
      else if (checkRequest?.status === "DENIED") {
        setStatus(checkRequest?.status);
        let errorVal = checkRequest?.errors[0]?.description;
        setMsg(errorVal);
        document.getElementById("demo").innerHTML = checkRequest?.status;
      }

    } catch (e) {
      if (!e?.status || e?.status >= 500) {
        console.log(e.status, 'statusss 500');
      } else if (e?.status === 401) {
        console.log(e.status, 'statusss 401');
      }
    }
  }
  return (
    {
      getStatus, msg, loading, setLoading, getLocation
    }
  )
};