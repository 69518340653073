import { NavLink } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";

const Header = () => {
  return (
    <>
      <Navbar expand="lg" className="menuTabNav">
        <Container fluid className="p-0">
          <Navbar.Toggle aria-controls="navbarScroll" className="ms-auto" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 align-items-end"
              style={{ maxHeight: "300px" }}
              navbarScroll
            >
              <Nav.Link className="li" as={NavLink} to="/dashboard">
                Dashboard
              </Nav.Link>

              <Nav.Link className="li" as={NavLink}
                to="/user-profile"
              >
                Profile
              </Nav.Link>

              <Nav.Link className=" li" as={NavLink} to="/hotpick">
                Hot Picks
              </Nav.Link>

              <Nav.Link className=" li" as={NavLink}

                to="/createduels"
              >
                Create Duels
              </Nav.Link>

            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
