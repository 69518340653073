import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { useDispatch, useSelector } from 'react-redux';
import { getUpcomingGames, dashboardSelector, isFetching } from '../../slices/dashboardSlice';
import moment from "moment-timezone";


const UpcomingGames = () => {

  const [getUpcoming, setUpcoming] = useState();
  let localuserid = window.localStorage.getItem("userid");
  const dispatch = useDispatch();
  useEffect(() => {
    //  setUpcoming(''); 
    dispatch(getUpcomingGames({ userId: localuserid })).unwrap().then((res) => {
      setUpcoming(res);
    })
  }, [dispatch])
  const { upcomingGamesList, isFetching } = useSelector(dashboardSelector);

  const cars = Array.from({ length: 100000 }).map((_, i) => upcomingGamesList);
  const [virtualCars, setVirtualCars] = useState(Array.from({ length: 5 }));
  const [lazyLoading, setLazyLoading] = useState(false);
  let loadLazyTimeout = null;

  const loadCarsLazy = (event) => {
    !lazyLoading && setLazyLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //simulate remote connection with a timeout
    loadLazyTimeout = setTimeout(() => {
      let _virtualCars = [...virtualCars];
      let { first, last } = event;

      //load data of required page
      const loadedCars = cars.slice(first, last);

      //populate page of virtual cars
      Array.prototype.splice.apply(_virtualCars, [
        ...[first, last - first],
        ...loadedCars,
      ]);

      setVirtualCars(_virtualCars);
      setLazyLoading(false);
    }, Math.random() * 1000 + 250);
  };

  const loadingTemplate = (options) => {
    return (
      <div
        className="flex align-items-center"
        style={{ height: "17px", flexGrow: "1", overflow: "hidden" }}
      >
        <Skeleton
          width={
            options.cellEven
              ? options.field === "year"
                ? "30%"
                : "40%"
              : "60%"
          }
          height="1rem"
        />
      </div>
    );
  };
  var timeInterval;
  const [sTime, setStartTime] = useState("");
  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownMinutes: "",
    countdownSeconds: "",
  });

  const eventTimer = (evntString) => {
    let countdownDateTime = new Date(`${evntString}`).getTime();
    let nowDate = new Date().toISOString();
    let newtime = new Date(nowDate);
    let currentTime = new Date(nowDate).getTime();

    const remainingDayTime = countdownDateTime - currentTime;
    const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));

    const totalHours = Math.floor(remainingDayTime / (1000 * 60 * 60));

    const totalMinutes = Math.floor((remainingDayTime / 1000 / 60) % 60);
    const totalSeconds = Math.floor((remainingDayTime / 1000) % 60);

    let remainingHours = `${totalHours}H ${totalMinutes}M ${totalSeconds}S`;
    setCountdownTime(remainingHours);
    if (remainingDayTime <= -countdownDateTime) {
      clearInterval(timeInterval);
      return;
    }
    if (remainingDayTime <= 0) {
      clearInterval(timeInterval);
      return "Event started";
    }
    return remainingHours;
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      eventTimer();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [sTime]);

  const timerTemplate = (rowData) => {
    const startTime = rowData.duel_start_time_utc;
    let araryofTime = rowData;

    let check12hr = moment(startTime).format("MM/DD/YYYY h:mm:ss A");
    setStartTime(startTime);
    return (
      <>
        <div id={check12hr}>
          <span className="timer">
            {eventTimer(rowData.duel_start_time_utc)}
          </span>
        </div>
      </>
    );
  };
  const NameBodyTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center gap-2 justify-content-center">
        <p className="fs-5 bg-light-blue p-2 mb-0 pro-head">
          {rowData.duel_mode === "Blood Bath"
            ? "B"
            : rowData.duel_mode === "Standard"
              ? "S"
              : rowData.duel_mode === "Bloodbath & Battel" ? "BB" : "SB"
          }
        </p>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    const startTime = rowData.duel_start_time_utc;
    let araryofTime = rowData;

    let check12hr = moment(startTime).format("MM/DD/YYYY h:mm:ss A");
    setStartTime(startTime);
    return (
      <>
        <div className="flex align-items-center gap-2" style={{width:"90px"}}>
          <p className="fs-6 mb-0 text-uppercase text-truncate">{rowData.duel_name}</p>
          <span className="bg-gray duel-summary text-truncate">{rowData.duel_description}</span>
        </div>
        <div id={check12hr}>
          <span className="timer">
            {eventTimer(rowData.duel_start_time_utc)}
          </span>
        </div>
      </>
    );
  };
  const entryFeeTemplate = (rowData) => {
    return (
      <>
        <div className="flex align-items-center gap-2">
          {rowData.duel_entry_fee === '' ? "$0.00" : (<>  <span>{rowData.duel_entry_fee}</span> </>)}

        </div>
      </>
    );
  };
  const buttonBodyTemplate = (rowData) => {
    return (
      <div id={rowData.id}>
        {rowData.is_duel_join === 1 && rowData.duel_entry_type === "Free" ? (<>
          <Link className="duel-btn text-decoration-none white-space disable-link" to={`/enter-duel/${rowData.id}`} onClick={topScroll} >Joined</Link>
        </>) : (<>
          <Link className="duel-btn text-decoration-none white-space" to={`/enter-duel/${rowData.id}`} onClick={topScroll}>ENTER DUEL</Link>
        </>)
        }
      </div>
    );
  };
  const topScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <div className='card-body upcoming-card my-duel-games-table-card'>
        {isFetching === true ? (<><p className="pink-text fw-bolder">Please wait</p></>)

          : (
            <>
              <DataTable
                value={upcomingGamesList}
                scrollable
                scrollHeight="572px"
                //responsiveLayout="scroll"
                // virtualScrollerOptions={{
                //   lazy: true,
                //   onLazyLoad: loadCarsLazy,
                //   itemSize: 1,
                //   delay: 200,
                //   showLoader: true,
                //   loading: lazyLoading,
                //   loadingTemplate,
                // }}

                // tableStyle={{ minWidth: "50rem" }}
                emptyMessage="There is no my duel data available yet!"
                className="my-duel-games-table"
              >
                {/* <Column body={NameBodyTemplate}  header="Duel Mode" filterField="duel_mode"   showFilterMatchModes={false}
           showFilterMenuOptions={false} filter filterPlaceholder="Search by duel mode" field="duel_mode"></Column> */}
                <Column
                  body={countryBodyTemplate} filterField="duel_name" showFilterMatchModes={false}
                  showFilterMenuOptions={false} filter filterPlaceholder="Search by duel name"
                  // className="upcoming-duel-column"
                  style={{ width: '40%' }}
                  header="Duel Name" field="duel_name"></Column>

                {/* <Column
                  body={timerTemplate}
                  header="Duel Start Time"
                  sortable
                  field="duel_start_time_utc"
                ></Column> */}
                <Column
                  field="duel_type" sortable
                  header="Duel Type"
                //body={statusBodyTemplate}
                />
                <Column
                  body={entryFeeTemplate}
                  sortable
                  field="duel_entry_fee"
                  header="Entry Fee"
                ></Column>
                <Column body={buttonBodyTemplate}></Column>
                <Column field="name" filterPlaceholder="Search"></Column>
              </DataTable>
            </>
          )
        }
      </div>
    </>
  )
}

export default UpcomingGames