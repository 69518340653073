import { useState } from "react";
import { omit } from "lodash";

const useForm = (callback) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const validate = (event, name, values) => {
    switch (name) {
      case "email":
        if (!values) {
          setErrors({
            ...errors,
            email: "Email address is required.",
          });
         // console.log(errors.email, "email");
        } else if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(values)
        ) {
          setErrors({
            ...errors,
            email: "Enter a valid email address",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "password":
        if (!values) {
          setErrors({
            ...errors,
            password: "Password is required",
          });
        } else {
          let newobj = omit(errors, "password");
          setErrors(newobj);
        }
        break;

      default:
        break;
    }
  };

  const handleChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;

    validate(event, name, val);

    setValues({
      ...values,
      [name]: val,
    });

   // console.log(values, "valueee");
  };

  //Submit validaion
  const validateFormOnSubmit = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Email address is required";
    } else if (values.email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(values.email)) {
        errors.email = "Please enter valid email address";
      }
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email address is invalid";
    }

    if (!values.password) {
      errors.password = "Password is required";
    }
  
    return errors;
  };
  const handleSubmit = (event) => { 
    if (event) event.preventDefault();

    if (Object.keys(errors).length === 0 && Object.keys(values).length !== 0) {
      callback();

    

      setErrors(validateFormOnSubmit(values));
    } else {
      setErrors(validateFormOnSubmit(values));
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    passwordShown,
    togglePasswordVisiblity,
  };
};

export default useForm;
