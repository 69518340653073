import React from 'react'

const MyActiveDuels = () => {
  return (
    <>
    <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">YOUR ACTIVE DUELS</h3>
            <small className="card-slabel">
              These are duels you are currently competing in
            </small>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-center align-items-center">
            <div className="text-center">No Data</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyActiveDuels