import { useSelector } from "react-redux";
import { SwtichArrowIcon, UpIcon } from "../../SVGIcon";
import { baseURL } from "../../config";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const AllDulers = ({
  duelSymbolsList,
  handleClickUser,
  selected,
  setSelected,
  dualUser,
}) => {

  const rankTemplate = (rowIndex, options) => {
    return (
      <>
        <p className="fw-bold mb-0 cfs-6">{options.rowIndex + 1}</p>
      </>
    )
  }

  const logoBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.user_avatar !== "" ? (
          <>
            <img
              src={`${baseURL}images/avatar/${rowData.user_avatar}`}
              className="img-fluid img-symbols"
            />
          </>
        ) : (
          <>
            <img
              src="/icon/default-profile.png"
              className="img-fluid img-symbols"
            />
          </>
        )}
      </>
    );
  };

  const userNAmeBodyTemplate = (rowData) => {
    return (
      <>
        <p className="mb-0 text-truncate" style={{ maxWidth: "80px" }}>{rowData.user_name}</p>
      </>
    )
  }

  const compareListTemplate = (rowData) => {
    return (
      <>
        <span
          className="spectate"
          onClick={() => {
            handleClickUser(
              rowData.join_duel_user_id,
              rowData.join_duel_unique_id
            );
            setSelected(rowData);
          }}
        >
          <SwtichArrowIcon />
        </span>
      </>
    );
  };
  const scoreTemplate = (rowData) => {
    return (
      <>
        {rowData?.score >= "0.00" ? <>{rowData?.score === "0.00" ? <p className="text-warning fw-bold cfs-6 mb-0 " style={{ marginLeft: "-0.2rem!important" }}> 0 </p>
          : <p className="green-text fw-bold cfs-6 mb-0" style={{ marginLeft: "-0.2rem!important" }}>{rowData?.score}</p>}</> : <p className="text-danger fw-bold cfs-6 mb-0" style={{ marginLeft: "-0.2rem!important" }}> {rowData?.score} </p>}
      </>
    );
  };

  const payoutTemplate = (rowData) => {
    return (
      <>
        <p className="mb-0">{rowData?.payout_prizes === '0' ? "" : `$${rowData?.payout_prizes}`}</p>
      </>
    )
  }


  const rowClass = (rowData, index) => {
    return {
      "bg-clicked": rowData.join_duel_unique_id === selected?.join_duel_unique_id
    };
  };
  return (
    <>
      <DataTable
        value={duelSymbolsList}
        sortMode="multiple"
        tableStyle={{ minWidth: "30rem" }}
        rowClassName={rowClass}
        scrollable
        scrollHeight={dualUser === 1 ? "460px" : "444px"}
        style={{ height: "460px" }}
        emptyMessage="There is no duelers available yet!"


      >
        <Column header="Rank" headerStyle={{ width: '1%', padding: "1rem 0.5rem" }}
          body={rankTemplate}></Column>
        <Column
          field="user_avatar"
          body={logoBodyTemplate}
          header="Profile"
          style={{ width: "5%", padding: "1rem 0.5rem" }}
        ></Column>
        <Column
          field="user_name"
          header="Name"
          body={userNAmeBodyTemplate}
          style={{ width: "10%" }}
        ></Column>
        <Column
          field="score"
          header="Score"
          body={scoreTemplate}
          style={{ width: "5%", padding: "1rem 0rem" }}
        ></Column>
        <Column
          field="payout_prizes"
          header="Payout"
          body={payoutTemplate}
          style={{ width: "5%", padding: "1rem 0.5rem" }}
        ></Column>
        <Column
          body={compareListTemplate}
          header="Compare"
          style={{ width: "1%", padding: "1rem 0.5rem", textAlign: "center" }}
        ></Column>
      </DataTable>
    </>
  );
};
export default AllDulers;
