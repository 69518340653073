import React from 'react';
import Footer from '../MainLayout/Footer/Footer';
import Header from '../MainLayout/Header/Header';
import Navbar from '../MainLayout/Navbar/Navbar';
import { Row, Col } from 'react-bootstrap';
import authHeader from '../../util/Authheader';
import NavbarStatic from '../MainLayout/Navbar/NavbarStatic';

const Faq = () => {
  let getToken = authHeader();

  return (
    <>
   {getToken === null ? <NavbarStatic/> : <Navbar />}
      <div className='main-layout'>
      {getToken === null ? '' : <Header />}
        <div className="py-3 text-start">
          <div className="card-custom">
            <div className='card-title text-center text-lg-start'>
              <h4 className="dark-blue mb-0">Frequently Asked Questions</h4>
            </div>

            <div className="container-fluid px-md-4 py-3 main-contact-container term-box text-lg-start text-center">
              <h4 className='pink-text'>What is MoonLeague?</h4>
              <p className='lh-lg'>We are a fantasy gaming company that offers a unique twist on traditional daily fantasy games. Instead of using professional athletes, our platform uses companies from the NYSE & Nasdaq, crypto currencies from Binance exchange, commodities & currencies that are all tracked in real time off of US Exchanges & Markets.</p>
              <hr className='color-blue' />
              <h4 className='pink-text'>What is the process to play a MoonLeague contest/Duel?</h4>
              <p className='lh-lg'>To play in a MoonLeague contest all a user need to do is 1st register for a free account, once that is done then decide if they want to do a Free or Paid game. If it’s a free game then you just look at our listings of free games then pick a contest and make your selections then sit back, watch and or chat with other contestants. If it’s a Paid game then you must get your account verified with our KYC process And be at least 18 years old then verify your in an allowed state as well.  If you meet all of those requirements then all that’s left is to make your selections, sit back and watch or chat just like in the Free contests.</p>
              <hr className='color-blue' />
              <h4 className='pink-text'>Who can participate in MoonLeague contests?</h4>
              <p className='lh-lg'>All users of the MoonLeague platform must be at least 14 years old to play in any of our Free contests. For our Paid contests Users have to be Verified through our KYC process, and atleast 18 years old or older depending on which state. Also the state that you  are located in has to be a state that specifically allows skill based gaming. We have a geolocation process to determine if your in a permitted state.</p>
              <hr className='color-blue' />
              <h4 className='pink-text'>What is the payout process like?</h4>
              <p className='lh-lg'>Once a Paid contest is over and validated a user who has won any amount will have that amount loaded back into there account and then go through process of withdrawal to their preferred means of deposit from our list of deposit options.</p>
              <hr className='color-blue' />
              <h4 className='pink-text'>When do contests run? </h4>
              <p className='lh-lg'>We run a variety of Free & Paid games through out the day while the US markets are up and running. Our Crypto based contests can and do run 24 hrs a day because Cryptocurrency in a 24 hour market.</p>
              <hr className='color-blue' />
              <h4 className='pink-text'>Can anyone create a private contest?</h4>
              <p className='lh-lg'>Yes, users have the ability to create a duel and choose which exchanges are involved and what rules are implemented in our “Create Duel” section. These user created duels are for Friends or followers of the users and once created show up in your “invites” section of a users Games section.</p>
              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Faq