import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import moment from "moment-timezone";
const AnotherActiveGames = ({ getActive, isFetching }) => {

  const cars = Array.from({ length: 100000 }).map((_, i) => getActive);
  const [virtualCars, setVirtualCars] = useState(Array.from({ length: 5 }));
  const [lazyLoading, setLazyLoading] = useState(false);
  let loadLazyTimeout = null;

  const loadCarsLazy = (event) => {
    !lazyLoading && setLazyLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //simulate remote connection with a timeout
    loadLazyTimeout = setTimeout(() => {
      let _virtualCars = [...virtualCars];
      let { first, last } = event;

      //load data of required page
      const loadedCars = cars.slice(first, last);

      //populate page of virtual cars
      Array.prototype.splice.apply(_virtualCars, [
        ...[first, last - first],
        ...loadedCars,
      ]);

      setVirtualCars(_virtualCars);
      setLazyLoading(false);
    }, Math.random() * 1000 + 250);
  };

  const loadingTemplate = (options) => {
    return (
      <div
        className="flex align-items-center"
        style={{ height: "17px", flexGrow: "1", overflow: "hidden" }}
      >
        <Skeleton
          width={
            options.cellEven
              ? options.field === "year"
                ? "30%"
                : "40%"
              : "60%"
          }
          height="1rem"
        />
      </div>
    );
  };

  let timeInterval;
  const [sTime, setStartTime] = useState("");
  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownMinutes: "",
    countdownSeconds: "",
  });

  const eventTimer = (evntString) => {
    let countdownDateTime = new Date(`${evntString}`).getTime();
    let nowDate = new Date().toISOString();
    let newtime = new Date(nowDate);
    let currentTime = new Date(nowDate).getTime();

    const remainingDayTime = countdownDateTime - currentTime;
    // console.log(remainingDayTime, "remainingDayTime");
    const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));

    const totalHours = Math.floor(remainingDayTime / (1000 * 60 * 60));

    const totalMinutes = Math.floor((remainingDayTime / 1000 / 60) % 60);
    const totalSeconds = Math.floor((remainingDayTime / 1000) % 60);

    let remainingHours = `${totalHours}H ${totalMinutes}M ${totalSeconds}S`;
    setCountdownTime(remainingHours);
    if (remainingDayTime <= -countdownDateTime) {
      clearInterval(timeInterval);
      return;
    }
    if (remainingDayTime <= 0) {


      clearInterval(timeInterval);
      return "Event ended";
    }

    return remainingHours;
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      eventTimer();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [sTime]);

  const timerTemplate = (rowData) => {
    const startTime = rowData.duel_end_time_utc;
    let araryofTime = rowData;

    let check12hr = moment(startTime).format("MM/DD/YYYY h:mm:ss A");
    setStartTime(startTime);
    return (
      <>
        <div id={check12hr}>
          <span className="timer">
            {/* {rowData.duel_start_time_utc} */}
            {eventTimer(rowData.duel_end_time_utc)}
          </span>
        </div>
      </>
    );
  };
  const NameBodyTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center gap-2 justify-content-center">
        <p className="fs-5 bg-light-blue p-2 mb-0 pro-head">
          {rowData.duel_mode === "Blood Bath"
            ? "B"
            : rowData.duel_mode === "Standard"
              ? "S"
              : rowData.duel_mode === "Blood Bath - Battel" ? "BB" : "SB"
          }
        </p>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    const startTime = rowData.duel_end_time_utc;
    let araryofTime = rowData;
    let check12hr = moment(startTime).format("MM/DD/YYYY h:mm:ss A");
    setStartTime(startTime);
    return (
      <>
        <div className="flex align-items-center gap-2">
          <p className="fs-6 mb-0 text-uppercase text-truncate">{rowData.duel_name}</p>
          <span className="bg-gray duel-summary text-truncate" style={{ width: "100%" }}>{rowData.duel_description}</span>
        </div>
        <div id={check12hr}>
          <span className="timer">
            {eventTimer(rowData.duel_end_time_utc)}
          </span>
        </div>
      </>
    );
  };
  const entryFeeTemplate = (rowData) => {
    return (
      <>
        <div className="flex align-items-center gap-2">
          {rowData.duel_entry_fee === '' ? "$0.00" : (<>  <span>${rowData.duel_entry_fee}</span> </>)}

        </div>
      </>
    );
  };
  const buttonBodyTemplate = (rowData) => {
    return (
      <div id={rowData.id}>
        <Link className="duel-btn text-decoration-none white-space" to={`/main-game/${rowData.id}`} onClick={topScroll}>Spectate</Link>
      </div>
    );
  };
  const topScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <div className='card-body upcoming-card'>
        {isFetching === true ? (<><p className="pink-text fw-bolder">Please wait</p></>)
          : (
            <>
              <DataTable
                value={getActive}
                scrollable
                scrollHeight="100%"
                emptyMessage="There is no active duel data available yet!"
                className="another-user-active-games-table"
              >

                <Column
                  body={countryBodyTemplate} filterField="duel_name" showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  className="another-user-active-duel-column"
                  style={{ width: '40%' }}
                  header="Duel Name" filter filterPlaceholder="Search by duel name" field="duel_name"></Column>

                <Column
                  body={entryFeeTemplate}
                  sortable
                  field="duel_entry_fee"
                  header="Entry Fee"
                ></Column>
                <Column body={buttonBodyTemplate}></Column>

                <Column field="name" filterPlaceholder="Search"></Column>
              </DataTable>
            </>
          )
        }
      </div>
    </>
  )
}

export default AnotherActiveGames