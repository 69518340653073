import React, { useEffect, useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getHotPickTopAllTankersAPI, getHotPickTopNasdaqTankersAPI, getHotPickTopNyseTankersAPI, getHotPickTopTankersAPI } from "../../slices/hotpickSlice";
import { Skeleton } from "primereact/skeleton";
const TopLoser = () => {
  const [activeExchange, setActiveExchange] = useState('1');
  const [paidGameData, setPaidGame] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [symbols, setSymbol] = useState([{ name: 'All', exchange_id: '1' }]);
  const acExchage = [
    { name: 'All', exchange_id: '1' },
    { name: 'NYSE', exchange_id: '2' },
    { name: 'NASDAQ', exchange_id: '3' },
    { name: 'CRYPTO', exchange_id: '4' }
  ];

  const mobile = window.matchMedia('(max-width: 400px)').matches;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const dispatch = useDispatch();
  const handleCoins = () => {

    if (activeExchange === "1") {
      setLazyLoading(true);
      dispatch(getHotPickTopAllTankersAPI())
        .unwrap()
        .then((res) => {
          setPaidGame(res);
          setLazyLoading(false);

        });
    }
    else if (activeExchange === "2") {
      setLazyLoading(true);
      dispatch(getHotPickTopNyseTankersAPI())
        .unwrap()
        .then((res) => {
          setPaidGame(res);
          setLazyLoading(false);

        });
    }
    else if (activeExchange === "4") {
      setLazyLoading(true);
      dispatch(getHotPickTopTankersAPI())
        .unwrap()
        .then((res) => {
          setPaidGame(res);
          setLazyLoading(false);

        });
    }
    else if (activeExchange === "3") {
      setLazyLoading(true);
      dispatch(getHotPickTopNasdaqTankersAPI())
        .unwrap()
        .then((res) => {
          setPaidGame(res);
          setLazyLoading(false);

        });
    }

  };

  const NameBodyTemplate = (rowData) => {
    return (
      <div >
        <p className="fs-6 mb-0">{rowData.symbol}</p>
        <span className="bg-gray duel-summary">{rowData.name_short}</span>
      </div>
    );
  };


  useEffect(() => {
    setSymbol(acExchage);
    handleCoins()

  }, [activeExchange])


  const exchangeBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <p className="fs-6 mb-0 pink-text">{mobile ? (rowData?.exchange === "NASDAQ" ? "NASD" : (rowData?.exchange === "CRYPTO" ? "CRYPTO" : rowData?.exchange)) : rowData?.exchange}</p>
      </div>
    );
  };

  const currentPriceBodyTemplate = (rowData) => {
    return (
      <>
        <div className="flex align-items-center gap-2"><span>${rowData?.current_price}</span></div>
      </>

    );
  };

  const symbolPocTemplate = (rowData) => {
    return (
      <>
        {rowData.symbol_poc !== '' ? (
          <>  {rowData.symbol_poc >= 0 ? <>{rowData.symbol_poc === '0' ? <p className="text-warning fw-bold cfs-6 mb-0">{rowData.symbol_poc}</p> : <p className="green-text fw-bold cfs-6 mb-0">{rowData.symbol_poc}{rowData.symbol_poc === '0' ? "" : "%"}</p>}</>
            : <p className="text-danger fw-bold cfs-6 mb-0">{rowData.symbol_poc}{rowData.symbol_poc === '0' ? "" : "%"}</p>}</>)

          : "-"

        }
      </>

    );
  };

  //Lazy Loading
  const cars = Array.from({ length: 100000 }).map((_, i) => paidGameData);

  const [virtualCars, setVirtualCars] = useState(Array.from({ length: 5 }));   //100000
  const [lazyLoading, setLazyLoading] = useState(false);


  let loadLazyTimeout = null;


  const loadCarsLazy = (event) => {
    !lazyLoading && setLazyLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //simulate remote connection with a timeout
    loadLazyTimeout = setTimeout(() => {
      let _virtualCars = [...virtualCars];
      let { first, last } = event;

      //load data of required page
      const loadedCars = cars.slice(first, last);

      //populate page of virtual cars
      Array.prototype.splice.apply(_virtualCars, [...[first, last - first], ...loadedCars]);

      setVirtualCars(_virtualCars);
      setLazyLoading(false);
    }, Math.random() * 1000 + 250);
  };

  const loadingTemplate = (options) => {
    return (
      <div className="flex align-items-center" style={{ height: '17px', flexGrow: '1', overflow: 'hidden' }}>
        <Skeleton width={options.cellEven ? (options.field === 'year' ? '30%' : '40%') : '60%'} height="1rem" />
      </div>
    );
  };


  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="custom-card-header">
          <div className="row card-title mx-auto">
            <div className="col-md-7 col-xs-12 d-flex justify-content-end align-items-center top-tankers-title">
              <h4 className="text-uppercase custom-heading mx-3">
                TOP TANKERS
              </h4>
            </div>
            <div className="col-md-5 col-xs-12 d-flex justify-content-end">
              <Dropdown value={activeExchange}
                onChange={(e) => { setActiveExchange((prev) => (prev = e.value)); }}

                options={symbols} optionLabel="name"
                placeholder="Select Exchange" optionValue="exchange_id" className="w-50 top-tankers-dropdown" />
            </div>
          </div>
        </div>
        <DataTable
          className="top-tanker-table"
          value={paidGameData}
          scrollable
          scrollHeight="655px"
          virtualScrollerOptions={{
            
            itemSize: 40,
            delay: 10,

          }}
          emptyMessage="There is no top tankers data available yet!">
          <Column body={NameBodyTemplate} header="Symbol" field="symbol"
            style={{ width: "200px" }} filter
            filterField="symbol"
            filterPlaceholder="Search by name"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}></Column>
          <Column
            field="exchange"
            header={`${mobile ? "Exch" : "Exchange"}`}
            body={exchangeBodyTemplate}
          />
          <Column
            field="current_price"
            header={`${mobile ? "$" : "Price"}`}
            body={currentPriceBodyTemplate}
          />
          <Column
            body={symbolPocTemplate}
            field="symbol_poc"
            header={`${mobile ? "%" : "Change"}`}
          ></Column>
        </DataTable>
        {/* </>)}  */}

      </div>
    </>
  )
}

export default TopLoser