import { Row, Form, Col, FormControl } from "react-bootstrap";
import Footer from "../MainLayout/Footer/Footer";
import Header from "../MainLayout/Header/Header";
import Navbar from "../MainLayout/Navbar/Navbar";
import { useState, forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputNumber } from "primereact/inputnumber";

import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import CreateDuelsForm from "./CreateDuelsForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { ToastContainer } from "react-toastify";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { useDispatch } from "react-redux";
import UserListModel from "./UserListModel";

import {
  createDuelAPI
} from "../../slices/createDuelSlice";
import { useNavigate } from "react-router-dom";
const CreateDuels = () => {
  const {
    duelCreate, setDuelCreate, type, isSending, isSent,
    onChangeDuelHandler, duelType, duelVisibil, duelEntryType,
    noTraders, inValidDate,
    duelMode,
    duelExchange,
    duelTypeList,
    duelTypeComingsonn,
    noTradersList,
    showMoneyList,
    duelVisibilList,
    duelModeList,
    duelExchangeList,
    duelEntryList,
    showMoney, showNewList,
    setShowMoney,
    setDuelType,
    setDuelVisibil,
    setDuelEntryType,
    setDuelExchange,
    setDuelMode,
    setNoTraders,
    handleDuelType,
    handleTotalTrade,
    exchangeList,
    handleDuelVisibility,
    handleDuelMode,
    handleEntryType,
    handleDuelExchange,
    handleShowMoney,
    selectedCountryTemplate,
    countryOptionTemplate,
    prizePoolValue,
    handlePrizePool,
    EntryLimit,
    handleEntryLimit,
    entryAmount,
    handleEntryAmount,
    selectedExchangeOption,
    ExchangeOptionTemplate,
    newhandle, handleChangeRaw1, isWeekday, duelForm, filterPassedTime,
    decrementCount, handleStocksMode, tStartDate, tSetStartDate, handleonFocus, incrementStocks, decrementStocks, disabled, setDisabled, stocksCount, setStocksCount, setStockDisabled, stocksDisabled,
    count, startDate, setStartDate, handleChangeRaw, handleCreateDuel, errors, ExchangeOptionDisable, getExchangeName, setExchangeName, filterExchangeist, duelExchangeModeList, setExchangeMode, exchangeMode
  } = CreateDuelsForm(() => handleContest());
  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <div onClick={onClick}>
        <input
          placeholder="Select Date"
          value={value}
          className="form-control"
      
          onChange={onChange}
          ref={ref}
        ></input>
        <span className="example-custom-input">
          <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
        </span>
      </div>
    </>
  ));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getDuelID,setDuelID] = useState('');
  const [getDuelLink,setDuelLink] = useState('');
  const handleContest = () => { 
    dispatch(createDuelAPI({ duelForm }))
   
      .unwrap()
      .then((res) => { 

        // console.log(res,'duel_id res');
        setDuelID(res.data.duel_id);
        setDuelLink(res.data.duel_link);
        // if(res.data.duel_id && res.data.duel_entry_type === 0) { 
          setModalShow(true);
        // }
        // else {
        //   setTimeout( () => {
        //     navigate("/dashboard");
        //   },1000)
       
        // }
      
      })
      .catch((err) => {
        console.log(err, "errors");
      });
  }

  const [modalShow, setModalShow] = useState(false);

  const groupedItemTemplate = (option) => {
    return (
        <div className="flex align-items-center">
            <div className="labelShow">{option.label}<span className="cominSoon">Coming Soon</span></div>
        </div>
    );
};

const panelFooterTemplate = (option) => {
  return (
    <div className="flex align-items-center">
    <div className="text">{option.name}<span className="cominSoon">Coming Soon</span></div>
    <li>Shoe me money</li>
</div>
)
};
  return (
    <>
      <Navbar />
      <div className="main-layout">
        <div className='container-fluid'>
          <div className='row'>
            <div className='mb-3'>
              <Header />
            </div>
            <div className="col-lg-12">
              <div className="editForm-sec create-duel pb-5">
                <h5 className="editForm-head">Create Duels</h5>
                <div className="px-md-5 py-md-3 p-3">
                  <Form noValidate onSubmit={handleCreateDuel}>
                    <Row>
                      <Col lg={6} xl={3} md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>Duel Name </Form.Label>

                          <FormControl
                            type="text"
                            placeholder="Duel Name"
                            autoComplete="off"
                            id="duelname"
                            name="duel_name"
                            required
                            value={duelCreate.duel_name}
                            onChange={onChangeDuelHandler}
                          />
                          {errors.duel_name && (
                            <div
                              className="help text-danger text-start"
                              aria-live="assertive"
                            >
                              {errors.duel_name}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      {/* <Col lg={6} xl={3} md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>Duel Type</Form.Label>
                          <div>
                            <Dropdown
                              name="duel_type"
                              value={duelType}
                              onChange={handleDuelType}
                              //  optionLabel="label" 
                              optionGroupLabel="label" 
                              // panelFooterTemplate={panelFooterTemplate}
                              optionGroupChildren="items" 
                              optionGroupTemplate={groupedItemTemplate}
                              // this up extra to hide show me my money
                              options={type}
                              optionLabel="name"
                              placeholder="Select duel type"
                            />
                            {errors.duel_type && (
                              <div
                                className="help text-danger text-start"
                                aria-live="assertive"
                              >
                                {errors.duel_type}
                              </div>
                            )}
                          </div>
                        </Form.Group>
                      </Col> */}
                      {/* Show Me Money */}
                      {/* {console.log(type, 'type type type')} */}
                      {/* {duelType?.name === "Show Me The Money" ? (
                        <>
                          <Col lg={6} xl={3} md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label>Show Me Money</Form.Label>
                              <div>
                                <Dropdown
                                  name="show_me_money"
                                  value={showMoney}
                                  onChange={handleShowMoney}
                                  options={showNewList}
                                  optionValue="amount"
                                  optionLabel="curreny_symbols"

                                  valueTemplate={selectedCountryTemplate}
                                  itemTemplate={countryOptionTemplate}

                                  placeholder="Select show me money"
                                />
                              </div>
                              {errors.show_me_money && (
                                <div
                                  className="help text-danger text-start"
                                  aria-live="assertive"
                                >
                                  {errors.show_me_money}
                                </div>
                              )}

                            </Form.Group>
                          </Col>
                        </>
                      ) : (
                        ""
                      )} */}

                      {/* Trader */}

                      {/* {duelType?.name === "Trader" ? ( */}
                        <>
                          <Col lg={6} xl={3} md={6}>
                            <Form.Group className="mb-4">
                            <Form.Label>Duel Type</Form.Label>
                           
                              <div>
                                <Dropdown
                                  name="trader"
                                  value={noTraders}
                                 
                                  onChange={handleTotalTrade}
                                  options={noTradersList}
                                  optionLabel="no_of_trades"
                                  placeholder="Select no. of trades"
                                />
                                {errors.trader && (
                                  <div
                                    className="help text-danger text-start"
                                    aria-live="assertive"
                                  >
                                    {errors.trader}
                                  </div>
                                )}
                              </div>
                            </Form.Group>
                          </Col>
                        </>
                      {/* ) : (
                        ""
                      )} */}

                      <Col lg={6} xl={3} md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>Duel Visibility</Form.Label>

                          <Dropdown
                            name="duel_visibility"
                            value={duelVisibil}
                            onChange={handleDuelVisibility}
                          
                            options={duelVisibilList}
                            optionLabel="name"
                            placeholder="Select duel visibility"
                          />
                          {errors.duel_visibility && (
                            <div
                              className="help text-danger text-start"
                              aria-live="assertive"
                            >
                              {errors.duel_visibility}
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={6} xl={3} md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>Duel Mode</Form.Label>
                          <Dropdown
                            name="duel_mode"
                            value={duelMode}
                            onChange={handleDuelMode}
                         
                            options={duelModeList}
                           
                            optionLabel="name"
                            placeholder="Select duel mode"
                          
                          />
                          {errors.duel_mode && (
                            <div
                              className="help text-danger text-start"
                              aria-live="assertive"
                            >
                              {errors.duel_mode}
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg={6} xl={3} md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>Duel Entry Type</Form.Label>

                          <Dropdown
                            name="duel_entry_type"
                            value={duelEntryType}
                          
                            onChange={handleEntryType}
                            options={duelEntryList}
                            optionLabel="name"
                            placeholder="Select Duel Entry Type"
                          />
                          {errors.duel_entry_type && (
                            <div
                              className="help text-danger text-start"
                              aria-live="assertive"
                            >
                              {errors.duel_entry_type}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    
                      {duelEntryType.name === "Paid" ? (
                        <>
                          <Col lg={6} xl={3} md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label>Enter Amount</Form.Label>
                           
                              <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">$</span>
                                <InputNumber
                                  placeholder="Enter Amount"
                                  autoComplete="off"
                                  id="enteramount"
                                  name="duel_entry_fee"
                                  value={entryAmount}
                                  onValueChange={handleEntryAmount}
                                  required
                                  min={0}
                                  minFractionDigits={2}
                                  maxFractionDigits={5}
                              
                                />
                              </div>
                              {errors.duel_entry_fee && (
                                <div
                                  className="help text-danger text-start"
                                  aria-live="assertive"
                                >
                                  {errors.duel_entry_fee}
                                </div>
                              )}
                            </Form.Group>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      <Col lg={6} xl={3} md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>Duel Exchange</Form.Label>
                          <MultiSelect
                            name="duel_exchange"

                            onChange={
                              handleDuelExchange

                            }

                            value={duelExchange}

                            itemTemplate={ExchangeOptionTemplate}

                            options={exchangeList}
                            optionValue="id"
                            optionLabel="exchange_name"
                            placeholder="Select duel exchange"
                            maxSelectedLabels={3}
                            selectionLimit={2}
                          //  optionDisabled={(option) => option.is_trading_open === 0}
                            onFocus={handleonFocus}

                          />

                          {errors.duel_exchange && (
                            <div
                              className="help text-danger text-start"
                              aria-live="assertive"
                            >
                              {errors.duel_exchange}
                            </div>
                          )}
                        </Form.Group>
                      </Col>

                     
                   

                    

                    
                  
                      {duelExchange?.length === 1 && duelExchange.includes(3) ? (<>

                        <Col lg={6} xl={3} md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label>Duel Start CRYPTO</Form.Label>

                            <DatePicker
                              name="duel_start"
                             

                              selected={startDate}
                          
                              timeInputLabel="Time:"
                              dateFormat="MM/dd/yyyy h:mm aa"
                              timeIntervals={15}
                              timeZone="America/New_York"
                            
                              showTimeSelect
                              onChange={(duel_start1) => {
                                setStartDate(duel_start1);

                                let duel_start = duel_start1.toISOString();

                             

                                setDuelCreate({ ...duelCreate, duel_start })
                                setDisabled(false);

                              }}

                            
                              onChangeRaw={(event) =>
                                handleChangeRaw(event.target.value)
                              }
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              minDate={new Date()}
                              filterTime={filterPassedTime}
                           
                              disabledKeyboardNavigation
                              customInput={<ExampleCustomInput />}
                              dayClassName={() =>
                                "example-datepicker-day-class"
                              }
                              popperClassName="example-datepicker-class"
                            />
                            {errors.duel_start && (
                              <div
                                className="help text-danger text-start"
                                aria-live="assertive"
                              >
                                {errors.duel_start}
                              </div>
                            )}

                          </Form.Group>
                        </Col>

                        <Col lg={6} xl={3} md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label>Duel Duration</Form.Label>
                            <div className="p-inputgroup flex-1">
                              <Button
                                icon="pi pi-minus" type="button"
                                className="p-button-success1 p-inputgroup-addon" onClick={decrementCount}
                              />
                              <FormControl className="rounded-0"
                                type="text"
                                placeholder="0:0"
                                autoComplete="off"
                                id="duel_duration"
                                name="duel_duration"
                                required
                                value={count}
                              
                              />

                           
                              <Button type="button" disabled={disabled}
                                icon="pi pi-plus" onClick={newhandle}
                                className="p-button-danger1 p-inputgroup-addon"
                              />

                            </div>
                            {errors.duel_duration && (
                              <div
                                className="help text-danger text-start"
                                aria-live="assertive"
                              >
                                {errors.duel_duration}
                              </div>
                            )}
                          
                          </Form.Group>
                        </Col>

                      </>)
                        // : exchangeMode === "Stocks" ? 
                        : (<>
                 
                          <Col lg={6} xl={3} md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label>Duel Start Trade</Form.Label>

                              <DatePicker
                                name="duel_start_trader"

                                
                                selected={tStartDate}
                                filterDate={isWeekday}
                                filterTime={filterPassedTime}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                               
                                timeIntervals={15}
                              
                                showTimeSelect
                              
                                onChange={(duel_start1) => {
                                  tSetStartDate(duel_start1);

                                  let duel_start_trader = duel_start1.toISOString();
                             

                                  setDuelCreate({ ...duelCreate, duel_start_trader: duel_start_trader })
                                  setStocksCount('0:0');
                                  setStockDisabled(false);

                                }}

                            
                                excludeDates={inValidDate}
                                minDate={new Date()}
                                minTime={setHours(setMinutes(new Date(), 30), 9)}
                                maxTime={setHours(setMinutes(new Date(), 0), 16)}
                                onChangeRaw={(event) =>
                                  handleChangeRaw1(event.target.value)
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"

                              
                                disabledKeyboardNavigation
                                customInput={<ExampleCustomInput />}
                                dayClassName={() =>
                                  "example-datepicker-day-class"
                                }
                                popperClassName="example-datepicker-class"
                              />
                              {errors.duel_start_trader && (
                                <div
                                  className="help text-danger text-start"
                                  aria-live="assertive"
                                >
                                  {errors.duel_start_trader}
                                </div>
                              )}

                            </Form.Group>
                          </Col>

                          <Col lg={6} xl={3} md={6}>
                            <Form.Group className="mb-4">
                              <Form.Label>Duel Duration Stocks</Form.Label>
                              <div className="p-inputgroup flex-1">
                                <Button
                                  icon="pi pi-minus" type="button"
                                  className="p-button-success1 p-inputgroup-addon" onClick={decrementStocks}
                                />
                              
                                <FormControl className="rounded-0"
                                  type="text"
                                  placeholder="0:0"
                                  autoComplete="off"
                                  id="duel_duration_stock"
                                  name="duel_duration_stock"
                                  required
                                  value={stocksCount}
                               
                                />

                               
                                <Button type="button" disabled={stocksDisabled}
                                  icon="pi pi-plus" onClick={incrementStocks}
                                  className="p-button-danger1 p-inputgroup-addon"
                                />

                              </div>
                              {errors.duel_duration_stock && (
                                <div
                                  className="help text-danger text-start"
                                  aria-live="assertive"
                                >
                                  {errors.duel_duration_stock}
                                </div>
                              )}
                           
                            </Form.Group>
                          </Col>
                        </>
                        )

                        // : ""

                      }

                      <Col lg={6} xl={6} md={6}>
                        <Form.Group className="">
                          <Form.Label>Duel Description</Form.Label>
                          <InputTextarea
                            name="duel_description"
                            onChange={onChangeDuelHandler}
                            rows={3}
                            cols={30}
                            placeholder="Duel Description" autoResize 
                          />
                        </Form.Group>
                        {errors.duel_description && (
                          <div
                            className="help text-danger text-start"
                            aria-live="assertive"
                          >
                            {errors.duel_description}
                          </div>
                        )}
                      </Col>

                    
                      <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 6, offset: 3 }}
                        className="text-center mb-5 mt-4"
                      >
                        <Button
                          type="submit"
                          className="btn-form" disabled={isSending}
                     
                       // onClick={() => setModalShow(true)}
                        >
                          Submit
                        </Button>
                      
                        <UserListModel show={modalShow} onHide={() => { setModalShow(false); navigate('/dashboard') }}  getduelID = {getDuelID} getDuellink={getDuelLink}/>

                        {isSending && <p className="text-info fw-bold">Please wait...</p>}
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
};
export default CreateDuels;



 

