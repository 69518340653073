import { VideoPlusIcon } from "../../SVGIcon"
import AddVideo from "../User/AddVideo";
import VideoScroll from "./VideoScroll"
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUsersVideoAPI } from '../../slices/video&postSlice.js';

const UploadVideos = () => {
    const [modalShow, setModalShow] = useState(false);

    let locacluserid = window.localStorage.getItem("userid");
    const [getVideolist, setVideoList] = useState([]);
    const dispatch = useDispatch();
    const handleAllVideo = async () => {

        await dispatch(getUsersVideoAPI({ userId: locacluserid }))
            .unwrap()
            .then((res) => {
                let videolist = res?.data;
                setVideoList(videolist);
            })
    }
    useEffect(() => {
        handleAllVideo();
    }, [dispatch])

    return (
        <>
            <div className="card card-custom gutter-b ">
                <div className="custom-card-header">
                    <div className='d-flex justify-content-end align-items-center card-title'>
                        <div className='mx-auto '><h4 className='text-center text-uppercase custom-heading'>Upload Video</h4></div>
                        <button className="btn fs-5 fw-bolder" onClick={() => setModalShow(true)}><span className="me-2"><VideoPlusIcon /></span>Add Video</button>
                    </div>
                </div>
                <div className="card-body">
                    <VideoScroll getusersvideolist={getVideolist} handlevideolist={handleAllVideo} dispatch={dispatch} />
                </div>
            </div>
            <AddVideo show={modalShow} setModalShow={setModalShow} onHide={() => setModalShow(false)} handleAllVideo={handleAllVideo} />
        </>
    )
}

export default UploadVideos

