import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from 'primereact/skeleton';
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { invitesUserList, isFetching, dashboardSelector } from "../../slices/dashboardSlice";
import { startTimer } from "../../util/common"
import moment from "moment-timezone";
import { Tag } from 'primereact/tag';
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

const Invitees = () => {
  const dispatch = useDispatch();
  const [invitedDuelsData, setInvitedDuelsData] = useState([]);
  let locacluserid = window.localStorage.getItem("userid");
  const userLogin = parseInt(locacluserid);
  useEffect(() => {
    //  setFreeGame('');
    dispatch(invitesUserList({ userId: locacluserid }))
      .unwrap()
      .then((res) => {
        // console.log(res,'invitesUserList');
        setInvitedDuelsData(res);
      });
  }, [dispatch]);
  const { isFetching, inviteUserResponse } = useSelector(dashboardSelector);
  // console.log(inviteUserResponse,'invitesUserList');
  // console.log(invitedDuelsData,'invitedDuelsData');

  const cars = Array.from({ length: 100000 }).map((_, i) => invitedDuelsData);
  const [virtualCars, setVirtualCars] = useState(Array.from({ length: 100000 }));   //100000
  const [lazyLoading, setLazyLoading] = useState(false);
  let loadLazyTimeout = null;

  const loadCarsLazy = (event) => {
    !lazyLoading && setLazyLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //simulate remote connection with a timeout
    loadLazyTimeout = setTimeout(() => {
      let _virtualCars = [...virtualCars];
      let { first, last } = event;

      //load data of required page
      const loadedCars = cars.slice(first, last);

      //populate page of virtual cars
      Array.prototype.splice.apply(_virtualCars, [...[first, last - first], ...loadedCars]);

      setVirtualCars(_virtualCars);
      setLazyLoading(false);
    }, Math.random() * 1000 + 250);
  };

  const loadingTemplate = (options) => {
    return (
      <div className="flex align-items-center" style={{ height: '17px', flexGrow: '1', overflow: 'hidden' }}>
        <Skeleton width={options.cellEven ? (options.field === 'year' ? '30%' : '40%') : '60%'} height="1rem" />
      </div>
    );
  };

  const NameBodyTemplate = (rowData) => {
    return (
      <div className="d-flex ">
        <p className="fs-5 bg-light-blue p-2 mb-0 pro-head">
          {rowData.duel_mode === "Bloodbath"
            ? "B"
            : rowData.duel_mode === "Standard"
              ? "S"
              : rowData.duel_mode === "Bloodbath & Battle" ? "BB"
                : rowData.duel_mode === "Standard & Battle" ? "SB" : ''
          }
        </p>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    const startTime = rowData.duel_start_time_utc;
    let araryofTime = rowData;

    let check12hr = moment(startTime).format('MM/DD/YYYY h:mm:ss A');
    setStartTime(startTime);
    return (
      <>
        <div className="flex align-items-center gap-2">
          <p className='fs-6 mb-0 text-uppercase text-truncate'>{rowData.duel_name}</p>
          <span className="bg-gray duel-summary text-truncate">{rowData.duel_description}</span>
        </div>
        <div id={check12hr}>
          <span className='timer'>
            {eventTimer(rowData.duel_start_time_utc)}</span>
        </div>
      </>
    );
  };
  const topScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const buttonBodyTemplate = (rowData) => {

    const joinedUser = rowData?.joined_by?.find((element) => element === userLogin);

    return (
      <div id={rowData.duel_id}>
         {joinedUser === userLogin && rowData.duel_entry_type === "Free" ? (<>
          <Link className="duel-btn text-decoration-none white-space disable-link" to={`/enter-duel/${rowData.duel_id}`} onClick={topScroll} >Joined</Link>
         </>) :(<>
          <Link className="duel-btn text-decoration-none white-space" to={`/enter-duel/${rowData.duel_id}`} onClick={topScroll}>ENTER DUEL</Link>
        </>)
        }

       
      </div>
    );
  };

  const [sTime, setStartTime] = useState("");
  const [count, setCount] = useState(false);
  var timeInterval;
  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownMinutes: "",
    countdownSeconds: "",
  });

  const eventTimer = (evntString) => {
    let countdownDateTime = new Date(`${evntString}`).getTime();
    let nowDate = new Date().toISOString();
    let newtime = new Date(nowDate);
    let currentTime = new Date(nowDate).getTime();

    const remainingDayTime = countdownDateTime - currentTime;
    const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));

    const totalHours = Math.floor(remainingDayTime / (1000 * 60 * 60));

    const totalMinutes = Math.floor((remainingDayTime / 1000 / 60) % 60);
    const totalSeconds = Math.floor((remainingDayTime / 1000) % 60);

    let remainingHours = `${totalHours}H ${totalMinutes}M ${totalSeconds}S`;
    setCountdownTime(remainingHours);
    if (remainingDayTime <= -countdownDateTime) {
      clearInterval(timeInterval);
      return;
    }
    if (remainingDayTime <= 0) {
      //return remainingHours = "00:00:00";

      clearInterval(timeInterval);
      return ("Event started");
    }
    return remainingHours;
  }

  useEffect(() => {
    let intervalId = setInterval(() => {
      eventTimer();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [sTime]);
  const timerTemplate = (rowData) => {
    const startTime = rowData.duel_start_time_utc;
    let araryofTime = rowData;

    let check12hr = moment(startTime).format('MM/DD/YYYY h:mm:ss A');
    setStartTime(startTime);
    return (<>
      <div id={check12hr}>
        <span className='timer'>
          {eventTimer(rowData.duel_start_time_utc)}</span>
      </div>
    </>)
  }
  //Sorting
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState(0);
  const [sortField, setSortField] = useState('');
  const sortOptions = [
    { label: 'Price High to Low', value: 'Trader' },
    { label: 'Price Low to High', value: 'Show Me The Money' }
  ];

  const onSortChange = (event) => {
    const value = event.value;

    if (value.indexOf('!') === 0) {
      setSortOrder(-1);
      setSortField(value.substring(1, value.length));
      setSortKey(value);
    } else {
      setSortOrder(1);
      setSortField(value);
      setSortKey(value);
    }
  };

  const [statuses] = useState([
    'Standard',
    'Blood Bath',
    'Standard - Battle',
    'Blood Bath - Battle'
  ]);
  const statusBodyTemplate = (rowData) => {
    return (
      (
        <>
          <div className="list-tag">
            <Tag value={rowData.duel_type}
            />
          </div>
        </>
      )

    );
  };
  const statusItemTemplate = (option) => {
    return (
      <>
        <div className="list-tag">

          <Tag value={option} />
        </div>
      </>
    )
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <>
        <Dropdown
          value={options.value}
          options={statuses}
          onChange={(e) => options.filterApplyCallback(e.value)}
          itemTemplate={statusItemTemplate}
          placeholder="Select One"
          className="p-column-filter"
          showClear
          style={{ minWidth: '12rem', width: '12rem' }}
        />
      </>
    );
  };
  return (
    <>
      <div className='card-body upcoming-card'>
        {isFetching === true ? (<><p className="pink-text fw-bolder">Please wait</p></>)
          : (
            <>
              <DataTable
                filter sortField="duel_start_time_utc" sortOrder={-1}
                filterBy="duel_type"
                // rowGroupHeaderTemplate="duel_type"
                // filters={filters}
                filterDisplay="menu"
                value={inviteUserResponse}
                scrollable
                scrollHeight="572px"
                // virtualScrollerOptions={{
                //   lazy: true,
                //   onLazyLoad: loadCarsLazy,
                //   itemSize: 6,
                //   delay: 200,
                //   showLoader: true,
                //   loading: lazyLoading,
                //  loadingTemplate,
                // }}
                // tableStyle={{ minWidth: "50rem" }}
                className="invitees-games-table"
                emptyMessage="There is no invite duel data available yet!"
              >
                {/* <Column body={NameBodyTemplate} header="Duel Mode" filterField="duel_mode"   showFilterMatchModes={false}
           showFilterMenuOptions={false}  */}

                {/* //filter filterPlaceholder="Search by duel mode" field="duel_mode"
           
            //  filter
          //filterElement={statusRowFilterTemplate} 
        //  showFilterMatchModes={false} 
        //  showFilterMenuOptions={false}
        //  showClearButton={false}
        //  showApplyButton={false} */}
                {/* ></Column> */}
                <Column
                  body={countryBodyTemplate} filterField="duel_name" showFilterMatchModes={false}
                  showFilterMenuOptions={false}
                  className="invitees-duel-column"
                  style={{ width: '40%' }}
                  header="Duel Name" filter filterPlaceholder="Search by duel name" field="duel_name"></Column>
                {/* <Column body={timerTemplate} header="Duel Start Time" sortable field="duel_start_time_utc"></Column> */}
                <Column field="duel_type" header="Duel Type" sortable
                  body={statusBodyTemplate}
                />
                <Column body={buttonBodyTemplate} ></Column>
                <Column field="name" filterPlaceholder="Search"></Column>
              </DataTable>
            </>
          )
        }
      </div>
    </>
  )
}

export default Invitees