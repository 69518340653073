import React from 'react';
import { DataTable } from "primereact/datatable";
import { baseURL } from "../../config";
import { Column } from "primereact/column";

const UserComparision = ({ anotherSymbolsList, show, multiUser }) => {
  const logoBodyTemplate = (rowData) => {
    return (
      <>{rowData.logo ? (<img src={rowData.logo} className="img-fluid img-coins" />) : (<img src='/images/bitcoin-icon.png' />)}</>
    )
  }
  const SymbolBodyTemplate = (rowData) => {
    return (
      <>
        <p className="mb-0 duel-heading cfs-6">{rowData.base_symbol}</p>
      </>
    )
  }

  const priceBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.percentage_change !== '' ? (
          <>  {rowData.percentage_change >= 0 ? <>{rowData.percentage_change === '0' ? <p className="text-warning fw-bold cfs-6 mb-0">{rowData.percentage_change}</p> : <p className="green-text fw-bold cfs-6 mb-0">{rowData.percentage_change}</p>}</>
            : <p className="text-danger fw-bold cfs-6 mb-0">{rowData.percentage_change}</p>}</>)

          : "-"

        }

      </>
    )
  }
  return (
    <>
      <div className="card card-custom gutter-b px-0">
        <div className="custom-card-header">
          <div className="d-flex justify-content-end card-title mb-0">
            <div className="mx-auto ">
              <h4 className="text-uppercase custom-heading">Comparison </h4>
            </div>
          </div>
          <div>
            <div className="d-flex w-100 ">
              {show
                ? (<>
                  <div
                    className="w-100 shadow">
                    <div className="d-flex w-100">
                      <div className="w-100 shadow">
                        <div className="bg-gray-back p-3">
                          <div className="d-flex justify-content-center align-items-center">
                            {multiUser.user_avatar !== "" ? (<>
                              <img src={`${baseURL}images/avatar/${multiUser?.user_avatar}`} className="img-fluid img-symbols" />

                            </>) : (<>
                              <img src="/icon/default-profile.png" className="img-fluid img-symbols" />

                            </>)}

                            <h5 className="mb-0 mt-0 ms-1">{multiUser?.user_name}</h5>
                          </div>
                        </div>
                        <div className="custom-active">
                          <DataTable
                            value={anotherSymbolsList} rows={10}
                            scrollable
                            tableStyle={{ minHeight: '35rem' }}
                            emptyMessage="No symbols found."
                          >

                            <Column field="logo" body={logoBodyTemplate} ></Column>
                            <Column field="base_symbol"
                              header="Symbol"
                              body={SymbolBodyTemplate}>
                            </Column>
                            <Column field="tier" header="Tier"></Column>
                            <Column
                              body={priceBodyTemplate} header="%"></Column>
                          </DataTable>
                        </div>
                        <div className="text-black cfs-6 fw-bold mb-0 d-flex justify-content-center py-4">Score: <span className="text-danger ms-2">
                          {multiUser?.score}</span></div>
                      </div>
                    </div>
                  </div>
                </>
                ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserComparision