import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "../config";
import authHeader from "../util/Authheader";
import axios from "axios";
import {toast} from 'react-toastify'


//Get Duel Exchange
export const getDuelExchange = createAsyncThunk(
 "api/duel/get-duel-exchange",
  async () => {
   
    const url = `${baseURL}api/duel/get-duel-exchange`;
    return await axios
      .get(url, { headers: authHeader() })
      .then((response) => {
        let data = response.data.data;
     // console.log(response.data, "get-duel-exchange");
        return data;
      })
      .catch((err) => {
        console.log(err, "get-duel-exchange error");
      });
  }
);

//Get All Duel Mode


export const getDuelMode = createAsyncThunk(
    "api/duel/get-duel-mode",
     async () => {
      
       const url = `${baseURL}api/duel/get-duel-mode`;
       return await axios
         .get(url, { headers: authHeader() })
         .then((response) => {
           let data = response.data.data;
        // console.log(response.data, "get-duel-mode");
           return data;
         })
         .catch((err) => {
           console.log(err, "get-duel-mode error");
         });
     }
   );

//Get All Show me money
export const getShowMeMoney = createAsyncThunk(
  "api/duel/show-me-money",
   async () => {
    
     const url = `${baseURL}api/duel/show-me-money`;
     return await axios
       .get(url, { headers: authHeader() })
       .then((response) => {
         let data = response.data.data;
     //  console.log(response.data, "getShowMeMoney");
         return data;
       })
       .catch((err) => {
         console.log(err, "show-me-money error");
       });
   }
 );
//Get No. of Traders   api/duel/get-no-of-traders
export const getTraders = createAsyncThunk(
    "api/duel/get-no-of-traders",
     async () => {
      
       const url = `${baseURL}api/duel/get-no-of-traders`;
       return await axios
         .get(url, { headers: authHeader() })
         .then((response) => {
           let data = response.data.data;
        // console.log(response.data, "get-no-of-traderse");
           return data;
         })
         .catch((err) => {
           console.log(err, "get-no-of-traderse error");
         });
     }
   );


   // Create Duel POST api/duel/create-duel


   export const createDuelAPI = createAsyncThunk(
    "api/duel/create-duel",
    async (args, thunkAPI) => {   
      const urlname = `${baseURL}api/duel/create-duel`;
     try {
        const response = await axios({ 
          method: "post",
          url: urlname,
          data: args.duelForm,
           headers: {
          
            ...authHeader(),
              
           },
        });
    
        let data = response.data;
       
        if (data.status_code === 400) {
        
          toast.error(data.message);
          return Promise.reject(data);
        } else {
          toast.success(data.message);
          //console.log(data,'success data');
          return data;
        }
    
     
      } catch (error) {
        console.log(error)
     
          if (error.response) {
            const message = error.response.data.message;
            return thunkAPI.rejectWithValue(message);
          }
      
      }
    }
    );


    //Get stocks Holidays list
    
    export const getStocksHolidays = createAsyncThunk(
      "api/duel/get-trading-holidays-calendar",
       async () => {
        
         const url = `${baseURL}api/duel/get-trading-holidays-calendar`;
         return await axios
           .get(url, { headers: authHeader() })
           .then((response) => {
             let data = response.data.data;
         //  console.log(response.data, "get-no-of-traderse");
             return data;
           })
           .catch((err) => {
             console.log(err, "get-no-of-traderse error");
           });
       }
     );
  

const createDuelSlice = createSlice({
  name: "createDuel",
  initialState: {
    exchangeList: [],
    duelModeList:[],
    noTradersList:[],
    showMoneyList:[],
    stocksHolidayList:[],
    status: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDuelExchange.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDuelExchange.fulfilled, (state, action) => {
        state.exchangeList = action.payload;
        state.status = "success";
      })
      .addCase(getDuelExchange.rejected, (state, action) => {
        state.status = "failed";
      })

      // Get Duel Mode List 
      .addCase(getDuelMode.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDuelMode.fulfilled, (state, action) => {
        state.duelModeList = action.payload;
        state.status = "success";
      })
      .addCase(getDuelMode.rejected, (state, action) => {
        state.status = "failed";
      })

      //Get Traders listStyle: 
     
      .addCase(getTraders.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getTraders.fulfilled, (state, action) => {
        state.noTradersList = action.payload;
        state.status = "success";
      })
      .addCase(getTraders.rejected, (state, action) => {
        state.status = "failed";
      })

      //Get Show Me Money
      
      .addCase(getShowMeMoney.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getShowMeMoney.fulfilled, (state, action) => {
        state.showMoneyList = action.payload;
        state.status = "success";
      })
      .addCase(getShowMeMoney.rejected, (state, action) => {
        state.status = "failed";
      })

      //Create Duel list

      
      .addCase(createDuelAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createDuelAPI.fulfilled, (state, action) => {
        state.showMoneyList = action.payload;
        state.status = "success";
      })
      .addCase(createDuelAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //getStocksHolidays 
      .addCase(getStocksHolidays.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getStocksHolidays.fulfilled, (state, action) => {
        state.stocksHolidayList = action.payload;
        state.status = "success";
      })
      .addCase(getStocksHolidays.rejected, (state, action) => {
        state.status = "failed";
      })

  },
});

export default createDuelSlice.reducer;
