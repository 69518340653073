
import { useState, useEffect } from 'react';
import UserChatComponent from "./UserChatComponent";
import { InputText } from 'primereact/inputtext';
import { useDispatch } from "react-redux";
import { getchatFriendsOnlyAPI, chatToFriendsAPI, clearAllChatAPI } from "../../slices/chatSlice";
import { Button, Form } from 'react-bootstrap';
import { getFriendListAPI } from '../../slices/userSlice';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
const UserChat = ({ singleUser }) => {
  const dispatch = useDispatch();
  let locacluserid = window.localStorage.getItem("userid");
  const [getFrndsChat, setFriendChats] = useState([]);
  const [FrindList, setFrindList] = useState([]);
  const [showChatConditionally, setshowChatConditionally] = useState();
  const [chatValidate, setChatValidate] = useState(false);

  const { pathname } = useLocation();

  const handleFriendAPI = async () => {
    await dispatch(getFriendListAPI({ userId: locacluserid }))
      .unwrap()
      .then((res) => {
        setFrindList(res?.data);
      })
  }

  useEffect(() => {
    handleFriendAPI();
  }, [])



  const handleAllFriendsChat = () => {
    dispatch(getchatFriendsOnlyAPI({ userId: locacluserid })).unwrap()
      .then((res) => {
        let friendsData = res.data;
        setFriendChats(friendsData);
      })
  }

  useEffect(() => {
    handleAllFriendsChat();
  }, [])

  const showChat = () => {
    if (FrindList?.find(e => e?.friend_user_name === singleUser?.user_name)) {
      return true;
    } else {
      return false;
    }
  }
  const conditionallyRenderChat = showChat();

  const [value, setValue] = useState("");
  const onChange = (e) => {
    const html = e.target.innerHTML;
    setValue(html);
  };

  const specialCharacters = ['#', '%', '^', '&', '*', '`', '~', '_', '=', '+', '<', '>'];
  const regexPattern = new RegExp('[' + specialCharacters.join('') + ']', 'g');
  const matches = value.match(regexPattern);

  const handleSubmitChat = async (e) => {
    e.preventDefault();
    if (value?.length < 250 && matches === null) {
      await dispatch(chatToFriendsAPI({ loggedin_user_id: parseInt(locacluserid), message: value })).unwrap().then((res) => {
        let chat_res = res;
        setValue('')
      }).catch((err) => console.log(err));
      await handleAllFriendsChat();
    }
  }
  const [duelData, setDuelData] = useState([]);

  const handleClearChat = () => {
    let clearMsg = {
      user_id: parseInt(locacluserid)
    };
    console.log(clearMsg, "clearMsgclearMsg");
    dispatch(clearAllChatAPI({ clearMsg }))
      .unwrap()
      .then((res) => {
        const data = res;
        console.log(data, "datadatadata");
        setDuelData(data);

      })
      .catch((err) => console.log(err));
  }


  return (
    <>
      <div className="card card-custom gutter-b user-chat-tab">
        <div className="custom-card-header">
          <div className='card-title mb-0 '>
            <div className="d-flex  justify-content-end card-title user-custom-title">
              <h4 className="card-label mx-auto text-center custom-heading d-inline user-custom-heading">USER CHAT</h4>
            </div>
          </div>
        </div>
        <div className="card-body-dark">
          <Form onSubmit={handleSubmitChat}>
            {pathname === "/profile" ? (<><div className="d-flex align-items-center p-3">
              {conditionallyRenderChat ? <><div className="me-3">
                <img src="/icon/default-profile.png" className="img-fluid" style={{ width: "50px" }} />
              </div>
                <div className='position-relative w-100'>
                  <InputText type="text" placeholder="Add a public comment" className="w-100" value={value} onChange={(e) => setValue(e.target.value)} />
                  <Button type="submit" className='chat-submit'>Submit</Button>
                </div>
              </>
                : ""}

            </div>
              <div className='text-start mb-3' style={{ marginLeft: "5rem" }}>
                <strong className={(value?.length >= 250) ? "p-invalid text-bold" : "p-invalid d-none"} style={{ color: "#dc3545" }}>Please Enter less than 250 Characters.</strong>
                <strong className={(matches !== null) ? "p-invalid text-bold" : "p-invalid d-none"} style={{ color: "#dc3545" }}>Special Characters are not allowed</strong>
              </div>
            </>) : (
              <>
                <div className="d-md-flex align-items-center p-3 justify-content-between">
                  <div className="col-md-10 col-12 d-flex align-items-center">
                    <div className="me-3">
                      <img src="/icon/default-profile.png" className="img-fluid" style={{ width: "50px" }} />
                    </div>
                    <div className="custom-search">
                      <InputText type="text" className="custom-search-input w-100" placeholder="Add a public comment" value={value} onChange={(e) => setValue(e.target.value)} />
                      <Button className="custom-search-botton" type="submit">Submit</Button>
                    </div>
                  </div>
                  <div className='col-md-2 col-12  btn-clear'>
                    <Button type="submit" className='clear-submit' onClick={handleClearChat}>Clear All</Button>
                  </div>
                </div>
                <div className='text-start mb-3' style={{ marginLeft: "5rem" }}>
                  <strong className={(value?.length >= 250) ? "p-invalid text-bold" : "p-invalid d-none"} style={{ color: "#dc3545" }}>Please Enter less than 250 Characters.</strong>
                  <strong className={(matches !== null) ? "p-invalid text-bold" : "p-invalid d-none"} style={{ color: "#dc3545" }}>Special Characters are not allowed</strong>
                </div>
              </>
            )
            }
          </Form>

          <p className="text-start ms-5">Friends Only</p>
          <div className='auto-scroll-chat'>
            <UserChatComponent getFrndsChat={getFrndsChat} />
          </div>

        </div>
        <ToastContainer />
      </div>
    </>
  )
}
export default UserChat