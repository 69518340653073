import React, { useState, useEffect } from "react";
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from "react-redux";
import { getCoinsAPI, getBloodBathCoinsAPI } from "../../slices/enterJoinSlice";
import { DataTable } from "primereact/datatable";
import { InputText } from 'primereact/inputtext';
import { Column } from "primereact/column";
import { Skeleton } from 'primereact/skeleton';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ButtonGroup, Button } from "react-bootstrap";

const ActiveExchange = () => {
  
  const [activeExchange, setActiveExchange] = useState('1');
  const [symbol, setSymbol] = useState([{ name: 'NYSE', exchange_id: '1' }]);
  const acExchage = [

    { name: 'NYSE', exchange_id: '1' },
    { name: 'NASDAQ', exchange_id: '2' },
    { name: 'Crypto', exchange_id: '3' }
  ];

  const [activeMode, setActiveMode] = useState('standard_mode')
  const [duelModes, setDuelsModes] = useState([{ name: 'Standard', id: "standard_mode" }]);


  const mapDuelModes = [
    { name: 'Standard', id: "standard_mode" }, { name: 'BloodBath', id: 'bloodbath_mode' }
  ]

  const [getEachCoin, setEachCoin] = useState([]);
  const [filterResult, setFilterResult] = useState([])
  const dispatch = useDispatch();
  const [activeTier, setActiveTier] = useState();
  //Make button Active
  const tierList = [
    {
      id: '1', tierval: 'A'

    },
    {
      id: '2', tierval: 'B'

    },
    {
      id: '3', tierval: 'C'

    },
  ]
  //Filter
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      base_symbol: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },

    });
    setGlobalFilterValue('');
  };

  const handleCoins = (currentVal) => {
    let exchangeId = {
      exchange_id: [currentVal],
    };
    if (activeMode === "standard_mode") {
      dispatch(getCoinsAPI({ exchangeId }))
        .unwrap()
        .then((res) => {

          let coinsData = res.data;
          let filterDataD = coinsData.filter((curElem) =>
            curElem.tier !== "D"
          )


          setEachCoin(filterDataD);
          setFilterResult(filterDataD)

          setActiveTier('All');
          setLoading(false);



        })
        .catch((err) => {
          console.log(err, "errors");
        });

        

    }
    else {
      dispatch(getBloodBathCoinsAPI({ exchangeId }))
        .unwrap()
        .then((res) => {

          let coinsData = res.data;
          let filterDataD = coinsData.filter((curElem) =>
            curElem.tier !== "D"
          )



          setEachCoin(filterDataD);
          setFilterResult(filterDataD)

          setActiveTier('All');
          setLoading(false);



        })
        .catch((err) => {
          console.log(err, "errors");
        });

    }

  }
  useEffect(() => {
    setSymbol(acExchage);
    handleCoins(activeExchange)

  }, [activeExchange])


  useEffect(() => {
    setDuelsModes(mapDuelModes);
    handleCoins(activeExchange)

  }, [activeMode])


  const filterItem = (cateItem) => {
    const updatedItem = getEachCoin.filter((curElem) =>
      curElem.tier === cateItem
    )
    setFilterResult(updatedItem);

  }


  //Lazy Loading
  const cars = Array.from({ length: 50 }).map((_, i) => getEachCoin);

  const [virtualCars, setVirtualCars] = useState(Array.from({ length: 5 }));   //100000
  const [lazyLoading, setLazyLoading] = useState(false);


  let loadLazyTimeout = null;


  const loadCarsLazy = (event) => {
    !lazyLoading && setLazyLoading(true);


    //simulate remote connection with a timeout
    loadLazyTimeout = setTimeout(() => {
      let _virtualCars = [...virtualCars];
      let { first, last } = event;

      //load data of required page
      const loadedCars = cars.slice(first, last);

      //populate page of virtual cars
      Array.prototype.splice.apply(_virtualCars, [...[first, last - first], ...loadedCars]);

      setVirtualCars(_virtualCars);
      setLazyLoading(false);
    }, 0);
  };

  const loadingTemplate = (options) => {
    return (
      <div className="flex align-items-center" style={{ height: '17px', flexGrow: '1', overflow: 'hidden' }}>
        <Skeleton width={options.cellEven ? (options.field === 'year' ? '30%' : '40%') : '60%'} height="1rem" />
      </div>
    );
  };


  const logoBodyTemplate = (rowData) => {
    return (
      <>{rowData.logo ? (<img src={rowData.logo} className="img-fluid img-coins" />) : (<img src='/images/bitcoin-icon.png' />)}</>
    )
  }

  const SymbolBodyTemplate = (rowData) => {
    return (
      <>
        <p className="mb-0 duel-heading cfs-6">{rowData.base_symbol}</p>
        <p className="mb-0 duel-heading cfs-6 text-truncate" style={{ width: "80px" }}>{rowData.name_short}</p>
      </>
    )
  }

  const priceBodyTemplate = (rowData) => {
    return (
      <>
        <p className="mb-0  dark-blue">${rowData.current_price}</p>
      </>
    )
  }

  const pocBodyTemplate = (rowData) => {

    return (
      <>
        {rowData.pct_change_factor !== '' ? (
          <>  {rowData.pct_change_factor >= 0 ? <>{rowData.pct_change_factor === 0 ? <p className="text-warning fw-bold cfs-6 mb-0">{rowData.pct_change_factor}</p> : <p className="green-text fw-bold cfs-6 mb-0">{rowData.pct_change_factor}{rowData.pct_change_factor === 0 ? "" : "%"}</p>}</>
            : <p className="text-danger fw-bold cfs-6 mb-0">{rowData.pct_change_factor}{rowData.pct_change_factor === 0 ? "" : "%"}</p>}</>)

          : "-"

        }


      </>
    )
  }

  const renderHeader = () => {
    return (
      <div className="row gy-3 active-exchange">


        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 text-start">
          <div className="dropbox">
            <Dropdown value={activeMode}
              onChange={(e) => { setActiveMode((prev) => (prev = e.value)); }}

              options={duelModes} optionLabel="name"
              placeholder="Select Duel Mode" optionValue="id" />
          </div>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 text-start">
          <div className="dropbox">
            <Dropdown value={activeExchange}
              onChange={(e) => { setActiveExchange((prev) => (prev = e.value)); }}

              options={symbol} optionLabel="name"
              placeholder="Select Exchange" optionValue="exchange_id" />
          </div>
        </div>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 justify-content-end d-flex">

          <ButtonGroup aria-label="First group">
            <Button
              className={`btn btn-group active ${activeTier === 'All' ? "activeBtn" : ""}`}
              onClick={() => { setFilterResult(getEachCoin); setActiveTier('All'); }}
            >
              All
            </Button>

            {tierList.map((c, i) => {
              return (
                <>
                  <Button key={i} id={c.id}
                    className={`btn btn-group ${activeTier === c.id ? "activeBtn" : ""}`}
                    onClick={(e) => { filterItem(c.tierval); setActiveTier(e.target.id); }}
                  >
                    {c.tierval}
                  </Button>
                </>
              )
            })}


          </ButtonGroup>
        </div>
        <div className="col-xxl-12 col-xl-12 col-12">
          <div className="d-grid search-input">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  useEffect(() => {


    initFilters();
  }, []);

  console.log(typeof window !== 'undefined')
  return (
    <>
      <div className="card card-custom gutter-b tab-content">
        <div className="custom-card-header">
          <div className="card-title text-center mb-0">
            <h4 className="card-label align-items-center custom-heading d-inline">ACTIVE EXCHANGE</h4>
          </div>

        </div>

        <div className="custom-active">
          <DataTable
            value={filterResult} rows={300} loading={loading}
            filters={filters} globalFilterFields={['base_symbol']} header={header}
            scrollable
            scrollHeight="400px"
            virtualScrollerOptions={{
              itemSize: 50,
              delay: 10,
            }}

            emptyMessage="There is no active exchange data available yet!"
          >
            <Column field="logo" body={logoBodyTemplate} style={{ width: "13%" }}></Column>
            <Column field="base_symbol" header="Symbol"
              body={SymbolBodyTemplate}
              filterField="base_symbol" showFilterMatchModes={false}
              showFilterMenuOptions={false}
              filter filterPlaceholder="Search by name"
            ></Column>
            <Column header="Tier" field="tier"
            ></Column>
            <Column header="Price"
              body={priceBodyTemplate}></Column>
            <Column header="Today (%)" body={pocBodyTemplate}></Column>
          </DataTable>
        </div>
      </div>
    </>
  )
}

export default ActiveExchange;