import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { uploadVideoAPI } from "../../slices/video&postSlice";
import { useDispatch } from "react-redux";
const AddVideo = (props) => {

    const [video, setVideo] = useState([]);
    const [preview, setPreview] = useState();
    const [videoUpload, setUpload] = useState([]);
    const dispatch = useDispatch();
    let locacluserid = window.localStorage.getItem("userid");

    const handleVideoChange = (event) => {
        const selectedVideo = event.target.files[0];
        setVideo(selectedVideo);
        setPreview(URL.createObjectURL(selectedVideo));
    };

    const handleSubmit = async () => {
        //return false;
        const formValues = {
            loggedin_user_id: locacluserid,
            video: video
        }

        await dispatch(uploadVideoAPI({ formValues })).unwrap()
            .then((res) => {
                props.setModalShow(false);
                props.handleAllVideo();
                setPreview('');
            }).catch((err) => console.log(err));

    }
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered keyboard={false} backdrop="static"
                className="add-video-modal"
            >
                <Modal.Header closeButton onClick={() => { setPreview('') }}>
                </Modal.Header>
                <Modal.Body className="p-5 modal-body-add-video">
                    <div className="border-wall">
                        <p className="bg-gray fw-bolder  text-center fs-6">You can upload your videos from a computer or from a mobile device.
                            The maximum file size is 3MB.</p>

                        <input type="file" id="video" accept="video/*" onChange={handleVideoChange} name="img" className="d-block-input file-selector-button" />
                        {preview && (
                            <div>
                                <label>Preview:</label>
                                <video className="add-video-frame" src={preview} controls width="300" height="200" />
                            </div>
                        )}
                    </div>
                    <div className="text-center">
                        <Button className="mt-3 me-3 " type="submit" closeButton onClick={handleSubmit}>Submit</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddVideo