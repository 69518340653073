
import './App.css';
import Routings from './Routing/Routes';
import "@fontsource/roboto";
import initFontAwesome from "./initFontAwesome";
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"; 
  
initFontAwesome();

function App() {  
  
  return (
    <div className="App">
      <Routings/>
    </div>
  );
}

export default App;
