import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//import ProfileService from "../services/profile-services";

import { baseURL } from "../config";
import authHeader from "../util/Authheader";
import axios from "axios";

//api/notification/1
  //GET all notification
  export const getNotificationsAPI = createAsyncThunk(
    "api/user/get-all-notification-by-user-id/",
    async (args, thunkAPI) => {  
      const urlname = `${baseURL}api/user/get-all-notification-by-user-id/${args.userId}`;
      return await axios
      .get(urlname, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
        return data;
      })
      .catch((err) => {
        console.log(err, "get all notification list");
      });
    }
    );

    // reset notification/by id

 export const resetNotificationsAPI = createAsyncThunk(
    "api/user/reset-notification-by-user-id/",
    async (args, thunkAPI) => {  
      const urlname = `${baseURL}api/user/reset-notification-by-user-id/${args.userId}`;
      return await axios
      .get(urlname, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
        return data;
      })
      .catch((err) => {
        console.log(err, "reset notification list");
      });
    }
    );

    const notificationSlice = createSlice({
        name: "userNotify",
        initialState: {
          status: null,
          getNotifyList:[]
        },
        extraReducers: (builder) => {
          builder
          //Get all Notification list
            
            .addCase(getNotificationsAPI.pending, (state, action) => {
              state.status = "loading";
              // console.log("payload pending", action.payload);
            })
            .addCase(getNotificationsAPI.fulfilled, (state, action) => {
              state.status = "success";
              // state.getNotifyList = action.payload;
            //  console.log("payload fulfilled", action.payload);
            })
            .addCase(getNotificationsAPI.rejected, (state, action) => {
              state.status = "failed";
              // console.log("payload fulfilled", action.payload);
            })

            .addCase(resetNotificationsAPI.pending, (state, action) => {
              state.status = "loading";
              // console.log("payload pending", action.payload);
            })
            .addCase(resetNotificationsAPI.fulfilled, (state, action) => {
              state.status = "success";
              // state.getNotifyList = action.payload;
            //  console.log("payload fulfilled", action.payload);
            })
            .addCase(resetNotificationsAPI.rejected, (state, action) => {
              state.status = "failed";
              // console.log("payload fulfilled", action.payload);
            })
      
           
        },
      });
      
      export default notificationSlice.reducer;