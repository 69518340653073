import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import authHeader from "../util/Authheader";

function useAuth() {
  const isAuthenticated = authHeader();
  if (isAuthenticated !== null) {
    return true;
  }
}

function PrivateRoute() {
  const auth = useAuth();


  return auth ? <Outlet /> : <Navigate to="/unauthorizedPage" />;
}

export default PrivateRoute;
