import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../slices/authSlice'
import ResetForm from './resetForm';
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const ResetPwd = () => {
  const { values, handleChange, handleSubmit, errors, newPasswordShown, cnfPasswordShown, toggleConfirmPassword, togglePasswordVisiblity } = ResetForm(() => resetPasswordAPI(values));

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const resetPasswordAPI = async (values) => {
    try {
      const res = await dispatch(resetPassword(values)).unwrap();

      if (res.status_code === 200) {

        setTimeout(() => {
          navigate("/");
        }, 1500)


      }
    }
    catch (error) {
      console.log("error tr", error);
    }


  };


  const eye = <FontAwesomeIcon icon={faEye} />;


  return (
    <>
      <div className="vid-container">
        <video muted autoPlay loop className="video">
          <source src="images/Moonleague.webm" type="video/webm" />
        </video>

        <div className="login-form">
          <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4">
            <div className="form-box">
              <Link to="/">
                <div className="logo">
                  <img
                    src="images/logo-white.png"
                    className="img-fluid img-responsive"
                    alt="moon logo"
                  />
                </div>
              </Link>
              <div className="text-center">
                <h3 className="fs-4 fw-normal mt-2 form-tag">Reset Password</h3>
                <div className="sign-font">
                  Already have an account?&nbsp;&nbsp;{" "}
                  <Link to="/">Sign in here</Link>
                </div>
              </div>
              <Form className="mt-4" noValidate onSubmit={handleSubmit}>
                <Form.Group
                  className="mb-3 text-start password-input"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="formlabel">
                    Password</Form.Label>
                  <Form.Control
                    type={newPasswordShown ? "text" : "password"}
                    placeholder="Enter Password"
                    className="form-input"
                    name="new_password"
                    autoComplete="new-password"
                    onChange={handleChange}

                  />

                  <div
                    className={`font-icons ${faEyeSlash}`}
                    onClick={togglePasswordVisiblity}
                  >
                    <FontAwesomeIcon
                      icon={newPasswordShown ? faEye : faEyeSlash}
                    />
                  </div>

                  {errors.new_password && (
                    <div
                      className="help text-danger text-start"
                      aria-live="assertive"
                    >
                      {errors.new_password}
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 text-start password-input">
                  <Form.Label className="formlabel">
                    Confirm Password</Form.Label>
                  <Form.Control
                    type={cnfPasswordShown ? "text" : "password"}
                    placeholder="Enter Confirm Password"
                    className="form-input"
                    name="confirm_new_password"
                    autoComplete="new-password"
                    onChange={handleChange}
                  />
                  <div
                    className={`font-icons ${faEyeSlash}`}
                    onClick={toggleConfirmPassword}
                  >
                    <FontAwesomeIcon
                      icon={cnfPasswordShown ? faEye : faEyeSlash}
                    />
                  </div>

                  {errors.confirm_new_password && (
                    <div
                      className="help text-danger text-start"
                      aria-live="assertive"
                    >
                      {errors.confirm_new_password}
                    </div>
                  )}
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3 custom-btn"
                  size="lg"
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default ResetPwd