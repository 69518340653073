//import {useNavigate } from "react-router-dom";
//let navigate = useNavigate();



//Change Location with reload
export const changeLocation =(placeToGo,navigate) => { 
 
    navigate(placeToGo, { replace: true });
    window.location.reload();
}
 // Get Current Year
 export const currentYear = () => {
 
   let newYear = new Date().getFullYear();
    return newYear;
 } 


 //Normalize function 
 export function normalize(phone) {
  //normalize string and remove all unnecessary characters
  phone = phone.replace(/[^\d]/g, "");

  //check if number length equals to 10
  if (phone.length === 10) {
      //reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  return null;
}

var phone = '(123)4567890';
phone = normalize(phone); //(123) 456-7890


//Convert to minutes
export const convertToMinutes = (timeString) => {
  const [hours, minutes] = timeString.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes;
  return totalMinutes;
};


export const startTimer = (eventDate) => {
  //const timeInterval = setInterval( () => {
   // let countdownDateTime = new Date(`${eventDate}`).getTime();

    let nowDate = new Date().toISOString();
    let currentTime = new Date(nowDate).getTime();
  
  const eventTime = new Date(`${eventDate}`).getTime();
  const remainingTime = eventTime - currentTime
  
  const totalDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  
  const totalHours = Math.floor(remainingTime / (1000 * 60 * 60));
  
  const totalMinutes = Math.floor((remainingTime / 1000 / 60) % 60);
  const totalSeconds = Math.floor((remainingTime / 1000) % 60);
  const runningCountdownTime = {
    countdownDays: totalDays,
    countdownHours: totalHours,
    countdownMinutes: totalMinutes,
    countdownSeconds: totalSeconds,
  };
  let restTime = `${runningCountdownTime.countdownDays} ${runningCountdownTime.countdownHours} ${runningCountdownTime.countdownMinutes} ${runningCountdownTime.countdownSeconds}`;
 
  
  // if (remainingTime <= -countdownDateTime) {
  //   clearInterval(timeInterval);
  //   return;
  // }
  // if (remainingTime <= 0) {
   
  //   clearInterval(timeInterval);
  // }
  return restTime;
  //})
}

const MONTH_NAMES = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

function getFormattedDate(date,T, prefomattedDate = false, hideYear = false) {
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${minutes}`;
  }

  if (prefomattedDate) {
      // Today at 10:20
      // Yesterday at 10:20
      return `${T}`;
  }

  if (hideYear) {
      // 10. January at 10:20
      return `${day}. ${month} at ${hours}:${minutes}`;
  }

  // 10. January 2017. at 10:20
  return `${day}. ${month} ${year}. at ${hours}:${minutes}`;
}

// --- Main function
export function timeAgo(dateParam) {
  const utcDate = new Date(dateParam);
  const formattedDate = utcDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
  });

  const formattedTime = utcDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      // second: '2-digit',
  });
  const newTimeFormat = `${formattedDate} ${formattedTime}`;
  // console.log("date object parameter",dateParam)
  if (!dateParam) {
      return null;
  }
  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();

//   if (seconds < 5) {
//       return 'now';
//   } else if (seconds < 60) {
//       return `${seconds} seconds ago`;
//   } else if (seconds < 90) {
//       return 'a minute ago';
//   } else if (minutes < 60) {
//       return `${minutes} minutes ago`;
//   }else if (minutes < 120) {
//     return `${hours} hours ago`;
// } else 
if (isToday) {
      return getFormattedDate(date,formattedTime, 'Today'); // Today at 10:20
  }  else 
  if (isThisYear) {
      // return getFormattedDate(date, false, true); // 10. January at 10:20
      return formattedDate;
  }
  // return getFormattedDate(date); // 10. January 2017. at 10:20
  return formattedDate;
}

