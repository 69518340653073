import React from 'react'
import { DeleteIcon } from '../../SVGIcon';
const EditDuelsSelectionList = ({ logo, baseSymbol, currentPrice,pct_change_factor, nameShort, id, tier, handleRemove,symbolId ,is_already_selected,setIsSelected},props) => {
    // console.log(id,"edit deleteee clicked",symbolId);
  return (
<>
<div className="d-flex justify-content-between align-items-start row-bg mt-3 mb-3">
                <div className="d-flex align-items-center">
                    <img src={logo} className="img-fluid img-coins" alt={nameShort} />

                </div>
                <div className="d-flex align-items-start text-start flex-column fix-width">
                    <p className="mb-0 duel-heading fs-5 ms-0 ms-sm-4 text-uppercase pink-text">
                        {baseSymbol}
                    </p>
                    <p className="mb-0 duel-heading fs-6 ms-0 ms-sm-4 ">
                        {nameShort}
                    </p>

                </div>
                <p className="mb-0 text-start">
                    {currentPrice}
                </p>
                <p className="mb-0 text-start">
                { pct_change_factor >= "0" ? <>{pct_change_factor === "0" ? <p className="text-warning fw-bold cfs-6 mb-0 " style={{marginLeft: "-0.2rem!important"}}> 0 </p> 
                          : <p className="green-text fw-bold cfs-6 mb-0" style={{marginLeft: "-0.2rem!important"}}>{pct_change_factor}%</p> }</> :<p className="text-danger fw-bold cfs-6 mb-0" style={{marginLeft: "-0.2rem!important"}}> {pct_change_factor}%</p>}
                </p>
                <p className="mb-0">
                    {tier}
                </p>

                <button className='btn btn-primary duel-btn p-2' id={id} onClick={() => handleRemove(id,symbolId)}><DeleteIcon /></button>
                

             
            </div>

</>
  )
}

export default EditDuelsSelectionList