import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-input-2/lib/style.css";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './assests/style.scss';
import './assests/style.css';
import './assests/responsive.scss';

//Redux toolkit
import { Provider } from 'react-redux';
import store from './store/store';

//import Echo from 'laravel-echo';
//import io from 'socket.io-client';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <>
      <Provider store={store}>
        <App />
      </Provider>
    </>
  </BrowserRouter>

);
// window.io = io;

// window.Echo = new Echo({
//     broadcaster: 'socket.io',
//     host: "https://moonleague.com", // Adjust the port as per your Laravel Echo server configuration
// });
// console.log(typeof window !== 'undefined');
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
