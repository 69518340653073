import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <>
  

  <div className="expired mt-5">
  <img src="https://api.moonleague.com/images/email_template/404.gif"/>

  <div className="message">
    <h1 className='pink-text'>Sorry, the page not found</h1>
    <p className='text-dark font-size-bold'>The page you were looking for does not exist.</p>
    <Link to="/" >Go to moonleague.com</Link>
  </div>

</div>
    </>
  )
}

export default Error