import React from 'react';

export const Legal = () => {
  return (
    <>
      <div className='main-layout vh-100'>
      <div className="py-3 text-start">
          <div className="card-custom">
            <div className='card-title text-center text-lg-start'>
              <h4 className="dark-blue mb-0">Legal Notice</h4>
            </div>

            <div className="container-fluid px-md-4 py-3 main-contact-container term-box text-lg-start">

              <p className='lh-lg'>You are subject to the laws of the state, province, city, country, or other legal entity in which you reside or from which you access Moon League. Moon League IS VOID WHERE PROHIBITED OR RESTRICTED BY LAW. If you open an account or use Moon League while located in a prohibited jurisdiction, you will be in violation of the law of such jurisdiction and these Terms of Service, and subject to having your account suspended or terminated without any notice to you. You hereby agree that Moon League cannot be held liable if laws applicable to you restrict or prohibit your participation. Moon League makes no representations or warranties, implicit or explicit, as to your legal right to participate in any Service offered on Moon League, nor shall any person affiliated, or claiming affiliation, with Moon League have authority to make any such representations or warranties. We reserve the right to restrict access to Moon League in any jurisdiction.</p>

              <h4 className='pink-text'>States MOONLEAGUE Paid Duels are Legal in</h4> 
              <ul className='d-flex my-4'>
                <div className='col-3'>
                  <li>Alabama</li>
                  <li>Alaska</li>
                  <li>California</li>
                  <li>Colorado</li>
                  <li>Georgia</li>
                  <li>Hawaii</li>
                  <li>Idaho</li>
                  <li>Illinois</li>
                  <li>Indiana</li>
                  <li>Iowa</li>
                  <li>Kansas</li>
                  <li>Kentucky</li>
                  <li>Maine</li>
                  <li>Massachusetts</li>
                  <li>Michigan</li>
                  <li>Minnesota</li>
                  <li>Mississippi</li>
                  <li>Missouri</li>
                  <li>Nebraska</li>
                  <li>Nevada</li> 
                </div> 
                <div className='col-3'>
                  <li>New Hampshire</li> 
                  <li>New Jersey</li>
                  <li>New Mexico</li>
                  <li>New York</li>
                  <li>North Carolina</li>
                  <li>North Dakota</li>
                  <li>Ohio</li>
                  <li>Oklahoma</li>
                  <li>Oregon</li>
                  <li>Pennsylvania</li>
                  <li>Rhode Island</li>
                  <li>Texas</li>
                  <li>Utah</li>
                  <li>Vermont</li>
                  <li>Virginia</li>
                  <li>Washington</li>
                  <li>West Virginia</li>
                  <li>Wisconsin</li>
                  <li>Wyoming</li>
                </div>         
                
              </ul>
              <h4 className='pink-text'>EXCLUDED LIST OF 11 STATES FOR PAID MOONLEAGUE DUELS:</h4>
                <ul className='my-3'>
                  <li>Arizona</li>
                  <li>Arkansas</li>
                  <li>Connecticut</li>
                  <li>Delaware</li>
                  <li>Florida</li>
                  <li>Louisiana</li>
                  <li>Maryland</li>
                  <li>Montana</li>
                  <li>South Carolina</li>
                  <li>South Dakota</li>
                  <li>Tennessee</li>
                </ul>
              <p className='lh-lg'>Our platform is currently restricted to United States citizens that are age 18 or older. In order to participate in a paid entry contest, you must be an authorized credit card holder, US citizen with a billing address within the United States, and 18 years of age or older.</p>
              <p className='lh-lg'>You can learn more about our <a href="https://www.moonleague.com/terms-conditions">TERMS & CONDITIONS</a> and <a href="https://www.moonleague.com/privacy-policy">PRIVACY POLICY</a> as well.</p>
              <p className='lh-lg'>Have other questions? Be sure to check out our FAQ section. You can also reach us via email at info@marketdraft.com. Thank you for your consideration.</p>
            </div>
          </div>
        </div>      
      </div>
    </>
  )
}