import { useEffect, useState } from "react";
import AnotherVideoScroll from "./AnotherVideoScroll";


const AnotherUploadVideos = ({ videolist }) => {

    const [anotherVideolist, setVideoList] = useState([]);
    useEffect(() => {
        setVideoList(videolist);
    }, [videolist])
    return (
        <>
            <div className="card card-custom gutter-b ">
                <div className="custom-card-header">
                    <div className='d-flex justify-content-end card-title'>
                        <div className='mx-auto '><h4 className='text-center text-uppercase custom-heading'>Videos</h4></div>

                    </div>
                </div>
                <div className="card-body">

                    <AnotherVideoScroll anotherVideolist={anotherVideolist} />
                </div>
            </div>
        </>
    )
}

export default AnotherUploadVideos