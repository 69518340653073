import React ,{useEffect,useState} from 'react'
import {CheckRequestStatus, JurisdictionArea,WebSdkLite,WebSdk} from "@xpointtech/xpoint-js";
import { useNavigate,Link } from 'react-router-dom';
import Footer from "./components/MainLayout/Footer/Footer";
import Header from "./components/MainLayout/Header/Header";
import Navbar from "./components/MainLayout/Navbar/Navbar";
const Geolocation = () => {
    const navigate = useNavigate();
    // useEffect(() => {
    //     dispatch(applyForContest(jurisdictionArea ? jurisdictionArea : undefined));
    // }, [dispatch, jurisdictionArea]);
     
  
    const [getStatus,setStatus] = useState();
    const [msg,setMsg] = useState('');
    let ClientKey = "moonleague5872stage";
   // let userId = "0c34ecac-5176-4b3d-966c-8a4dc8be8894"; //allowed
    let userId = "0c34ecac-5176-4b3d-966c-8a4dc8be889445"; // denied

    useEffect( () => { 
      
         getLocation();
   
    }, []);



const [loading,setLoading] = useState(false);


    
    async function getLocation () {
      setLoading(true);
         window.xpjs = new WebSdkLite();
      //  window.xpjs = new WebSdk();
        try {
           
                await xpjs.setUserId(userId, ClientKey);
          //const checkRequest = await xpjs.checkStatus();
        const checkRequest = await xpjs.liteCheck();
        //  console.log(checkRequest,'checkRequest Location');
        //  console.log(checkRequest.status,"status");
            //const checkRequest =   xpjs.checkStatus();
            // console.log(checkRequest,'checkRequest');
            setLoading(false);
            if (checkRequest.status === CheckRequestStatus.WAITING 
                // && !checkInterval.current
                ) {
                // checkInterval.current = setInterval(() => {
                //    // dispatch(applyForContest(jurisdictionArea ? jurisdictionArea : undefined));
                // }, 3000);
            }
            if (checkRequest.status === CheckRequestStatus.ALLOWED) { 
                // console.log(checkRequest.status, "status");
                setStatus(checkRequest.status);
                navigate("/dashboard", {replace: true});
            }
            else if (checkRequest.status === "DENIED") {
                setStatus(checkRequest.status);
                let errorVal = checkRequest.errors[0].description;
                setMsg(errorVal);
                //document.getElementById("demo").innerHTML = checkRequest.status;
                // console.log(checkRequest.status, "status");
            }
        

//      await xpjs.setUserId(userId, ClientKey);
 
//      const checkRequest = await xpjs.liteCheck() ;
//      console.log(checkRequest,'checkRequest');
//      console.log(checkRequest.status,"status");

//   if(checkRequest.status === "ALLOWED") {
//         document.getElementById("demo").innerHTML = checkRequest.status;
//         console.log(checkRequest.status,"status");
//     }
//     else if (checkRequest.status === "DENIED") {
//         document.getElementById("demo").innerHTML = checkRequest.status;
//         console.log(checkRequest.status, "status");
//     }
//      if(checkRequest.errors !==""){
//      console.log(checkRequest.errors[0].description,'checkRequest1');

//      let errorVal = checkRequest.errors[0].description;
//      document.getElementById("demo").innerHTML = errorVal;
//      }

    // return errorVal;
 
    // if(checkRequest.status === "ALLOWED") {
    //     document.getElementById("demo").innerHTML = checkRequest.status;
    //     console.log(checkRequest.status,"status");
    // }
    // else if (checkRequest.status === "DENIED") {
    //     document.getElementById("demo").innerHTML = checkRequest.status;
    //     console.log(checkRequest.status, "status");
    // }

//      xpjs
//   .liteCheck()
//   .then((checkRequestData) => { 
//     console.log(checkRequestData,'checkRequestData');
     
//     if (checkRequestData.status === "ALLOWED") {
//         document.getElementById("demo").innerHTML = checkRequestData.status;
//         console.log("you are alloweded");
//     } else if (checkRequestData.status === "DENIED") {
//         document.getElementById("demo").innerHTML = checkRequestData.status;
//         console.log("you are denied");
//     }
//   })

  } catch (e) {
    if (!e?.status || e?.status >= 500) {
        console.log(e.status,'statusss 500');
      // Xpoint Verify is not launched or misconfigured.
      // Need to launch Xpoint Verify and enable location serivce in OS settings.
    } else if (e?.status === 401) {
        console.log(e.status,'statusss 401');
      // clientKey doens't exist. Please make sure you use correct clientKey provided by Xpoint Support team.  
    }
  }
      }


    
  return (
    <>
       <Navbar />
       {/* <Header /> */}
       <div className='main-layout vh-100'>
    <div className="d-flex justify-content-center text-black-50 align-items-center flex-column fw-semibold">
            {/* <h2 className="">
                Checking your location <br/>
                in New Jersey
            </h2> */}

               {loading && (<><p className='text-info fw-semibold'>Please wait...</p></>)}
             
             {getStatus === "DENIED" && (<>  <h2 className="text-danger fw-semibold m-3">{getStatus} </h2> 
         
             <p className="text-dark m-3">{msg}</p>
             <div className=''><Link to="/dashboard" className='btn duel-btn btn-dark'>Go to home</Link></div>
             </>)}


          
        </div>
       
        </div>
        <Footer/>
    
    </>
  )
}

export default Geolocation