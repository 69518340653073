import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { getUserDetails } from "../../slices/profileSlice";
import { useNavigate } from 'react-router-dom';
import { getFollowersAPI, getFriendListAPI } from '../../slices/userSlice';
const ProfileDashboard = () => {
    const userProfile = useNavigate();
    const UserNavigate = () => {
        userProfile('/user-profile')
    }
    let locacluserid = window.localStorage.getItem("userid");
    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState({});
    const [followersList, setFollowersList] = useState([]);
    const [followersCount, setFollowersCount] = useState();
    useEffect(() => {
        const getUserAPI = async () => {
            await dispatch(getUserDetails({ userId: locacluserid }))
                .unwrap()
                .then((res) => {

                    setUserDetails(res?.data);
                });
        };

        getUserAPI();
    }, [dispatch]);

    const handleAllFollowers = async () => {
        await dispatch(getFollowersAPI({ userId: locacluserid }))
            .unwrap()
            .then((res) => {

                setFollowersList(res?.data);
                setFollowersCount(res?.followers_count);
            })
    }
    useEffect(() => {

        handleAllFollowers();

    }, [dispatch])


    const handleFriendAPI = async () => {
        await dispatch(getFriendListAPI({ userId: locacluserid }))
            .unwrap()
            .then((res) => {
                //  console.log(res, 'setFriendList');

            })
    }

    useEffect(() => {
        handleFriendAPI();
    }, [dispatch])



    return (
        <>
            <div id="container-main">
                <div className='profile-box d-flex flex-row justify-content-between box'>
                    <div className='user-profile d-flex'>
                        <div className='user-pic me-3'>
                            {userDetails?.avatar ? (
                                <>
                                    <img
                                        src={userDetails?.avatar}
                                        className="img-fluid"
                                    />
                                </>
                            ) : (
                                <>
                                    <img
                                        src="https://api.moonleague.com/images/email_template/user.png"
                                        className="img-fluid"
                                    />
                                </>
                            )}
                        </div>
                        <div className='user-name'>
                            <div className='user'>{userDetails?.user_name}</div>
                            <p className='user-email'>{userDetails?.email}</p>
                        </div>
                    </div>

                    <div className='profile-list d-flex'>
                        <div className="profilelist-box d-flex align-items-center me-3 center-mobile">
                            <div className="profilelist-icon"><svg xmlns="http://www.w3.org/2000/svg" width="53.19" height="46.541"
                                viewBox="0 0 53.19 46.541">
                                <path id="Icon_metro-trophy" data-name="Icon metro-trophy"
                                    d="M45.788,10.5V3.856H12.544V10.5H2.571v6.649a9.98,9.98,0,0,0,12.966,9.516,16.63,16.63,0,0,0,10.3,6.773V43.749H22.517A6.649,6.649,0,0,0,15.868,50.4h26.6a6.649,6.649,0,0,0-6.649-6.649H32.49V33.443a16.63,16.63,0,0,0,10.3-6.773,9.98,9.98,0,0,0,12.966-9.516V10.5ZM12.544,23.179a6.032,6.032,0,0,1-6.025-6.025V13.829h6.025v3.324a16.582,16.582,0,0,0,1.088,5.925,6.033,6.033,0,0,1-1.088.1Zm39.269-6.025A6.019,6.019,0,0,1,44.7,23.079a16.582,16.582,0,0,0,1.088-5.925V13.829h6.025v3.324Z"
                                    transform="translate(-2.571 -3.856)" fill="#fff" />
                            </svg></div>
                            <div className="ms-3 profile-text">
                                <div className="d-flex">
                                    <div className="me-3">
                                        {userDetails?.lifetime_gains >= '0.00' ? <>{userDetails?.lifetime_gains === '0.00' ? <p className="text-warning fs-5 mb-0  font-size-bold">{userDetails?.lifetime_gains}</p> : <p className="green-text fs-5 mb-0  font-size-bold">{userDetails?.lifetime_gains}{userDetails?.lifetime_gains === '0.00' ? "" : "%"}</p>}</>
                                            : <p className="text-danger fs-5 mb-0  font-size-bold">{userDetails?.lifetime_gains}{userDetails?.lifetime_gains === '0.00' ? "" : "%"}</p>}

                                        <span className="mb-0 profile-winning">Lifetime</span>
                                    </div>
                                    <div>
                                        {userDetails?.weekly_gains >= '0.00' ? <>{userDetails?.weekly_gains === '0.00' ? <p className="text-warning fs-5 mb-0  font-size-bold">{userDetails?.weekly_gains}</p> : <p className="green-text fs-5 mb-0  font-size-bold">{userDetails?.weekly_gains}{userDetails?.weekly_gains === '0.00' ? "" : "%"}</p>}</>
                                            : <p className="text-danger fs-5 mb-0  font-size-bold">{userDetails?.weekly_gains}{userDetails?.weekly_gains === '0.00' ? "" : "%"}</p>}

                                        <span className="mb-0 profile-winning">Week</span>
                                    </div>
                                </div>
                                <h5 className='text-black mb-0 text-center'>Gains</h5>
                            </div>
                        </div>

                        <div className="profilelist-box d-flex align-items-center me-3">
                            <div className="profilelist-icon"><svg xmlns="http://www.w3.org/2000/svg" width="52.86" height="41.5"
                                viewBox="0 0 52.86 41.5">
                                <path id="Icon_material-account-balance-wallet"
                                    data-name="Icon material-account-balance-wallet"
                                    d="M54.578,39.083v2.306c0,2.536-2.5,4.611-5.564,4.611H10.064C6.976,46,4.5,43.925,4.5,41.389V9.111C4.5,6.575,6.976,4.5,10.064,4.5H49.013c3.06,0,5.564,2.075,5.564,4.611v2.306H29.539c-3.088,0-5.564,2.075-5.564,4.611V34.472c0,2.536,2.476,4.611,5.564,4.611ZM29.539,34.472H57.36V16.028H29.539Zm11.128-5.764c-2.309,0-4.173-1.545-4.173-3.458s1.864-3.458,4.173-3.458,4.173,1.545,4.173,3.458S42.976,28.708,40.667,28.708Z"
                                    transform="translate(-4.5 -4.5)" fill="#fff" />
                            </svg>
                            </div>
                            <div className="ms-3 profile-text">
                                <p className="fs-5 mb-0  font-size-bold text-truncate mb-2" style={{ maxWidth: "100px" }}>${userDetails?.wallet_balance}</p>
                                <h5 className='text-black'>Balance</h5>
                            </div>
                        </div>
                        <div className="profilelist-box d-flex align-items-center me-3">
                            <div className="profilelist-icon"><svg xmlns="http://www.w3.org/2000/svg" width="64.391" height="44.879"
                                viewBox="0 0 64.391 44.879">
                                <path id="Icon_awesome-user-friends" data-name="Icon awesome-user-friends"
                                    d="M19.317,24.689A11.22,11.22,0,1,0,8.049,13.47,11.239,11.239,0,0,0,19.317,24.689ZM27.044,27.9h-.835a15.619,15.619,0,0,1-13.784,0H11.59A11.568,11.568,0,0,0,0,39.435V42.32a4.82,4.82,0,0,0,4.829,4.808H33.805a4.82,4.82,0,0,0,4.829-4.808V39.435A11.568,11.568,0,0,0,27.044,27.9Zm21.249-3.206a9.617,9.617,0,1,0-9.659-9.617A9.64,9.64,0,0,0,48.293,24.689ZM53.123,27.9H52.74a12.739,12.739,0,0,1-8.894,0h-.382a11.212,11.212,0,0,0-5.6,1.543,14.626,14.626,0,0,1,3.994,10v3.847c0,.22-.05.431-.06.641H59.562a4.82,4.82,0,0,0,4.829-4.808A11.239,11.239,0,0,0,53.123,27.9Z"
                                    transform="translate(0 -2.25)" fill="#fff" />
                            </svg>
                            </div>
                            <div className="ms-3 profile-text">
                                <p className="fs-3 mb-0  font-size-bold">{userDetails?.friends}</p>
                                <h5 className='text-black'>Friends</h5>
                            </div>
                        </div>
                        <div className="profilelist-box d-flex align-items-center">
                            <div className="profilelist-icon">

                                <img src="https://api.moonleague.com/images/email_template/follow.png" className='img-fluid' style={{ padding: "13px" }} />
                            </div>
                            <div className="ms-3 profile-text">
                                <p className="fs-3 mb-0  font-size-bold">{userDetails?.following}</p>
                                <h5 className='text-black'>Following</h5>
                            </div>
                        </div>

                        <div className="profilelist-box d-flex align-items-center profile-list-followers">
                            <div className="profilelist-icon"><svg xmlns="http://www.w3.org/2000/svg" width="57.25" height="45.8"
                                viewBox="0 0 57.25 45.8">
                                <path id="Icon_awesome-user-check" data-name="Icon awesome-user-check"
                                    d="M20.037,22.9A11.45,11.45,0,1,0,8.587,11.45,11.449,11.449,0,0,0,20.037,22.9Zm8.015,2.862H26.559a15.572,15.572,0,0,1-13.042,0H12.022A12.026,12.026,0,0,0,0,37.785v3.721A4.3,4.3,0,0,0,4.294,45.8H35.781a4.3,4.3,0,0,0,4.294-4.294V37.785A12.026,12.026,0,0,0,28.052,25.762ZM56.946,14.277l-2.487-2.514a1.061,1.061,0,0,0-1.5-.009l-9.375,9.3-4.07-4.1a1.061,1.061,0,0,0-1.5-.009l-2.514,2.5a1.061,1.061,0,0,0-.009,1.5l7.308,7.362a1.061,1.061,0,0,0,1.5.009L56.937,15.78A1.069,1.069,0,0,0,56.946,14.277Z"
                                    fill="#fff" />
                            </svg>
                            </div>
                            <div className="ms-3 profile-text">
                                <p className="fs-3 mb-0  font-size-bold">{userDetails?.followers}</p>
                                <h5 className='text-black'>Followers</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ProfileDashboard