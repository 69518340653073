import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useState } from "react";
import PaymentConfirm from "./PaymentConfirm";
const ConfirmModel = (props) => {
    const [modalShow1, setModalShow1] = useState(false);
    return (
        <>
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered  keyboard={false} backdrop="static"
            >
                <Modal.Header closeButton >
                </Modal.Header>
                <Modal.Body className="p-5">
                    <p className="pink-text fw-bolder text-center fs-4">Do you want to proceed to payment to join the duel</p>
                    <div className="text-center">
                        <Button className="mt-3 me-3 outline-btn" type="button" closeButton>Cancel</Button>
                        <Button className="mt-3" type="button" onClick={
                            () => {
                                setModalShow1(true);
                                props.onHide();
                            }
                            }>
                            Confirm</Button>
                    </div>
                </Modal.Body>
            </Modal>
            <PaymentConfirm show={modalShow1} onHide={() => setModalShow1(false)} />
        </>
    )
}

export default ConfirmModel