import { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap"
import UserListModalComponent from "./UserListModalComponent"
import { CopyIcon } from "../../SVGIcon"
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "./Checkbox";
import { friendFollowersListAPI } from '../../slices/userSlice';
const UserListModel = (props) => {
    const [friendlist, setFriendList] = useState([]);
    const dispatch = useDispatch();
    let locacluserid = window.localStorage.getItem("userid");

    const handleFrndFollowersList = () => {
        dispatch(friendFollowersListAPI({ userId: locacluserid }))
            .unwrap()
            .then((res) => {
                let newData = res.data;
                setFriendList(newData);

            }).catch((err) => console.log(err));
    }
    useEffect(() => {

        handleFrndFollowersList();
    }, [])



    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered keyboard={false} backdrop="static"
            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4 className="dark-blue text-uppercase mb-0">Invite User</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <div className="fix-height">
                        <Row>
                            <UserListModalComponent friendlist={friendlist} getduelID={props.getduelID} getDuellink={props.getDuellink}
                            />

                        </Row>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default UserListModel