import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseURL } from "../config";
import axios from "axios";
import authHeader from "../util/Authheader";
import { toast } from "react-toastify";


// for friendsOnly
export const getchatFriendsOnlyAPI = createAsyncThunk(
    "api/chat/get-messages-list-by-user-id/",
    async (args, thunkAPI) => { 
      const urlname = `${baseURL}api/chat/get-messages-list-by-user-id/${args.userId}`;
      return await axios
        .get(urlname, { headers: authHeader() })
        .then((response) => {
          let data = response.data;
  
          return data;
        })
        .catch((err) => {
          console.log(err, "get all message list");
        });
    }
  );

//post for friends only

//api/chat/send-message-users

export const chatToFriendsAPI = createAsyncThunk(
    "api/chat/send-message-to-friends",
    async (args, thunkAPI) => {   
      const urlname = `${baseURL}api/chat/send-message-to-friends`;
      // console.log(args,'ChatFrndData');
      return await axios
      .post(urlname, args,{ headers: authHeader() })
      .then((response) => {
        let data = response.data;
        return data;
       
      })
      .catch((err) => {
        console.log(err, "get chat message response");
      });
    }
    );
//chat for All users
//api/chat/get-messages-list-by-user-id/1
export const getchatAllUsersAPI = createAsyncThunk(
    "api/chat/get-all-messages-list/",
    async (args, thunkAPI) => { 
      const urlname = `${baseURL}api/chat/get-all-messages-list/${args.userId}`;
      return await axios
        .get(urlname, { headers: authHeader() })
        .then((response) => {
          let data = response.data;
  
          return data;
        })
        .catch((err) => {
          console.log(err, "get all Chat of users");
        });
    }
  );

  //Send message to Users
  export const chatWithALLAPI = createAsyncThunk(
    "api/chat/send-message-users",
    async (args, thunkAPI) => {  
      const urlname = `${baseURL}api/chat/send-message-users`;
      return await axios
      .post(urlname, args,{ headers: authHeader() })
      .then((response) => {
        let data = response.data;
        return data;
       
       
      })
      .catch((err) => {
        console.log(err, "get chat message response");
        toast.error(err)
      });
    }
    );

      //Clear message 

export const clearAllChatAPI = createAsyncThunk(
  "api/chat/clear-your-friend-chats-by-userid",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/chat/clear-your-friend-chats-by-userid`;
    return await axios
      .post(urlname, args.clearMsg, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
        // return data;
       
        if (data.status_code === 400 || data.status_code === 404) {
        
          toast.error(data.message);
          return Promise.reject(data);
        } else {
          toast.success(data.message);
          //console.log(data,'success data');
          return data;
        }
      })
      .catch((err) => {
        console.log(err, "get clear chat");
      });
  }
);

const chatSlice = createSlice({
    name: "chat",
    initialState: {
      status: null,
      getchatFriendsList: [],
      getchatAllUsersList:[],
      chatFrendsResponse:[],
      chatwithAllResponse:[],
      clearAllChatAPIResponse:[]
   
    },
    extraReducers: (builder) => {
      builder
        //get chats
      
         //getchatFriendsOnlyAPI
        .addCase(getchatFriendsOnlyAPI.pending, (state, action) => {
          state.status = "loading";
        })
        .addCase(getchatFriendsOnlyAPI.fulfilled, (state, action) => {
          state.getchatFriendsList = action.payload;
          state.status = "success";
        })
        .addCase(getchatFriendsOnlyAPI.rejected, (state, action) => {
          state.status = "failed";
        })
        //getchatAllUsersAPI
  
        .addCase(getchatAllUsersAPI.pending, (state, action) => {
            state.status = "loading";
          })
          .addCase(getchatAllUsersAPI.fulfilled, (state, action) => {
            state.getchatAllUsersList = action.payload;
            state.status = "success";
          })
          .addCase(getchatAllUsersAPI.rejected, (state, action) => {
            state.status = "failed";
          })
          //chatToFriendsAPI
          .addCase(chatToFriendsAPI.pending, (state, action) => {
            state.status = "loading";
          })
          .addCase(chatToFriendsAPI.fulfilled, (state, action) => {
            state.chatFrendsResponse = action.payload;
            state.status = "success";
          })
          .addCase(chatToFriendsAPI.rejected, (state, action) => {
            state.status = "failed";
          })
        
          //chatWithALLAPI
          .addCase(chatWithALLAPI.pending, (state, action) => {
            state.status = "loading";
          })
          .addCase(chatWithALLAPI.fulfilled, (state, action) => {
            state.chatwithAllResponse = action.payload;
            state.status = "success";
          })
          .addCase(chatWithALLAPI.rejected, (state, action) => {
            state.status = "failed";
          })

           //clearAllChatAPI
           .addCase(clearAllChatAPI.pending, (state, action) => {
            state.status = "loading";
          })
          .addCase(clearAllChatAPI.fulfilled, (state, action) => {
            state.clearAllChatAPIResponse = action.payload;
            state.status = "success";
          })
          .addCase(clearAllChatAPI.rejected, (state, action) => {
            state.status = "failed";
          })
    },
  });
  
  export default chatSlice.reducer;
  