import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "../config";
import authHeader from "../util/Authheader";
import axios from "axios";
import { toast } from "react-toastify";


//Upload Video
export const uploadVideoAPI = createAsyncThunk(
  "api/user/upload-video",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/user/upload-video`;

    const formdata = new FormData();
    Object.keys(args.formValues).forEach((key) => {
      formdata.append(key, args.formValues[key]);
    });

    try {
      const response = await axios({
        method: "post",
        url: urlname,
        data: args.formValues,

        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader(),
        },
      });

      let data = response.data;
      console.log(data,"datadatadata");
      if (data.status_code === 400) {
        toast.error(data.message);
        return Promise.reject(data);
      } else {
        toast.success(data.message);
        return data;
      }
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

// api/user/get-users-videos/61
export const getUsersVideoAPI = createAsyncThunk(
  "api/user/get-users-videos/",
  async (args, thunkAPI) => { 
    const urlname = `${baseURL}api/user/get-users-videos/${args.userId}`;
    return await axios
      .get(urlname, { headers: authHeader() })
      .then((response) => {
        let data = response.data;
        return data;
      })
      .catch((err) => {
        console.log(err, "get all Videos of users");
      });
  }
);

//Delete Video Screen
// api/user/delete-video/1
export const deleteVideoAPI = createAsyncThunk(
  "api/user/delete-video/",
  async (args, thunkAPI) => { 
    const urlname = `${baseURL}api/user/delete-video/${args.videoId}`;
    return await axios
      .get(urlname, { headers: authHeader() })
      .then((response) => {
        let data = response.data;

        return data;
      })
      .catch((err) => {
        console.log(err, "get delete Videos of users");
      });
  }
);

//Insert Post

//api/user/insert-post
//All post
export const insertPostAPI = createAsyncThunk(
    "api/user/insert-post",
    async (args, thunkAPI) => {
      const urlname = `${baseURL}api/user/insert-post`;
  
      const formdata = new FormData();
      Object.keys(args.formValues).forEach((key) => {
        formdata.append(key, args.formValues[key]);
      });
  
      try {
        const response = await axios({
          method: "post",
          url: urlname,
          data: args.formValues,
  
          headers: {
            "Content-Type": "multipart/form-data",
            ...authHeader(),
          },
        });
  
        let data = response.data;
        // console.log(data, "data");
        if (data.status_code === 400) {
          // console.log("eror thow", data);
          toast.error(data.message);
          return Promise.reject(data);
        } else {
          toast.success(data.message);
          return data;
        }
      } catch (error) {
        if (error.response) {
          const message = error.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
      }
    }
  );
  
export const getUsersPostAPI = createAsyncThunk(
  "api/user/all-posts/",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/user/all-posts/${args.userId}`;
    return await axios
      .get(urlname, { headers: authHeader() })
      .then((response) => {
        let data = response.data;

        return data;
      })
      .catch((err) => {
        console.log(err, "get all Post of users");
      });
  }
);


//Like and Unlike post
//api/user/like-unlike-post-by-user-id/1
export const getUsersLikeAPI = createAsyncThunk(
  "api/user/like-unlike-post-by-user-id/",
  async (args, thunkAPI) => { 
    const urlname = `${baseURL}api/user/like-unlike-post-by-user-id/${args.userId}/${args.anotherUserId}`;
    return await axios
      .get(urlname, { headers: authHeader() })
      .then((response) => {
        let data = response.data;

        return data;
      })
      .catch((err) => {
        console.log(err, "get all like unlike of users");
      });
  }
);

//Add Like or Unlike to other posts
//api/user/like-unlike-post

export const likeUnlikePostAPI = createAsyncThunk(
  "api/user/like-unlike-post",
  async (args, thunkAPI) => {  
    const urlname = `${baseURL}api/user/like-unlike-post`;
    return await axios
    .post(urlname, args,{ headers: authHeader() })
    .then((response) => {
      let data = response.data;

      return data;
    })
    .catch((err) => {
      console.log(err, "get chat message response");
    });
  }
  );


const videoPostSlice = createSlice({
  name: "video&Post",
  initialState: {
    status: null,
    getUsersPostList: [],
    getUsersVideoList: [],
    uploadVideoResponse: [],
    insertPostResponse: [],
    deletedVideoResponse:[],
    getAllLikeUnLike:[],
    likeUnlikeResponse:[]
  },
  extraReducers: (builder) => {
    builder
      //Uplaod Video
      .addCase(uploadVideoAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(uploadVideoAPI.fulfilled, (state, action) => {
        state.uploadVideoResponse = action.payload;
        state.status = "success";
      })
      .addCase(uploadVideoAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //getUsersVideoAPI
      .addCase(getUsersVideoAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUsersVideoAPI.fulfilled, (state, action) => {
        state.getUsersVideoList = action.payload;
        state.status = "success";
      })
      .addCase(getUsersVideoAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //deleteVideoAPI
      .addCase(deleteVideoAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteVideoAPI.fulfilled, (state, action) => {
        state.deletedVideoResponse = action.payload;
        state.status = "success";
      })
      .addCase(deleteVideoAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //getUsersPostAPI
      .addCase(getUsersPostAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUsersPostAPI.fulfilled, (state, action) => {
        state.getUsersPostList = action.payload;
        state.status = "success";
      })
      .addCase(getUsersPostAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //Insert Post
      
      .addCase(insertPostAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(insertPostAPI.fulfilled, (state, action) => {
        state.insertPostResponse = action.payload;
        state.status = "success";
      })
      .addCase(insertPostAPI.rejected, (state, action) => {
        state.status = "failed";
      })
      //All Like And Unlike
      //getUsersLikeAPI
      .addCase(getUsersLikeAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUsersLikeAPI.fulfilled, (state, action) => {
        state.getAllLikeUnLike = action.payload;
        state.status = "success";
      })
      .addCase(getUsersLikeAPI.rejected, (state, action) => {
        state.status = "failed";
      })
      //Like Unlike Post by users on another profile
      
      .addCase(likeUnlikePostAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(likeUnlikePostAPI.fulfilled, (state, action) => {
        state.likeUnlikeResponse = action.payload;
        state.status = "success";
      })
      .addCase(likeUnlikePostAPI.rejected, (state, action) => {
        state.status = "failed";
      })
  },
});




export default videoPostSlice.reducer;
