
import { useEffect, useState, useMemo } from 'react';
import { Tabs, Tab, Container, Row, Col, Form, Nav } from 'react-bootstrap';
import UserCard from './UserCard';
import { userList, getUsersList, getFollowingUserAPI, getFollowingUserList, getFollowersAPI, getFollowersList, FollowUserAPI, FollowUserResponse, UnFollowUserAPI, getFriendListAPI, friendReuestsReceviedAPI, friendReuestsSentAPI, addfriendReuestsAPI, UnfriendAPI, AcceptRejectFrndAPI, CancelSendRequestAPI } from '../../slices/userSlice';
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, } from '../../slices/profileSlice';
import FollowingUserCard from './FollowingUserCard';
import FollowersUserCard from './FollowersUserCard';
import FriendsUserCard from './FriendsUserCard';
import FriendRequestsCard from './FriendRequestsCard';
// import debouce from "lodash.debounce";
import debounce from 'lodash.debounce'
import { source } from '../../util/common';
import axios from 'axios';
const Users = () => {
  const [key, setKey] = useState("allUser")
  const dispatch = useDispatch()
  const [user, setUser] = useState([]);
  let locacluserid = window.localStorage.getItem("userid");
  const [followingList, setFollowingList] = useState([]);
  const [followingCount, setFollowingCount] = useState();
  const [followersList, setFollowersList] = useState([]);
  const [followersCount, setFollowersCount] = useState();
  const [friendList, setFrindList] = useState([]);
  const [friendCount, SetFriendCount] = useState();
  const [friendRequestRcvdList, SetFriendRequestRcvdList] = useState([]);
  const [friendRequestRcvdCount, SetFriendRequestRcvdCount] = useState(0);
  const [friendRequestSentList, SetFriendRequestSentList] = useState([]);
  const [friendRequestSentCount, SetFriendRequestSentCount] = useState(0);





  const handleAllUser = () => {
    //  setUser('');
    dispatch(getAllUsers())
      .unwrap()
      .then((res) => {
        setUser(res);

      });
  }
  useEffect(() => {
    ; handleAllUser();
  }, [dispatch]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(handleAllUser(), { cancelToken: source.token });
  //       // Handle the response data here
  //       console.log(response,"responseresponse");
  //     } catch (error) {
  //       if (axios.isCancel(error)) {
  //         console.log('Request canceledydfsdd:', error.message);
  //       } else {
  //         // Handle other errors
  //       }
  //     }
  //   };

  //   fetchData();

  //   return () => {
  //     // Anything in here is fired on component unmount.
  //     source.cancel('Component unmounted');
  //     console.log('Request canceledttttt');
  //   };
  // }, [dispatch]);
  //Get Followed BY


  const handleAllFollowers = async () => {

    await dispatch(getFollowersAPI({ userId: locacluserid }))
      .unwrap()
      .then((res) => {
        //  console.log(res, 'setFollowingList');

        setFollowersList(res.data);
        setFollowersCount(res.followers_count);
      })
  }

  // console.log(followersList,'followersList')

  const handleAllFollowing = async () => {
    await dispatch(getFollowingUserAPI({ userId: locacluserid }))
      .unwrap()
      .then((res) => {
        //  console.log(res, 'setFollowingList');
        setFollowingList(res.data);
        setFollowingCount(res.following_user_count);
      })

    //    await dispatch(getFollowersAPI({userId: locacluserid}))
    //     .unwrap()
    //     .then((res) => { 
    //      console.log(res, 'setFollowingList');

    //     setFollowersList(res.data);
    //     setFollowersCount(res.followers_count);
    //     })
  }

  useEffect(() => {
    handleAllFollowing();
  }, [])

  //Followers User Card

  useEffect(() => {
    handleAllFollowers();
  }, [])


  const handleFriendAPI = async () => {
    await dispatch(getFriendListAPI({ userId: locacluserid }))
      .unwrap()
      .then((res) => {
        //  console.log(res, 'setFriendList');
        setFrindList(res?.data);
        SetFriendCount(res?.friends_count);
      })
  }

  useEffect(() => {
    handleFriendAPI();
  }, [])
  //request_received_count
  //Get Requests Received Count
  // friendReuestsReceviedAPI,friendReuestsSentAPI //request_sent_count

  const handleFriendRequestsRcvd = async () => {
    await dispatch(friendReuestsReceviedAPI({ userId: locacluserid }))
      .unwrap()
      .then((res) => {
        //  console.log(res, 'friendReuestsReceviedAPI');

        SetFriendRequestRcvdList(res?.data);
        SetFriendRequestRcvdCount(res?.request_received_count);
      })
  }
  useEffect(() => {
    handleFriendRequestsRcvd();
  }, [])

  //SetFriendRequestSentList ,SetFriendRequestSentCount

  const handleFriendRequestsSent = async () => {
    await dispatch(friendReuestsSentAPI({ userId: locacluserid }))
      .unwrap()
      .then((res) => {
        //  console.log(res, 'friendReuestsSentAPI');

        SetFriendRequestSentList(res?.data);
        SetFriendRequestSentCount(res?.request_sent_count);
      })
  }
  useEffect(() => {
    handleFriendRequestsSent();
  }, [])

  const { getUsersList, getFollowingUserList, getFollowersList } = useSelector((state) => state.userLists);
  //    console.log(getUsersList, "getUsersList");
  //  console.log(getFollowingUserList?.following_user_count, "getFollowingUserList");
  //  console.log(getFollowersList?.followers_count, "getFollowersList");

  const [followTrue, setFollowTrue] = useState(false);
  //Follow User 
  const handleFollow = (follow_user_id) => {
    //return false;
    let followId = follow_user_id;
    let FollowUserData = {
      user_id: locacluserid,
      following_user_id: followId
    }


    dispatch(FollowUserAPI({ FollowUserData })).unwrap()
      .then((res) => {
        let newData = res.data;
        handleAllUser();
        handleAllFollowing();
        handleAllFollowers();
        //  setFollowTrue(true);
        // console.log("tryeeee");
        // if(newData.is_following === "Yes" && FollowUserData.following_user_id === followId) {
        //     setFollowTrue(true);
        //     console.log("tryeeee");
        // }
      }).catch((err) => console.log(err));

  }



  const handleUnFollow = (follow_user_id) => {
    //return false;
    let followId = follow_user_id;
    let UnFollowUserData = {
      user_id: locacluserid,
      following_user_id: followId
    }

    dispatch(UnFollowUserAPI({ UnFollowUserData })).unwrap()
      .then((res) => {
        let newData = res.data;
        handleAllUser();
        handleAllFollowing();
        handleAllFollowers();
        //  setFollowTrue(true);
        //   console.log("tryeeee");
        // if(newData.is_following === "Yes" && FollowUserData.following_user_id === followId) {
        //     setFollowTrue(true);
        //     console.log("tryeeee");
        // }
      }).catch((err) => console.log(err));
  }


  //Send Friend Requests
  //  friendReuestsSentAPI

  const handleSendRequest = (friend_id) => {
    let followId = friend_id;
    let SendfrndData = {
      loggedin_user_id: locacluserid,
      friend_id: followId

    }

    dispatch(addfriendReuestsAPI({ SendfrndData })).unwrap()
      .then((res) => {
        let newData = res.data;
        handleAllUser();
        handleFriendRequestsRcvd();
        handleFriendRequestsSent();
        // handleAllFollowing();
        //  handleAllFollowers();
        //  setFollowTrue(true);
        //   console.log("tryeeee");
        // if(newData.is_following === "Yes" && FollowUserData.following_user_id === followId) {
        //     setFollowTrue(true);
        //     console.log("tryeeee");
        // }
      }).catch((err) => console.log(err));
  }




  //UnfriendAPI

  const handleUnfriend = (friend_id) => {
    let unfrndID = friend_id;
    let unfrndData = {
      loggedin_user_id: locacluserid,
      friend_id: unfrndID
    }

    dispatch(UnfriendAPI({ unfrndData })).unwrap()
      .then((res) => {
        let newData = res.data;
        handleAllUser();
        handleFriendAPI();
        // console.log("friend API");

      }).catch((err) => console.log(err));
  }


  //confirm accept frnd
  const handleCnfRjcFrnd = (friend_id, isconfirrm) => {
    let frndId = friend_id;
    let isConfirm = isconfirrm;
    let frndConfirmData = {
      loggedin_user_id: locacluserid,
      friend_id: frndId,
      is_request_accepted_or_cancelled: isConfirm
    }
    // AcceptRejectFrndAPI
    dispatch(AcceptRejectFrndAPI({ frndConfirmData })).unwrap()
      .then((res) => {
        let newData = res.data;
        handleAllUser();
        handleFriendAPI();

        handleFriendRequestsRcvd();
        //    console.log("friend API");

      }).catch((err) => console.log(err));
  }


  //cancel send request
  //CancelSendRequestAPI

  const handleCancelRequest = (friend_id) => {
    let frndId = friend_id;
    let cancelfrndRequestData = {
      loggedin_user_id: locacluserid,
      friend_id: frndId
    }

    dispatch(CancelSendRequestAPI({ cancelfrndRequestData })).unwrap()
      .then((res) => {
        let newData = res.data;
        handleAllUser();
        handleFriendAPI();
        //  handleFriendRequestsRcvd();
        handleFriendRequestsSent();
        // console.log("friend API");

      }).catch((err) => console.log(err));
  }
  //Search
  // const [searchTerm, setSearchTerm] = useState("");
  const [list, setList] = useState([]);
  const [searchTabs, setSearchTabs] = useState("All(94)");
  const [searchFollowerList, setsearchFollowerList] = useState([]);
  const [searchFollowingList, setSearchFollowingList] = useState([]);
  const [searchFriendsList, setSearchFriendsList] = useState([]);
  const [searchFriendReuqestsList, setSearchFriendReuqestsList] = useState([]);
  const [SearchfriendRequestSentList, setSearchfriendRequestSentList] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  let listToDisplay;
  const filterResults = (e) => {
    // e.preventDefault();
    let search = e.target.value;
    setSearchValue(search);
    switch (searchTabs) {
      case "All(94)":
        if (search !== "") {
          listToDisplay = user.filter((c) => c.user_name.startsWith(search.toLowerCase()));
          setList(listToDisplay);
        }
        else {
          setList([]);
        }
        break;

      case "Followers":
        if (search !== "") {
          listToDisplay = followersList?.filter((c) => c.follower_user_name.startsWith(search.toLowerCase()));
          setsearchFollowerList(listToDisplay);
        }
        else {
          setsearchFollowerList([]);
        }
        break;

      case "Followings":
        if (search !== "") {
          listToDisplay = followingList?.filter((c) => c.following_user_name.startsWith(search.toLowerCase()));
          setSearchFollowingList(listToDisplay);
        }
        else {
          setSearchFollowingList([]);
        }
        break;

      case "Friends":
        if (search !== "") {
          listToDisplay = friendList?.filter((c) => c.friend_user_name.startsWith(search.toLowerCase()));
          setSearchFriendsList(listToDisplay);
        }
        else {
          setSearchFriendsList([]);
        }
        break;

      case "Friend Reuqests":
        // console.log("friendRequestRcvdList---------", friendRequestRcvdList, friendRequestSentList)
        if (search !== "") {
          listToDisplay = friendRequestRcvdList?.filter((c) => c.friend_user_name.startsWith(search.toLowerCase()));
          setSearchFriendReuqestsList(listToDisplay);
          listToDisplay = friendRequestSentList?.filter((c) => c.friend_user_name.startsWith(search.toLowerCase()));
          setSearchfriendRequestSentList(listToDisplay);
          // SearchfriendRequestSentList?
        }
        else {
          setSearchFriendReuqestsList([]);
          setSearchfriendRequestSentList([]);
        }
        break;

      default:
        break;
    }
    // setSearchTerm(e.target.value);
  };

  //   const renderUserList = () => {
  //     return listToDisplay.map((fruit, i) => <p key={i}>{fruit}</p>);
  //   };
  // const filterResults = useMemo(() => { 
  //   return debounce(handleChange, 2000);
  // }, []);

  // console.log(list,'List');
  // useEffect(() => {
  // //  setList(listToDisplay)
  //   return () => {
  //     filterResults.cancel();
  //   };
  // },[list]);

  // useEffect( () => {
  //     handleUnfriend();
  // },[])
  return (
    <>
      <div className='d-flex justify-content-between align-items-start p-4 all-user'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="allUser">
          <Row>
            <Col sm={12} md={8} lg={9} className="mb-3 ">
              <Nav variant="pills" className='user-tab' >
                <Nav.Item>
                  <Nav.Link onClick={(e) => setSearchTabs(e.target.title)} eventKey="allUser" title="All(94)">All ({user?.length - 1})</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={(e) => setSearchTabs(e.target.title)} eventKey="followerUser" title="Followers">Followers ({followersCount ? followersCount : 0})</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={(e) => setSearchTabs(e.target.title)} eventKey="followingUser" title="Followings">Followings ({followingCount ? followingCount : 0})</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={(e) => setSearchTabs(e.target.title)} eventKey="friendsUser" title="Friends">Friends ({friendCount ? friendCount : 0})</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={(e) => setSearchTabs(e.target.title)} eventKey="getfriendReuest" title="Friend Reuqests">Friend Requests ({friendRequestRcvdCount ? friendRequestRcvdCount : 0})</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12} md={4} lg={3} className="mb-3">
              <div className='serachInput'>
                <Form>
                  <input type="text" placeholder='Search here...' className='search' value={searchValue} onChange={filterResults} />
                </Form>
              </div>
            </Col>
            <Col lg={12} className='mt-4'>
              <Tab.Content>
                <Tab.Pane eventKey="allUser">
                  <Row className='gy-4'>
                    {/* {
                                            user.map((elem, index) =>
                                                <UserCard key={index} data={elem.user_name} avatar={elem.avatar} />
                                            )
                                        } */}
                    <UserCard getuser={searchValue.length > 0 ? list : user} handlefollow={(i) => { handleFollow(i); setFollowTrue(true); }} followtrue={followTrue} handleunfollow={handleUnFollow} handlesendrequest={handleSendRequest} handlecancelrequest={handleCancelRequest} handlecnfRjcfrnd={handleCnfRjcFrnd} handleunfrnd={handleUnfriend} />
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="followerUser" onClick={handleAllFollowers}>
                  <Row className='gy-4'>
                    <FollowersUserCard followerslist={searchValue.length > 0 ? searchFollowerList : followersList} handlefollow={(i) => { handleFollow(i); setFollowTrue(true); }} handleunfollow={handleUnFollow} />
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="followingUser" onClick={handleAllFollowing}>

                  <Row className='gy-4'>
                    <FollowingUserCard followinglist={searchValue.length > 0 ? searchFollowingList : followingList} handleunfollow={handleUnFollow} />
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="friendsUser" onClick={handleFriendAPI}>
                  <Row className='gy-4'>
                    <FriendsUserCard friendlist={searchValue.length > 0 ? searchFriendsList : friendList} handleunfrnd={handleUnfriend} />
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="getfriendReuest">
                  <Row className='gy-4'>
                    <FriendRequestsCard friendrequestrcvdlist={searchValue.length > 0 ? searchFriendReuqestsList : friendRequestRcvdList} friendrequestrcvdcount={friendRequestRcvdCount} friendrequestsentlist={searchValue.length > 0 ? SearchfriendRequestSentList : friendRequestSentList} friendrequestcentcount={friendRequestSentCount} handlecnfRjcfrnd={handleCnfRjcFrnd} handlecancelrequest={handleCancelRequest} />
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>


    </>
  )
}
export default Users