import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react'

const Participants = ({ participant }) => {




  const entryProfileTemplate = (rowData) => {
    return (
      <>
        {rowData.avatar ? (<img src={rowData.avatar} className="img-fluid img-symbols" />) : (<img src='/icon/default-profile.png' className="img-fluid img-symbols" />)}
      </>
    )
  }

  const entryNameTemplate = (rowData) => {
    return (
      <>
        {rowData?.user_name}
      </>
    )
  }



  return (
    <>
    {participant?.length > 0  
      ? <DataTable value={participant} scrollable scrollHeight="flex"  className='trending-modal-datatble text-center'>
        <Column field="avatar" header="Profile" className='trending-modal-table-profile-column' headerStyle={{ width: '50%'}} body={entryProfileTemplate}></Column>
        <Column field="user_name" header="Name" className='trending-modal-table-name-column' headerStyle={{ width: '50%' }} body={entryNameTemplate}></Column>
      </DataTable>
      : <p className='noDatamessage'>There are no participants yet!</p>}
    </>
  )
}

export default Participants