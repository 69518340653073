import React, { useState, Suspense } from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import Friends from './Friends';
import Followings from './Followings';
import { useDispatch, useSelector } from 'react-redux';
import FriendRequests from './FriendRequests';
import Followers from './Followers';

const HotPicks = React.lazy(() => import('./HotPicks'))
const Community = () => {
  const [key, setKey] = useState('hotPicks');
  const dispatch = useDispatch();


  const { getFollowersList, getFriendList, FriendRequestsReceivedList } = useSelector((state) => state.userLists);

  return (
    <>
      <div className="card card-custom gutter-b community-card">
        <div className="custom-card-header">
          <div className='card-title text-center mb-0'>
            <h4 className="card-label align-items-center custom-heading d-inline">COMMUNITY</h4>
          </div>
          <div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="hotPicks">
              <div className='custom-tabs-community'>
                <Row>
                  <Col xxl={12} lg={12} sm={12}  >
                    <Nav variant="pills" className='user-tab d-flex d-flex justify-content-lg-around justify-content-md-around justify-content-sm-around community-nav-tab-phone px-2' >
                      <Nav.Item>
                        <Nav.Link eventKey="hotPicks" title="HOTPICKS">HOT</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="friends" title="FRIENDS" >FRIENDS</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="follwing" title="FOLLOWING">FOLLOWING</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="follwers" title="FOLLOWERS">FOLLOWERS</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="frendrequest" title="Friend Request">REQUEST</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>

                </Row>
              </div>
              <Col lg={12}>
                <Tab.Content className=''>
                  <Tab.Pane eventKey="hotPicks">


                    <Suspense fallback={<div className='pink-text fw-bold'>Wait...</div>}>
                      <HotPicks />
                    </Suspense>
                  </Tab.Pane>
                  <Tab.Pane eventKey="friends">


                    <Friends getfriendlist={getFriendList} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="follwing">


                    <Followings />
                  </Tab.Pane>
                  <Tab.Pane eventKey="follwers">
                    <Followers getfollowerslist={getFollowersList} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="frendrequest">


                    <FriendRequests friendRequestsReceivedList={FriendRequestsReceivedList} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>

            </Tab.Container>
          </div>

        </div>

      </div>

    </>
  )
}

export default Community