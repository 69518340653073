import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL, KycURL } from "../config";
import authHeader from "../util/Authheader";
import axios from "axios";

//Invite users to participate in a duel
export const kycverificationAPI = createAsyncThunk(
  "api/user/invite-users",
  async (args, thunkAPI) => {
    const urlname = `${baseURL}api/user/invite-users`;
    return await axios
      .post(urlname, args.InviteDuelData, { headers: authHeader() })
      .then((response) => {
        let data = response.data;

        return data;
      })
      .catch((err) => {
        console.log(err, "get all users following");
      });
  }
);


//api/user/all-friends-followers/61
export const kycVerify = createAsyncThunk(
  "api/idiq.svc/",
  async (thunkAPI) => {
    const urlname = "https://web.idologylive.com/api/idiq.svc?username=sumit.singh&password=Chetu@123456&firstName=Rohan&lastName=Verma&address=Ghaziabad&zip=201009&output=json&image=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==&backImage=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==&countryCode=USA&scanDocumentType=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==&faceImage=data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg"
    // const urlname = `${baseKYCURL}api/idiq.svc?username=sumit.singh&password=Chetu@12345&firstName=Rohan&lastName=Verma&address=Ghaziabad&zip=201009&output=json`
    return await axios
      .post(urlname, { headers: authHeader() })
      .then((response) => {
        let data = response;
        return data;
      })
      .catch((err) => {
        console.log(err, "user data");
      });
  }
);

export const verifyKyc = createAsyncThunk(
  "api/verify/kyc",
  async (params, { rejectWithValues }) => {
    try {
      const url = `${KycURL}api/idiq.svc?username=sumit.singh&password=Chetu@1234&firstName=${params.firstName}&lastName=${params.lastName}&address=${params.address}&zip=${params.zip}&output=json`;
      const response = await axios.post(url, { headers: authHeader() });
      return response.data;
    } catch (error) {
      rejectWithValues(error);
    }
  }
)

export const checkKYCStatus = createAsyncThunk(
  "api/kyc/status",
  async (id, { rejectWithValues }) => {
    // console.log("QueryId",id);
    try {
      const url = `https://web.idologylive.com/api/idscan.svc?username=sumit.singh&password=Chetu@1234&queryId=${id}&output=json`;
      const response = await axios.post(url, { headers: authHeader() });
      return response.data;
    } catch (error) {
      rejectWithValues(error);
    }
  }
)

export const saveKYCStatus = createAsyncThunk(
  "api/kyc/update-kyc-status-by-userid",
  async (params, { rejectWithValues }) => {
    try {
      const url = `${baseURL}api/kyc/update-kyc-status-by-userid`;
      const response = await axios.post(url,params, { headers: authHeader() });
      return response.data;
    } catch (error) {
      rejectWithValues(error);
    }
  }
)

const kycSlice = createSlice({
  name: "enter_Kyc",
  initialState: {
    status: null,
    kycUser: {},
    results: null
  },
  extraReducers: (builder) => {
    builder
      //Get FreeGames List
      .addCase(kycverificationAPI.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(kycverificationAPI.fulfilled, (state, action) => {
        // state.bloodBathExchangeCoinsList = action.payload;
        state.status = "success";
      })
      .addCase(kycverificationAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      // kyc verify
      .addCase(kycVerify.pending, (state, action) => {
        state.status = "partial";
        // console.log("payload pending", action.payload);
      })
      .addCase(kycVerify.fulfilled, (state, action) => {
        state.status = "success";
        state.getUsersList = action.payload;
        //  console.log("payload fulfilled", action.payload);
      })
      .addCase(kycVerify.rejected, (state, action) => {
        state.status = "failure";
        // console.log("payload fulfilled", action.payload);
      })

      // kyc verify
      .addCase(verifyKyc.pending, (state, action) => {
        state.status = "partial";
        // console.log("payload pending", action.payload);
      })
      .addCase(verifyKyc.fulfilled, (state, action) => {
        state.status = "success";
        state.kycUser = action.payload;
        console.log("payload fulfilled", action.payload);
      })
      .addCase(verifyKyc.rejected, (state, action) => {
        state.status = "failure";
        console.log("payload fulfilled", action.payload);
      })

      .addCase(checkKYCStatus.pending, (state, action) => {
        state.status = "partial";
        // console.log("payload pending", action.payload);
      })
      .addCase(checkKYCStatus.fulfilled, (state, action) => {
        state.status = "success";
        state.results = action.payload;
        console.log("payload fulfilled", action.payload);
      })
      .addCase(checkKYCStatus.rejected, (state, action) => {
        state.status = "failure";
        console.log("payload fulfilled", action.payload);
      })
      .addCase(saveKYCStatus.pending, (state, action) => {
        state.status = "partial";
        // console.log("payload pending", action.payload);
      })
      .addCase(saveKYCStatus.fulfilled, (state, action) => {
        state.status = "success";
        state.results = action.payload;
        console.log("payload fulfilled", action.payload);
      })
      .addCase(saveKYCStatus.rejected, (state, action) => {
        state.status = "failure";
        console.log("payload fulfilled", action.payload);
      });

  },
});

export default kycSlice.reducer;