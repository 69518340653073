import React from 'react'
import Footer from '../components/MainLayout/Footer/Footer'
import Header from '../components/MainLayout/Header/Header'
import Navbar from '../components/MainLayout/Navbar/Navbar'
import Profile from '../components/Profile/Profile'

const ProfilePage = () => {
  return (
    <>
      <Navbar />
      <div className="main-layout">
        <div className='container-fluid'>
          <div className='row'>
            <div className='mb-3'>
            <Header />
            </div>
            <Profile />
          </div>
        </div>
      </div>
      <Footer />

    </>

  )
}

export default ProfilePage