import Modal from 'react-bootstrap/Modal';
import FeaturedAds from '../FeaturedAds/FeaturedAds';
import GameLevel from '../User/GameLevel';
import UserChat from '../UserChat/UserChat';
import AnotherUserProfileDashboard from './AnotherUserProfileDashboard';
import AnotherLatestPost from './AnotherLatestPost';
import AnotherUploadVideos from './AnotherUploadVideo';
import AnotherCommunity from './AnotherCommunity';
import AnotherGames from './AnotherGames';

function AnotherUser({ show, handleClose,singleUser,anotherUserId,setShowModel }) {

   

    return (
        <>
        <div className='w-another-user'>
            {show ? <Modal show={show} onHide={() => setShowModel(false)}
                dialog className="modal-90w" dialogClassName="another-user-dialog">
                <Modal.Header onClick={handleClose} closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='mb-3'>
                                <AnotherUserProfileDashboard 
                                singleUser={singleUser}
                                />
                            </div>
                            <div className='mb-3 another-feauredAds'>
                                <FeaturedAds />
                            </div>
                         
                                <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
                                    <div className='mb-3'>
                                        <GameLevel />
                                    </div>
                                    <div className='mb-3'>
                                        <AnotherLatestPost postlist={singleUser?.post_data} username={singleUser?.user_name} avatar={singleUser?.avatar} anotherUserId={anotherUserId}/>
                                    </div>

                                </div>
                                <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                                    <div className='mb-3 another-uVideo'>
                                        <AnotherUploadVideos videolist={singleUser?.video_data}/>
                                    </div>
                                    <div className='mb-3 another-community'>
                                        <AnotherCommunity friendslist={singleUser?.friends_data} followinglist={singleUser?.followed_to}/>
                                    </div>
                                    <div className="mb-3">
                                        <AnotherGames anotherUserId={anotherUserId}/>
                                        </div>
                                </div>
                                <div className='mb-3'>
                                    <UserChat singleUser={singleUser}/>
                                </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : null
            }
            </div>
                </>
                );
}

                export default AnotherUser;