import React from 'react'
import { DeleteIcon, delIcon } from '../../SVGIcon'

const JoinDuelsSelectionList = ({ logo, baseSymbol, currentPrice, nameShort, id, tier, handleRemove, pct_change_factor }, props) => {
    // console.log(id,"JOIN delete click",props);
    return (
        <>
            <div className="d-flex justify-content-between align-items-start row-bg mt-3 mb-3 join-duel-component">
                <div className="d-flex align-items-center join-duel-component-symbol-img-container">
                    <img src={logo} className="img-fluid img-coins" alt={nameShort} />

                </div>
                <div className="d-flex align-items-start text-start flex-column fix-width join-duel-component-symbol">
                    <p className="mb-0 duel-heading fs-5 ms-0 ms-sm-4 text-uppercase pink-text">
                        {baseSymbol}
                    </p>
                    <p className="mb-0 duel-heading fs-6 ms-0 ms-sm-4 ">
                        {nameShort}
                    </p>

                </div>
                <p className="mb-0 text-start">
                    {currentPrice}
                </p>

                <p className="mb-0 text-start">
                { pct_change_factor >= "0" ? <>{pct_change_factor === "0" ? <p className="text-warning fw-bold cfs-6 mb-0 " style={{marginLeft: "-0.2rem!important"}}> 0 </p> 
                          : <p className="green-text fw-bold cfs-6 mb-0" style={{marginLeft: "-0.2rem!important"}}>{pct_change_factor}%</p> }</> :<p className="text-danger fw-bold cfs-6 mb-0" style={{marginLeft: "-0.2rem!important"}}> {pct_change_factor}% </p>}
                </p>

                <p className="mb-0">
                    {tier}
                </p>

                <button className='btn btn-primary duel-btn p-2' id={id} onClick={() => handleRemove(id)}><DeleteIcon /></button>

                {/* <button className="btn btn-primary duel-btn p-2" 
                //  onClick={() => { toast.success(props.baseSymbol); setClicked(true); } }
                    // onClick={() => props.onSelect(props)}
                >  Minus
                      {/* { getClicked ?  "-" : (<PlusIcon /> )} */}
                {/* </button> */}

            </div>

        </>
    )
}

export default JoinDuelsSelectionList