import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { FollowIcon, FriendIcon } from "../../SVGIcon";
import AnotherUser from "../AnotherUser/AnotherUser";
import { VirtualScroller } from "primereact/virtualscroller";
import { Skeleton } from "primereact/skeleton";
import { classNames } from "primereact/utils";
import { Link } from "react-router-dom";
import { singleUserList } from '../../slices/userSlice';
import { useSelector, useDispatch } from "react-redux";
const FollowingUserCard = ({ followinglist, handleunfollow }) => {
  const [showModel, setShowModel] = useState(false);
  const handleShowModel = () => setShowModel(!showModel);
  const [singleUser, setSingleUser] = useState([]);
  const [friendCount, SetFriendCount] = useState();
  const [anotherUserId, setAnotherUserId] = useState('');

  const dispatch = useDispatch();
  const handleSingleUser = (user_id) => {
    setAnotherUserId(user_id);
    dispatch(singleUserList({ userId: user_id })).unwrap()
      .then((res) => {
        let newData = res;
        setSingleUser(...newData)

      }).catch((err) => console.log(err));
    handleShowModel();

  }
  return (
    <>


      <Row className="card-user">
        {followinglist?.map((c, i) => {
          return (
            <>
              <Col md={4} lg={4} sm={12}>
                <div className="card user-card p-3 mb-3" id={c.following_user_id} key={i}>
                  <Link className="user-link disable-links">
                    {c.following_user_avatar !== "" ? (
                      <img src={c.following_user_avatar} className="card-img-top" alt="..." />
                    ) : (
                      <img
                        src="/icon/default-profile.png"
                        className="card-img-top"
                        alt="..."
                      />
                    )}

                    <h5
                      className="mt-3 mb-4 text-center  pink-text text-in-tablet"
                      onClick={() => handleSingleUser(c.following_user_id)}
                    >
                      {c.following_user_name}
                    </h5>
                  </Link>
                  <div className="card-body">

                    <button className="btn pink-text btn-outline btn-selected ms-3 fs-6 btn-tablet" id={c.userid} onClick={() => handleunfollow(c.following_user_id)}>

                      UNFOLLOW
                    </button>
                  </div>
                </div>
              </Col>
            </>
          );
        })}
        {followinglist?.length === 0 && <p className="pink-text fs-5 fw-bolder">Following Not Found</p>}
      </Row>
      <AnotherUser show={showModel} handleClose={handleShowModel} friendCount={friendCount} singleUser={singleUser} anotherUserId={anotherUserId} />
    </>
  )
}

export default FollowingUserCard