import ActiveExchange from "../ActiveExchange/ActiveExchange"
import Community from "../Community/Community"
import FeaturedAds from "../FeaturedAds/FeaturedAds"
import Games from "../Games/GamesDuel"
import Footer from "../MainLayout/Footer/Footer"
import Header from "../MainLayout/Header/Header"
import Navbar from "../MainLayout/Navbar/Navbar"
import MostPick from "../MostPick/MostPick"
import ProfileDashboard from "../Profile/ProfileDashboard"
import TopGainer from "../TopGainer/TopGainer"
import TopLoser from "../TopLoser/TopLoser"
import HotPickChat from "../UserChat/HotPickChat"

const HotPick = () => {
    return (
        <>
            <Navbar />
            <div className='main-layout'>
                <div className='container-fluid hotpicks-invitee'>
                    <div className='row'>
                        <div className="mb-3">
                            <Header />
                        </div>
                        <div className=' mb-3'>
                            <ProfileDashboard />
                        </div>
                        <div className=' mb-3'>
                            <FeaturedAds />
                        </div>

                        <div className='col-xl-8 col-lg-12 col-md-12 col-sm-12'>
                            <div className="row">
                                <div className='col-md-12 mb-3'>
                                    <MostPick />
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <TopGainer />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <TopLoser />
                                </div>
                         
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                            <div className='mb-3'>
                                <ActiveExchange />
                            </div>
                            <div className='mb-3'>
                                <Community />
                            </div>
                            <div className="mb-3">
                                <Games />
                            </div>
                        </div>
                     
                        <div className="col-md-12 mb-3">
                                  <HotPickChat/>
                                </div>
                       
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default HotPick