import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveGames,
  dashboardSelector,
} from "../../slices/dashboardSlice";
import moment from "moment-timezone";
import { Carousel } from "primereact/carousel";
import { DateIcon } from "../../SVGIcon";
import { baseURL } from "../../config";
const HotActiveDuels = () => {
  const [getActiveDuels, setActiveDuels] = useState([]);
  let isUser = window.localStorage.getItem("userid");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getActiveGames({ userId: isUser }))
      .unwrap()
      .then((res) => {
        setActiveDuels(res);

      });
  }, [dispatch]);
  const { activeGamesList, isFetching } = useSelector(dashboardSelector);
  // console.log(activeGamesList.length,'activeGamesList');
  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  let timeInterval;
  const [sTime, setStartTime] = useState("");
  const [countdownTime, setCountdownTime] = useState({
    countdownDays: "",
    countdownHours: "",
    countdownMinutes: "",
    countdownSeconds: "",
  });

  const eventTimer = (evntString) => {
    let countdownDateTime = new Date(`${evntString}`).getTime();
    let nowDate = new Date().toISOString();
    let newtime = new Date(nowDate);
    let currentTime = new Date(nowDate).getTime();

    const remainingDayTime = countdownDateTime - currentTime;
    // console.log(remainingDayTime, "remainingDayTime");
    const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));

    const totalHours = Math.floor(remainingDayTime / (1000 * 60 * 60));

    const totalMinutes = Math.floor((remainingDayTime / 1000 / 60) % 60);
    const totalSeconds = Math.floor((remainingDayTime / 1000) % 60);

    let remainingHours = `${totalHours}H ${totalMinutes}M ${totalSeconds}S`;
    setCountdownTime(remainingHours);
    if (remainingDayTime <= -countdownDateTime) {
      clearInterval(timeInterval);
      return;
    }
    if (remainingDayTime <= 0) {
      clearInterval(timeInterval);
      return " 00:00";
    }

    return remainingHours;
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      eventTimer();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [sTime]);

  const timerTemplate = (rowData) => {
    const startTime = rowData.duel_end_time_utc;
    let araryofTime = rowData;

    let check12hr = moment(startTime).format("MM/DD/YYYY h:mm:ss A");
    setStartTime(startTime);
    return (
      <>
        <div id={check12hr}>
          <span className="timer">{eventTimer(rowData.duel_end_time_utc)}</span>
        </div>
      </>
    );
  };


  const productTemplate = (product) => { 
    const product_dulers = product?.duellers_details?.map((v) => { return (v) })

    return (
      <div className="border-1 surface-border border-round m-2 text-center hot-Active-card isjoined active-duels-carasoul">
        <div className="p-3 text-start">
          {product.is_duel_joined_by_loggedin_user === 1
            ? <div className="ribbon">JOINED</div> : <div className="ribbon" style={{ visibility: "hidden" }}>JOINED</div>}
          <h6>
            2nd Place - {product_dulers[1]?.score !== '' ? (<> {product_dulers[1]?.score >= "0" ? <>{product_dulers[1]?.score === "0" ? <span className="text-warning fw-bold cfs-6 mb-0 ms-2">{product_dulers[1]?.score}</span> : <span className="green-text fw-bold cfs-6 mb-0 ms-2">{product_dulers[1]?.score}%</span>}</> : <span className="text-danger fw-bold cfs-6 mb-0 ms-2">{product_dulers[1]?.score}%</span>}</>) : "-"}
          </h6>
          <h5 className="dark-blue mb-1 duel-name">{product.duel_name}</h5>
          <p className="green-text mb-0 fw-500">
            <span className="me-2 ">
              <DateIcon />
            </span>
            {eventTimer(product.duel_end_time_utc)} Remaining
          </p>

          <hr />
          <div className="d-flex">
            {product_dulers[0]?.avatar !== null && product_dulers[0]?.avatar !== "" ? <img src={`${baseURL}images/avatar/${product_dulers[0]?.avatar}`} alt="profile image" /> : (<><img src="/icon/default-profile.png" alt="profile image" /></>)}
            <div className="ms-4">
              <p className="mb-0 fw-500">1st Place</p>
              <h6 className="activeName text-truncate" style={{width:"135px"}}>{product_dulers[0]?.user_name}</h6>
              {product_dulers[0]?.score !== '' ? (<> {product_dulers[0]?.score >= "0" ? <>{product_dulers[0]?.score === "0" ? <p className="text-warning fw-bold cfs-6 mb-0 ms-2">{product_dulers[0]?.score}</p> : <p className="green-text fw-bold cfs-6 mb-0 ms-2">{product_dulers[0]?.score}%</p>}</> : <p className="text-danger fw-bold cfs-6 mb-0 ms-2 ">{product_dulers[0]?.score}%</p>}</>) : "-"}
              {/* <p className="green-text mb-0 fw-500">{product_dulers[0]?.score}%</p> */}
            </div>
          </div>
        </div>

        <Link className="btn blue-text fs-4 text-center btn-bg w-100" to={`/main-game/${product.id}`}
          state={
            {
              uniqueId: product.unique_id,
              duelId: product.id,
              is_duel_joined_by_loggedin_user: product.is_duel_joined_by_loggedin_user,
              is_loggedin_user_joined_multiple_times: product.is_loggedin_user_joined_multiple_times,
              duel_name: product.duel_name,
              duel_mode: product.duel_mode,
              duel_guid: product.duel_guid,
              slot: product.slot
            }

          }
        >

          Spectate

        </Link>
      </div>
    );
  };
  return (
    <>
      <div className="card card-custom gutter-b ">
        <div className="custom-card-header">
          <div className="d-flex justify-content-end card-title">
            <div className="mx-auto ">
              <h4 className="text-center text-uppercase custom-heading">
                Active Duels
              </h4>
            </div>
          </div>
        </div>
        <div className="hot-active-body">
          {getActiveDuels?.length > 0 ? (
            <>
              <Carousel
                className="active-duels-carasoul"
                value={getActiveDuels}
                numScroll={1} numVisible={3}
                responsiveOptions={responsiveOptions}
                //  circular
                autoplayInterval={3000}
                itemTemplate={productTemplate}
                showIndicators={false}
              />
            </>
          ) : (
            <>
              <p className="bg-gray fw-500" style={{ height: "262px" }}>There is no active duel data available yet!</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HotActiveDuels;