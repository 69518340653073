import { useState, useEffect } from "react";
import { Button, } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'
import { Column } from "primereact/column";
import Checkbox from "./Checkbox";
import { inviteDuelAPI } from "../../slices/userSlice";
import { DataTable } from "primereact/datatable";
import { CancleIcon, CheckIcon } from "../../SVGIcon";
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ToastContainer, toast } from "react-toastify";
import { baseURL } from "../../config";
const UserListModalComponent = ({ friendlist, getduelID }) => {

  const dispatch = useDispatch();

  const [list, setList] = useState([]);
  const [disableBtn, setdisableBtn] = useState(false);

  useEffect(() => {
    setList(friendlist);
  }, [list]);


  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const handleSelectAll = (e) => {
    debugger;
    setIsCheckAll(!isCheckAll);
    let localvarisable = [];


    let allSelectedUsers = list?.map((li) => {
      return {
        invite_user_id: li.user_id.toString(),
      };
    });
    setIsCheck(allSelectedUsers);
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {


    const { id, checked } = e.target;
    // console.log(id, checked, "idss");
    setIsCheck([...isCheck, { invite_user_id: id }]);

    setIsCheckAll(false);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item.invite_user_id !== id));

    }

    const isAllChecked = isCheck.length;
  };



  const catalog = list?.map(({ user_id, user_name }) => {
    return (
      <>
        <Checkbox
          key={user_id}
          type="checkbox"
          name={user_name}
          id={user_id}
          handleClick={handleClick}
          isChecked={isCheck.includes(user_id)}
        />
        {user_name}
      </>
    );
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    user_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  });
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <div className="d-flex flex-wrap gap-2 align-items-center justify-content-between m-3">
        <h4 className="m-0">Invite friends</h4>
        <span className="p-input-icon-left ">
          <i className="pi pi-search" />
          <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Search by name" />
        </span>
      </div>
    );
  };

  const header = renderHeader();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [rowClick, setRowClick] = useState(true);

  let filterInvitedId = selectedProducts.map((c) => {
    return {
      invite_user_id: c.user_id
    };
  });


  const countryBodyTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center gap-2 flex-row">
        <div className="community-pic">{rowData.avatar !== "" ? (<><img src={`${baseURL}${rowData.avatar}`} alt="friends_image" /></>) : (<><img src="/icon/default-profile.png" alt="friends_image" /> </>)}</div>
        <div className="me-3"><p className="fs-6 mb-0 text-uppercase">{rowData.user_name}</p></div>
      </div>
    );
  };
  const followersTemplate = (rowData) => {
    return (
      <>
        <div className="flex align-items-center gap-2">
          {rowData.is_follower === 1 ? (<><span className="pink-text fw-500"><CheckIcon /></span></>) : (<>  <span className="fw-500 text-danger"><CancleIcon /></span> </>)}
        </div>
      </>
    );
  };
  const friendsTemplate = (rowData) => {
    return (
      <>
        <div className="flex align-items-center gap-2">
          {rowData.is_friends === 1 ? (<><span className="pink-text fw-500"><CheckIcon /></span></>) : (<>  <span className="fw-500 text-danger"><CancleIcon /></span></>)}
        </div>
      </>
    );
  };
  const navigate = useNavigate();
  let locacluserid = window.localStorage.getItem("userid");

  const handleInvite = async () => {
    setdisableBtn(true);
    let InviteDuelData = {
      user_id: locacluserid,
      duel_id: getduelID,
      data: filterInvitedId
    }
    // console.log(InviteDuelData);
    if (selectedProducts?.length === 0) {
      window.alert("please select any friend to invite");
    }
    else {


      dispatch(inviteDuelAPI({ InviteDuelData }))

        .unwrap()
        .then((res) => {
          toast.success("Invite sent successfully");
          let newData = res.data;

          setTimeout(() => {
            navigate('/dashboard');
          }, 1000)

        })
        .catch((err) => {
          console.log(err, "errors");
        });
    }
  }
  return (
    <>
      <div className="mt-3">
        <DataTable value={list} header={header} filters={filters} onFilter={(e) => setFilters(e.filters)} selectionMode={rowClick ? null : 'checkbox'} globalFilterFields={['user_name']}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="user_id"
          tableStyle={{ minWidth: '50rem' }}
          emptyMessage="No duels found.">
          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>

          <Column body={countryBodyTemplate}
            field="user_name"
            header="Name"
            filter filterPlaceholder="Search by user name"
            filterField="user_name" showFilterMatchModes={false} showFilterMenuOptions={false}></Column>


          <Column field="is_follower" header="Follower"
            body={followersTemplate}
          ></Column>
          <Column field="is_friends" header="Friends"
            body={friendsTemplate}
          ></Column>
        </DataTable>
      </div>
      <div className="text-center">
        {disableBtn ? (<Button className="mt-3" disabled type="button">Submit</Button>) : (<Button className="mt-3" type="button" onClick={handleInvite}>Submit</Button>)}
      </div>

      <ToastContainer />
    </>
  );
};

export default UserListModalComponent;
