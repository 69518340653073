import { Row, Col } from "react-bootstrap";
import YourPosition from "./YourPosition";
import AllDulers from "./AllDulers";
import ComparisonDueler from "./ComparisonDueler";
import UnityGame from "./UnityGame";
import { useDispatch } from "react-redux";
import {
  getAllSlotDuelerByDuelId,
  specateAnotherDuelsSymbolListAPI,
  specateBattleSymbolSwappingIdAPI,
  specateBloodbathDuelsSymbolByIdAPI,
  specateDuelsSymbolByIdAPI,
  specateGetBloodbathBattleList,
  specateGetStandardBattleList,
  specateStandardBlockerBattleSymbolSwappingIdAPI,
  specateStandardSymbolSwappingIdAPI,
  specateSymbolSwappingIdAPI,
} from "../../slices/gameSlice";
import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import BattleComponent from "./BattleComponent";
import MainTraderEdit from "./MainTraderEdit";
import UserComparision from "./UserComparision";
import { editDuelById, getEditBloodbathCoinsAPI, getEditCoinsAPI } from "../../slices/editJoinSlice";
import { ToastContainer, toast } from "react-toastify";
import HotPickChat from "../UserChat/HotPickChat";


const PlayGame = () => {
  const { contestId } = useParams();
  const location = useLocation();
  const { state } = location;
  let isUser = window.localStorage.getItem("userid");

  const [duelSymbolsList, setDuelSymbolsList] = useState([]);
  const [duelersList, setAllDuelers] = useState([]);
  const [anotherSymbolsList, setAotherSymbolsList] = useState([]);
  const [dulersCount, setDulersCount] = useState();
  const [duelInfo, setDuelInfo] = useState();
  const [duelmode, setDuelMode] = useState([]);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(false);
  const [selectedBlocker, setSelectedBlocker] = useState(false);
  const [battleselected, setbattleSelected] = useState(false);
  const [getDuelSlot, setgetDuelSlot] = useState([]);
  const [showFirst, setShowFirst] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const [showBlocker, setShowBlocker] = useState(false);
  const [standardList, setStandardList] = useState([]);
  const [multiUser, setMultiUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reSet, setreSet] = useState(false)
  const [BattleswapSymbol, setBattleswapSymbol] = useState([])
  const [duelByID, setDuelByID] = useState([]);
  const [editCoinList, setEditCoinList] = useState([]);
  const [getEachCoin, setEachCoin] = useState([]);
  const [filterResult, setFilterResult] = useState([])
  const [selectCoins, SetSelectedCoins] = useState([]);
  const [isEditing, setisEditing] = useState(null);
  const [isBattleEditing, setisBattleEditing] = useState(null);
  const [activeTier, setActiveTier] = useState();
  const [swapSymbol, setswapSymbol] = useState([]);

  const blocker = standardList[5]?.battle_name;

  const dispatch = useDispatch();

  // edit trade start
  let exchangeID1 = duelByID?.exchange_id;
  let activeMode = duelByID?.duel_mode;


  let exchangeId = {
    loggedin_user_id: isUser,
    duel_id: contestId,
    unique_id: location.state?.uniqueId,
    exchange_id: exchangeID1

  };
  useEffect(() => {
    dispatch(editDuelById({ loggedin_user_id: isUser, duel_id: contestId, unique_id: location.state?.uniqueId }))
      .unwrap()
      .then((res) => {
        setDuelByID(res);
      });

  }, [dispatch]);


  //  let exchangeID1 = duelByID?.duel_exchange;
  const handlegetEditCoinsAPI = () => {
    if (duelmode === "Standard" || duelmode === "Standard & Battle") {
      setLoading(true);
      dispatch(getEditCoinsAPI({ exchangeId }))
        .unwrap()
        .then((res) => {
          let result = res.data

          let filterD = res.data.filter((d) => d.tier !== "D")
          setEachCoin(filterD);
          setFilterResult(filterD)
          SetSelectedCoins(filterD);
          setActiveTier('All');
          setLoading(false);

        })
        .catch((err) => {
          console.log(err, "errors");
        });
    }

    else if (duelmode === "Bloodbath" || duelmode === "Bloodbath & Battle") {
      setLoading(true);
      dispatch(getEditBloodbathCoinsAPI({ exchangeId }))
        .unwrap()
        .then((res) => {
          let result = res.data
          let filterD = res.data.filter((d) => d.tier !== "D")
          setEachCoin(filterD);
          setFilterResult(filterD)
          SetSelectedCoins(filterD);
          setActiveTier('All');
          setLoading(false);

        })
        .catch((err) => {
          console.log(err, "errors");
        });
    }
  }

  const handlestandardBattleList = () => {
    setLoading(true);
    if (duelmode === "Bloodbath & Battle") {
      dispatch(specateGetBloodbathBattleList())
        .unwrap()
        .then((res) => {
          setStandardList(res?.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
    else {
      dispatch(specateGetStandardBattleList())
        .unwrap()
        .then((res) => {
          setStandardList(res?.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }
 

  const handleAllSlotDuelerByid = () => {
    let duelerData = {
      duel_guid: location.state?.duel_guid,
     
    };
    dispatch(getAllSlotDuelerByDuelId({ duelerData }))
      .unwrap()
      .then((res) => {
        setDuelSymbolsList(res?.data);
        setDulersCount(res?.count_total_duelers);
      })
      .catch((err) => console.log(err));

  };


  // **** trade swap icons
  const handleSwitch = (rowData) => {
    let replaced_obj = {
      join_duel_symbol_id: selected.join_duel_symbol_id,
      join_duel_symbol_exchange: rowData.exchange_id,
      base_symbol_id: rowData.id,
      percentage_change: "0",
      join_duel_base_symbol: rowData.base_symbol,
      logo: rowData.logo,
      join_duel_symbol_tier: rowData.tier,
    }
    const updatedDuelersList = duelersList.map((item) => {
      if (item.base_symbol_id === selected?.base_symbol_id) {
        if (rowData.tier === selected.join_duel_symbol_tier) {
          if (item?.battle_person_id > 0) {
            replaced_obj["before_apply_blocker"] = item?.before_apply_blocker
            replaced_obj["is_blocker_apply"] = item?.is_blocker_apply
            replaced_obj["battle_person_id"] = item?.battle_person_id
          }

          return replaced_obj; // Replace the object with the matching object
        }
        else {

          toast.error("Choose same tier for swap");
        }

      }
      return item; // Keep the original object if no match

    });
    setAllDuelers(updatedDuelersList);
  }


  const getSymbolSwappingId = (swapId, swapExchangeId, swapSymbol, swapTier, swapCurrentPrice) => {
    setLoading(true);
    let SwapSymbolsData = {
      slot_symbol_id: swapId,
      swapped_exchange_id: swapExchangeId,
      swapped_base_symbol: swapSymbol,
      swapped_tier: swapTier,
      start_price: swapCurrentPrice,
    };

    dispatch(specateSymbolSwappingIdAPI({ SwapSymbolsData }))
      .unwrap()
      .then((res) => {
        setswapSymbol(res?.data);
        if (activeMode === "Standard") {
          handleJoinedDuelersListByDuel();
        }
        else if (activeMode === "Standard & Battle") {
          handleJoinedDuelersListByDuel();
        }
        else if (activeMode === "Bloodbath") {
          handleJoinedBattleDuelersListByDuel();
        }
        else if (activeMode === "Bloodbath & Battle") {
          handleJoinedBattleDuelersListByDuel();
        }
        setLoading(false);
        setreSet(false);
        setSelected(false);
        handlegetEditCoinsAPI();
        setisEditing(null);


      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.message);

      });
  };
  const handleSwapDuel = (swapId, swapExchangeId, swapSymbol, swapTier, swapCurrentPrice) => {
    getSymbolSwappingId(swapId, swapExchangeId, swapSymbol, swapTier, swapCurrentPrice);
  };

  const handleEdit = (data) => {
    setisEditing(data);
  }

  const [buttonBattle, setbuttonBattle] = useState([]);

  // ******* Battle add id and percentange 
  const handleBattleEdit = (data) => {
    if (duelmode === "Bloodbath & Battle") {
      const battle = anotherSymbolsList.map(item =>
        item.base_symbol_id === battleselected?.base_symbol_id ? {
          ...item, id: data?.id,
          bloodbath_percentage: data?.bloodbath_percentage
        } : item)
      setAotherSymbolsList(battle);
      setbuttonBattle(battle);

    }
    else {
      const battle = anotherSymbolsList.map(item =>
        item.base_symbol_id === battleselected?.base_symbol_id ? {
          ...item, id: data?.id,
          standard_percentage: data?.standard_percentage
        } : item)
      setAotherSymbolsList(battle);
      setbuttonBattle(battle);



    }
  };


  // ******* submit Battle add id and percentange 
  const getBattleSymbolSwappingId = (dataBattle,) => {
    const BattleSwapSymbolsData = {
      duel_id: contestId,
      symbol_unique_id: dataBattle.symbol_unique_id,
      bloodbath_symbol_exchange_id: dataBattle.exchange_id,
      bloodbath_symbol: dataBattle.symbol,
      bloodbath_base_symbol: dataBattle.base_symbol,
      bloodbath_symbol_tier: dataBattle.tier,
      bloodbath_person_id: dataBattle.id,
      bloodbath_person_percentage: dataBattle.bloodbath_percentage,
      person_id_started_bloodbath: isUser,
      user_id_againest_bloodbath: multiUser.user_id,
      started_join_unique_id: location.state?.uniqueId

    };

    const StandardBattleSymbolsData = {
      duel_id: contestId,
      symbol_unique_id: dataBattle.symbol_unique_id,
      battle_symbol_exchange_id: dataBattle.exchange_id,
      battle_symbol: dataBattle.symbol,
      battle_base_symbol: dataBattle.base_symbol,
      battle_symbol_tier: dataBattle.tier,
      battle_person_id: dataBattle.id,
      battle_person_percentage: dataBattle.standard_percentage,
      person_id_started_battle: isUser,
      user_id_againest_battle: multiUser.user_id,
      started_join_unique_id: location.state?.uniqueId

    }

    if (duelmode === "Bloodbath & Battle") {
      setLoading(true);
      dispatch(specateBattleSymbolSwappingIdAPI({ BattleSwapSymbolsData }))
        .unwrap()
        .then((res) => {
          setBattleswapSymbol(res);
          setreSet(false);
          setbattleSelected(false);
          setisBattleEditing(null);
          handlestandardBattleList();
          getAnotherUserSymbolsList(multiUser.user_id, { join_unique_id: multiUser?.join_unique_id });


        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(err.message);
        });
    }
    else if (duelmode === "Standard & Battle") {
      setLoading(true);
      dispatch(specateStandardSymbolSwappingIdAPI({ StandardBattleSymbolsData }))
        .unwrap()
        .then((res) => {
          setBattleswapSymbol(res);
          setreSet(false);
          setbattleSelected(false);
          setisBattleEditing(null);
          handlestandardBattleList();
          getAnotherUserSymbolsList(multiUser.user_id, { join_unique_id: multiUser?.join_unique_id });

        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(err.message);
        });
    }

  };

  const tipHide = BattleswapSymbol?.status
  // Balltle with blocker battle
  const getBlockerBattleSymbolSwappingId = (dataBattle,) => {
    const StandardBlockerBattleSwapSymbolsData = {
      duel_id: contestId,
      join_duel_symbol_id: dataBattle.join_duel_symbol_id,
      blocker_symbol: dataBattle.join_duel_symbol,
      blocker_base_symbol: dataBattle.join_duel_base_symbol,
      user_id_apply_blocker: isUser
    };
    if (duelmode === "Bloodbath & Battle") {
      setLoading(true);
      dispatch(specateStandardBlockerBattleSymbolSwappingIdAPI({ StandardBlockerBattleSwapSymbolsData }))
        .unwrap()
        .then((res) => {
          setBattleswapSymbol(res);
          handleJoinedBattleDuelersListByDuel();
          setSelectedBlocker(false)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(err.message);
        });
    }
    else if (duelmode === "Standard & Battle") {
      setLoading(true);
      dispatch(specateStandardBlockerBattleSymbolSwappingIdAPI({ StandardBlockerBattleSwapSymbolsData }))
        .unwrap()
        .then((res) => {
          setBattleswapSymbol(res);
          handleJoinedDuelersListByDuel();
          setSelectedBlocker(false)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(err.message);
        });
    }
  };



  const handlegetBlockerBattle = (dataBattle) => {
    getBlockerBattleSymbolSwappingId(dataBattle);
    return () => {
      getBlockerBattleSymbolSwappingId(dataBattle);
    }
  }

  const handlecancel = () => {
    if (activeMode === "Standard" || activeMode === "Standard & Battle") {
      handleJoinedDuelersListByDuel();
    }
    else {
      handleJoinedBattleDuelersListByDuel();
    }
    setSelected(false);
  };

  const [selectedOption, setSelectedOption] = useState(1);

  const handleOptionClick = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

  };


  useEffect(() => {
    handleAllSlotDuelerByid();

  }, [dispatch, selectedOption]);

  let localuserId = window.localStorage.getItem("userid");

  const handleJoinedDuelersListByDuel = () => {
    let JoinedDuelData = {
      duel_id: location.state?.duelId,
      unique_id: location.state?.uniqueId,
      loggedin_user_id: localuserId,
    };

    setLoading(true);
    dispatch(specateDuelsSymbolByIdAPI({ JoinedDuelData }))
      .unwrap()
      .then((res) => {
        setAllDuelers(res?.data);
        setDuelInfo(res);
        setDuelMode(res?.duel_mode);
        setLoading(false);

      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });

  };

  const handleJoinedBattleDuelersListByDuel = () => {
    let JoinedDuelData = {
      duel_id: location.state?.duelId,
      unique_id: location.state?.uniqueId,
      loggedin_user_id: localuserId,
    };
    setLoading(true);
    dispatch(specateBloodbathDuelsSymbolByIdAPI({ JoinedDuelData }))
      .unwrap()
      .then((res) => {

        setAllDuelers(res?.data);
        setDuelInfo(res);
        setDuelMode(res?.duel_mode);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });

  };

  useEffect(() => {
    if (activeMode === "Standard") {
      handleJoinedDuelersListByDuel();
    }
    else if (activeMode === "Standard & Battle") {
      handleJoinedDuelersListByDuel();
    }
    else if (activeMode === "Bloodbath") {
      handleJoinedBattleDuelersListByDuel();
    }
    else if (activeMode === "Bloodbath & Battle") {
      handleJoinedBattleDuelersListByDuel();
    }
  }, [activeMode]);

  const getAnotherUserSymbolsList = (anotherUserId, selectedDuelId) => {

    let AnotherSymbolsData = {
      join_unique_id: selectedDuelId,
      user_id: anotherUserId,
    };


    dispatch(specateAnotherDuelsSymbolListAPI({ AnotherSymbolsData }))
      .unwrap()
      .then((res) => {
        setMultiUser(res);
        setAotherSymbolsList(res?.data);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const handleClickUser = (anotherUserId, selectedDuelId) => {
    getAnotherUserSymbolsList(anotherUserId, selectedDuelId);
  };
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (duelersList === 9) {
      setDisabled(false);
    }
    else {
      setDisabled(true);
    }
  }, [duelersList])

  const Trader = duelInfo?.trader

  return (
    <>
      <div className="card card-custom gutter-b px-0">
        <div className="custom-card-header">
          <div className="row card-title mx-auto">
            <div className="col-md-7 d-flex text-end mobile-list">

              {location.state?.is_duel_joined_by_loggedin_user === 1
                ? <h4 className='text-uppercase custom-heading'> {location.state?.duel_name}</h4>
                : <h4 className="text-uppercase custom-heading">LEADERBOARD </h4>}

            </div>
            <div className="col-md-5 d-flex text-end mobile-list align-items-center">
 
              <span className="text-center fw-500 ms-3">
                {location.state?.duel_mode}
              </span>
            </div>

          </div>
        </div>
        <div className="p-3">
          <Row className="reverse-game">
            <Col lg={4}>
              <Row>
                {location.state?.is_duel_joined_by_loggedin_user === 1
                  ? <Col lg={12} className="mb-3">
                    <YourPosition dualUser={location.state?.is_loggedin_user_joined_multiple_times} getDuelSlot={getDuelSlot}
                      handleOptionClick={handleOptionClick} selectedOption={selectedOption}
                      duelInfo={duelInfo}
                      setSelectedOption={setSelectedOption} dualName={location.state?.duel_name} duelSymbolsList={duelSymbolsList} />
                  </Col>
                  : null}
                <Col lg={12} >
                  <div className="card card-custom gutter-b ">
                    <div className="custom-card-header">
                      <div className="d-flex justify-content-end card-title mb-0">
                        <div className="mx-auto ">
                          <h4 className="text-uppercase custom-heading">
                            All Duelers
                          </h4>
                        </div>
                        <span className="fw-bolder me-4 fs-4">
                          {dulersCount}
                        </span>
                      </div>
                    </div>
                    <AllDulers
                      duelSymbolsList={duelSymbolsList}
                      handleClickUser={handleClickUser} selected={selected}
                      setSelected={setSelected}
                      dualUser={location.state?.is_loggedin_user_joined_multiple_times}
                      style={{ height: "459px" }}
                    />

                  </div>
                </Col>
              </Row>
            </Col>

            <Col lg={8} className="Unity-game-col">
              <UnityGame />
            </Col>
          </Row>
          <div className="mt-3">
            <Row>
              {show && location.state?.is_duel_joined_by_loggedin_user === 0 ? <Col lg={4} md={6}>
                <UserComparision
                  duelersList={duelersList}
                  setAllDuelers={setAllDuelers}
                  duelSymbolsList={duelSymbolsList}
                  duelInfo={duelInfo}
                  duelmode={location.state?.duel_mode}
                  isJoined={location.state?.is_duel_joined_by_loggedin_user}
                  anotherSymbolsList={anotherSymbolsList} show={show}
                  handlestandardBattleList={handlestandardBattleList}
                  dualUser={location.state?.is_loggedin_user_joined_multiple_times}
                  multiUser={multiUser}
                />
              </Col> : null}
              {location.state?.is_duel_joined_by_loggedin_user === 1
                ? <><Col lg={4} md={6}>
                  <ComparisonDueler
                    duelersList={duelersList}
                    setAllDuelers={setAllDuelers}
                    duelSymbolsList={duelSymbolsList}
                    duelInfo={duelInfo}
                    duelmode={location.state?.duel_mode}
                    isJoined={location.state?.is_duel_joined_by_loggedin_user}
                    anotherSymbolsList={anotherSymbolsList} show={show}
                    showFirst={showFirst} setShowFirst={setShowFirst}
                    showSecond={showSecond} setShowSecond={setShowSecond}
                    setShowBlocker={setShowBlocker} showBlocker={showBlocker}
                    handlestandardBattleList={handlestandardBattleList}
                    handlegetEditCoinsAPI={handlegetEditCoinsAPI}
                    selected={selected} setSelected={setSelected}
                    isEditing={isEditing} setisEditing={setisEditing} handleEdit={handleEdit}
                    handleSwapDuel={handleSwapDuel}
                    multiUser={multiUser} loading={loading}
                    setswapSymbol={setswapSymbol} handlecancel={handlecancel}
                    setbattleSelected={setbattleSelected} battleselected={battleselected}
                    isBattleEditing={isBattleEditing}
                    getBattleSymbolSwappingId={getBattleSymbolSwappingId}
                    setSelectedBlocker={setSelectedBlocker} selectedBlocker={selectedBlocker}
                    buttonBattle={buttonBattle} reSet={reSet} setreSet={setreSet} handlegetBlockerBattle={handlegetBlockerBattle}
                    exchangeID1={exchangeID1} swapSymbol={swapSymbol} Trader={Trader} tipHide={tipHide}

                  />
                </Col>



                  {
                    showFirst && (
                      <Col lg={4} md={6}>
                        <MainTraderEdit
                          duelByID={duelByID}
                          getEachCoin={getEachCoin}
                          filterResult={filterResult}
                          setFilterResult={setFilterResult}
                          selectCoins={selectCoins}
                          activeTier={activeTier}
                          setActiveTier={setActiveTier}
                          selected={selected}
                          isEditing={isEditing} setisEditing={setisEditing} handleEdit={handleEdit}
                          isBattleEditing={isBattleEditing}
                          handleSwitch={handleSwitch} loading={loading} setbattleSelected={setbattleSelected} setreSet={setreSet}
                        />
                      </Col>
                    )
                  }
                  {
                    showSecond && (
                      <Col lg={4} md={6} className="battle-col">
                        <BattleComponent standardList={standardList} duelmode={duelmode}
                          setisBattleEditing={setisBattleEditing} isBattleEditing={isBattleEditing}
                          handleBattleEdit={handleBattleEdit} battleselected={battleselected} setbuttonBattle={setbuttonBattle}
                          buttonBattle={buttonBattle} setreSet={setreSet} anotherSymbolsList={anotherSymbolsList} uniqueId={location.state?.uniqueId}
                          loading={loading} reSet={reSet} />
                      </Col>
                    )
                  }
                  {showFirst || showSecond ? <Col lg={4} md={12} sm={12} className="hot-pick-chat-col">
                    <HotPickChat />
                  </Col>
                    : <Col lg={8} md={6} sm={12} className="hot-pick-chat-col2">
                      <HotPickChat />
                    </Col>
                  }
                </>

                : <>
                  {show ? <Col lg={8} md={6} sm={12}>
                    <HotPickChat />
                  </Col>
                    : <Col lg={12} md={12} sm={12}>
                      <HotPickChat />
                    </Col>
                  }</>
              }

            </Row>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PlayGame;
