import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL } from "../config";
import authHeader from "../util/Authheader";
import axios from "axios";

//api/hotpick/
  //GET all hotpicks/get-most-picked-symbols-allexchange
  export const getHotPickAllCryptoAPI = createAsyncThunk(
    "api/hotpicks/get-most-picked-symbols-allexchange",
    async (args, thunkAPI) => { 
      const urlname = `${baseURL}api/hotpicks/get-most-picked-symbols-allexchange`;
      return await axios
        .get(urlname, { headers: authHeader() })
        .then((response) => {
          let data = response.data;
  
          return data;
        })
        .catch((err) => {
          console.log(err, "get delete Videos of users");
        });
    }
  );

  //GET all hotpicks/get-most-picked-symbols-crypto
export const getHotPickCryptoAPI = createAsyncThunk(
    "api/hotpicks/get-most-picked-symbols-crypto",
    async (args, thunkAPI) => { 
      const urlname = `${baseURL}api/hotpicks/get-most-picked-symbols-crypto`;
      return await axios
        .get(urlname, { headers: authHeader() })
        .then((response) => {
          let data = response.data;
  
          return data;
        })
        .catch((err) => {
          console.log(err, "get delete Videos of users");
        });
    }
  );

    //GET all hotpicks/get-most-picked-symbols-nyse
export const getHotPickNyseAPI = createAsyncThunk(
  "api/hotpicks/get-most-picked-symbols-nyse",
  async (args, thunkAPI) => { 
    const urlname = `${baseURL}api/hotpicks/get-most-picked-symbols-nyse`;
    return await axios
      .get(urlname, { headers: authHeader() })
      .then((response) => {
        let data = response.data;

        return data;
      })
      .catch((err) => {
        console.log(err, "get delete Videos of users");
      });
  }
);

  //GET all hotpicks/get-most-picked-symbols-nasdaq
  export const getHotPickNasdaqAPI = createAsyncThunk(
    "api/hotpicks/get-most-picked-symbols-nasdaq",
    async (args, thunkAPI) => { 
      const urlname = `${baseURL}api/hotpicks/get-most-picked-symbols-nasdaq`;
      return await axios
        .get(urlname, { headers: authHeader() })
        .then((response) => {
          let data = response.data;
  
          return data;
        })
        .catch((err) => {
          console.log(err, "get delete Videos of users");
        });
    }
  );

      //GET all api/hotpicks/get-top-gainers-all-exchange
export const getHotPickTopAllGainerAPI = createAsyncThunk( "api/hotpicks/get-top-gainers-all-exchange", async (args, thunkAPI) => { 
  const url = `${baseURL}api/hotpicks/get-top-gainers-all-exchange`;
  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response.data?.data;
      return data;
    })
    .catch((err) => {
      // console.log(err, "get-paid-games");
      if (err.response) {
          const message = err.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    });
})

    //GET all api/hotpicks/get-top-gainers-for-crypto
export const getHotPickTopGainerAPI = createAsyncThunk( "api/hotpicks/get-top-gainers-for-crypto", async (args, thunkAPI) => { 
  const url = `${baseURL}api/hotpicks/get-top-gainers-for-crypto`;
  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response.data?.data;
      return data;
    })
    .catch((err) => {
      // console.log(err, "get-paid-games");
      if (err.response) {
          const message = err.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    });
})

    //GET all api/hotpicks/get-top-gainers-nyse-exchange
    export const getHotPickTopGainerNyseAPI = createAsyncThunk( "api/hotpicks/get-top-gainers-nyse-exchange", async (args, thunkAPI) => { 
      const url = `${baseURL}api/hotpicks/get-top-gainers-nyse-exchange`;
      return await axios
        .get(url, { headers: authHeader() })
        .then((response) => {
          let data = response.data?.data;
          return data;
        })
        .catch((err) => {
          // console.log(err, "get-paid-games");
          if (err.response) {
              const message = err.response.data.message;
              return thunkAPI.rejectWithValue(message);
            }
        });
    })

    
    //GET all api/hotpicks/get-top-gainers-nasdaq-exchange
    export const getHotPickTopGainerNasdaqAPI = createAsyncThunk( "api/hotpicks/get-top-gainers-nasdaq-exchange", async (args, thunkAPI) => { 
      const url = `${baseURL}api/hotpicks/get-top-gainers-nasdaq-exchange`;
      return await axios
        .get(url, { headers: authHeader() })
        .then((response) => {
          let data = response.data?.data;
          return data;
        })
        .catch((err) => {
          // console.log(err, "get-paid-games");
          if (err.response) {
              const message = err.response.data.message;
              return thunkAPI.rejectWithValue(message);
            }
        });
    })

         //GET all api/hotpicks/get-top-tankers-all-exchange
export const getHotPickTopAllTankersAPI = createAsyncThunk( "api/hotpicks/get-top-tankers-all-exchange", async (args, thunkAPI) => { 
  const url = `${baseURL}api/hotpicks/get-top-tankers-all-exchange`;
  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response.data?.data;
      return data;
    })
    .catch((err) => {
      // console.log(err, "get-paid-games");
      if (err.response) {
          const message = err.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    });
})

     //GET all api/hotpicks/get-top-tankers-for-crypto
export const getHotPickTopTankersAPI = createAsyncThunk( "api/hotpicks/get-top-tankers-for-crypto", async (args, thunkAPI) => { 
  const url = `${baseURL}api/hotpicks/get-top-tankers-for-crypto`;
  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response.data?.data;
      return data;
    })
    .catch((err) => {
      // console.log(err, "get-paid-games");
      if (err.response) {
          const message = err.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    });
})

     //GET all api/hotpicks/get-top-tankers-nyse-exchange
     export const getHotPickTopNyseTankersAPI = createAsyncThunk( "api/hotpicks/get-top-tankers-nyse-exchange", async (args, thunkAPI) => { 
      const url = `${baseURL}api/hotpicks/get-top-tankers-nyse-exchange`;
      return await axios
        .get(url, { headers: authHeader() })
        .then((response) => {
          let data = response.data?.data;
          return data;
        })
        .catch((err) => {
          // console.log(err, "get-paid-games");
          if (err.response) {
              const message = err.response.data.message;
              return thunkAPI.rejectWithValue(message);
            }
        });
    })

         //GET all api/hotpicks/get-top-tankers-nasdaq-exchange
export const getHotPickTopNasdaqTankersAPI = createAsyncThunk( "api/hotpicks/get-top-tankers-nasdaq-exchange", async (args, thunkAPI) => { 
  const url = `${baseURL}api/hotpicks/get-top-tankers-nasdaq-exchange`;
  return await axios
    .get(url, { headers: authHeader() })
    .then((response) => {
      let data = response.data?.data;
      return data;
    })
    .catch((err) => {
      // console.log(err, "get-paid-games");
      if (err.response) {
          const message = err.response.data.message;
          return thunkAPI.rejectWithValue(message);
        }
    });
})

const hotpickSlice = createSlice({
    name: "enter_Hotpick",
    initialState: {      
      status: null,
      getHotPickTopGainerList:[],
      getHotPickTopGainerNyseList:[],
      getHotPickTopGainerNasdaqList:[],
      getHotPickTopTankerNyseList:[],
      getHotPickTopTankerNasdaqList:[],
      getHotPickCryptoAPI:[],
      getHotPickNyseAPI:[],
      getHotPickNasdaqAPI:[],
      getHotPickAllCryptoAPI:[]
    },
    extraReducers: (builder) => {
      builder
      //Get getHotPickCryptoAPI List
    .addCase(getHotPickCryptoAPI.pending, (state,action) => {
        state.status = "loading";
      })
      .addCase(getHotPickCryptoAPI.fulfilled, (state, action) => {
        state.getHotPickCryptoAPI = action.payload;
        state.status = "success";
      })
      .addCase(getHotPickCryptoAPI.rejected, (state, action) => {
        state.status = "failed";
      })

       //Get getHotPickCryptoAPI List
    .addCase(getHotPickAllCryptoAPI.pending, (state,action) => {
      state.status = "loading";
    })
    .addCase(getHotPickAllCryptoAPI.fulfilled, (state, action) => {
      state.getHotPickAllCryptoAPI = action.payload;
      state.status = "success";
    })
    .addCase(getHotPickAllCryptoAPI.rejected, (state, action) => {
      state.status = "failed";
    })

        //Get getHotPickNyseAPI List
    .addCase(getHotPickNyseAPI.pending, (state,action) => {
      state.status = "loading";
    })
    .addCase(getHotPickNyseAPI.fulfilled, (state, action) => {
      state.getHotPickNyseAPI = action.payload;
      state.status = "success";
    })
    .addCase(getHotPickNyseAPI.rejected, (state, action) => {
      state.status = "failed";
    })

      //Get getHotPickNasdaqAPI List
      .addCase(getHotPickNasdaqAPI.pending, (state,action) => {
        state.status = "loading";
      })
      .addCase(getHotPickNasdaqAPI.fulfilled, (state, action) => {
        state.getHotPickNasdaqAPI = action.payload;
        state.status = "success";
      })
      .addCase(getHotPickNasdaqAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //Get getHotPickTopGainerAPI List
    .addCase(getHotPickTopGainerAPI.pending, (state,action) => {
      state.status = "loading";
    })
    .addCase(getHotPickTopGainerAPI.fulfilled, (state, action) => {
      state.getHotPickTopGainerList = action.payload;
      state.status = "success";
    })
    .addCase(getHotPickTopGainerAPI.rejected, (state, action) => {
      state.status = "failed";
    })

       //Get getHotPickTopGainerNyseAPI List
       .addCase(getHotPickTopGainerNyseAPI.pending, (state,action) => {
        state.status = "loading";
      })
      .addCase(getHotPickTopGainerNyseAPI.fulfilled, (state, action) => {
        state.getHotPickTopGainerNyseList = action.payload;
        state.status = "success";
      })
      .addCase(getHotPickTopGainerNyseAPI.rejected, (state, action) => {
        state.status = "failed";
      })

         //Get getHotPickTopGainerNasdaqAPI List
    .addCase(getHotPickTopGainerNasdaqAPI.pending, (state,action) => {
      state.status = "loading";
    })
    .addCase(getHotPickTopGainerNasdaqAPI.fulfilled, (state, action) => {
      state.getHotPickTopGainerNasdaqList = action.payload;
      state.status = "success";
    })
    .addCase(getHotPickTopGainerNasdaqAPI.rejected, (state, action) => {
      state.status = "failed";
    })

      //Get getHotPickTopTankersAPI List
      .addCase(getHotPickTopTankersAPI.pending, (state,action) => {
        state.status = "loading";
      })
      .addCase(getHotPickTopTankersAPI.fulfilled, (state, action) => {
        state.getHotPickTopGainerList = action.payload;
        state.status = "success";
      })
      .addCase(getHotPickTopTankersAPI.rejected, (state, action) => {
        state.status = "failed";
      })

       //Get getHotPickTopTankersAPI List
       .addCase(getHotPickTopAllTankersAPI.pending, (state,action) => {
        state.status = "loading";
      })
      .addCase(getHotPickTopAllTankersAPI.fulfilled, (state, action) => {
        state.getHotPickTopGainerList = action.payload;
        state.status = "success";
      })
      .addCase(getHotPickTopAllTankersAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //Get getHotPickTopNyseTankersAPI List
      .addCase(getHotPickTopNyseTankersAPI.pending, (state,action) => {
        state.status = "loading";
      })
      .addCase(getHotPickTopNyseTankersAPI.fulfilled, (state, action) => {
        state.getHotPickTopTankerNyseList = action.payload;
        state.status = "success";
      })
      .addCase(getHotPickTopNyseTankersAPI.rejected, (state, action) => {
        state.status = "failed";
      })

      //Get getHotPickTopNasdaqTankersAPI List
      .addCase(getHotPickTopNasdaqTankersAPI.pending, (state,action) => {
        state.status = "loading";
      })
      .addCase(getHotPickTopNasdaqTankersAPI.fulfilled, (state, action) => {
        state.getHotPickTopTankerNasdaqList = action.payload;
        state.status = "success";
      })
      .addCase(getHotPickTopNasdaqTankersAPI.rejected, (state, action) => {
        state.status = "failed";
      })
  
    },
  });
  
  export default hotpickSlice.reducer;