import React, { useState } from "react"
import { PlusIcon, MinuesIcon } from "../../SVGIcon";

const EditDuelsComponent = (props) => {
   

  return (
   <>
     <div className="d-flex justify-content-between align-items-center row-bg mt-3 mb-3 " >
                    <div className="d-flex align-items-center">
                        <img src={props.logo} className="img-fluid img-coins" alt={props.nameShort}  />
                    </div>
                    <div className="d-flex align-items-start flex-column fix-width text-start">
                        <p className="mb-0 duel-heading fs-5 ms-0 ms-sm-4 text-uppercase pink-text">{props.baseSymbol}</p>
                        <p className="mb-0 duel-heading fs-6 ms-0 ms-sm-4">{props.nameShort}</p>
                    </div>
                    <p className="mb-0 text-start">{props.currentPrice}</p>
                    <p className="mb-0 text-start">
                    { props.pct_change_factor >= "0" ? <>{props.pct_change_factor === "0" ? <p className="text-warning fw-bold cfs-6 mb-0 " style={{marginLeft: "-0.2rem!important"}}> 0 </p> 
                          : <p className="green-text fw-bold cfs-6 mb-0" style={{marginLeft: "-0.2rem!important"}}>{props.pct_change_factor}%</p> }</> :<p className="text-danger fw-bold cfs-6 mb-0" style={{marginLeft: "-0.2rem!important"}}> {props.pct_change_factor}% </p>}
                    </p>
                    <p className="mb-0">{props.tier}</p>
                   {props.buttonProps === 'Minus' ? <button className="btn btn-primary duel-btn p-2" id={props.id}disabled><MinuesIcon /></button>
                    : <button className="btn  duel-btn p-2" id={props.id} onClick={() => props.onSelect(props.id) }><PlusIcon /></button>}

                </div>
                
               
               
               
   </>
  )
}

export default EditDuelsComponent