import { useState, useRef } from "react";
import { omit } from "lodash";

const RegisterForm = (callback) => {
  const [mob, setMob] = useState();
  const handlemobileChange = (event) => {
    let values = event.target.value;

    setMob(values);


    valid(values) ? setValid(true) : setValid(false);

  };
  const [values, setValues] = useState({ username: '', email: '', mobile: '', cnfpassword: '', password: '', terms: '', birth_date: '' });
  const [errors, setErrors] = useState({});

  const validate = (name, evt, values) => {
    switch (name) {

      case "username":
        if (!values) {
          setErrors({
            ...errors,
            username: "Username is required.",
          });
        } else if (!new RegExp(/^[a-zA-Z0-9_]{6,12}$/).test(values)) {
          setErrors({
            ...errors,
            username:
              "Contains characters between 6 to 12 and whiteSpace and special characters are not allowed.",
          });
        } else {
          let newObj = omit(errors, "username");
          setErrors(newObj);
        }
        break;

      case "mobile":

        let newObj = omit(errors, "mobile");
        setErrors(newObj);


        break;

      case "email":
        if (!values) {
          setErrors({
            ...errors,
            email: "Email address should be required.",
          });
        } else if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(values)
        ) {
          setErrors({
            ...errors,
            email: "Enter a valid email address",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;

      case "password":
        if (!values) {
          setErrors({
            ...errors,
            password: "Password is required.",
          });
        } else if (
          !new RegExp(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
          ).test(values)
        ) {
          setErrors({
            ...errors,
            password:
              "Password should be at least 8 characters containing uppercase, lowercase, number and special characters",
          });
        } else if (values.cnfpassword && values !== values.cnfpassword) {
          setErrors({
            ...errors,
            password: "Password and Confirm Password does not match.",
          });
        } else {
          let newObj = omit(errors, "password");
          setErrors(newObj);
        }
        break;

      case "cnfpassword":
        if (!values.cnfpassword) {
          setErrors({
            ...errors,
            cnfpassword: "Confirm Password is required.",
          });
        }

        if (values.password && values !== values.password) {
          setErrors({
            ...errors,

            cnfpassword: "Password does not match.",
          });
        } else {
          let newObj = omit(errors, "cnfpassword");
          setErrors(newObj);
        }
        break;

      case "birth_date":
        const currentDate = new Date();
        const userDob = new Date(values.birth_date);

        const ageDiff = currentDate.getFullYear() - userDob.getFullYear();
        if (!values.birth_date) {
          setErrors({
            ...errors,
            birth_date: "Date of birth should be required.",
          });
        }
        if (ageDiff < 14) {
          setErrors({
            ...errors,
            birth_date: "You must be at least 14 years old to register.",
          });
        }
        else {
          let newObj = omit(errors, "birth_date");
          setErrors(newObj);
        }



        break;

      default:
        break;
    }
  };
  //OnChange validation
  const handleChange = (event) => {
    let name = event.target.name;
    let val = event.target.value;

    validate(name, event, val);
    setValues({
      ...values,
      [name]: val,
    });
  };


  const validateForm = errors => {
    let valid = true;
    Object.values(errors).forEach(
      val => val.length > 0 && (valid = false)
    );
    return valid;
  };

  const validateSubmiit = () => {
    let validationError = { ...errors };
    if (!values.email) {
      validationError.email = "Email address should be required";
    } else if (values.email !== "undefined") {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(values.email)) {
        validationError.email = "Please enter valid email address";
      }
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      validationError.email = "Email address is invalid";
    }
    const reg = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@#$%^&*]).{8,}$"
    );
    if (!values.password) {
      validationError.password = "Password is required";
    }
    else if (values.password.length < 8) {
      validationError.password = "Password must be 8 or more characters";
    }

    else if (!reg.test(values.password)) {
      validationError.password =
        "Password should be at least 8 characters containing uppercase, lowercase, number and special characters";
    }

    if (!values.cnfpassword) {
      validationError.cnfpassword = "Confirm Password is required";
    } else if (values.password !== values.cnfpassword) {
      validationError.cnfpassword = "Confirmed password is not matching with password";
    }

    if (!values.username) {
      validationError.username = "Username is required";
    } else if (values.username.length < 6) {
      validationError.username = "Username must be 6 or more charachters.";
    } else if (values.username !== "undefined") {
      const re = /^\S*$/;
      if (values.username.length < 6 || !re.test(values.username)) {
        validationError.username = "Please enter valid username.";
      }
    }



    const currentDate = new Date();
    const userDob = new Date(values.birth_date);

    const ageDiff = currentDate.getFullYear() - userDob.getFullYear();
    if (!values.birth_date) {
      validationError.birth_date = "Date of birth is required";
    }
    else if (ageDiff < 14) {
      validationError.birth_date = "You must be at least 14 years old to register."
    }

    setErrors(validationError);
    return validationError;
  };

  const validateFormOnSubmit = (values, value) => {

    let errors = {};

    if (!values.email) {
      errors.email = "Email address is required";
    } else if (values.email !== "undefined") {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(values.email)) {
        errors.email = "Please enter valid email address";
      }
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email address is invalid";
    }

    const reg = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@#$%^&*]).{8,}$"
    );

    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be 8 or more characters";
    }
    else if (!reg.test(values.password)) {
      errors.password =
        "Password should be at least 8 characters containing uppercase, lowercase, number and special characters";
    }

    if (!values.cnfpassword) {
      errors.cnfpassword = "Confirm Password is required";
    } else if (values.password !== values.cnfpassword) {
      errors.cnfpassword = "Confirmed password is not matching with password";
    }

    if (!values.username) {
      errors.username = "Username is required";
    } else if (values.username.length < 6) {
      errors.username = "Username must be 6 or more charachters.";
    } else if (values.username !== "undefined") {
      const re = /^\S*$/;
      if (values.username.length < 6 || !re.test(values.username)) {
        errors.username = "Please enter valid username.";
      }
    }


    const currentDate = new Date();
    const userDob = new Date(values.birth_date);

    const ageDiff = currentDate.getFullYear() - userDob.getFullYear();

    if (ageDiff < 14) {
      errors.birth_date = "You must be at least 14 years old to register."
    }

    return errors;
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();




    const validationError = validateSubmiit();
    if (validateForm(validationError)) {
      callback();

    } else {
      console.error("Invalid Form", errors);
    }
  };

  //Password
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [cnfpasswordShown, setCnfPasswordShown] = useState(false);
  const toggleCnfPasswordVisiblity = () => {
    setCnfPasswordShown(cnfpasswordShown ? false : true);
  };


  //Mobile number

  const handleMobile = (value, data, event) => {
    let name = event.target.name;
    let val = event.target.value;
    validate(name, event, val);
    setValues({ ...values, mobile: value });

  }
  const [countrycodes, setCountryCode] = useState();

  const handleCountryChange = (value, data, event) => {
    setValues({ mobile: value.replace(/[^0-9]+/g, '').slice(data.dialCode.length) })
  }
  const handleCountry = (value, data, event, formattedValue) => {
    let name = event.target.name;
    let val = event.target.value;
    const countryCode = data.dialCode;



    setCountryCode(countryCode);
    setValues({
      ...values,
      [name]: val,
    });


  }
  const [isValid, setValid] = useState(null);



  const valid = (value) => {
    let errors = {};
    if (!values) {
      errors.mobile = "Phone number is required";
    } else if (!values.length > 6) {
      errors.mobile = "Phone number is required";
    }
    return errors;
  };

  const checkboxForm = useRef();
  //Checkbox Checked
  const [agreement, setAgreement] = useState(true);

  const handleTerms = (event) => {
    let checkedValue = event.target.checked;
    setAgreement(!agreement);



    if (agreement === true) {
      setValues({ ...values, terms: '1' });
    }
    else {
      setValues({ ...values, terms: '' });
    }



  }

  return {
    values,
    errors,
    setValues,
    handleChange,
    handleSubmit,
    togglePasswordVisiblity,
    passwordShown,
    cnfpasswordShown,
    toggleCnfPasswordVisiblity,
    handlemobileChange, handleMobile,
    isValid, mob, setMob, handleCountry, handleCountryChange, handleTerms, agreement, checkboxForm
  };
};

export default RegisterForm;
