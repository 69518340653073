import Carousel from 'react-bootstrap/Carousel';
const FeaturedAds = () => {
    return (
        <>
                <div className="custom-card-header card-custom">
                    <div className="custom-carousel">
                        <div className='card-title text-center'>
                            <h4 className="card-label align-items-center custom-heading d-inline">FEATURED ADs</h4>
                        </div>
                        <div className='card-body'>
                            <Carousel>
                                <Carousel.Item>
                                    <div className='d-flex justify-content-evenly innter-carousel'>
                                        <img
                                            className="d-block w-100"
                                            src="/images/banner.png"
                                          
                                            alt="First slide"
                                        />
                                        <img
                                            className="d-block w-100"
                                            src="/images/FEATUREDads01.png"
                                            alt="First slide"
                                        />
                                        <img
                                            className="d-block w-100"
                                            src="/images/FEATUREDads01.png"
                                            alt="First slide"
                                        />
                                        <img
                                            className="d-block w-100"
                                            src="/images/FEATUREDads01.png"
                                            alt="First slide"
                                        />
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='d-flex justify-content-evenly innter-carousel'>
                                        <img
                                            className="d-block w-100"
                                            src="/images/FEATUREDads01.png"
                                            alt="First slide"
                                        />
                                        <img
                                            className="d-block w-100"
                                            src="/images/FEATUREDads01.png"
                                            alt="First slide"
                                        />
                                        <img
                                            className="d-block w-100"
                                            src="/images/FEATUREDads01.png"
                                            alt="First slide"
                                        />
                                        <img
                                            className="d-block w-100"
                                            src="/images/FEATUREDads01.png"
                                            alt="First slide"
                                        />
                                    </div>

                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='d-flex justify-content-evenly innter-carousel'>
                                        <img
                                            className="d-block w-100"
                                            src="/images/FEATUREDads01.png"
                                            alt="First slide"
                                        />
                                        <img
                                            className="d-block w-100"
                                            src="/images/FEATUREDads01.png"
                                            alt="First slide"
                                        />
                                        <img
                                            className="d-block w-100"
                                            src="/images/FEATUREDads01.png"
                                            alt="First slide"
                                        />
                                        <img
                                            className="d-block w-100"
                                            src="/images/FEATUREDads01.png"
                                            alt="First slide"
                                        />
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
           
        </>
    )
}

export default FeaturedAds