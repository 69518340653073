import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils';

const BattleComponent = ({ standardList, duelmode,  handleBattleEdit, anotherSymbolsList,  setreSet,  uniqueId, loading }) => {

  const avatarTemplate = (rowData) => {
    return (
      <p className="dark-blue fs-5  mb-0 flex-wrap justigy-content-start d-flex">{rowData.battle_name}</p>
    );
  };

  const buttonTemplate = (rowData) => {

    const battleIcon = anotherSymbolsList?.find(item => item?.battle_person_id === rowData.id)

    // console.log(battleIcon,"battleIconbattleIcon" , anotherSymbolsList);

    const stockClassName = classNames('widths', {
      'btn-success-battle text-white': rowData.id !== battleIcon?.battle_person_id,
    });
    const redClassName = classNames('widths', {
      'btn-danger-battle text-white': rowData.id === battleIcon?.battle_person_id,
    });
    const yellowClassName = classNames('widths', {
      'btn-warning-battle text-white': rowData.id === battleIcon?.battle_person_id,
    });
    return (
      <>
        {rowData.id === battleIcon?.battle_person_id && battleIcon?.started_join_unique_id === uniqueId
          ? <><button id={rowData.id} className={`disabled ${redClassName}`} >Add </button> </>
          : rowData.id === battleIcon?.battle_person_id && battleIcon?.started_join_unique_id !== uniqueId
            ? <><button id={rowData.id} className={`disabled ${yellowClassName}`} >Add </button> </>

            : <button id={rowData.id} className={`${stockClassName}`} onClick={() => { handleBattleEdit(rowData); setreSet(true) }} >Add </button>
        }

      </>
    )
  }



  const valueTemplate = (rowData) => {
    return (
      <>
        {duelmode === "Bloodbath & Battle"
          ? <p className="fs-5 mb-0 flex-wrap justify-content-start d-flex text-success ">{rowData.bloodbath_percentage}{rowData.bloodbath_percentage === "0" ? "" : "%"}</p>
          : <p className="fs-5 mb-0 flex-wrap justify-content-start d-flex text-danger"> {rowData.standard_percentage}{rowData.standard_percentage === "0" ? "" : "%"}</p>
        }

      </>
    )
  }

  return (
    <>
      <div className="card card-custom gutter-b ">
        <div className="custom-card-header">
          <div className='d-flex justify-content-end card-title mb-0'>
            <div className='mx-auto margin-battle'><h4 className='text-uppercase custom-heading'>Battle</h4></div>
          </div>

          <DataTable value={standardList}
            loading={loading}
            tableStyle={{ minHeight: '40.5rem' }}>
            <Column
              field="battle_name"
              scrollHeight="569px"
              scrollable
              body={avatarTemplate}
              header="Name"
            ></Column>

            <Column header="%" field={duelmode === "Bloodbath & Battle" ? 'bloodbath_percentage' : 'standard_percentage'} body={valueTemplate}></Column>
            <Column body={buttonTemplate}></Column>
          </DataTable>
          <div className='d-flex justify-content-between mb-3'>
            <button type="button" className="btn btn-danger-battle mx-2" style={{ fontWeight: 400 }}>
              Selected by me</button>
            <button type="button" className="btn btn-success-battle mx-2" style={{ fontWeight: 400 }}>
              Available</button>
            <button type="button" className="btn btn-warning-battle mx-2" style={{ fontWeight: 400 }}>
              Selected by other</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default BattleComponent